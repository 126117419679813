import { template } from "../templating";

export type TranslationsDE = typeof de;

const de = {
  abbreviations: {
    centimeter: {
      abbreviation: "cm",
      fullForm: "zentimeter",
    },
    kilogram: {
      abbreviation: "kg",
      fullForm: "Kilogramm",
    },
    kilometer: {
      abbreviation: "km",
      fullForm: "Kilometer",
    },
    kilometers: {
      abbreviation: "km",
      fullForm: "Kilometer",
    },
    sapv: {
      abbreviation: "SAPV",
      fullForm: "Spezialisierte ambulante Palliativversorgung",
    },
    sgb: {
      abbreviation: "SGB",
      fullForm: "Sozialgesetzbuch",
    },
    sgbV: {
      abbreviation: "SGB V",
      fullForm: "Das Fünfte Buch Sozialgesetzbuch",
    },
    sgbXI: {
      abbreviation: "SGB XI",
      fullForm: "Das Elfte Buch Sozialgesetzbuch",
    },
  },
  accessibility: {
    skipLink: "Zum Hauptinhalt springen",
  },
  acp: {
    accountRoleAccessTooltip:
      "Wenn ZUGRIFF ausgewählt ist, hat der Nutzer Zugriff auf die Partner-App, kann sich anmelden und die Anfragen bearbeiten.",
    accountRoleAdmissionsTooltip:
      "Wenn ADMISSIONS ausgewählt ist, hat der Benutzer Zugriff auf die Einstellungen der Recare Admissions Form",
    accountRoleAnalyticsTooltip:
      "Wenn ANALYTICS ausgewählt ist, hat der Nutzer Zugriff auf das Recare Analytics Dashboard in der Partner App. ",
    accountRoleContactTooltip:
      "Wenn KONTAKT ausgewählt ist, wird der Nutzer als Kontaktperson für die Ansprechperson im Krankenhaus angezeigt.",
    accountRoleVoiceTooltip:
      "Wenn VOICE ausgewählt ist, hat der Benutzer Zugriff auf die Recare Voice App",
    accountTimeline: {
      accountCreated: "Benutzerkonto wurde erstellt",
      accountDeactivated: "Account deaktiviert",
      accountReactivated: "Account reaktiviert",
      accountUpdated: "Benutzerkonto wurde aktualisiert",
      activationEmailSent: "Aktivierungs-E-Mail wurde gesendet",
      clinicStatusChanged: "Status der Klinik aktualisiert",
      domainAdded: (args: { domainname: string }) =>
        template("Domain {{domainname}} wurde zur Whitelist hinzugefügt", args),
      domainRemoved: (args: { domainname: string }) =>
        template(
          "Domain {{domainname}} wurde von der Whitelist entfernt ",
          args,
        ),
      login: "Benutzer hat sich angemeldet",
      passwordChange: "Passwort wurde geändert",
      passwordResetSuccessfully: "Passwort wurde erfolgreich zurückgesetzt",
      passwordSetByAdmin: "Passwort wurde festgelegt",
      permissionsUpdated: (args: { resource: string }) =>
        template(" Berechtigungen wurden aktualisiert: {{resource}}", args),
      resetPasswordRequestedByAdmin:
        "Passwort zurücksetzen-E-Mail wurde gesendet",
      resetPasswordRequestedByUser:
        '"Passwort vergessen": Passwort wurde vom Nutzer angefordert',
      ssoLogin: "Account hat sich über SSO angemeldet",
      title: "Aktivitäten",
    },
    acpAccountProfile: {
      activeAddons: "Aktive add-on Features",
      activeIntegrations: "Aktive Integrationen",
      addOnRcmReport: "Recare Analytics",
      addOnRcmReportPurchased: "Gekauft",
      addOnRecareAdmissions: "Recare Admissions",
      addOnRecareAdmissionsPurchased: "Gekauft",
      addOnRecareVoicePurchased: "Gekauft",
      addonTcare: "Übergangspflege",
      datesTcareTrial: (args: { fromDate: string; untilDate: string }) =>
        template("von {{fromDate}} bis {{untilDate}}", args),
      integrationArchive: "Archiv Integration",
      integrationInformationMessage:
        "Wenn Sie eine aktive Integration haben, wird diese hier angezeigt. Bitte beachten Sie, dass diese Informationen nur zu Ihrer Information dienen und automatisch ausgefüllt werden.",
      integrationReceiver: "Receiver Integration",
      integrationValue: {
        active: "aktiv",
        inactive: "inaktiv",
      },
      ssoInfo: "SSO Account",
      tcarePurchased: "Gekauft",
      tcareTrial: "Testphase",
    },
    acpCompany: {
      ikNumberAlreadyExists: (args: { companyId: string }) =>
        template(
          "IK-Nummer wird bereits für Einrichtung # {{companyId}} verwendet",
          args,
        ),
      ikNumberAlreadyExistsError: "Diese IK-Nummer ist bereits vorhanden",
      ikNumberAlreadyTaken: (args: { ikNumber: string; companyId: string }) =>
        template(
          "IK-Nummer {{ikNumber}} wird bereits für Einrichtung #{{companyId}} verwendet",
          args,
        ),
      messageIkNumber:
        "Da der Anbieter Teil einer Integration ist, kann die IK-Nummer nicht geändert werden - bitte wenden Sie sich an das Produktteam",
    },
    addAccount: "Konto hinzufügen",
    addAccounts: "Benutzerkonten hinzufügen",
    addHospital: "Krankenhaus hinzufügen",
    addListOfStation: "Liste der Stationen hinzufügen",
    addPriorityProvider: "Prio Nachversorger hinzufügen",
    addProvider: "Nachversorger hinzufügen",
    alreadyHasPermission: "Account hat dafür schon Berechtigungen",
    barthelIndex: {
      addNewAssessment: "Neue Beurteilung hinzufügen",
      bathing: "Baden/Duschen",
      bathingValueFive:
        "Selbständiges Baden oder Duschen inkl. Ein-/Ausstieg, sich reinigen und abtrocknen",
      date: "Erhoben am",
      deplacement: "Aufstehen und Gehen",
      deplacementValueFifteen:
        "Ohne Aufsicht oder personelle Hilfe vom Sitz in den Stand kommen und mindestens 50 m ohne Gehwagen (aber ggf. mit Stöcken/Gehstützen) gehen",
      deplacementValueFive:
        "Mit Laienhilfe oder Gehwagen vom Sitz in den Stand kommen und Strecken im Wohnbereich bewältigen. Alternativ: im Wohnbereich komplett selbständig im Rollstuhl",
      deplacementValueTen:
        "Ohne Aufsicht oder personelle Hilfe vom Sitz in den Stand kommen und mindestens 50 m mit Hilfe eines Gehwagens gehen",
      dialogTitle: "Barthel-Index",
      dressing: "An- und Auskleiden",
      dressingValueFive:
        "Kleidet mindestens den Oberkörper in angemessener Zeit selbständig an und aus, sofern die Utensilien in greifbarer Nähe sind",
      dressingValueTen:
        "Zieht sich in angemessener Zeit selbständig Tageskleidung, Schuhe (und ggf. benötigte Hilfsmittel z.B. Antithrombose-Strümpfe, Prothesen) an und aus",
      editHasData: "Detaillierte Beurteilung angeben",
      editHasNoData: "Details ansehen oder neue Beurteilung hinzufügen",
      fecalIncontinence: "Stuhlkontinenz",
      fecalIncontinenceValueFive:
        "Ist durchschnittlich nicht mehr als 1x/Woche stuhlinkontinent oder benötigt Hilfe bei rektalen Abführmaßnahmen / (AP)-Versorgung",
      fecalIncontinenceValueTen:
        "Ist stuhlkontinent, ggf. selbständig bei rektalen Abführmaßnahmen oder Anus-praeter (AP)-Versorgung",
      fecalIncontinenceValueZero:
        "Ist durchschnittlich mehr als 1x/Woche stuhlinkontinent",
      fieldsMissing: "Einige Angaben sind noch unvollständig",
      food: "Essen",
      foodValueFive:
        "Hilfe bei mundgerechter Vorbereitung, aber selbständiges Einnehmen oder Hilfe bei PEG-Beschickung/-Versorgung",
      foodValueTen:
        "Komplett selbständig oder selbständige PEG-Beschickung/-Versorgung",
      foodValueZero: "Kein selbständiges Einnehmen und keine MS/PEG-Ernährung",
      inputPlaceholder: "Summe und Datum der Beurteilung",
      maxAmountOfDetailsReached:
        "Es können maximal  vier Beurteilungen angelegt werden",
      personalCare: "Sich waschen",
      personalCareValueFive:
        "Vor Ort komplett selbständig inkl. Zähneputzen, Rasieren und Frisieren",
      relocating: "Aufsetzen und Umsetzen",
      relocatingFive:
        "Erhebliche Hilfe (geschulte Laienhilfe oder professionelle Hilfe)",
      relocatingValueFifteen:
        "Komplett selbständig aus liegender Position in (Roll-)Stuhl und zurück",
      relocatingValueTen:
        "Aufsicht oder geringe Hilfe (ungeschulte Laienhilfe)",
      relocatingZero: "Wird faktisch nicht aus dem Bett transferiert",
      stairClimbing: "Treppensteigen",
      stairClimbingValueFive:
        "Mit Aufsicht oder Laienhilfe mind. ein Stockwerk hinauf und hinunter",
      stairClimbingValueTen:
        "Ohne Aufsicht oder personelle Hilfe (ggf. inkl. Stöcken/Gehstützen) mindestens ein Stockwerk hinauf- und hinuntersteigen",
      toiletUse: "Toilettenbenutzung",
      toiletUseValueFive:
        "Vor Ort Hilfe oder Aufsicht bei Toiletten- oder Toilettenstuhlbenutzung oder deren Spülung / Reinigung erforderlich",
      toiletUseValueTen:
        "Vor Ort komplett selbständige Nutzung von Toilette oder Toilettenstuhl inkl. Spülung / Reinigung",
      toiletUseValueZero: "Benutzt faktisch weder Toilette noch Toilettenstuhl",
      uninaryIncontinence: "Harninkontinenz",
      uninaryIncontinenceValueFive:
        "Kompensiert seine Harninkontinenz selbständig und mit überwiegendem Erfolg (durchschnittlich nicht mehr als 1x/Tag Einnässen von Kleidung oder Bettwäsche) oder benötigt Hilfe bei der Versorgung seines Harnkathetersystems",
      uninaryIncontinenceValueTen:
        "Ist harnkontinent oder kompensiert seine Harninkontinenz / versorgt seinen Dauerkatheter (DK) komplett selbständig und mit Erfolg (kein Einnässen von Kleidung oder Bettwäsche)",
      uninaryIncontinenceValueZero:
        "Ist durchschnittlich mehr als 1x/Tag harninkontinent",
      valueZero: 'Erfüllt "5" nicht',
    },
    batchSendActivation: "Aktivierungs-E-Mail an alle erstellten Konten senden",
    billing: "Abrechnung",
    blacklistedAssessmentFields: "Blacklisted assessment fields",
    careproviderPages: {
      catchmentArea: "Einzugsgebiet",
      completeProfile: "Profil vervollständigen",
      completeProfileAccountsWarning:
        "Sobald das Profil vervollständigt und gespeichert ist erhalten neue Benutzerkonten eine Aktivierungs-Email um das Passwort festzulegen. Existierende Benutzerkonten werden zu dem Team des Nachversorgers hinzugefügt. Bitte stellen Sie sicher, dass die Daten korrekt sind.",
      dpaSection: {
        churchOwnedInfo: "Hat kirchlichen Träger:",
        currentDpaVersion: "Die aktuelle AVV-Version finden Sie {select:hier}",
        dpaUploadOnlyForChurch:
          "Bitte beachten: das Hochladen des AVV ist nur für Einrichtungen mit kirchlichem Träger notwendig, da wir hier eine unterschriebene Version benötigen. Wenn kein kirchlicher Träger vorhanden, müssen Sie die AVV in der App bestätigen.",
        noDpaUploadedYet: "AVV noch nicht hochgeladen",
        signedAt: "DPA unterschrieben am:",
        uploadedFile: "Hochgeladene Datei:",
      },
      duplicatedFrom: "Duplikat von",
      nearbyClinics: "Umliegende und Prio Kliniken",
      onboarding: {
        onboardingContact: "Onboarding-Kontakt",
      },
      stats: {
        acceptedRequests: "Angenommene Anfragen",
        activeSince: "Aktiv seit",
        allTime: "All time",
        attributedPatients: "Zugewiesene Patienten",
        messagesSent: "Nachrichten gesendet",
        onboardingSource: "Art des onboarding",
        pastDays: "30 Tage",
        receivedRequests: "Erhaltene Anfragen",
        requestsDeclined: "Abgelehnte Anfragen",
        responseRate: "Antwortrate",
        responseTime: "Antwortzeit",
        title: "Statistiken",
      },
      statusForm: {
        errorActiveAccountsLinked:
          "Bitte die verknüpften aktiven Konten aus dem Einrichtungsprofil entfernen (wenn veraltet), oder das Einrichtungsprofil manuell aktivieren (wenn die aktiven Konten weiterhin Zugriff haben sollten). Achtung: Bitte beachten, ob die aktiven Nutzerkonten auch mit weiteren Einrichtungsprofilen verknüpft sind und weiterhin Zugriff haben sollen (Kette) und in diesem Fall das Einrichtungsprofil manuell aktivieren.",
        errorInvalidStatus:
          'Der Status "Invalid" ist veraltet. Bitte nutzen Sie "Inaktiv" + eine Begründung.',
        errorMissingSpecializations:
          "Bitte fügen Sie die erforderlichen Fachabteilungen hinzu, bevor Sie den Status ändern können.",
        errorNoFacilitiesNoServices:
          'Bitte fügen Sie die erforderliche Art der Einrichtung und/oder Leistungen hinzu, bevor Sie den Status ändern können. Der Status "Trash" oder "Inactive" kann trotzdem gesetzt werden.',
        errorTransitionToPendingPassword:
          'Bitte füllen Sie das Profil inkl. Accounts aus und klicken auf den Button "Profil vervollständigen". Der Status ändert sich dann automatisch zu "Pending Password Activation".',
        onboardingStatus: "Onboarding-Status",
        reason: "Begründung",
        status: "Status",
      },
      statusUpdateErrorNoAccount:
        "Anbieter kann nicht auf den Status aktiv aktualisiert werden, keine aktiven Konten angehängt",
      tabs: {
        accounts: "Konten",
        capacity: "Kapazitäten",
        catchment: "Einzugsgebiet",
        profile: "Profil",
        services: "Leistungen",
        timeline: "Verlauf",
      },
    },
    careSeekerContacts: "Kontakte",
    clinics: "Krankenhäuser",
    confirmDeleteInvoice: (args: { invoiceReference: string }) =>
      template(
        "Sind Sie sich sicher, dass Sie Rechnung {{invoiceReference}} löschen möchten?",
        args,
      ),
    consultants: {
      consultantName: "Name des Beraters",
      createSubheading: "Berater erstellen",
      description: "Beschreibung",
      documentation: {
        events: {
          consultantCreated: {
            title: "Berater erstellt",
          },
          consultantFileCreated: {
            title: "Beraterdatei hochgeladen",
          },
          consultantFileDeleted: {
            title: "Beraterdatei Gelöscht",
          },
          consultantUpdated: {
            description: "Folgende Felder wurden aktualisiert:",
            title: "Berater aktualisiert",
          },
          noEvents: "Keine Veranstaltungen für diesen Berater",
        },
        title: "Dokumentation",
      },
      generalInformation: "Allgemeine Informationen",
      noRolesTooltip: "Sie haben keine Rollen, um Berater zu bearbeiten",
      openingHours: {
        description:
          'Wenn keine Öffnungszeiten angegeben werden, werden auf der Profilseite keine Öffnungszeiten angezeigt. Wenn für mindestens einen Tag die Öffnungszeiten angegeben werden, werden Öffnungszeiten angezeigt. Alle nicht ausgefüllten Tage werden dann als "Geschlossen" angezeigt.',
        errorMissingHours:
          "Öffnungszeiten können nicht leer sein, wenn die Beratungsstelle an diesem Tag geöffnet ist.",
        formattingHint:
          "Format: 8.00 - 18.00 oder 8.00 - 12.00 & 14.00 - 18.00",
        heading: "Öffnungszeiten",
        placeholder: (args: { day: string }) =>
          template("Öffnungszeiten für {{day}}", args),
      },
      projectSponsor: "Projektträger",
      searchPlaceholder: "Berater hinzufügen",
      subheading: "Beraterdaten aktualisieren",
      uploadFilesCardTitle: "Hochladen von Beraterprofilfotos",
    },
    copiedToClipboard: "Erfolgreich in die Zwischenablage kopiert",
    copyPasteStations: "Drei Excel Spalten kopieren und einfügen",
    copyReceiverAccountActivationLink: "Aktivierungslink (Empfänger) kopieren",
    copySelfonboardingLink: "Selbstanmeldungslink kopieren",
    copySenderAccountActivationLink: "Aktivierungslink (Sender) kopieren",
    createConsultant: "Beratungsangebot erstellen",
    createReceiver: "Empfänger erstellen",
    createSender: "Sender erstellen",
    deactivateAccountDetails:
      "Die einzige Möglichkeit, dieses Konto zu reaktivieren, ist über die Aktivierungs-E-Mail",
    deactivateAccountTitle: "Möchtest du dieses Konto wirklich deaktivieren?",
    digitalPatientRecord: "Digitale Patientenakte",
    emailAlreadyLinked:
      "Diese E-Mail-Adresse ist schon einem Benutzerkonto zugewiesen.",
    emailsSent: "E-Mails wurden versendet",
    errorAddingRole: (args: { emailDomain: string; list: string }) =>
      template(
        'Das Konto wurde jedoch hinzugefügt, da die Konto-E-Mail-Domain "{{emailDomain}}" nicht in dieser für Careseekers zugelassenen Domains [{{list}}] aufgeführt ist. Das Hinzufügen von Rollen wurde abgelehnt.',
        args,
      ),
    errorAddingSeekerRoles: (args: { emailDomain: string }) =>
      template(
        'Für diesen Careseeker können keine Rollen hinzugefügt werden, da die E-Mail-Domain "{{emailDomain}}" für dieses Konto nicht in den zulässigen E-Mail-Domains für diesen Absender ist.',
        args,
      ),
    errorOnboardingMailNearbyClinicMissing:
      "Infomail konnte nicht gesendet werden, keine umliegenden und/oder Prio-Kliniken vorhanden",
    excelImportAccounts: "Liste der Benutzerkonten hinzufügen",
    excelImportAccountsBadFormat:
      "Die Daten wurden nicht im richtigen Format eingegeben.",
    excelImportAccountsPlaceholder: "Excel-Liste kopieren und einfügen",
    excelImportAccountsRowError: (args: { row: string }) =>
      template(
        "Problem in Zeile {{row}}. Bitte auf Fehler überprüfen (z.B. E-Mail bereits vorhanden etc.)",
        args,
      ),
    failedToLoadReceiverRules: "Failed to load rules",
    filterBySocialWorker: "Nicht automatisch nach Mitarbeiter filtern",
    firstLogin: "Erste Login",
    generalInformation: "Allgemeine Informationen",
    hospitalConfiguration: "KH-spezifische Konfigurationen",
    hospitalFance: "Krankenhaus - Frankreich",
    hospitalInformation: "Informationen über das Krankenhaus",
    hospitalInterests: {
      details: "Weitere Kommentare",
      geriatrics: "Geriatrie",
      homeCare: "Homecare",
      kisIntegration: "KIS integration",
      medicalSupplies: "Hilfsmittel",
      rehab: "Reha",
      title: "Verkaufspotential: Interesse an weiteren Modulen",
      transportation: "Krankentransport",
    },
    hospitalProfile: "Profil",
    identities: "Krankenhäuser",
    inactiveClinics: "Inaktive Krankenhäuser",
    informationSystem: "KIS",
    institutionIdentifier: "Institutionskennzeichen",
    internalAccounts: "Internal Accounts",
    internalPermissions: "Interne Berechtigungen",
    internalRemarks: "Interne Bemerkungen",
    internetBrowser: "Browser",
    invitationEmailSent: (args: { account: string }) =>
      template("Einladungs-E-Mail wurde an {{account}} versendet", args),
    ipWhitelistingForLogins: "Allowed IP ranges for login",
    kisApiActivated: "KIS API activated",
    lastLogin: "Letzte Login",
    listedInbToCApp: "Wir möchten in der B2C App gelistet sein",
    login: {
      prefixPreprod: "Preprod",
      prefixProd: "Production",
      titlePreprod: "ACP Preprod",
      titleProd: "ACP Production ",
    },
    missingContactsData: "Fehlende Informationen müssen ausgefüllt werden",
    noOnboardingEmail: "Keine Onboarding-E-Mail vorhanden",
    notes: "Notizen",
    notifyActivationSent: "Aktivierungs-E-Mail wurde versendet",
    notifyPasswordResetSent: "Passwort zurücksetzen-E-Mail wurde versendet",
    numberOfBeds: "Bettenzahl",
    onboardingEmail: "Onboarding-E-Mail",
    onboardingRadius: "Onboarding Radius (km)",
    onboardingRequirements: "Onboarding Anforderungen",
    organisationProviders: "Bonuspunkte für eigene Nachversorger",
    pasteZipcodesButton: "Aus Zwischenablage einfügen",
    pasteZipcodesTooltip:
      'Um eine Liste einzufügen, kopiere (cmd+c) die PLZ im richtigen Format (z.B. 10999, 10559) und klicke auf "Aus Zwischenablage einfügen"',
    permissions: "Berechtigungen",
    priorityListActivationTarget: "Prio Nachversorger Aktivierungsziel",
    priorityProviders: "Prio Nachversorger",
    priorityProvidersList: {
      name: "Name",
      onboarding: "Onboarding Status",
      patientType: "Art",
      status: "Status",
    },
    profile: "Profil",
    providerSearch: {
      enterLocation: "Bitte geben Sie eine Adresse oder ein Krankenhaus ein",
      facility: "Einrichtung",
      manuallyAddedProviders: "Alle manuell hinzugefügten Anbieter anzeigen",
      manuallyAddedProvidersTitle: "Manuell hinzugefügte Nachversorger",
      numberOfProvidersWithStatus: (args: {
        numberOfProviders: string;
        status: string;
      }) =>
        template(
          "Es gibt {{numberOfProviders}}  Einrichtungen mit Status {{status}}.",
          args,
        ),
      patientType: "Art",
      rateOfAllProvidersInCertainAreawithFacility: (args: {
        rateOfFacilityProviders: string;
        facilities: string;
        radiusWithFacility: string;
      }) =>
        template(
          "Diese machen  {{rateOfFacilityProviders}}% aller Versorger der Art ({{facilities}}) in einem Radius von {{radiusWithFacility}}km aus.",
          args,
        ),
      rateOfAllProvidersInCertainAreawithService: (args: {
        rateOfServiceProvider: string;
        services: string;
        radiusWithService: string;
      }) =>
        template(
          "Diese machen {{rateOfServiceProvider}}% aller Versorger mit Leistungen ({{services}}) in einem Radius von {{radiusWithService}}km aus.",
          args,
        ),
      rateOfProviders: (args: {
        rateOfProviders: string;
        totalDescription: string;
        selectionRadius: string;
      }) =>
        template(
          "Diese machen {{rateOfProviders}}% aller Versorger ({{totalDescription}}) in einem Radius von {{selectionRadius}}km aus.",
          args,
        ),
      services: "Leistungen",
      showMoreButton: "mehr anzeigen",
    },
    recareAccounts: "Recare Benutzerkonten",
    receiverFilters: {
      careproviderId: "Receiver ID",
      careseekerId: "Careseeker ID",
      createdAt: "Created at",
      false: "false",
      filterId: "Filter ID",
      isWhitelist: "Is whitelist rule",
      productGroup: "Product group",
      searchPartialZipcode: "Search partial zipcode",
      true: "true",
    },
    receiverRules: "Receiver rules",
    resetGroups: {
      activeFiles: "Aktive Dateien",
      activePatients: "Aktive Patienten",
      activeRequests: "Aktive Anfragen",
      archived: "Archivierte Patienten, Anfragen und Dateien",
      columnCount: "Anzahl",
      columnLostSession: "Verlorene Session",
      errorText:
        "Leider haben Sie nicht die Rechte, Gruppen zurückzusetzen. Bitte kontaktieren Sie einen der folgenden Accounts, wenn Sie diese Gruppe zurücksetzen möchten:",
      menuResetGroup: "Encryption Group zurücksetzen",
      resetGroupsSubtitle: (args: { sessionNumber: string }) =>
        template(
          "Wenn Sie die Gruppe zurücksetzten, gehen alle nicht freigegebenen verschlüsselten Daten verloren, daher sollten sie dies nur tun, wenn Sie keine andere Möglichkeit haben. Die Gruppe muss den Zugang zu {{sessionNumber}} Sitzungen wiederherstellen. Die folgende Tabelle enthält die Anzahl der verlorenen Sitzungen.",
          args,
        ),
      resetGroupsTitle:
        "Sind Sie sicher, dass Sie die Gruppe zurücksetzen wollen?",
      successMessage: "Seald Gruppe erfolgreich zurückgesetzt.",
      understandButton: "Verstanden",
    },
    satisfactionScore: {
      dateLastScore: "Zuletzt aktualisiert:",
      submitSatisfactionScore: "Zufriedenheitswert angeben",
      title: "Kundenzufriedenheit",
    },
    searchAccounts: "Accounts suchen",
    searchClinics: "Krankenhaus suchen",
    searchProviders: "Nachversorger Umkreissuche",
    sendActivationMail: "Aktivierungs-E-Mail senden",
    senderPage: {
      bankAccountOwner: "Kontoinhaberin / Kontoinhaber",
      bankingInformationTitle: "Angaben zur Honorarabrechnung",
      bankName: "Geldinstitut (Name, Ort)",
      billingAddress: "Straße, Hausnummer, Postleitzahl, Ort",
      hospitalGerman: "Krankenhaus - Deutschland",
      hospitalNumber: "Institutionskennzeichen",
      iban: "IBAN",
      insurance: "Krankenversicherung",
    },
    senderRules: "Sender rules",
    senderType: "Auswahl der Absenderkategorie",
    sendInfoEmail: "Infomail senden",
    sendPasswordResetEmail: "Passwort zurücksetzen-E-Mail",
    sendWelcomeEmail: "Aktivierungs-E-Mail",
    sessionExpirationDuration: "User session expiration (in minutes)",
    startDate: "Startdatum",
    stationCode: "Station",
    stationDescription: "Beschreibung",
    stationPhoneNumber: "Stationsrufnummer",
    stations: "Liste der Stationen",
    test: "test",
    title: "Titel",
    transferPerMonth: "Anzahl der Überleitungen pro Monat",
    whitelistedHomeCareSpecializations:
      "Aktivierte Spezialisierungen für die häusliche Pflege",
    whitelistedHospitalSpecializations: "Aktivierte Hospital-Fachabteilungen",
    whitelistedRehaSpecializations: "Aktivierte Reha-Fachabteilungen",
    workshopDate: "Datum der Schulung",
  },
  actions: {
    abort: "Abbrechen",
    accept: "Annehmen",
    actions: "Aktionen",
    add: "Hinzufügen",
    addMember: "Mitarbeiter hinzufügen",
    addMoreInformation: "Mehr Informationen hinzufügen",
    addSearch: "Suche hinzufügen",
    applyFilters: "Filter anwenden",
    archiveButton: "Archiv",
    back: "Zurück",
    backToAnswers: "Zurück zu den Antworten",
    backToDashboard: "Zurück zum Dashboard",
    backToSearch: "Zurück zur Suche",
    book: "buch",
    bookTransport: "Transport buchen",
    cancel: "Abbrechen",
    chooseFile: "Wähle eine Datei",
    clear: "Löschen",
    clearDate: "Datum löschen",
    close: "Schließen",
    confirm: "Bestätigen",
    continueSearch: "Suche fortsetzen",
    create: "Erstellen",
    deactivate: "Deaktivieren",
    decline: "Ablehnen",
    delete: "Löschen",
    discard: "Verwerfen",
    dismiss: "Ablehnen",
    download: "Herunterladen",
    edit: "Bearbeiten",
    editPatientProfile: "Patientenprofil bearbeiten",
    expand: "Erweitern",
    finish: "Fertigstellen",
    giveAccess: "Freigeben",
    handoverInsurance: "An Kostenträger übergeben",
    import: "Importieren",
    invalid: "unzulässig",
    invalidTimeFormat: "Unzulässiges Zeitformat",
    learnMore: "Mehr erfahren",
    minimize: "Minimieren",
    missing: "Erforderlich",
    moreOptions: "Weitere Optionen",
    next: "Weiter",
    no: "Nein",
    notify: "Senden",
    pause: "Pausieren",
    print: "Drucken",
    printDashboard: "Übersicht Drucken",
    refresh: "Aktualisieren",
    reject: "Ablehnen",
    resend: "Erneut senden",
    resendRequest: "Anfrage erneut senden",
    reset: "Zurücksetzen",
    review: "Prüfen",
    revokeAccess: "Zugriff widerrufen",
    save: "Speichern",
    saveAndExit: "Speichern und zurück",
    saveChanges: "Änderungen speichern",
    saved: "Gespeichert",
    saving: "Speichert...",
    searchForMobile: "Nach ambulant suchen",
    seeDetails: "Detailansicht",
    select: "Auswählen",
    selectAnotherDate: "Anderes Datum wählen",
    selectFileFromComputer: "Dateien vom Computer",
    selectPlaceholder: "Bitte auswählen",
    send: "Senden",
    shareFile: "Teilen",
    shareFroms: "Antrag übermitteln",
    showResults: "Ergebnisse zeigen",
    skip: "Überspringen",
    start: "Starten",
    stopSearch: "Suche beenden",
    submit: "Senden",
    tryAgain: "Erneut versuchen",
    undo: "Rückgängig machen",
    undoActionCompletion:
      "Wir haben die Aktion rückgängig gemacht und die Anfrage hat ihren vorherigen Status angenommen.",
    undoConfirm: "Ihre Änderungen wurden rückgängig gemacht.",
    unknownId: "Fallnummer unbekannt",
    update: "Aktualisieren",
    uploadFile: "Hochladen",
    validate: "Annehmen",
    validateAndGrantAccess: "annehmen und Stammdaten freigeben",
    validationError: "Bitte füllen Sie alle Pflichtfelder aus",
    validationErrorMissingOrInvalid:
      "Manche Pflichtfelder sind nicht ausgefüllt oder sind ungültig",
    valueEmtpy: "noch nicht angegeben",
    yes: "Ja",
    youCanUpload: "Sie können eine Datei hochladen",
  },
  address: {
    address: "Adresse",
    alternativeSearchLocationCheckbox:
      "Patientenadresse entspricht nicht dem Ausgangspunkt der Suche ",
    anotherAddress: "Eine andere Adresse",
    city: "Ort",
    clinicAddress: "Klinikadresse",
    country: "Land",
    enterAddress: "Bitte geben Sie hier die Adresse ein",
    houseNumberBlockedIE:
      "Da Sie den Internet Explorer verwenden, müssen Sie die Hausnummer über den Chat mit dem Empfänger teilen. Bitte verwenden Sie einen aktuelleren Browser wie Google Chrome, Mozilla, Firefox oder Microsoft Edge, um die Hausnummer hier direkt hinzuzufügen.",
    invalidAddress: "Die angegebene Adresse ist ungültig",
    location: "Standort",
    locationOfPatient: "Ausgangspunkt der Suche",
    noNeedForAddress:
      "Sie müssen nur die Postleitzahl für diese Art der Überleitung eingeben.",
    number: "Hausnummer",
    patientAddress: "Patientenadresse",
    pickupAddress: "Abholadresse",
    pleaseAddHouseNumber:
      "Suchen Sie eine Adresse und vergessen Sie dabei nicht die Hausnummer.",
    street: "Straße",
    streetNumber: "Straße und Hausnummer",
    zipcode: "Postleitzahl",
    zipcodePlaceHolder: "Postleitzahl eingeben",
    zipcodeSelectPlaceholder: "Geben Sie eine oder mehrere Optionen ein",
  },
  assessments: {
    formValidation: {
      careDurationMissing: "Bitte wählen Sie Erwartete Pflegedauer aus",
      careDurationTitle: "Erwartete Behandlungsdauer *",
      characterLimit: (args: { valueLength: string; characterLimit: string }) =>
        template(
          "Die eingegebene Wertlänge ({{valueLength}}) ist größer als die Zeichenbegrenzung {{characterLimit}}",
          args,
        ),
      dateOfBirthMissing: "Bitte geben Sie das Geburtsdatum ein",
      dateOfBirthTitle: "Geburtsdatum *",
      genderMissing: "Bitte wählen Sie ein Geschlecht aus",
      mandatoryExplanation: "* markiert ein Pflichtfeld.",
      patientAddressMissing: "Bitte geben Sie die Patientenadresse ein",
      patientAddressTitle: "Patientenadresse *",
      publicPensionMissing:
        "Bitte wählen Sie die zutreffende gesetzliche Rentenversicherung aus",
      singleSelect: "Bitte wählen Sie eine der folgenden Optionen *",
      singleSelectError: "Bitte wählen Sie eine Option aus, um fortzufahren.\n",
      specialisationMissing: "Bitte wählen Sie mindestens eine Fachabteilung.",
      specializationsMissing: "Bitte wählen Sie eine Fachabteilung aus",
      specializationsTitle: "Fachabteilungen *",
      startDateMissing: "Bitte wählen Sie ein gültiges Datum in der Zukunft.",
      startDateTitle: "Startdatum *",
      supplementaryInsuranceDetailsMissing:
        "Bitte geben Sie die Details über die Zusatzversicherung an.",
      treatmentLocationMissing:
        "Bitte geben sie den Ausgangspunkt der Suche ein",
      treatmentLocationTitle: "Ausgangspunkt der Suche *",
      typeOfCareMissing:
        "Bitte wählen Sie mindestens eine Versorgungsform aus.",
      typeOfCareTitle: "Versorgungsform *",
    },
    htohinfobox:
      "Wir filtern Empfänger innerhalb des Suchradius um diese Postleitzahl. ",
    medicalSupplies: {
      deliveryAnotherAddress: "Versorgung an einer anderen Adresse",
      deliveryDetails: "Versorgungsadresse",
      deliveryHospital: "Versorgung im Krankenhaus",
      deliveryInfo:
        "Bitte beachten Sie, dass die gewünschte Versorgung an die Adresse gebunden ist, die Sie hier auswählen. Das gewünschte Versorgungsdatum haben Sie bereits ausgewählt. Wenn Sie eine Versorgung zu einem anderen Zeitpunkt und/oder an einer anderen Adresse haben möchten, können Sie dies in einer zusätzlichen Suche tun.",
      deliveryPatientsAddress: "Versorgung bei der Adresse des Patienten",
      medicalSupplies: "Hilfsmittel & Homecare",
      prescription: "Verordnung",
      prescriptionDoctor: "Name und Funktion des verordnenden Arztes",
      prescriptionIssued: "Verordnung wurde ausgestellt",
    },
    onePageAssessment: {
      activateOnePageAssessment: "Das einseitige Assessment aktivieren",
      additionalInformation: "Zusätzliche Informationen",
      caution: "Mit Vorsicht zu verwenden",
      disclaimer:
        "Diese Funktion befindet sich in der Entwicklung und sollte nur zu Testzwecken verwendet werden",
    },
    rehab: {
      singerUploadInfo:
        "Sie können den SINGER-Fragebogen als eine Dateikategorie im Bereich Datei-Upload hochladen.",
    },
    transportSearch: {
      additionalInformationPlaceholder: "zB. Besonderheiten",
      addTransportAfterValidatedSearchExplanation:
        "Ein Empfänger wurde gefunden. Sie haben nun die Möglichkeit den Transport des Patienten ebenfalls über Recare zu buchen. ",
      addTransportAfterValidatedSearchTitle:
        "Möchten Sie für diesen Patienten einen Transport buchen?",
      endTimePlaceholder: "09:00",
      minimumTimeWindow: "Mindestzeitfenster 1 Stunde",
      monitoringHeart: "EKG",
      monitoringNeeded: "Benötigte Patientenüberwachungsgeräte",
      monitoringPulse: "Pulsoximeter",
      onsiteSupportRequired:
        "Der Patient benötigt keine Betreuung oder eine Betreuung vor Ort ist sichergestellt.",
      oxygenNeeded: "Benötigt der Patient Sauerstoff?",
      pickUpTime: "Gewünschter Abholzeitraum ",
      searchDestination: "Zieladresse",
      startTimePlaceholder: "08:00",
      titleTransportSearch: "Transportsuche",
      transportStyle: "Transportart",
      transportStyleCarryingChair: "Tragestuhl",
      transportStyleLyingDown: "Liegend",
      transportStyleSeated: "Sitzend",
      transportStyleWheelchair: "Rollstuhl",
      transportType: "Transporttyp",
      visualImpairement: "Ist der Patient sehbehindert?",
    },
  },
  auctionRequest: {
    accept: "",
    acceptAnotherDateRequest:
      "Patientenanfrage mit alternativem Datum annehmen",
    acceptInfo:
      "Nach dem Klick auf ANNEHMEN können Sie Ihre angebotene Leistung bestätigen und ggf. das Startdatum anpassen",
    acceptInfoNewChat:
      "Bearbeiten Sie diese Anfrage (Aufnahmekriterien sind anpassbar)",
    acceptModalTitle: "",
    acceptOptionalMessagePlaceholder:
      "Geben Sie hier Ihre Nachricht ein (optional):",
    acceptRequest: "Patientenanfrage annehmen",
    acceptTestRequest: 'Schreiben Sie z.B. "Test" hier',
    accessEncryptedDataHint:
      "Fragen Sie die Freigabe der Stammdaten bei der Klinik an",
    answerTestRequest:
      "Um Ihre kostenlose Registrierung abzuschließen, klicken Sie bitte auf ANNEHMEN oder ABLEHNEN. Welche Antwort Sie auswählen, ist bei der Testanfrage unwichtig.",
    arrivalTime: "Ankunftszeit",
    automaticDeclineWithoutTime:
      "Wenn Sie ein Datum eingeben, wird im System hinterlegt, dass Sie bis dahin keine Kapazitäten für Neupatienten haben. Sie erhalten bis zu diesem Datum keine Anfragen (abgesehen von Anfragen basierend auf Ihren Patientensuchen). Wenn Sie kein Datum eingeben, wird nur diese Anfrage abgelehnt - Ihre aktuellen Kapazitätseinstellungen werden im System nicht geändert.",
    automaticDeclineWithTime:
      "Ihre Kapazitäten werden bis zum gewählten Datum blockiert, Sie erhalten keine weiteren Anfragen und alle Anfragen bis einschließlich des Datums werden abgelehnt. Sie können Ihre Kapazität in den Einstellungen jederzeit ändern",
    backToRequests: "ZURÜCK ZU DEN ANFRAGEN",
    bcpName: "Pflegefinder Bayern",
    bcpProfileName: "Profil",
    cancelConfirmation:
      "Sind Sie sich sicher, dass Sie diesen Schritt rückgängig machen wollen?",
    capacityDate: "Datum auswählen",
    capacityTime: "Zeit auswählen",
    capacityTimeOptional: "Zeit auswählen (optional)",
    catchmentComment:
      "Sobald es im System überprüft wurde, werden Ihre Anfragen angepasst.",
    commentWhichServicesOptional:
      "Optional: Welche Leistungen bieten Sie gerade nicht an?",
    coronaOnlyAcceptHint:
      "Wenn Sie diese Anfrage annehmen, wird dieser Patient Ihnen automatisch zugewiesen.",
    decline: "",
    declinedReasonOther: "Wir lehnen aufgrund anderer Gründe ab",
    declinedReasonPayer: "Wir lehnen aufgrund des Kostenträgers ab",
    destination: "Ziel",
    emptyMessageWarning: "Schreiben Sie eine Nachricht",
    encryptedMessage: "Verschlüsselte Nachricht",
    entryDate: "Datum",
    entryDateTimeExplainer:
      "Bitte wählen Sie ein mögliches Startdatum und die Ankunftszeit",
    entryDateTimeExplainerOptional:
      "Bitte wählen Sie ein mögliches Startdatum und die Ankunftszeit (optional)",
    errorAlert: {
      text: "",
      title: "",
    },
    explainOtherReason: "Bitte erläutern Sie",
    explainOtherReasonOptional: "Zusätzliche Kommentare (optional)",
    explainWhyYouDecline:
      "Bitte teilen Sie uns den Hauptgrund Ihrer Ablehnung mit",
    fakeData: "KEINE ECHTEN DATEN",
    geographicallyUnattractive:
      "Dieser Patient liegt nicht in unserem Einzugsgebiet",
    germanyWideSearchWarning:
      "Dies ist eine deutschlandweite Suche. Daher erhalten Sie diese Anfrage, auch wenn sie ggf. außerhalb Ihres Einzugsgebietes liegt.",
    hospitalName: "Name der Klinik",
    lastWorkedOn: (args: { time: string; personResponsible: string }) =>
      template("Zuletzt bearbeitet: {{time}}, {{personResponsible}}", args),
    leaveAComment: "Bitte hinterlassen Sie Ihre Nachricht hier:",
    loadingError: "Anfrage konnte nicht geladen werden",
    mapCaption:
      "Der Standort des Patienten liegt innerhalb des blauen Radius. Die Zieladresse liegt innerhalb des gelben Radius.",
    messengerTab: "Nachrichten",
    mostImportantTransferDetails: "Wichtigste Überleitungsdetails",
    noAdditionalInformationProvided:
      "Es wurden keine zusätzlichen Informationen angegeben.",
    noCapacity: "Wir haben derzeit gar keine verfügbaren Kapazitäten",
    noCapacityExplainer:
      "Recare möchte Ihnen keine unnötige Arbeit machen.\nBitte teilen Sie uns mit, ab wann Sie wieder neue Patienten aufnehmen können:",
    noCapacityForPatient:
      "Wir haben derzeit keine Kapazitäten für diesen bestimmten Patienten. Bitte senden Sie uns aber weitere Anfragen",
    noCapacityForPatientReason:
      "Wir bieten die benötigten Leistungen zurzeit nicht an",
    noCapacityHint:
      "Sie können dies jederzeit in Ihren “Einstellungen” aktualisieren.",
    noCapacityOther: "",
    noCapacityReason:
      "Wir haben zurzeit generell keine Kapazität für neue Patienten",
    noCapacityReasonExplainer:
      "Wann können Sie wieder neue Patienten aufnehmen?",
    noGermsInfections: "Derzeit liegt keine Infektion/Keimbesiedlung vor.",
    offeringMultipleSolutions:
      "Dieser Anbieter bietet mehrere Optionen an. Bitte wählen Sie eine aus, die den Bedürfnissen des Patienten am besten entspricht.",
    onSitePatientCareEnsured:
      "Der Patient benötigt keine Betreuung oder eine Betreuung vor Ort ist sichergestellt.",
    other: "Anderes",
    pageUnavailable: "Die Seite ist nicht mehr verfügbar.",
    patientAdmissionDetails: "Details zur Patientenaufnahme",
    patientInfoTab: "Patient",
    patientNoLongerAvailable:
      "Der Patient ist leider nicht mehr verfügbar, daher kann die Anfrage nicht mehr beantwortet werden.",
    patientRedacted: "Kommunikation ist nicht mehr möglich",
    patientUpdatedExplanation:
      "Das Patientenprofil wurde aktualisiert. Neu hinzugefügte Informationen sind grün markiert, gelöschte Informationen sind rot markiert.",
    pickUpAddress: "Abholadresse",
    pickUpInformation: "Abholinformationen",
    pickuptime: "Gewünschter Abholzeitraum",
    placeholderTimeField: "hh:mm",
    pleaseExplainOptional: "Bitte erläutern Sie (optional)",
    pleaseLogin: "Bitte melden Sie sich an",
    pleasePickAReason: "Bitte wählen Sie einen Hauptgrund aus:",
    preferredSelection: (args: { receiverTypeSingularPreferred: string }) =>
      template(
        "Sie wurden als {{receiverTypeSingularPreferred}} ausgewählt",
        args,
      ),
    preWaitingClinicAnswer: "Bitte hinterlassen Sie Ihre Nachricht:",
    providerNotes: {
      add: "Hinzufügen",
      typeHere: "Geben Sie hier Ihre Notiz über den Patienten ein...",
    },
    rehabSpecific: {
      acceptModalTitle: "Reservierung",
      acceptRequest: "Reservieren",
    },
    requestAccepted:
      'Sie haben die Anfrage angenommen.Sie finden Sie Ihre Anfrage unter dem Reiter "Offen".',
    requestAcceptedBedCapacity:
      "Sie haben die Anfrage angenommen. Ihre Bettenkapazität wurde um 1 verringert.",
    requestAcceptedInProgress:
      'Sie haben die Anfrage angenommen. Sie finden Ihre Anfrage unter dem Reiter "In Bearbeitung".',
    requestAnswered: (args: { socialWorker: string }) =>
      template("Ihre Nachricht wurde an {{socialWorker}} gesendet.", args),
    requestAutoValidated:
      "Der Patient wurde Ihnen zugewiesen. Sie finden die Anfrage im Reiter “Zugewiesen”.",
    requestDeclinedReasonNoCapacity: (args: { date: string }) =>
      template(
        "Sie haben die Anfrage abgelehnt. Wir senden Ihnen wieder Anfragen ab dem {{date}}.",
        args,
      ),
    requestDeclinedReasonOther:
      'Sie haben die Anfrage abgelehnt. Sie finden diese weiterhin unter dem Reiter "Abgelehnt".',
    requestDeclinedReasonOtherTab:
      'Sie haben die Anfrage abgelehnt. Sie finden diese weiterhin unter dem Reiter "Andere".',
    requestDeclinedReasonUnattractiveLocation:
      "Sie haben die Anfrage abgelehnt. Ihr Einzugsgebiet wurde aktualisiert.",
    reservationFeature: {
      date: "Datum",
      expiredEvent: "Reservierung abgelaufen",
      extendCTA: "Reservierungszeit verlängern +24h",
      reservationExtended: "Reservierungszeit wurde verlängert ",
      reservedEvent: (args: { expirationDate: string }) =>
        template("Platz reserviert bis {{expirationDate}}", args),
      time: "Uhrzeit",
      title: "Platz reserviert bis",
      tooltip:
        "Wenn die Klinik nicht bis zum angegebenen Datum antwortet, wird die Annahme automatisch rückgängig gemacht.",
    },
    reserveInfo:
      "Nach dem Klick auf RESERVIEREN können Sie Ihre angebotene Leistung bestätigen und ggf. das Startdatum anpassen",
    selectDeclineReason: "Wählen Sie Ihren Ablehnungsgrund aus ",
    selectFile: "Datei auswählen",
    sendMessage: "Nachricht senden",
    testPendingRequestTitle:
      "Bitte beantworten Sie diese Test-Anfrage. Egal was Sie auswählen, es wird der Klinik nicht übermittelt und dient lediglich dazu, Ihre Registrierung abzuschliessen :)",
    testPendingRequestTitleGeriatrics:
      "Bitte beantworten Sie diese Test-Anfrage.",
    testRequestHeader:
      "Dies ist nur eine TEST-Anfrage von Recare Solutions, um Ihre Registrierung abzuschliessen",
    testRequestHint:
      "Bei echten Anfragen können Sie Nachrichten auch vor Annahme oder Ablehnung austauschen.",
    time: "Uhr",
    tooltipSentByCostpayer: "Die Anfrage wurde vom Kostenträger gesendet",
    transportDate: "Reisedatum ",
    treatmentWithin: "Wir behandeln Patienten innerhalb",
    treatWithin: "Wir versorgen Patienten:",
    tryAgain:
      "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie Recare.",
    typeMessageHere: "Tippen Sie Ihre Nachricht hier ein",
    typeMessageHereOptional: "Nachricht eingeben (optional)",
    typeMessageNotPossible: "Kein Nachrichtenaustausch möglich",
    typeOfTransfer: "Art der Überleitung",
    undoAlert: "",
    uploadAFile: "Bitte laden Sie hier Ihre Datei hoch:",
    uploadDelete: "Datei löschen",
    uploadError: "Ein Fehler ist aufgetreten - bitte versuchen Sie es erneut.",
    uploadInProgress: "Ihre Datei wird hochgeladen...",
    uploadOk: "Ihre Datei wurde erfolgreich hochgeladen.",
    validateProvider: (args: { providerName: string }) =>
      template("Sie sind dabei, {{providerName}} zu validieren", args),
    validationDateChangeCare: (args: { date: string }) =>
      template(
        "Die Einrichtung kann den Patienten am {{date}} aufnehmen. Wenn Sie bestätigen, wird das geplante Startdatum der Versorgung im Patientenprofil automatisch aktualisiert.",
        args,
      ),
    validationDateChangeRehab: (args: { date: string }) =>
      template(
        "Die Klinik kann den Patienten am {{date}} aufnehmen. Wenn Sie diese bestätigen, wird das geplante Startdatum der Versorgung aktualisiert.",
        args,
      ),
    waitingClinicAnswer: "Ihre Nachricht wurde versendet",
    waitingClinicAnswerCaption:
      "Wurde Ihre Frage beantwortet, so können Sie die Anfrage entweder annehmen oder ablehnen",
    youMustLogin:
      "Sie müssen sich anmelden, um verschlüsselte Nachrichten einzusehen und zu versenden.",
    youMustLoginToAccept:
      "Um Anfragen anzunehmen oder Nachrichten zu versenden, müssen Sie angemeldet sein",
    youPreferredSelection: {
      care: "Sie wurden als präferierter Nachversorger ausgewählt",
      geriatrics: "Sie wurden als Wunschklinik ausgewählt",
      valdidateInfo: "Bitte antworten Sie dem Anbieter",
    },
    yourComment: "Ihre Nachricht",
  },
  auctionResponse: {
    accessDenied:
      "Sie haben keinen Zugriff auf diese Daten. Bitte kontaktieren Sie Recare für weitere Informationen.",
    accessDeniedError:
      "Leider ist ein Fehler aufgetreten. Recare wurde darüber informiert und kümmert sich darum.",
    accessDeniedPending:
      "Derzeit können diese Inhalte nicht angezeigt werden. Um den Nachrichtenverlauf wieder sehen zu können, muss sich eine der folgenden Personen in Recare anmelden:",
    addressingNeeds: "Passend zu den Patientenbedürfnissen bieten wir",
    answerQuestion: "Nachricht beantworten",
    areYouSureNotAddInfo:
      "Sind Sie sich sicher, dass Sie keine weiteren Informationen angeben möchten?",
    areYouSureResendRequest:
      "Sind Sie sich sicher, dass Sie die Anfrage erneut senden möchten?",
    decline: "Nachversorger ablehnen",
    declined: "Nachversorger hat diese Anfrage abgelehnt",
    declinedReasonNoCapacity: (args: { date: string }) =>
      template("Wir nehmen erst ab dem {{date}} wieder Patienten auf", args),
    declinedReasonNoCapacityForPatient:
      "Wir haben derzeit keine Kapazitäten für diesen Patienten",
    declinedReasonNoCapacityForPatientUntilDate: (args: { date: string }) =>
      template(
        "Wir haben derzeit keine Kapazitäten für diesen Patienten bis zum {{date}}",
        args,
      ),
    declinedReasonNoCapacityNoDate: "Wir haben derzeit keine Kapazität",
    declinedReasonOther: (args: { reasonDescription: string }) =>
      template("Ablehnungsgrund: {{reasonDescription}}", args),
    declinedReasonPayer: "Wir lehnen aufgrund des Kostenträgers ab",
    declinedReasonUnattractiveLocation:
      "Der angefragte Standort liegt nicht in unserem Einzugsgebiet",
    feedbackExplainer:
      "Bitte beachten Sie, dass Ihr Feedback nur intern an Recare weitergeleitet wird und Ihre Rückmeldung nicht dem Versorger angezeigt wird.",
    feedbackReceived: "Vielen Dank, wir haben Ihr Feedback erhalten!",
    fileName: "Name der Datei",
    furtherServices: "Weitere Leistungen",
    genderOfCaregiver: "Geschlecht der Pflegekräfte",
    giveAccess: "Freigeben",
    giveAccessEncryptedData: "Stammdaten freigeben",
    giveProviderFeedback: "Geben Sie uns Feedback zu diesem Versorger!",
    internalNotes: "Interne Notizen",
    noCapacityReasons: {
      femaleRoom: "Frauenbett",
      maleRoom: "Männerbett",
      noCapacityFor: "Wir haben keine Kapazitäten für",
      noCapacityForA: "Wir haben kein freies",
      reasonCancelDeclination:
        "Automatische Nachricht: Wir haben jetzt wieder Kapazität und können Patienten aufnehmen.",
      treatmentOfPatientsWith: "Aufnahme von Patienten mit",
      treatmentOfPatientsWithCarelevel: "Aufnahme von Patienten mit Pflegegrad",
      treatmentOfPatientsWithoutCarelevel:
        "Aufnahme von Patienten ohne Pflegegrad",
      weDontTakePatientsWithCarelevel:
        "Wir nehmen keine Patienten mit Pflegegrad",
      weDontTakePatientsWithoutCarelevel:
        "Wir nehmen keine Patienten ohne Pflegegrad",
    },
    notEncryptedTooltip:
      "Übermitteln Sie keine sensible Patientendaten. Die Nachrichten sind nicht verschlüsselt.",
    offeringSolutions: "Sie bieten die folgende(n) Lösung(en):",
    otherSpecialisations: "Weitere Fachbereiche",
    possibleEntryDate: (args: { date: string }) =>
      template("Möglicher Aufnahmetermin: {{date}}", args),
    possibleEntryDateInfo: "Möglicher Aufnahmetermin:  ",
    possibleEntryTime: "Mögliche Eintrittszeit: ",
    preDeclined: (args: { providerName: string }) =>
      template("Sie werden {{providerName}} ablehnen", args),
    preDeclinedDocumentationSubtitle:
      "Fügen Sie Ihre Ablehnungsgründe der Dokumentation hinzu:",
    preDeclinedHint: "Nachricht hinterlassen (optional)",
    preDeclinedMessageSubtitle:
      "Teilen Sie dem Nachversorger Ihre Ablehnungsgründe mit:",
    preDeclinedPlaceholder: "Grund angeben (optional)",
    preDeclinedPlaceholderLabel:
      "Teilen Sie dem Anbieter Ihre Ablehnungsgründe mit (optional):",
    preDismissHint: "Erläutern Sie Ihre Begründung hier",
    preDismissTitle: "Sie möchten die vorgeschlagene Wunschklinik ablehnen",
    preSendRequestHint: "Hinterlassen Sie eine Notiz (optional)",
    preSendRequestTitle: "Hier können Sie ihre Entscheidung begründen",
    preValidate: (args: { providerName: string }) =>
      template("Sie werden {{providerName}} annehmen", args),
    preValidateHint: "Nachricht hinterlassen (optional)",
    preWaitingProviderAnswerHint: "Nachricht eingeben",
    providerProfile: "Anbieter",
    recipientHint: "",
    requestReservedAnotherProvider:
      "Anfrage wurde von einer anderen Einrichtung reserviert",
    reviewSuggestionTitle:
      "Überprüfen Sie die Wunschklinik und entscheiden Sie, ob eine Anfrage versendet werden soll.",
    roomType: "Zimmertyp",
    send: "Senden",
    sendEncryptedMessage: "Verschlüsselte Nachricht senden",
    sendEncryptedMessageHint:
      "Sensible Patientendaten können sicher ausgetauscht werden. Alle Nachrichten sind verschlüsselt.",
    sendEncryptedMessageTitle: "Sie können eine Nachricht senden",
    sendFaxHint:
      "Da die Einrichtung nicht auf Recare aktiv ist, versuchen eine Anfrage per Fax an diese Einrichtung zu senden, sofern uns die Faxnummer vorliegt.",
    sendFaxTitle: "Anfrage wird per Fax versendet",
    sendFeedback: "An Recare senden",
    sendFile: "Datei senden",
    sendFileTitle: "Sie können eine Datei senden.",
    sendMessage: "Nachricht senden",
    sendMessageHint: "Tippen Sie Ihre Nachricht hier ein",
    sendMessageTitle:
      "Sie können eine Nachricht senden, bevor Sie annehmen oder ablehnen",
    spokenLanguages: "Sprachen der Pflegekräfte",
    startDate: "Mögliches Startdatum",
    toggleShowMap: "Karte anzeigen",
    toggleShowPhotos: "Fotos anzeigen",
    toggleShowPictures: "Bilder anzeigen",
    tooltipDisabledValidate:
      "Um die Klinik anzunehmen, überprüfen Sie bitte zunächst die von der Klinik vorgeschlagene(n) Wunschklinik(en)",
    waitingClinicAnswer:
      "Mehr Information wurde angefordert. Bitte antworten Sie:",
    waitingForReview: "Die Anfrage wird vom Kostenträger geprüft",
    warningMessage: (args: { providerName: string }) =>
      template(
        "Sind Sie sicher, dass Sie {{providerName}} die folgenden Stammdaten freigeben möchten?",
        args,
      ),
    writeFeedbackTitle:
      "Bitte teilen Sie uns hier Ihr Feedback zu diesem Versorger mit:",
  },
  bToC: {
    disclaimer:
      "Alle Informationen werden durch den Versorgungsanbieter oder das Beratungsangebot selbst bereitgestellt. Recare, als Anbieter des Weser Ems Portals, übernimmt keine Garantie für die Richtigkeit der Angaben.",
    emailAddress: "info@recaresolutions.com",
    emailReportProvider: "Anbieter melden",
    filterDrawer: {
      age: "Alter der zu versorgenden Person",
      ageRangePlaceholder: "Altersintervall ausfüllen",
      capacityFilterTitle: "Verfügbarkeit",
      chipShortTermStaticCare: "Kurzzeitpflege",
      chipStaticCare: "Vollstationäre Pflege",
      furtherFilters: "Weitere Filter",
      intensiveCare: "Intensivpflege",
      palliativeCare: "Palliativpflege",
      psychiatricCare: "Psychiatrische Pflege",
      searchRadius: "Suchradius",
      searchRadiusPlaceholder: "Ort eingeben",
      serviceMobile: "Leistungen",
      shortTermsStaticCare: " Vorübergehende Pflege im Heim (Kurzzeitpflege)",
      showFreeCapacity: "Nur freie Plätze anzeigen",
      showResults: "Ergebnisse anzeigen",
      solution: "Art der Pflege",
      solutionMobile: "Amb. Versorgungsarten",
      solutionStatic: "Stat. Versorungsarten",
      startDate: "Start der Versorgung",
      startDatePlaceholder: "Startdatum auswählen",
      staticCare: "Langzeitpflege im Heim (Vollstationäre Pflege)",
      titleFilters: "Filter",
      treatmentLocation: "Ort",
    },
    footer: {
      aboutRecare: "Über Recare",
      imprint: "Impressum",
      privacyPolicy: "Datenschutz",
      support: "Hilfe",
      website: "Website",
    },
    getStartedButton: "Los geht's!",
    linkToSPN:
      "Für weitere Beratung, wenden Sie sich bitte an den {select:Senioren- und Pflegestützpunkt}",
    navbar: {
      title: "Pflegeportal Weser-Ems",
    },
    noResultsWithState: (args: { state: string }) =>
      template(
        "Keine Ergebnisse gefunden. Bitte geben Sie für Ihre Suche eine gültige Postleitzahl oder einen gültigen Ort innerhalb von {{state}} ein",
        args,
      ),
    onboardingScreens: {
      buttonAsap: "So bald wie möglich",
      buttonAtHome: "Zuhause",
      buttonBasicCare: "Grundpflege",
      buttonBasicMedicalCare: "Behandlungspflege",
      buttonCareHome: "In einem Heim",
      buttonDailyLivingSupportServices: "Angebote zur Unterstützung im Alltag",
      buttonDontKnow: "Weiß ich noch nicht",
      buttonForMyself: "Für mich",
      buttonForSomeoneElse: "Andere Person",
      buttonHelpAtHome: "Hauswirtschaftliche Versorgung",
      buttonInDays: "In ein paar Tagen",
      buttonInMonths: "In ein paar Monaten",
      buttonInWeeks: "In ein paar Wochen",
      buttonLongterm: "Langfristig",
      buttonSkip: "Überspringen",
      buttonTemporary: "Vorübergehend",
      dailyLivingSupportServices: {
        pageContent: {
          bannerInformation:
            "Dann wenden Sie sich gerne an Ihren {select:Senioren- und Pflegestützpunkt}, an Ihre zuständige Pflegekasse oder nutzen Sie selbst die Suchfunktion der Pflegekassen.",
          bannerSubtitle: "Suchangebote der Pflegekassen:",
          bannerTitle:
            "Sie sind auf der Suche nach Angeboten zur Unterstützung im Alltag?",
          bulletEight: "Leistungen der Angebote zur Unterstützung im Alltag",
          bulletFive: "Tages- und Nachtpflege",
          bulletFour:
            "Hauswirtschaftliche Dienstleistungen im unmittelbaren Umfeld der Pflegebedürftigen",
          bulletOne:
            "Betreuung und Beaufsichtigung (in Einzel- oder Gruppenbetreuung)",
          bulletSeven: "Leistungen ambulanter Dienste im Sinne des § 36 SGB XI",
          bulletSix: "Kurzzeitpflege",
          bulletThree: "Pflegebegleitung für die Angehörigen",
          bulletTwo: "Alltagsbegleitung der Pflegebedürftigen",
          hintText:
            "Hinweis: Sie verlassen die Seite des Pflegeportals Weser-Ems und werden an eine externe Seite weitergeleitet.",
          linkAok: "AOK-Pflegenavigator",
          linkBkk: "BKK PflegeFinder",
          linkVdek: "vdek-Pflegelotse",
          paragraphFive:
            "Nähere Informationen erhalten Sie bei dem {select:Niedersächsischen Ministerium für Soziales, Arbeit, Gesundheit und Gleichstellung}.",
          paragraphFour:
            "Alle Menschen, bei denen eine Pflegebedürftigkeit festgestellt wurde und, die noch zuhause wohnen, haben nach § 45 b SGB XI Anspruch auf einen Entlastungsbeitrag von 125 Euro monatlich. Der Entlastungsbeitrag kann eingesetzt werden für:",
          paragraphOne:
            "Wird bei einem Menschen eine Pflegebedürftigkeit nach dem Sozialgesetzbuch XI festgestellt, ist eine vollständige Eigenversorgung des Pflegebedürftigen nicht mehr gewährleistet. ",
          paragraphThree:
            "Die Angebote zur Unterstützung im Alltag (AZUA) können einen Baustein in der Versorgung des pflegebedürftigen Menschen und seinen/ ihren Angehörigen darstellen, um im Alltag zu unterstützen. Angebote zur Unterstützung im Alltag sind jedoch keine Pflegeleistungen. Angebote zur Unterstützung im Alltag können z.B. sein:",
          paragraphTwo:
            "Der Pflegebedürftige benötigt Hilfestellungen von bspw. An- und Zugehörigen, nahestehenden Personen oder dem ambulanten Pflegedienst im Alltag, um in der eigenen Häuslichkeit verbleiben zu können.",
        },
        title: "Angebote zur Unterstützung im Alltag",
      },
      hintZipcodeOrCity: "Postleitzahl oder Stadt",
      infoMobileCare: {
        infoBasicCare:
          "Umfasst Leistungen der Pflegeversicherung für pflegebedürftige Menschen. Dazu gehören Körperpflege, Ernährung, Mobilität, Vorbeugung (Prophylaxen), die Förderung von Eigenständigkeit und Kommunikation.",
        infoBasicMedicalCare:
          "Umfasst alle Tätigkeiten, die auf ärztliche Verordnung durchgeführt werden. Darunter fallen beispielsweise die Wundversorgung, der Verbandwechsel, die Medikamentengabe oder die Blutdruck- und Blutzuckermessung.",
        infoDailyLivingSupportServices:
          "Umfasst Unterstützung und Entlastung Pflegebedürftiger und Angehöriger bei der Bewältigung Ihres Alltags. Zu den Angebotsformen zählen unter anderem Einzelbetreuung, Gruppenbetreuung, Entlastung von Pflegenden, Entlastung im Alltag durch individuelle Hilfen, Entlastung im Alltag bei der Haushaltsführung.",
        infoHelpAtHome:
          "Umfasst unter anderem Tätigkeiten wie das Einkaufen, das Kochen von Mahlzeiten, die Reinigung der Wohnung, Geschirrspülen sowie das Wechseln und Waschen von Kleidung.",
        title: "Welcher Pflegetyp beinhaltet welche Leistungen?",
      },
      placeholderZipcodeOrCity:
        "Geben Sie eine Postleitzahl oder eine Stadt ein",
      subtitleWelcome:
        "Wir führen Sie durch die nächsten Schritte, um ein passendes Pflegeangebot zu finden.",
      titleForWhom: "Für wen suchen Sie einen Pflegeplatz?",
      titleLoadingScreen: "Vielen Dank! Wir generieren die Ergebnisse…",
      titleTemporaryOrLongterm:
        "Suchen Sie nach einem vorübergehenden oder langfristigen Pflegeplatz?",
      titleWelcomeWeserEms: "Willkommen zum Pflegeportal der Weser-Ems Region",
      titleWhatMobileCare: "Welche Art von Versorgung benötigen Sie?",
      titleWhenStartDate: "Ab wann suchen Sie einen Pflegeplatz?",
      titleWhereToSearch: "Wo suchen Sie nach einer Pflege oder Betreuung?",
      titleWhichCare: "Wo soll die Pflege stattfinden?",
      yourChoice: "Ihre Auswahl",
    },
    preprodLogin: "Anmelden",
    providerDetailPage: {
      additionalMaterials: "Zusätzliche Info-Materialien",
      address: "Adresse",
      capacityavailable: "Freie Kapazität",
      capacityOnRequest: "Kapazität auf Anfrage",
      contactDetails: "Kontaktdaten",
      fax: "Fax:",
      hasCapacity: "Freie Plätze vorhanden",
      lastUpdated: "Zuletzt aktualisiert:",
      noCapacity: "Keine freien Plätze vorhanden bis:",
      noCapacityavailable: "Keine Kapazität",
      noCapacityShort: "Keine Kapazität",
      noCapacityUpdateYet: "Zuletzt aktualisiert: keine Angabe",
      reportProvider: "Diesen Anbieter melden",
      services: "Leistungen",
      telephone: "Tel:",
      tooltipCapacity:
        "Kapazitäten können sich jederzeit ändern. Bitte kontaktieren Sie die Einrichtung für mehr Informationen.",
      unknownCapacity: "Keine Informationen über freie Plätze vorhanden",
      watermarkTextExampleImage: "Beispielbild",
    },
    providerList: {
      away: "entfernt",
      ctaNudgeGotIt: "Alles klar",
      errorBadFilters:
        "Sie verwenden eine veraltete Version der App, die nicht alle Funktionalität unterstützt. Bitte upgraden Sie die App.",
      filterChipFreeCapacity: "Freie Plätze",
      filtersButton: "Filter",
      hintNudge:
        "Hier können Sie die Ergebnisse verfeinern, indem Sie Filter setzen.",
      infoNoResults: "Keine Ergebnisse",
      showMoreButton: "Mehr anzeigen",
      solutions: {
        helpAtHome: "Hausw. Versorgung",
        mobileCare: "Ambulante Pflege",
        shortTermStaticCare: "Kurzzeitpflege",
        staticCare: "Stationäre Pflege",
      },
      title: "Liste an Einrichtungen",
      titleWithCount: (args: { count: string }) =>
        template("Liste an Einrichtungen ({{count}})", args),
    },
    reset: {
      buttonResetResults: "Ergebnisse zurücksetzen",
      cancelButton: "Abbrechen",
      resetButton: "Zurücksetzen",
      subtitleModal:
        "Die aktuelle Liste der Einrichtungen geht dabei verloren. Sie werden erneut durch die ersten Schritte geleitet, um die Filter neu einzurichten.",
      titleModal:
        "Sind Sie sicher, dass Sie die Ergebnisse zurücksetzen wollen?",
    },
  },
  capacityDetailPage: {
    bedsAvailableTotal: (args: { availableBedsOfProvider: string }) =>
      template("{{availableBedsOfProvider}} Bett/en verfügbar", args),
    unknownTooltip:
      "Die Information ist nicht auf dem neuesten Stand. Trotzdem werden Ihre Anfragen weiterhin an diese Einrichtung geschickt. Bitte wenden Sie sich direkt an diese Einrichtung, um sich über die tatsächlich vorhandenen Kapazitäten zu informieren.",
  },
  capacitySearch: {
    clearFilters: "Löschen",
    onlyAvailableProviders:
      "Nur Einrichtungen mit verfügbaren Betten anzeigen, die den ausgewählten Kriterien entsprechen",
    placeholderDefaultSearch: (args: {
      totalCountOfProviders: string;
      totalAmountOfBeds: string;
    }) =>
      template(
        "Es gibt {{totalCountOfProviders}} Einrichtung/en auf der Plattform - {{totalAmountOfBeds}} Bett/en",
        args,
      ),
    placeholderModule: "Modul/e auswählen",
    placeholderNoResults: "Keine Ergebnisse",
    placeholderRadius: "Suchradius eingeben",
    placeholderServices: "Leistung/en auswählen",
    placeholderZipcode: "PLZ eingeben",
    table: {
      availableBeds: (args: { availableBedsOfProvider: string }) =>
        template(
          "Betten verfügbar ({select:{{availableBedsOfProvider}}})",
          args,
        ),
      bedsAvailable: (args: { availableBedsOfProvider: string }) =>
        template(
          "Betten verfügbar: {select:{{availableBedsOfProvider}}}",
          args,
        ),
      distance: "Entfernung",
      lastUpdate: "Zuletzt aktualisiert",
      module: "Modul",
      moreDetails: "Mehr Details",
      provider: (args: { amountOfFilteredProviders: string }) =>
        template("Einrichtung ({select:{{amountOfFilteredProviders}}})", args),
    },
    title: "Finden Sie Einrichtungen in der Nähe",
    unknownBedcount: "Unbekannt",
  },
  careprovider: {
    createCareprovider: "Neuen Nachversorger erstellen",
    createProviderError: "Fehler - bitte kontaktieren Sie Recare",
    providerNotAvailable: "Versorger nicht verfügbar",
  },
  careproviderAccount: {
    centralEmail:
      "Möchten Sie Anfragen an eine zentrale E-Mail-Adresse erhalten?\nDann klicken Sie bitte auf {select:E-Mail-Einstellungen}.",
    changePassword: "Passwort ändern",
    hintPersonalNotification:
      "Möchten Sie Anfragen an diese E-Mail erhalten? {select:Klicken Sie hier} um Ihre Einstellungen zu verwalten.",
    hintWeightSetting:
      "Sie werden möglicherweise auch Anfragen für Patienten erhalten, die bis zu 9 kg mehr wiegen als die von Ihnen gewählte Gewichtsgrenze.",
    receiveDailyDigest:
      "Eine tägliche Zusammenfassung der Anfragen erhalten (keine weiteren E-Mails)",
    receiveRequests: "Pro Anfrage eine E-Mail erhalten",
    receiveRequestsSMS: "Pro Anfrage eine SMS erhalten",
    saved: "Die Informationen wurden gespeichert",
    title: "Mein Benutzerkonto",
    titlePersonalDetails: "Persönliche Angaben",
  },
  careproviderApp: {
    accountMigration: {
      addMembers: "Mitarbeiter hinzufügen",
      addMembersBanner:
        "Laden Sie Ihre Kollegen ein, um gemeinsam Patientenanfragen zu bearbeiten.",
      completeRegistration:
        "Bitte überprüfen Sie Ihren E-Mail-Posteingang und folgen Sie den Anweisungen, um Ihre Konto-Bestätigung abzuschliessen.",
      completeRegistrationInfo:
        'Falls Sie keine E-Mail erhalten haben, klicken Sie auf "E-Mail erneut senden". Falls Ihre angegebene E-Mail-Adresse fehlerhaft ist, kontaktieren Sie uns unter der Rufnummer +49 30 56 79 60 57.',
      createAccountBanner:
        "Zum 01.02.2020 ist die Nutzung von Recare nur noch mit einem persönlichen Benutzerkonto möglich. Erstellen Sie jetzt Ihr kostenfreies Konto!",
      createAccountModal:
        "Bestätigen Sie Ihr Benutzerkonto, um vollen Zugang auf alle neuen Funktionen zu erhalten - kostenlos!",
      createAccountSubtitle:
        "Die Nutzung von Recare nur noch mit einem bestätigten Benutzerkonto möglich.",
      finishSignupBanner:
        "Fast geschafft! Um Ihre Konto-Bestätigung abzuschließen, folgen Sie den Anweisungen in der E-Mail. Keine E-Mail erhalten? Kontaktieren Sie uns unter +49 30 56 79 60 57.",
      finishSignupModal: {
        subtitle:
          'Um Zugriff auf die Empfehlungen zu erhalten, müssen Sie Ihre Registrierung abschließen. Folgen Sie den Anweisungen in der E-Mail "Willkommen bei Recare". Falls Sie diese nicht empfangen haben, melden Sie sich bei uns unter der +49 30 56 79 60 57.',
        subtitleReminder:
          'Um Recare nutzen zu können, müssen Sie zuerst Ihr neues Benutzerkonto bestätigen. Folgen Sie den Anweisungen in der E-Mail "Willkommen bei Recare". Falls Sie diese nicht empfangen haben, melden Sie sich bei uns unter der +49 30 56 79 60 57 oder klicken auf "E-Mail erneut senden", um diese nochmals zu erhalten. Schließen Sie die Seite, bevor Sie den Link in der erhaltenen E-Mail öffnen. Recare bleibt weiterhin für Sie kostenlos.',
        title: "Fast geschafft!",
      },
      inviteContactsPopup: {
        footer:
          "Nachdem Sie Ihre Kollegen hinzugefügt haben, erhalten diese eine Einladung per E-Mail, um Ihr Konto einzurichten.",
        invite: "Einladen",
        skip: "Überspringen",
        subtitle:
          "Laden Sie Ihre Kollegen ein, um gemeinsam Patientenanfragen zu bearbeiten.",
        title: "Laden Sie Ihre Kollegen ein!",
      },
      resendSignupEmail: "E-Mail erneut senden",
      signUp: "Registrieren",
      signupEmailResent: "Die E-Mail wurde erfolgreich versendet!",
      signupPopup: {
        administration: {
          title: "Benutzerverwaltung:",
        },
        benefitTitle: "Diese Funktionen stehen Ihnen dann zur Verfügung",
        contactUs:
          "Bei Fragen können Sie sich auch gerne telefonisch unter der +49 30 56 79 60 57 bei uns melden.",
        encryption: {
          text: "z.B. Austausch von Stammdaten, Versenden von unbegrenzten Nachrichten und Dateien",
          title: "Ende-zu-Ende-Verschlüsselung übertragener Daten:",
        },
        recommendations: {
          text: "geben Sie proaktiv Ihre Kapazitäten für weitere, verfügbare Patienten an, die auf Ihr Leistungsspektrum passen",
          title: "Empfehlungen:",
        },
        selectOrTypeEmail:
          "Geben Sie Ihre E-Mail-Adresse ein oder wählen Sie diese aus",
        upgrade: "Erstellen",
      },
    },
    archive: {
      title: "Archivierte Anfragen",
    },
    areYouSureArchiveRequests:
      "Sind Sie sich sicher, dass Sie die ausgewählten Anfragen archivieren möchten?",
    areYouSuredeclineAllRequests:
      "Sind Sie sich sicher, dass Sie die ausgewählten Anfragen ablehnen möchten?",
    attributed: {
      title: "Zuweisungen",
    },
    bookmark: {
      removeBookmark: "Priorisierung aufheben",
      removeBookmarkToast:
        "Sie haben die Priorisierung der Anfrage aufgehoben.",
      setBookmark: "Priorisieren",
      setBookmarkToast:
        "Diese Anfrage wurde für Sie priorisiert. Diese befindet sich nun ganz oben auf Ihrer Liste der Übersichtsseite.",
    },
    capacityManagement: {
      addBed: "Bett hinzufügen",
      bannerCapacity: (args: { availableBedsOfProvider: string }) =>
        template(
          "Sie haben {{availableBedsOfProvider}} Bett/en verfügbar. Bitte aktualisieren Sie Ihre Kapazitäten, um effiziente Überleitungen zu gewährleisten.",
          args,
        ),
      bedCount: (args: { numberOfBeds: string }) =>
        template("{{numberOfBeds}} Bett/en insgesamt verfügbar", args),
      bedCountPerSpecialization: (args: {
        amountOfBedsPerSpecialization: string;
      }) =>
        template("{{amountOfBedsPerSpecialization}} Bett/en verfügbar", args),
      capacityAvailable: "Sie haben Kapazität für neue Patienten",
      columnNumberOfBeds: "Anzahl der Betten",
      columnServices: "Leistungen",
      declinePanelBanner:
        "Derzeit erhalten Sie weiterhin neue Anfragen. Aktualisieren Sie Ihre Kapazität regelmäßig.",
      hintTextAddAnotherBed: "Weiteres Bett mit gleichen Merkmalen hinzufügen",
      hintTextRemoveBed: "Klicken Sie hier, um das Bett zu entfernen",
      infoCommentSection:
        "Helfen Sie Einrichtungen, mehr über Ihre Kapazitäten und Modalitäten für Coronavirus (COVID-19)-Patienten zu erfahren. Ihre zusätzliche Information wird den suchenden Einrichtungen angezeigt.",
      modalBedSettingsTitle: "Welche Leistungen bieten Sie an?",
      noCapacity: "Sie haben keine Kapazität für neue Patienten",
      placeholderTextComment: "Zusätzliche Information",
      serviceIntensiveCare: "Isolation",
      serviceVentilation: "Beatmung",
      snoozeFeature: {
        countdownTimer: "Kapazität ist gültig für",
        duration: "Laufzeit der Kapazität",
        hours: "Stunde/n",
        lastUpdatedDate: "Zuletzt aktualisiert",
        snoozeTooltip:
          "Wenn die Zeit abläuft, erhalten Sie neue Anfragen. Klicken Sie “Aktualisieren” oder fügen Betten hinzu/entfernen Betten, um den Countdown erneut zu starten.",
        toastUpdate: "Ihre Änderungen wurden gespeichert.",
        validUntilDate: "Gültig bis",
      },
      toastCommentSaved: "Ihre Information wurde erfolgreich gespeichert.",
      updateCapacity:
        "Aufgrund Ihrer Kapazität erhalten Sie weiterhin Anfragen. Wir empfehlen die Kapazität regelmäßig zu aktualisieren.",
    },
    commentOnProfile: {
      missingInformation:
        "Sie benötigen weitere Informationen über den Patienten?",
      modalCaption:
        "Ihr Feedback wird direkt an Recare geschickt und sorgfältig überprüft. Bitte weisen Sie darauf hin, welche nützlichen Informationen noch fehlen, damit wir das Patientenprofil verbessern können. Ihre Nachricht wird nicht an das Krankenhaus weitergeleitet, nutzen Sie dafür unseren Chat auf der rechten Seite, um direkt in Kontakt mit dem Krankenhaus zu treten.",
      modalPlaceholder: "Geben Sie hier Ihr Feedback ein",
      modalTitle: "Welche Patientendaten fehlen Ihnen?",
      subtitleInfo:
        "Ihr Kommentar wird an das Krankenhaus geschickt und sollte Ihnen die fehlenden Angaben per Nachricht zukommen lassen. ",
    },
    continueToNext: "Zur nächsten Anfrage",
    coronaEligibilityBanner:
      "Aufgrund des Coronaviruses (COVID-19) können absofort alle AHB-Überleitungen der gesetzlichen Krankenversicherungen per Direkteinweisungsverfahren erfolgen. Bitte stellen Sie dennoch sicher, dass der zuständige Kostenträger den ausgefüllten AHB-Antrag erhält. Diese Regelung gilt voraussichtlich bis zum 31.05.2020. Mehr Informationen dazu erhalten Sie {select:hier}.",
    coronaEligibilityBannerEnd: ".",
    coronaEligibilityLink: "hier",
    dashboard: {
      all: "Alle",
      archived: "Archiviert",
      archiveSelected: (args: { selectedRequests: string }) =>
        template("Archivieren ({{selectedRequests}})", args),
      archiveWithCount: "Archiv",
      attributed: "Zugewiesen",
      attributedHint:
        'Bitte beachten Sie, dass zugewiesene Anfragen nach 30 Tagen automatisch archiviert werden. Sie finden diese weiterhin unter dem Reiter "Archiviert".',
      attributedHintOtherTab:
        'Bitte beachten Sie, dass zugewiesene Anfragen nach 30 Tagen automatisch archiviert werden. Sie finden diese weiterhin unter dem Reiter "Andere".\n',
      bcpInformation:
        "Durch Ihre Listung im Pflegefinder Bayern erhalten Sie Direktanfragen von Bürgerinnen und Bürgern.",
      bcptab: "Direktanfragen",
      bulkFilter: {
        allRequests: (args: { allRequests: string }) =>
          template("Alle ({{allRequests}})", args),
        none: "Keine",
        visibleRequests: (args: { visibleRequests: string }) =>
          template("Sichtbare Anfragen ({{visibleRequests}})", args),
      },
      capacityBanners: {
        canAccept:
          "Aufgrund Ihrer Kapazitätseinstellungen können Sie folgende Patienten annehmen.",
        canNotAccept:
          "Aufgrund Ihrer Kapazitätseinstellungen können Sie folgende Patienten nicht annehmen.",
        declineAll: (args: { qtdRequests: string }) =>
          template("Alle ({{qtdRequests}}) Anfragen ablehnen", args),
      },
      capacityHint: "Kapazitäten zurzeit verfügbar",
      capacityNotification: {
        noButton: "Nein",
        subtitleAvailable:
          "Zurzeit haben Sie freie Kapazitäten angegeben. Ist das korrekt?",
        subtitleNotAvailable:
          "Zurzeit haben Sie keine freien Kapazitäten angegeben. Ist das korrekt?",
        title: "Sind Ihre Kapazitätsangaben aktuell?",
        yesButton: "Ja",
      },
      cards: {
        attributed: "Patient wurde Ihnen zugewiesen",
        careLevelOnCards: "Pflegegrad:",
        declined: "Abgelehnt",
        lastWorkedOn: "Zuletzt bearbeitet:",
        noRequests: "",
        pending: "Offen",
        sentByCostpayer: "Vom Kostenträger gesendet",
        transitionalCare: "mit Übergangspflege",
        unavailable: "Nicht verfügbar",
        youAccepted: "Von Ihnen angenommen",
        youDeclined: "Von Ihnen abgelehnt",
      },
      checkOurRecommendations: "Schauen Sie bei unseren Empfehlungen vorbei",
      clearAllFilters: "Löschen",
      columns: {
        patient: "Patient",
        received: "Empfangen",
        referring: "Zuweiser",
        transfer: "Überleitung",
      },
      declined: "Abgelehnt",
      declinedHint:
        'Bitte beachten Sie, dass abgelehnte Anfragen nach 10 Tagen automatisch archiviert werden. Sie finden diese weiterhin unter dem Reiter "Archiviert".',
      declineSelected: (args: { selectedRequests: string }) =>
        template("Ablehnen ({{selectedRequests}})", args),
      inProgressTab: "In Bearbeitung",
      linkBcp:
        "Klicken Sie hier, um weitere Informationen zum Pflegefinder Bayern in einem neuen Fenster zu öffnen.",
      markAsSeen: "Als gelesen markieren",
      newTab: "Neu",
      noCapacityHint: (args: { capacityDate: string }) =>
        template("Kapazitäten wieder verfügbar ab {{capacityDate}}", args),
      noRequestFilterResults:
        "Es gibt keine Anfragen, die Ihren Kriterien entsprechen",
      noRequestsForCapacity:
        "Keine offenen Anfragen, für die Sie Kapazität haben?",
      noRequestsMessage: {
        all: "Sie haben noch keine Anfragen",
        archived: "Es gibt keine archivierten Anfragen",
        attributed: "Sie haben noch keine zugewiesenen Anfragen",
        clinicNameBcp: "Pflegefinder Bayern",
        declined: "Sie haben noch keine abgelehnten Anfragen.",
        direct: "Sie haben keine neuen Direktanfragen.",
        inProgress: "Sie haben noch keine bearbeiteten Anfragen.\n",
        new: "Sie haben keine neuen Anfragen.",
        other: "Sie haben noch keine anderen Anfragen.",
        pending: "Sie haben noch keine offenen Anfragen",
        unavailable: "Es gibt keine nicht verfügbaren Anfragen",
      },
      otherTab: "Andere",
      pending: "Offen",
      referrerDirect: "Direktanfrage",
      toastArchiveSuccess:
        "Die Anfrage/n wurden archiviert. Sie finden diese im Reiter “Andere”.\n\n",
      toastBulkDeclineSuccess:
        "Anfrage/n wurden abgelehnt. Sie finden sie auf der anderen Registerkarte.",
      toastBulkSeenSuccess:
        "Anfrage/n wurden als gesehen markiert. Sie finden sie auf der anderen Registerkarte.",
      unavailable: "Nicht verfügbar",
      unavailableHint:
        'Bitte beachten Sie, dass nicht mehr verfügbare Anfragen nach 10 Tagen automatisch archiviert werden. Sie finden diese weiterhin unter dem Reiter "Archiviert".',
    },
    inactiveProfile: {
      titleBottom: "Bitte rufen Sie Recare unter der +49 30 56 79 60 57‬ an.",
      titleTop: "ist derzeit nicht aktiv.",
    },
    menu: {
      archive: "Archiv",
      capacityCorona: "Kapazitäten",
      recommendations: "Empfehlungen",
      requests: "Anfragen",
      search: "Suche",
      settings: "Einstellungen",
    },
    navBar: {
      dashboard: "Dashboard",
      reverseSearch: "Patientensuche",
    },
    notifications: {
      markAllAsRead: "Alle als gelesen markieren",
      noNotifications: "Es liegen keine Benachrichtigungen vor",
      notificationsDisabledHint:
        "Sie haben die Benachrichtigungen deaktiviert. Bitte aktivieren Sie sie in den Einstellungen Ihres Gerätes.",
      specialization: "Fachgebiet",
      title: "Aktuelle Mitteilungen",
      type: "Art der Einrichtung",
    },
    patientDataImport: {
      importModalHeadline: "Übernahme der Patientenangaben aus dem KIS",
    },
    patientShopping: {
      empty: "Derzeit sind keine Empfehlungen verfügbar.",
      seeRecommendations: "Empfehlungen ansehen",
    },
    requestsAfterCapacityDate: {
      plural: (args: { date: string }) =>
        template("Anfragen nach dem {{date}}", args),
      singular: (args: { date: string }) =>
        template("Anfrage nach dem {{date}}", args),
    },
    requestUnavailable: "Anfrage nicht verfügbar",
    requestUnavailableContinueToNext:
      "Diese Anfrage ist leider nicht mehr verfügbar. Möchten Sie mit der nächsten verfügbaren Anfrage fortfahren?",
    responsiblePerson: {
      modalEncryptionWarning:
        "Übermitteln Sie keine sensible Patientendaten. Die Nachrichten sind nicht verschlüsselt.",
      modalPlaceholder: "Ihre Nachricht an die ausgewählte Person",
      modalTitle: (args: { assignedName: string }) =>
        template(
          "Der zuständige Mitarbeiter wurde erfolgreich geändert. Möchten Sie {{assignedName}} über die Zuweisung per E-Mail informieren?",
          args,
        ),
      titleResponsiblePerson: "Zuständiger Mitarbeiter:",
      tooltipTitle: "Zuständiger Mitarbeiter:",
    },
    search: {
      noEntry: "Derzeit haben Sie keine Anfragen",
    },
    settings: {
      accounts: {
        activateAccount: "Account aktivieren",
        activateAccountToast:
          "Sie haben den Status für dieses Konto geändert. Wir haben einen Aktivierungslink an diese E-Mail-Adresse geschickt.",
        addMemberModalActiveEmailError: "Diese E-Mail-Adresse ist schon aktiv.",
        addMemberModalCentralEmailError:
          "Diese E-Mail-Adresse wird schon als zentrale E-Mail-Adresse verwendet.",
        addMemberModalEmailAlreadyInvited:
          "Diese E-Mail-Adresse hat schon eine Einladung erhalten, Sie können diese aber erneut senden.",
        addMemberModalEmailDontEnterInfo:
          "Hier sollte nicht die zentrale E-Mail-Adresse für Anmeldungen (z.B. info@ oder kontakt@), sondern die persönliche E-Mail-Adresse für ein Benutzerkonto eingegeben werden.",
        addMemberModalSend: "Senden",
        addMemberModalTitle: "Einladung per E-Mail senden",
        columnMemberDetails: "Mitarbeiter-Details",
        columnStatus: "Status",
        deactivateAccount: "Account deaktivieren",
        deactivateAccountToast: "Sie haben dieses Konto deaktiviert.",
        deactivateMemberModalTitle:
          "Möchten Sie diesen Mitarbeiter wirklich deaktivieren?",
        deleteMemberModalTitle:
          "Möchten Sie diesen Mitarbeiter wirklich löschen?",
        editModalTitle: "Mitarbeiter-Informationen bearbeiten",
        editTooltip: "Informationen bearbeiten",
        hintTextRegisteredMembers:
          "Nur registrierte Mitarbeiter können gesicherte Nachrichten mit der Klinik austauschen. Nachdem ein Mitarbeiter hinzugefügt wurde, erhält dieser per E-Mail eine Einladung, sein Konto einzurichten.",
        membersCardAddMember: "Mitarbeiter hinzufügen",
        membersCardCaption1:
          "Nur registrierte Benutzer können sichere Nachrichten mit den Kliniken austauschen.",
        membersCardCaption2:
          "Nachdem Sie einen Mitarbeiter hinzugefügt haben, erhält dieser eine Einladung per E-Mail, um sein Benutzerkonto einzurichten.",
        membersCardEmail: "E-Mail-Adresse",
        membersCardName: "Name",
        membersCardSendAgain: "Erneut senden",
        membersCardStatus: "Status",
        membersCardStatusActive: "Aktiv",
        membersCardStatusInactive: "Inaktiv",
        membersCardStatusSent: "Einladung versendet",
        membersCardTitle: "Registrierte Mitarbeiter Ihres Teams",
        receivedInvitationToast: (args: { email: string }) =>
          template(
            "{{email}} hat eine Einladung zur Registrierung erhalten.",
            args,
          ),
        resendActivationMail: "Einladung erneut versenden",
        roleClinic:
          "Dieser Mitarbeiter soll den Kliniken als Kontaktperson angezeigt werden",
        teamTitle: "Registrierte Mitglieder",
        title: "Benutzerkonten",
        youMarker: "(Du)",
      },
      bottomSectionAriaLabel: "Weitere Navigationsmöglichkeiten",
      browserNotifications: {
        checkboxInfo:
          "Für alle Anfragen, Nachrichten und Zuweisungen aktivieren",
        completedManualSteps:
          "Browser-Benachrichtigungen gewährt und Recare abonniert",
        deviceStatusComplete:
          "Wenn alle Schritte abgeschlossen sind, sollten Sie nun Recare-Benachrichtigungen auf Ihrem Gerät erhalten",
        explainer:
          "Um Browserbenachrichtigungen von Recare zu erhalten, befolgen Sie bitte die folgenden Schritte",
        notificationsDeactivatedExplanatoryText:
          'Um Echtzeit-Benachrichtigungen für Anfragen, Nachrichten und Zuweisungen erhalten zu können, öffnen Sie bitte Ihre Browser-Einstellungen. Unter "Datenschutz und Sicherheit" können Sie Benachrichtigungen von Recare zulassen. Für weitere Hilfe können Sie unseren Kundenservice gerne unter der  {select:030 5679 6057} erreichen.',
        notificationsDeactivatedInfo:
          "Derzeit durch Ihre Browser-Einstellungen blockiert",
        pleaseUseSafari:
          "Bitte verwenden Sie Safari, um Browserbenachrichtigungen unter MacOS zu erhalten",
        previouslyEnabled:
          "Wenn Sie dieser App zuvor die Benachrichtigungsberechtigung verweigert haben, sollten Sie die Benachrichtigungen wieder aktivieren, indem Sie in Ihren Einstellungen Berechtigungen zulassen und die App schließen und erneut öffnen.",
        pushManagerErrorStatus:
          "Kein Push-Abonnement, schließen Sie die App und öffnen Sie sie erneut. Andernfalls wenden Sie sich bitte mit Ihren Debug-Informationen an recare.",
        stepOne: "Schritt 1: Browser-Benachrichtigungen zulassen",
        stepTwo: "Schritt 2: Benachrichtigungen von recare zulassen",
        title: "Browser-Benachrichtigungen",
      },
      capacity: {
        acceptNewPatientsWhen: "Wir können neue Patienten aufnehmen ab dem: ",
        banner: {
          button: "Ja, wir haben Kapazitäten",
          confirmation: "Vielen Dank für die Bestätigung.",
          subtitle:
            "Momentan haben Sie freie Kapazitäten angegeben. Ist das noch aktuell?",
          title:
            "Sie haben Ihre Angaben vor mehr als zwei Wochen zuletzt aktualisiert.",
        },
        calendarWarning: "Bitte wählen Sie ein Datum innerhalb der 60 Tage.",
        capacityAvailable: "Wir haben derzeit Kapazität",
        capacityTitle: "Unsere Kapazitäten",
        dateInFuture: "Datum und Uhrzeit muss in der Zukunft liegen",
        exceptions: "Ausnahmen:",
        explanation:
          "Bitte halten Sie Ihre Kapazitätsangaben auf dem neuesten Stand. Je aktueller die Angaben sind, desto effizienter ist der Prozess für alle Beteiligten. Änderungen sind jederzeit möglich und werden sofort auf der Recare-Plattform übernommen.\nAusnahme mit Vorteilen: Starten Sie über „Suche” eine aktive Patientensuche/mehrere Suchen mit spezifischen Kriterien, erhalten Sie hierfür Anfragen, auch wenn Sie „Wir haben keine Kapazität” ausgewählt haben",
        hintCapacityAvailable:
          "Aufgrund Ihrer Kapazität erhalten Sie zurzeit neue Patientenanfragen.",
        hintCapacityAvailableExcept:
          "Aufgrund Ihrer Kapazität erhalten Sie zurzeit neue Patientenanfragen, die nicht den Ausnahmen entsprechen.",
        hintCapacityNotAvailable:
          "Sie erhalten derzeit keine neuen Patientenanfragen.",
        instructions:
          "Um Ihre Kapazitätsangaben zu ändern, klicken Sie bitte auf eine der unten stehenden Optionen:",
        lastUpdated: (args: { date: string }) =>
          template("Zuletzt aktualisiert: {{date}}", args),
        saveButton: "Änderungen speichern",
        tileCapacity: {
          text: "So erhalten Sie zu Ihrem Leistungsspektrum passende Anfragen. ",
          title: "Wir haben Kapazität",
        },
        tileNoCapacity: {
          dateInformation: "Wir werden neue Patienten aufnehmen können ab",
          errorDateInPast:
            "Bitte geben Sie ein gültiges Datum innerhalb der nächsten 60 Tage an.",
          errorTimeInPast: "Bitte geben Sie eine gültige Uhrzeit an.",
          errorTimeInPastDateOkay:
            "Bitte geben Sie ein gültiges Datum innerhalb der nächsten 60 Tage an oder ändern Sie die Uhrzeit auf eine zukünftige Zeit",
          text: "Geben Sie das Datum an, bis zu dem Sie keine neuen Anfragen erhalten möchten. Für die Tage danach erhalten Sie weiter Anfragen.",
          title: "Wir haben keine Kapazität",
        },
        title: "Kapazitäten",
        titleNew: "Kapazitätseinstellungen",
        toastMessageHasCapacitySaved:
          "Angabe wurde erfolgreich gespeichert - Sie haben Kapazität",
        toastMessageHasNoCapacitySaved:
          "Angabe erfolgreich gespeichert - Sie haben keine Kapazität",
        toggleNoYes: "Nein / Ja",
      },
      dPA: {
        downloadContract: "Vertrag herunterladen",
        fileName: "Auftragsdatenverarbeitung.pdf",
        hintFileRestriction:
          "Es ist nur eine Datei erlaubt. Die Datei kann nicht mehr geändert werden, sobald sie auf Bestätigen geklickt haben.",
        hintHowTo:
          "Bitte laden Sie den Auftragsdatenverarbeitungsvertrag herunter und drucken Sie diesen aus. Nachdem Sie ihn unterschrieben haben, laden Sie ihn bitte wieder bei Recare hoch.",
        modalTitleSure:
          "Sind Sie sicher, dass Sie die Datei entfernen möchten?",
        signScan: "Unterschreiben und einscannen",
        titleDPA: "Auftragsdatenverarbeitung",
        titleOtherDocuments: "Weitere Dokumente",
        uploadContract: "Zurück auf Recare hochladen",
      },
      email: {
        addressAlreadyLinked:
          "Diese E-Mail-Adresse ist schon einem Benutzerkonto zugewiesen.",
        advanced: "Erweitert",
        centralAddress: "Zentrale E-Mail-Adresse für Anmeldungen",
        centralAddressHint:
          "Sollten Sie eine zentrale E-Mail-Adresse für Anmeldungen (z.B. info@ oder kontakt@) haben, können Sie diese hier aktivieren.\nBitte beachten Sie, dass diese Email Adresse nur Anfragen erhält und kein Benutzerkonto sein kann.",
        centralAddressToast: (args: { email: string }) =>
          template("Sie werden E-Mails an {{email}} erhalten", args),
        companyInbox: "Posteingang des Unternehmens",
        companyMailAddress: (args: { companyMail: string }) =>
          template("Unternehmens-E-Mail-Adresse {{companyMail}}", args),
        deactivateAll: "Ich möchte überhaupt keine E-Mails erhalten",
        hideAdvancedSettings: "Erweiterte Einstellungen ausblenden",
        hintSetUpCompanyMail:
          "{select:Klicken Sie hier} um Ihre Unternehmens-E-Mail-Adresse einzurichten.",
        modalDeactivateEmailSubtitle:
          "Dies könnte die Kommunikation zwischen Ihnen und den Kliniken erheblich beeinträchtigen. Von nun an müssen Sie immer die Plattform prüfen, ob neue Anfragen eingegangen sind.",
        modalDeactivateMailTitle:
          "Sind Sie sicher, dass Sie keine E-Mails mehr erhalten möchten?",
        noCentralAddress: "Wir haben keine zentrale E-Mail-Adresse",
        notificationsTitle: "Erhalten Sie Benachrichtigungen an",
        personalInbox: "Persönlicher Posteingang",
        personalMailaddress: (args: { userAddress: string }) =>
          template("Persönliche E-Mail-Adresse {{userAddress}}", args),
        receiveOnePerDay:
          "Ich möchte nur eine tägliche Zusammenfassung der Anfragen erhalten",
        saveAndSend: "Speichern und Einladung schicken",
        showAdvancedSettings: "Erweiterte Einstellungen anzeigen",
        title: "E-Mail-Einstellungen",
        toggleOffOn: "Aus / An",
        withCentralAddress:
          "Wir haben eine zentrale E-Mail-Adresse und wollen die Anfragen hier erhalten",
      },
      extensions: {
        connected: "Verbunden",
        connectionStatusActive: "Aktiv seit:",
        connectionStatusInactive: "Inaktiv seit:",
        connext: {
          additionalInfo:
            "Verbinden Sie Ihr Connext Vivendi Konto mit Recare um automatisch alle Patientendaten direkt in Ihre Software bei einer Patientenzuweisung zu erhalten. ",
          title: "Connext Vivendi",
        },
        ctaConnect: "Verbinden",
        infoExtensions:
          "Verbinden Sie sich mit Ihrem Softwarepartner, um den Datentransfer von Recare zu Ihrem Tool zu erleichtern.",
        korian: {
          additionalInfo:
            "Das Korian CRM ist automatisch mit Recare verbunden. Recare Daten und Patientenanfragen werden direkt in Ihre Software übertragen.",
          title: "Korian CRM",
        },
        title: "Erweiterungen",
      },
      facility: {
        additionalCosts:
          "Als eine private Einrichtung erheben wir zusätzliche Kosten",
        additionalCostsPlaceholder: "Bitte erläutern Sie",
        availableEquipmentTitle: "Verfügbar",
        cardiovascularExplorationTitle: "Kardiovaskuläre Untersuchung",
        coronaService: "Wir nehmen Coronavirus (SARS-CoV-2)-Patienten auf",
        coronaTitle: "Coronavirus (SARS-CoV-2)",
        ctaAddAnotherDay: "Weitere hinzufügen",
        digestiveEndoscopies: "Verdauungsendoskopie",
        endTime: "bis",
        furtherInformationCardTitle: "Weitere Informationen",
        healthInsuranceFrance: "Versicherung des Patienten",
        healthInsuranceMandatoryFrance: "Verpflichtend",
        healthInsuranceOptionalFrance: "Optional",
        hintCompanyMailNotification:
          "Möchten Sie Anfragen an diese E-Mail erhalten? {select:Klicken Sie hier} um Ihre Einstellungen zu verwalten.",
        hintWorkingHours:
          "Wenn Sie keine individuellen Geschäftszeiten angeben, werden diese standardmäßig auf 7 - 17 Uhr gesetzt. Ihre Geschäftszeiten werden den Kliniken angezeigt.",
        imageryTitle: "Bildgebende Verfahren",
        institutionsNotPartOfSearch:
          "Derzeit sind folgende Einrichtungen noch nicht Teil der Suche. Bitte wählen Sie Zutreffende als zusätzliche Information aus:",
        notSetYet: "nicht angegeben",
        organization: "Unternehmen",
        patientCharacteristicsCardTitle: "Patientenmerkmale",
        rangeServicesCardTitle: "Leistungsspektrum",
        receiverPartOfOrganization: (args: { organizationName: string }) =>
          template(
            "Sie sind Teil der Einrichtung {select:{{organizationName}}}",
            args,
          ),
        receiversOfSameOrganization:
          "Zur gleichen Einrichtung gehören die folgenden Bereiche:",
        socialWorkerOnSite: "",
        startTime: "Von",
        technicalEquipmentTitle: "Technische Ausrüstung",
        title: "Einrichtung",
        titleCompanyInformation: "Informationen zum Unternehmen",
        titleWorkingHours: "Geschäftszeiten",
        updateInformationModalTitle: "Aktualisieren Sie Ihre allgemeinen Daten",
      },
      language: {
        en: "",
        fr: "",
      },
      payers: {
        explanationSubtitle:
          "Für Patienten mit folgenden Versicherungen nehmen wir Vorreservierungen an",
        infofieldRehabShortInsurances:
          "Das Kurzprofil ist auf die Patienteninformationen reduziert, die benötigt werden, um potenziell passende Empfänger zu kontaktieren. Zur Übermittlung wichtiger medizinischer Informationen, laden Sie bitte auch relevante Dokumente (Arztbefund / AHB-Formular) hoch und leiten Sie diese weiter.",
        titlePayers: "Kostenträger",
      },
      relatedCareseekers: {
        activeSince: "Aktiv seit",
        distanceToClinic: (args: { distance: string }) =>
          template("{{distance}} entfernt", args),
        name: "Name",
        sectionTitle: "Übersicht aller Partner-Kliniken in Ihrer Nähe",
        soonAvailable: "Bald verfügbar",
        title: "Partner-Kliniken",
      },
      reversePatientSearch: {
        addSearch: "Neue Suche hinzufügen",
        betaBanner:
          "Diese Funktion befindet sich derzeit in der Beta-Phase und kann sich im Laufe der Zeit in Funktion und Aussehen ändern.",
        chipBasedOnSearch: "Suchergebnis",
        deleteSearch: "Löschen",
        editingTitle: "Suche bearbeiten",
        editSearch: "Bearbeiten",
        filtersTitle: "",
        hintNoCapacity:
          "Sie haben angegeben, dass Sie derzeit keine Kapazitäten für neue Patienten haben. Daher erhalten Sie nur Anfragen für Patienten, die Ihren Suchkriterien entsprechen. Wenn Sie auch Anfragen für andere Patienten erhalten möchten, ändern Sie dies bitte in Ihren Kapazitätseinstellungen.",
        hintTextCapacityMode:
          'Suchen Sie detailliert, indem Sie "Geschlecht", "Alter", "Geplanter Start" und "Pflegegrad" festlegen oder flexibel, indem Sie nur eins der Kriterien bestimmen. Suchen können jederzeit problemlos bearbeitet, gelöscht oder pausiert werden. Sie können auch mehrere Suchen starten indem Sie die "Suche hinzufügen" Funktion nutzen. Sie erhalten Anfragen für Patienten, die Ihren Suchkriterien entsprechen, auch wenn Ihre Gesamtkapazität deaktiviert sind.',
        hintTextInformation:
          'Suchen Sie detailliert,  indem Sie "Geschlecht", "Alter", "Geplanter Start" und "Pflegegrad" festlegen oder flexibel, indem Sie nur eins der Kriterien bestimmen. Suchen können jederzeit problemlos bearbeitet, gelöscht oder pausiert werden; Sie können auch mehrere Suchen  starten indem Sie die "Suche hinzufügen" Funktion nutzen. ',
        loadingError: "",
        pauseSearch: "Pausieren",
        searchAdded: "Die Suche wurde erfolgreich hinzugefügt",
        searchDeleted: "Die Suche wurde erfolgreich gelöscht",
        searchEdited: "Die Suche wurde erfolgreich bearbeitet",
        searchPaused: "Die Suche wurde pausiert",
        searchStarted: "Die Suche wurde gestartet",
        searchStatus: "Status der Suche",
        searchStatusActive: "aktiv",
        searchStatusPaused: "pausiert",
        showResults: "Ergebnisse anzeigen",
        startSearch: "Starten",
        subtitle: "xxx",
        subtitleInformationText:
          'Haben Sie derzeit oder in näherer Zukunft freie Kapazität? Sie können mit Hilfe der Suchfunktion in nur wenigen Schritten, basierend auf Ihrer voraussichtlichen Kapazität Patientensuchen erstellen. Sie werden per E-Mail benachrichtigt, wenn Patienten, die Ihre Suchkriterien erfüllen, im System vorliegen. Daneben sehen Sie diese Patienten wie gewohnt unter "Anfragen" (Patienten die Ihre Suchkriterien erfüllen sind als “Suchergebnis” gekennzeichnet).',
        subtitleSearchesAvailable:
          'Suchen Sie derzeit nach Patienten, die bestimmte Kriterien erfüllen? \nUntenstehend sehen Sie Ihre aktuelle(n) Suche(n), die Sie jederzeit bearbeiten, löschen oder pausieren können. Wenn Sie auf "Suche hinzufügen" klicken, können Sie weitere Suchen hinzufügen.',
        subtitleSearchesNotAvailable:
          "Suchen Sie derzeit nach Patienten, die bestimmte Kriterien erfüllen?\nSetzen Sie die Filter unten entsprechend, um sofort benachrichtigt zu werden, wenn ein solcher Patient angelegt wird.",
        title: "Patientensuche",
        toastError: "Etwas ist schief gelaufen, bitte versuchen Sie es erneut",
        toastErrorEmptyFilters:
          "Bitte fügen Sie mindestens eine Option hinzu, um Ihre Suche speichern zu können",
        toastShowResultsError:
          "Etwas ist schief gelaufen. Bitte versuchen Sie es erneut",
        toastShowResultsSuccess: "Das Ergebnis Ihrer Suche wird angezeigt",
      },
      searchCareprovider: "",
      sidebar: {
        ariaLabelChangePassword:
          "Hier klicken, um ein neues Passwort zu setzen",
        ariaLabelLogout: "Hier klicken, um sich auszuloggen",
        ariaLabelReportContent: "Hier klicken, um Inhalte zu melden",
        avContract: "AV-Vertrag",
        avContractAddendum: "Akt. Anlagen AV-Vertrag",
        companyProfile: "Unternehmensprofil",
        faq: "FAQ",
        findPatients: "Patientensuche",
        legal: "Rechtliches",
        myAccount: "Mein Konto",
        notifications: "Benachrichtigungen",
        ourCapacity: "Kapazitäten",
        ourServices: "Leistungen",
        partnerClinics: "Partner-Kliniken",
        payers: "Kostenträger",
        personalDetails: "Persönliche Details",
        privacyPolicy: "Datenschutzerklärung",
        reportContent: "Inhalte melden",
        search: "Suche",
        services: "Leistungen",
        team: "Team",
        teamManagement: "Nutzerverwaltung",
        title: "Einstellungen",
      },
      switchCareprovider: "",
      topSectionAriaLabel: "Navigation der Einstellungsseiten",
      uploadFiles: {
        confirmImageRights:
          "Ich bestätige, dass das Bild von mir hochgeladen und genutzt werden darf und frei von entgegenstehenden Rechten Dritter ist sowie nicht gegen die Nutzungsbedingungen verstößt. Bei Zuwiderhandlungen kann das Bild entfernt werden.",
        errorFileNameExistsAlready:
          "Dateiname existiert bereits - bitte ändern Sie den Namen der Datei und laden diese erneut hoch",
        errorMaxFiles: (args: { maxCount: string }) =>
          template("Sie können maximal {{maxCount}} Dateien hochladen.", args),
        errorMaximumFiles: "Sie können maximal 5 Dateien hochladen.",
        errorPermittedFormat:
          "Bitte laden Sie nur Dateien im erlaubten Format hoch.",
        imageDeletionDisclaimer:
          "Recare behält sich vor jederzeit Bilder zu entfernen sollten diese Nutzungsrechte oder andere Rechte verletzen.",
        images: {
          imageMaxResolution: "max. Auflösung: 4000 x 3000",
          imagesMaxSize: "max. Bildgröße: 15 MB",
          permittedFormat: "erlaubtes Format: .png, .jpg, .jpeg",
          subtitle:
            "Bitte ergänzen Sie Ihr Profil durch Bilder Ihrer Einrichtung, um einen visuellen Eindruck Ihres Leistungsspektrums zu ermöglichen. Diese Bilder werden den Versorgungssuchenden angezeigt.",
          title: "Bildmaterialien (max. 5 Bilder)",
          uploadImages: "Bilder hochladen",
        },
        marketing: {
          documentsMaxSize: "max. Dateigröße: 15 MB",
          infoUploadFiles:
            "Laden Sie nützliches Material hoch, wie z.B. Flyer oder Fotos. Diese Dateien werden dem Krankenhaus in der Reihenfolge der Liste angezeigt.",
          permittedFormat: "erlaubtes Format: .pdf, .doc, .docx",
          title: "Werbematerial (max. 5 Dateien)",
          uploadFiles: "Dateien hochladen",
        },
        orderSuccessfullyChanged: "Die Reihenfolge wurde erfolgreich geändert.",
        toastConfirmCheckbox:
          "Bitte bestätigen Sie zuerst, bevor Sie ein Bild hochladen.",
        toastFileDeletedSuccess: "Ihre Datei wurde gelöscht.",
      },
    },
    showElectivePatient: "Elektiver Patient",
    status: {
      requestSent: "Antworten",
    },
    topLevelNavBar: {
      faq: "FAQ",
      newsletter: "Newsletter",
    },
    warningAcceptedRequestsSelected:
      "Einige der Anfragen wurden bereits von Ihnen angenommen.",
  },
  careproviderCard: {
    age: "Alter",
    contactInformation: "Kontaktinformationen",
    costCoverage: {
      applied: "Beantragt",
      approved: "Bestätigt",
      declined: "Abgelehnt",
      declineHint: "(für die Reha-Einrichtung nicht sichtbar)",
      declineReason: "Ablehngrund",
      noAppliedYet: "Noch nicht beantragt",
      sendNotification: "Benachrichtigung an die Klinik senden",
      title: "Status der Kostenübernahme",
      updateStatus: "Bitte aktualisieren Sie den Status",
    },
    distanceFromPatient: (args: { distance: string }) =>
      template("{{distance}} vom Patienten entfernt", args),
    healthInsurance: "Versicherung",
    otherServices: "Folgende Leistungen bieten wir darüber hinaus an:",
    ourServices: "Unsere Leistungen",
    patientCharacteristics: "Patientenmerkmale",
    patientServices: "Passend zu den Patientenbedürfnissen bieten wir:",
    weight: "Gewicht",
  },
  careproviderProfile: {
    acceptedWeight: "Gewicht der Patienten",
    addMoreLanguages: "Weitere Sprachen hinzufügen",
    capacity: {
      declineExpirationDate: "Datum wählen",
      declineExpirationDateInfo:
        "Wenn Sie kein Datum angeben, werden Sie weiterhin Patientenanfragen mit den ausgewählten Merkmalen erhalten.",
      declineExpirationOptional: "Datum auswählen (optional)",
      noCapacityForGivenReasonUntil: (args: {
        reasons: string;
        date: string;
      }) => template("{{reasons}} bis zum {{date}}", args),
      weCouldReceiveNewPatients: "Wir könnten neue Patienten aufnehmen",
      weCurrentlyHaveNoCapacity:
        "Wir haben derzeit gar keine verfügbaren Kapazitäten",
      youAreNotTakingPatients: "Sie nehmen derzeit keine neuen Patienten auf",
      youAreReceivingNewRequests: "Sie erhalten neue Anfragen per E-Mail",
      youChangedYourCapacitySettings: "Sie haben Ihre Einstellungen geändert.",
      youCouldReceiveNewPatients: "Sie könnten neue Patienten aufnehmen",
      youCouldReceiveNewPatientsExceptFor:
        "Wir könnten neue Patienten aufnehmen, außer:",
      youHavePendingRequests: {
        plural: (args: { number: string }) =>
          template(
            "Sie haben {select:{{number}} unbeantwortete Anfragen}",
            args,
          ),
        singular: (args: { number: string }) =>
          template(
            "Sie haben {select:{{number}} unbeantwortete Anfrage}",
            args,
          ),
      },
      youHavePendingRequestsBefore: {
        plural: (args: { number: string; date: string }) =>
          template(
            "Sie haben {select:{{number}} unbeantwortete Anfragen} vor dem {{date}}",
            args,
          ),
        singular: (args: { number: string; date: string }) =>
          template(
            "Sie haben {select:{{number}} unbeantwortete Anfrage} vor dem {{date}}",
            args,
          ),
      },
      yourCapacity: "Ihre Kapazitäten",
      youWillNotReceiveRequestsThatContain:
        "Sie werden keine neuen Anfragen erhalten, die Folgendes enthalten",
      youWillNotReceiveRequestsTill: (args: { date: string }) =>
        template("Sie erhalten keine neuen Anfragen bis zum {{date}}", args),
      youWillNotReceiveRequestsWithFollowingCharacteristics:
        "Sie erhalten bis zum gewählten Datum keine Patientenanfragen mit den folgenden Merkmalen: ",
    },
    clinicContactInformation: (args: { careseekerName: string }) =>
      template("{{careseekerName}} Kontaktdaten", args),
    companyName: {
      care: "Name der Einrichtung",
      geriatrics: "Name der Klinik",
    },
    contact: "Kontaktperson",
    contactDetails: "Kontaktdaten",
    contactPersonInHospital: "Ansprechperson",
    contactSendEmails:
      "Bitte markieren Sie die Checkbox, wenn der Kontakt die Anfragen per E-Mail erhalten soll. Alle Kontakte werden dem Sozialdienst des Krankenhauses zur Information angezeigt.",
    duplicateIkNumber: "Diese IK-Nummer wurde bereits eingegeben.",
    errorMissingMandatoryFields: "Bitte füllen Sie alle Pflichtfelder aus.",
    extraNotes: {
      hint: "Fügen Sie Ihre Notizen hinzu…",
      info: "Ihre Notizen werden nicht der Einrichtung angezeigt. Sie können hier fehlende Informationen, wie z.B. Fax-Nummer, Verfügbarkeit etc. hinzufügen. Sie können jederzeit auf Ihre Notizen zurückgreifen.",
      title: "Fügen Sie mehr Informationen zur Einrichtung hinzu",
      titleEdit: "Interne Notizen bearbeiten",
    },
    generalInformation: "Allgemeine Daten",
    healthInsurance: "Versicherung der Patienten",
    hintAdditionalInformation:
      "Bitte ergänzen Sie Ihr Profil durch eine Beschreibung Ihrer Einrichtung und Ihres Leistungsspektrums. Diese Information wird den Versorgungssuchenden angezeigt.",
    ikNumberAlreadyTaken: (args: { ikNumber: string }) =>
      template("IK-Nummer {{ikNumber}} ist ungültig", args),
    ikNumberEmpty: "Bitte füllen Sie das Feld aus",
    ikNumberErrorDigits: "Dieses Feld muss genau 9 Ziffern enthalten.",
    ikNumberHint: "placeholder",
    ikNumberIntegration:
      "Da der Anbieter Teil einer Integration ist, kann die IK-Nummer nicht geändert werden - bitte kontaktieren Sie den Support",
    ikNumberLabel: "Institutionskennzeichen",
    ikNumberSuccess: "IK-Nummern erfolgreich aktualisiert",
    isNewCareProvider: "NEU bei Recare",
    languagesSpoken: "Sprachen der Pflegekräfte",
    letUsKnowAnyRemarks: "Kommentar hinterlassen",
    loadingError: "Profil konnte nicht geladen werden",
    loadingErrorRedacted:
      "Das Profil wurde gelöscht und kann nicht geladen werden",
    locationOfPatient: "Einzugsgebiet",
    locationOfPatientExplanation:
      "Bitte geben Sie an,  in welchem Kilometerradius Sie Patienten versorgen können, oder in welchen Postleitzahlengebieten Sie tätig sind",
    max: "Maximum",
    min: "Minimum",
    myAccount: "Einstellungen",
    otherRemarks: "Zusätzliche Informationen zu Ihrer Einrichtung",
    patientTypeLabel: "Patiententyp",
    placeholderAdditionalInformation:
      "z.B. Empfangszeiten, weitere Anforderungen, Beschreibung der Einrichtung etc. ",
    prerequisites:
      "Ist dies eine Voraussetzung für die Patientenaufnahme? Wenn Sie dies auswählen, erhalten Sie ausschließlich Anfragen für diese Leistung.",
    profileCardTitle:
      "Diese Informationen sind notwendig für Recare und für Kliniken, um Sie kontaktieren zu können.",
    providerNotAlreadyAdded: "Anbieter bereits zur Suche hinzugefügt",
    receiverName: (args: { receiverTypeSingular: string }) =>
      template("{{receiverTypeSingular}}-Name", args),
    servicesPsychiatricSpecialisation: "Psychiatrische Spezialisierung",
    sexOfNurses: "Geschlecht der Pflegekräfte",
    toTreatPatientRadius:
      "Patienten werden in einem bestimmten Umkreis versorgt (KM).",
    toTreatPatientZipcodes:
      "Patienten werden in einem bestimmten Einzugsgebiet versorgt (PLZ)",
    weTreatPatientFederalStateWide: "Bundeslandweit",
    weTreatPatientGermanyWide: "Deutschlandweit",
    weTreatPatientRadius: "Innerhalb der folgenden Fahrtstrecke (km)",
    weTreatPatientZipcodes: "oder innerhalb der folgenden Gebiete (PLZ)",
    weTreatPatientZipcodesStartingWith:
      "innerhalb der folgenden Gebiete (PLZ) beginnend mit",
    years: "Jahre",
    yourProfile: "Ihre Einrichtung",
    yourProfileLink: "Ihr Profil",
  },
  careseeker: {
    billingEmail: "Rechnungs-E-Mail-Adresse",
    billingEndDate: "Ende der kostenpfl. Phase",
    billingName: "Rechnungsname",
    billingPeriod: "Abrechnungszeitraum",
    billingStartDate: "Anfang der kostenpfl. Phase",
    billingTitle: "Rechnungsinformationen",
    checkProtectedDescription:
      "Bitte prüfen Sie anhand der Krankenversicherungsnummer, ob es sich bei diesem Patienten um einen Mitarbeiter/eine Mitarbeiterin handelt. Mitarbeiterdaten dürfen nur von speziell berechtigten Personen eingesehen werden.",
    checkProtectedTitle: "Prüfung auf schützenswerte Patientendaten",
    contractInformation: "Vertragsinformationen",
    contractNotes: "Vertragsnotizen",
    contractStartDate: "Vertrags-Startdatum",
    customerId: "Kunden-Nr",
    extensionContractLink: "Vertragsverlängerung",
    financeOffice: "Adresse der Finanzabteilung",
    financeOfficeName: "Name der Finanzabteilung",
    flatRate: "Service-gebühr",
    generateInvoice: "Rechnung Erstellen",
    generateInvoiceForClinic: (args: { careseekerName: string }) =>
      template("Rechnung für {{careseekerName}} erstellen", args),
    informationCostPayerValidation:
      "Mit Klick auf ANNEHMEN bestätigen Sie eine rechtlich unverbindliche und vorläufige Reservierung.",
    invoiceComment: "Zusätzliche Rechnungsinformationen",
    invoiceDelivery: "Rechnungsversand",
    invoicesTitle: "Rechnungen",
    invoiceType: "Art",
    invoiceViaEmail: "Per E-Mail",
    invoiceViaPost: "Per Post",
    linkToPdf: "Link zum PDF",
    longMobileAsssessment: "Ausführliches Patientenprofil notwendig",
    maintenancePAge:
      "Entschuldigung, wir sind nicht erreichbar. Derzeit werden Wartungsarbeiten durchgeführt. Bitte besuchen Sie uns bald wieder.",
    paymentTitle: "Zahlungsinformationen",
    pricePerPatient: "Erfolgreiche Vermittlung",
    protectedCheckQuestion:
      "Handelt es sich hierbei um schützenswerte Mitarbeiterdaten?",
    protectedPatients: "Geschützte Personen",
    remoteWorkshopFee: "Fernschulungsgebühr",
    serviceDate: "Leistungsdatum",
    setupFee: "Bereitstellungsgebühr",
    shortMobileAssessment: "Kurzes Patientenprofil ausreichend",
    signedContractReceived: "Unterschriebenen Vertrag erhalten am",
    totalAmount: "Gesamtbetrag (€)",
    trialEndDate: "Ende der Kick Off Phase",
    trialStartDate: "Anfang der Kick Off Phase",
    workshopFee: "Schulungsgebühr",
  },
  coronaTesting: {
    additionalComments: "",
    additionalCommentsNo:
      "Weitere Informationen, z.B. dass keine COVID-19 Symptome vorliegen",
    additionalCommentsYes:
      "Weitere Informationen, z.B. wann wurde der Test durchgeführt",
    auctionLabel: "Coronavirus (SARS-CoV-2)",
    coronaBannerAddMobile:
      "Aufgrund der aktuellen Verbreitung des Coronavirus stehen die meisten Kurzzeit- oder stationäre Pflegeplätze nicht mehr zur Verfügung. Wir raten daher eindringlich parallel nach einer ambulanten Einrichtung zu suchen, um eine weitere Ausbreitung einzudämmen. {select:Klicken Sie hier} um ambulante Einrichtungen zu Ihrer Suche hinzuzufügen.",
    hasBeenVaccinated: "Patient wurde vollständig geimpft",
    hasPatientBeenTested:
      "Wurde der Patient auf das Coronavirus (SARS-CoV-2) getestet?",
    negative: "Negativ",
    patientNotTested: "Patient wurde nicht getestet",
    pending: "Ergebnis ausstehend",
    pleaseSpecifyVaccination: "Bitte erläutern Sie",
    positiveCured: "Positiv, vollständig genesen",
    positiveUncured: "Positiv, noch nicht genesen",
    results: "Ergebnisse",
    testResultLabel: "Testergebnis",
    unknown: "Informationen unbekannt, ob ein Test erfolgt ist",
    vaccinationUnknown: "Impfstatus wurde nicht angegeben",
  },
  crisp: {
    support: "Hilfe",
  },
  csat: {
    csatCommentPlaceholder: "Geben Sie hier Ihr Feedback ein",
    csatCommentQuestion: "Wie können wir uns verbessern?",
    csatQuestion: "Wie zufrieden sind Sie mit Recare?",
    csatThanksSubtitle:
      "Ihr Feedback ist sehr wichtig, denn es hilft uns, Recare kontinuierlich zu verbessern.",
    csatThanksTitle: "Vielen Dank für Ihr Feedback!",
    dissatisfied: "",
    neutral: "",
    satisfied: "",
    scaleNotAtAll: "überhaupt nicht",
    scaleVeryMuch: "sehr zufrieden",
    veryDissatisfied: "",
    verySatisfied: "",
  },
  faxRequest: {
    coverPage: {
      dearSirOrMadam: "Sehr geehrte Damen und Herren,",
      disclaimer: {
        explainationLineTwo:
          "Aus Datenschutzgründen dürfen Patientendaten nicht per Fax gesendet werden.",
        explanation:
          "Wenn Sie das vollständige Patientenprofil sehen möchten, können Sie sich auf unserer Plattform {select:www.recaresolutions.com} {select:kostenfrei} registrieren. Bei Rückfragen stehen wir Ihnen gerne telefonisch unter +49 30 56 79 60 57 zur Verfügung.",
        explanationLineOne:
          "Die Recare Deutschland GmbH ist Software- und Vertragspartner des Krankenhauses.",
        title: "Dies ist keine Werbung",
      },
      hospitalInformation: {
        contactPerson: "Kontaktperson:",
        email: "E-Mail:",
        telephoneNumber: "Tel.:",
        title: "Kontaktinformation des Senders",
      },
      infoBodyTextRequest:
        "ein Patient ist auf der Suche nach einem geeigneten Versorger. Haben Sie freie Kapazitäten für diesen Patienten? Ein pseudonymisiertes Patienten-Profil finden Sie im Anhang (Seite 2).",
      infoBodyTextValidation:
        "Sie wurden vom Krankenhaus als Versorger für einen Patienten ausgewählt. Wir informieren Sie daher, dass ein Patient zu Ihnen überwiesen wird. Ein pseudonymisiertes Patientenprofil finden Sie im Anhang (Seite 2).",
      infoTextPatientRequest:
        "Wenn Sie freie Kapazitäten haben und den Patienten aufnehmen können oder mehr Informationen benötigen, kontaktieren Sie bitte die Kontaktperson des Krankenhauses und nennen Sie die Recare-Patienten-Identifikationsnummer  {select:patientId}.",
      infoTextPatientRequestLineOne:
        "Sie haben eine Patientenanfrage erhalten. Anbei finden Sie das pseudomisiertes Patientenprofil. ",
      infoTextPatientValidation:
        "Wenn Sie mehr Informationen benötigen, kontaktieren Sie bitte direkt die Kontaktperson des Krankenhauses und nennen Sie die Recare-Identifikationsnummer {select:patientId}.",
      infoTextPatientValidationLineOne:
        "Ein Patient wurde Ihnen zugewiesen. Anbei finden Sie ein pseudomisiertes Patientenprofil.",
      subtitleHospitalName: (args: { senderName: string }) =>
        template("vom {{senderName}}", args),
      titlePatientRequest: "Patientenanfrage",
      titlePatientValidation: "Patientenzuweisung",
    },
    faxEvents: {
      faxBounced: "Fax konnte nicht zugestellt werden",
      faxDelivered: (args: { receivername: string }) =>
        template("Fax erfolgreich an {{receivername}} durchgestellt", args),
      faxEventTitle:
        "Bitte klicken Sie ANNEHMEN wenn dieser Empfänger ausgewählt wurde.",
      faxNumberNotAvailable: (args: { receivername: string }) =>
        template("Faxnummer von {{receivername}} nicht vorhanden", args),
      faxSent: (args: { receivername: string }) =>
        template("Fax wurde an {{receivername}} versendet", args),
    },
    faxIntroBodyOne: (args: { patientId: string }) =>
      template(
        "die Klinik sucht nach einer passenden Versorgung für eine Patientin/einen Patienten mit Nachsorgebedarf – und fragt Sie gezielt nach freien Kapazitäten an. Bitte geben Sie der Klinik Ihre Rückmeldung unter Nennung der Patienten-Identifikationsnummer {{patientId}}  der Recare-Plattform.",
        args,
      ),
    faxIntroBodyTwo:
      "Erhalten Sie künftig mehr kostenlose, zu Ihrem Leistungsspektrum passende Klinikanfragen mit der Recare-Plattform. Registrieren Sie sich direkt:\nhttps://recaresolutions.com/nachversorger-registrierung/.",
    faxIntroSalutation: "Sehr geehrte Damen und Herren,",
    fromSender: (args: { clinicName: string }) =>
      template("von {{clinicName}}", args),
    optOutOfReceivingFax:
      "Empfänger erhält Faxe (für Status INACTIVE und CREATED)",
    optOutOfSendingFax:
      "Keine Faxe an inaktive Empfänger senden (Pflege & Reha)",
    recareId: (args: { patientId: string }) =>
      template("Recare-ID: {{patientId}}", args),
    titleNew: "Anfrage für eine Patientin/einen Patienten mit Nachsorgebedarf",
  },
  fileHisUploadComponent: {
    title:
      "Bitte wählen Sie die Dateien, die Sie hochladen und teilen möchten aus",
  },
  fileSection: {
    cannotDelete:
      "Diese Datei kann nicht gelöscht werden, da Sie nicht von Ihnen hochgeladen wurde.",
    dragAndDrop: {
      disclaimerFileSize: "max. Dateigröße: 20 MB",
      disclaimerFileType: "unterstützte Dateitypen: PDF",
      instructionDragAndDrop: "Hochzuladende Dateien hier hinziehen",
      selectBetweenDragAndDropOptions: "Oder",
      title: "Dateien vom Computer hochladen",
    },
    eligibilityPage: {
      sendFileLabel: "Zur Dateifunktion",
      sendFileToolTip: "Testen Sie die neue Dateifunktion",
    },
    events: {
      documentIsBeingUploaded: "Ihr Dokument wird hochgeladen…",
      fileWasSharedWith: (args: { fileName: string }) =>
        template("{{fileName}} wurde geteilt mit:", args),
    },
    explanationIcon:
      "Dieses Symbol zeigt an, dass die Datei Ende-zu-Ende verschlüsselt ist.",
    explanationText:
      "Laden Sie hier PDF-Dateien von bis zu 20 MB zentral hoch und teilen diese sicher mit Hilfe von Ende-zu-Ende-Verschlüsselung direkt mit Leistungserbringern, klicken Sie hierfür auf “Teilen”. Zusätzlich sehen Sie hier auch alle Dateien, die Sie von Leistungserbringern erhalten haben. Sobald eine Zuweisung durch Sie final erfolgt ist, können die bereitgestellten Dateien durch andere als den ausgewählten Nachversorger nicht mehr heruntergeladen werden. Der Dateinname wird entsprechend verschlüsselt.",
    fileDeleteModal: {
      title: "Sind Sie sicher, dass Sie diese Datei löschen wollen?",
      toastSuccess: "Datei erfolgreich gelöscht",
    },
    fileFaxComponent: "Möchten Sie dieses Dokument an den Kostenträger faxen?",
    fileIntegration: {
      fileNotYetImported: "Noch nicht importiert",
      import: "KIS-Import",
      importedFromHis: "Aus KIS importiert",
      title: "Bitte wählen Sie die Datei/en aus, die Sie importieren möchten",
      toastSuccess:
        'Erfolgreich importiert. Sie finden die Datei/en im Reiter "Dateien", nachdem Sie die Patientenerstellung abgeschlossen haben.',
    },
    fileListComponent: {
      category: "Kategorie",
      dateSourceColumn: "Datum und Quelle",
      descriptionFilesAvailable:
        "Diese Dateien können hochgeladen und geteilt werden",
      descriptionFilesUnsupported:
        "Diese Dateien können nicht hochgeladen werden da sie nicht den Dateistandards entsprechen",
      descriptionNewFileVersionAvailable: "Neuere Dateiversion vorhanden",
      employeeColumn: "Mitarbeiter",
      fileErrorReason: "Fehlergrund",
      fileName: "Name der Datei",
      placeholderNothingReceived: "Sie haben noch keine Dateien erhalten.",
      placeholderNothingSent: "Sie haben noch keine Dateien versendet.",
      placeholderNothingUploaded: "Sie haben noch keine Dateien hochgeladen.",
      reason: "Grund",
      reloadButton: "Aktualisieren",
      shareDate: "Datum",
      sharedBy: "Geteilt von",
      sharedWith: "Geteilt mit",
      shareOptions: {
        allReceivers: "Allen Empfängern",
        allReceiversHospital: "Allen Empfängern + Krankenhaus",
        allReceiversInsurance: "Allen Empfängern + Krankenversicherung",
        clinic: "Krankenhaus",
        insurance: "Krankenversicherung",
        noOne: "Nicht geteilt",
        selectedReceivers: "Ausgewählten Empfängern",
        selectedReceiversHospital: "Ausgewählten Empfängern + Krankenhaus",
        selectedReceiversInsurance:
          "Ausgewählten Empfängern + Krankenversicherung",
      },
      source: "Ursprung",
      sourceComputerInfo: "Vom Computer",
      sourceHIS: "-",
      sourceHISInfo: "Aus dem KIS",
      status: "Status",
      statusFormatError: "Formatfehler",
      statusSizeError: "Dateigröße",
      titleFileReceived: "test",
      titleFilesAvailable: "Verfügbare Dateien",
      titleFilesReceived: "Empfangene Dateien",
      titleFilesSent: "Versendete Dateien",
      titleFilesUnavailable: "Nicht hochladbare Dateien",
      titleFilesUnsupported: "Nicht hochladbare Dateien",
      titleFilesUploaded: "Hochgeladene Dateien",
      uploadDate: "Hochgeladen am",
      uploadedBy: "Hochgeladen von",
      validation: {
        tooltipHisFileNoDeletion:
          "Die Datei liegt nur im KIS vor und kann daher nicht gelöscht werden",
      },
    },
    fileShareComponent: {
      shareOptions: {
        allPotentialReceivers: "Allen potenziellen Empfängern",
        noone: "Niemand",
        noResults:
          "Noch keine Anfragen gesendet - bitte wählen Sie vorerst eine andere Option",
        selectedReceivers: "Ausgewählten Empfängern",
        shareOptionsLegalDisclaimer:
          "Dokumente können personenbezogene Daten enthalten, die über die Recare Suche nicht freigeben wurden. Bitte stellen Sie vor dem Versand sicher, dass alle potenziellen Empfänger Zugriff auf das Dokument haben dürfen. Sobald eine Zuweisung durch Sie final erfolgt ist, können die bereitgestellten Dateien durch andere als den ausgewählten Nachversorger nicht mehr heruntergeladen werden. Der Dateinname wird entsprechend verschlüsselt.",
      },
      shareWithCostpayerTitle:
        "Möchten Sie die Datei mit dem Kostenträger teilen?",
      shareWithHospitalTitle:
        "Möchten Sie die Datei mit dem Krankenhaus teilen?",
      shareWithReceiverTitle: "Mit wem möchten Sie diese Datei teilen?",
      successPage: {
        explanationText: "Sie haben Ihre Dateien geteilt mit:",
        title: "Erfolgreich geteilt!",
      },
      toastSuccess: "Ihre Dateiänderung wurde erfolgreich gespeichert",
    },
    fileUploadComponent: {
      descriptionFilesReceived: "Dateien die mit Ihnen geteilt wurden",
      encryptedFieldSecure: "Diese Informationen sind verschlüsselt",
      fileCategory: "Kategorie auswählen",
      fileName: "Name der Datei",
      infoSectionOneText:
        "Auf der Datei-Übersichtsseite können Sie Dateien mit allen potentiellen Empfängern teilen.",
      infoSectionOneTitle: "Mit allen potentiellen Empfängern teilen",
      infoSectionThreeText:
        "Dateien können Sie jederzeit löschen, indem Sie auf die drei Punkte neben der hochgeladenen Datei klicken. Dateien werden automatisch für alle Parteien gelöscht. Durch Klicken auf die drei Punkte können Sie auch die Zugriffsrechte anpassen, um Dateien zu teilen oder den Zugriff zu entfernen.",
      infoSectionThreeTitle: "Dateien löschen und Zugriffsrechte ändern",
      infoSectionTwoText:
        "Falls Sie eine Datei nur mit einem einzigen Empfänger teilen möchten, gehen Sie zu “Suche” und klicken Sie auf den Empfänger Ihrer Wahl, um zum Nachrichtencenter zu gelangen. Sie können dort unter der Rubrik Dateien Dokumente teilen. Dokumente die über das Nachrichtencenter versendet werden sind auch in der Datei-Übersicht einsehbar. Diese sind als geteilt mit “Ausgewählten Empfängern” gekennzeichnet.",
      infoSectionTwoTitle: "Mit einem einzigen Empfänger teilen",
      selectFile: "Datei auswählen",
      titleModal:
        "Bitte überprüfen Sie den Dateinamen und wählen Sie eine Kategorie",
      toastSuccess: "Ihre Datei wurde erfolgreich hochgeladen.",
      uploadFile: "Datei hochladen",
    },
    fileUploadStates: {
      cancelled: (args: { numberOfFiles: string }) =>
        template(" {{numberOfFiles}} Hochladen wurde abgebrochen", args),
      cancelUpload: (args: { numberOfFiles: string }) =>
        template(" {{numberOfFiles}} Hochladen abbrechen", args),
      descriptionFilesAvailable: "These files can be uploaded and shared",
      error: (args: { numberOfFiles: string }) =>
        template("Fehlgeschlagene Hochladevorgänge - {{numberOfFiles}} ", args),
      errorRetryPrompt: "Hochladen war nicht erfolgreich",
      loading: (args: { numberOfFiles: string }) =>
        template("Hochladen von {{numberOfFiles}} im Gang", args),
      statusNotShared: "Ungeteilt",
      statusShared: "Geteilt",
      success: (args: { numberOfFiles: string }) =>
        template("Erfolgreiches Hochladen von {{numberOfFiles}} ", args),
      uploadStopped: "Hochladen abgebrochen",
    },
    headline: "Datei-Übersicht",
    seeDocument: "Siehe Datei",
    tabTitle: "Dateien",
    toastError:
      "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie Recare.",
    toastErrorTitle: "Fehler beim Hochladen",
  },
  fileUploader: {
    emptyFileError:
      "Die Datei ist 0 Bytes und konnte daher nicht hochgeladen werden.",
    fileCouldNotFetch:
      "Hochladen war nicht erfolgreich. Bitte probieren Sie es erneut",
    fileError: "Bitte wählen Sie eine Datei aus",
    fileSizeError: "Die Datei überschreitet die Maximalgröße von 20 MB",
    fileTypeError:
      "Diese Funktionalität erlaubt nur das Hochladen und Teilen von PDF Dokumenten",
    fileUploadError: "Die Datei konnte nicht hochgeladen werden",
    titleError: "Bitte geben Sie einen Dateinamen ein",
  },
  filterBar: {
    checkboxTransitionalCare: "Nur Suchen mit Übergangspflege anzeigen",
    chipTransitionalCare: "Übergangspflege",
    clearAll: "löschen",
    filterBy: "Filtern nach",
    saveAsDefault: "Filter als Standard speichern",
    senderClinic: "Krankenhaus",
    showLess: "Weniger anzeigen",
    showMore: "Mehr anzeigen",
    showOnlyElective: "Elektive Patienten",
    showPendingEligibility:
      "Nur Patienten mit ausstehender Kostenübernahme anzeigen",
    specialization: "Fachabteilung",
    titleEligibility: "Kostenübernahme",
    titleTransitionalCare: "Übergangspflege",
  },
  general: {
    and: "und",
    asap: "Ab sofort",
    aUser: "ein Benutzer",
    banner: {
      cta: {
        noEncryptionAccessAlert:
          "Wir konnten Ihr Kundenkonto nicht mit unserer sicheren Verschlüsselungs-Umgebung verbinden. Aus diesem Grund können Sie nicht auf verschlüsselte Daten zugreifen oder neue Patienten erstellen. Bitte loggen Sie sich aus und erneut ein. Falls das Problem weiterhin besteht, kontaktieren Sie bitte unseren Support. ",
        noEncryptionAccessAlertAction: "Logout",
        noEncryptionAccessAlertTitle: "Verschlüsselung nicht verfügbar",
        noSealdConnectionAlert:
          "Ihre Verbindung zu seald.io ist nicht verfügbar, wodurch die Ende-zu-Ende Verschlüsselung behindert wird. Bitte prüfen Sie ihre Verbindung {select:hier} oder kontaktieren Sie unseren Kundendienst, um das Problem zu beheben.",
        partialEncryptionAccessInfo:
          "Die verschlüsselten Daten sind derzeit für eine kurze Zeit nicht verfügbar. Bitte haben Sie Geduld und wenden Sie sich an Ihre Recare-Kontaktperson, wenn das Problem weiterhin besteht.",
        sunsetInternetExplorer: "Browser ändern",
      },
      internalSealdError:
        "Die Verschlüsselung (Seald) funktioniert nicht, wahrscheinlich weil Sie eines unserer gemeinsamen Konten zur gleichen Zeit wie jemand anderes in verschiedenen Testumgebungen verwenden. Bitte loggen Sie sich aus, löschen Sie den Cache/Cookies und melden Sie sich erneut an.",
      noAccessPatientDataInternetExplorer:
        "Im Internet Explorer können wir die Patientendaten nicht sicher verarbeiten. Um die Daten anzuzeigen, wechseln Sie bitte den Browser.",
      noAccessPatientDataInternetExplorerTitle:
        "Patientendaten aufgrund Ihres Browsers gesperrt ",
      refreshSealdButtonClicked: "Schaltfläche Aktualisieren geklickt",
      refreshSealdFailed:
        "Keine Verbindung möglich. Bitte versuchen Sie es erneut.",
      refreshSealdInProgress: "Verbindung wird überprüft",
      refreshSealdSuccess: "Verbindung wurde erfolgreich hergestellt.",
      searchCategoryMoved:
        "Diese Suche wurde ursprünglich für eine andere Suchkategorie gestartet. Bitte beachten Sie, dass die Dokumentation nur die aktuell angezeigte Suchkategorie abdeckt.",
      sunsetInternetExplorer:
        "Recare wird die Unterstützung für den Internet Explorers 11 bald einstellen, da dieser von Microsoft nicht mehr gewartet wird. Für weitere Informationen und Hilfe klicken Sie auf den folgenden Button",
      sunsetInternetExplorerSenders:
        "Recare wird die Unterstützung für den Internet Explorers 11 ab dem 01. September 2021 einstellen, da der Browser von Microsoft nicht mehr gewartet wird. Für weitere Informationen und Hilfe klicken Sie auf den folgenden Button",
    },
    captionDateNotInPast:
      "Hinweis: Beide Daten dürfen nicht in der Vergangenheit liegen.",
    characterCounter: (args: { count: string; maxLength: string }) =>
      template("{{count}} von {{maxLength}} Zeichen übrig", args),
    colon: ":",
    columnHeader: "Spaltenüberschrift",
    confirmation: "Bestätigung",
    containsLink:
      "Aus datenschutzrechtlichen Gründen darf hier kein Link eingegeben werden.",
    date: "Stand",
    disablePatientCreateButton:
      "Ihr Nutzerkonto hat keinen verschlüsselten Zugang zu Ihrer Einrichtung. Versuchen Sie es erneut oder kontaktieren Sie den Recare Kundendienst.",
    error:
      "Entschuldigung, es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    errorCodes: {
      1000: "SSO-Login fehlgeschlagen, bitte versuchen Sie es erneut oder kontaktieren Sie Recare.",
      1001: "Sie sind bereits mit einem anderen Konto in der Sitzung angemeldet. Bitte überprüfen Sie Ihre E-Mail-Adresse, da diese nicht mit der bei der Anmeldung verwendeten E-Mail-Adresse übereinstimmt. Verwenden Sie die gleiche E-Mail-Adresse und versuchen Sie es erneut.",
      1002: "Dieses Konto ist nicht autorisiert. Bitte kontaktieren Sie Ihre IT-Abteilung.",
      1003: "Sie können sich nicht einloggen, da das Konto inaktiv ist. Bitte wenden Sie sich an Ihren Nutzerverwalter oder an Ihre Recare-Kontaktperson.",
    },
    errorDateInPast: "Datum kann nicht in der Vergangenheit liegen",
    errorIELink:
      "Wie Sie Ihren Browser und Standardbrowser ändern können, {select:finden Sie hier} . Sollte dies nicht möglich sein, kontaktieren Sie bitte Ihre IT.",
    errorNotSupportIE:
      "Recare unterstützt die Verwendung des Internet Explorers nicht mehr. Bitte nutzen Sie einen anderen Browser um fortzufahren (z.B. Firefox, Edge oder Chrome)",
    errorNotSupprtIEPatient:
      "Es tut uns Leid, aber dieses Patientenprofil kann nicht geöffnet werden, da Sie gerade Internet Explorer als Browser nutzen und persönliche Daten nicht sicher darüber übermittelt werden können. ",
    errorRequestLimit:
      "Sie haben Ihr Anfragelimit für diese Aktion erreicht. Bitte versuchen Sie es später noch einmal.",
    fax: "Fax",
    fieldsMissing: "",
    for: "für",
    formValidationError:
      "Bitte stellen Sie sicher, dass die Felder korrekt ausgefüllt wurden",
    formValidationErrorOnlyNumbers: "Bitte geben Sie nur Ziffern an",
    from: "von",
    fromAge: "ab",
    fromDate: "Ab dem",
    group: "Gruppe",
    helloName: (args: { name: string }) => template("Hallo, {{name}}!", args),
    itChecklist: "IT Checkliste",
    lessThan: "unter",
    loading: "Wird geladen",
    loginPostIdentityReset:
      "Ihre Kontensicherheit wurde aktualisiert. Bitte loggen Sie sich erneut ein, und wenn aufgefordert, geben Sie den neuen Bestätigungscode, der Ihnen per Email gesendet wurde, ein.",
    logOut: "Ausloggen",
    missing: "Fehlt",
    moreThan: "über",
    navbarAccount: "Konto",
    navbarBeamerItem: "Neuigkeiten von Recare",
    navbarClinicSwitcherPlaceholder: "Klinikum auswählen",
    navbarCustomerSuppport: "Kundenservice",
    navbarLogoAlt: "Recare Logo - zur Startseite",
    navbarMenu: "Menü",
    navbarNotificationCenter: "Mitteilungen",
    navbarProviderSwitcherPlaceholder: "Nachversorger auswählen",
    never: "niemals",
    new: "Neu",
    no: "Nein",
    none: "Keine",
    noneR: "Keiner",
    noPreference: "Keine Präferenz",
    noResult: "Keine Ergebnisse",
    notAuthorizedToAccessThisPage:
      "Sie haben derzeit nicht die Berechtigung, auf diese Seite zuzugreifen.",
    notAuthorizedToPerformAction:
      "Sie sind nicht berechtigt, dies durchzuführen.",
    notSpecified: "",
    oneMoment: "Einen Moment",
    oops: "",
    or: "oder",
    other: "Anderes",
    printingPleaseWait: "Drucken wird vorbereitet. Bitte warten Sie...",
    printModal: {
      receiver: {
        chatHistory: "Nachrichtenverlauf",
        patientProfile: "Patientenprofil",
      },
      sender: {
        chatHistory: "Nachrichtenverlauf",
        informationAboutProvider: (args: { providerName: string }) =>
          template("Informationen über {{providerName}}", args),
      },
      title: "Was möchten Sie gerne drucken?",
    },
    promptFilterSet: "Die ausgewählten Filter wurden angewendet.",
    redactEncryptedData: {
      info: "Verschlüsselte Daten wurden aus Datenschutzgründen gelöscht.",
      placeholder: "entfernt",
    },
    refreshToSeeChanges: "Aktualisieren Sie, um Änderungen zu sehen.",
    reportContent: "Inhalte melden",
    required: "Erforderlich",
    retry: "wiederholen",
    secure: "Sicher",
    selectDefaultPlaceholder: "Geben Sie eine oder mehrere Optionen ein",
    selectDefaultPlaceholderShort: "Bitte eingeben oder auswählen",
    smallerThan: "kleiner als",
    solutionsMissing: "[Lösungen fehlen]",
    sort: "Sortieren",
    status: {
      error: "Etwas ist schief gelaufen",
      loading: "Wird geladen, bitte warten",
      new: "Neu",
    },
    success: "Angaben wurden erfolgreich gespeichert",
    switchToReceiver: "Zu Empfänger wechseln",
    switchToSender: "Zu Krankenhaus wechseln",
    tallerThan: "größer als",
    to: "bis",
    toAge: "bis",
    today: "Heute",
    toEditPleaseContactRecare:
      "Bitte kontaktieren Sie Recare, wenn Sie die Fachabteilung ändern möchten.",
    tomorrow: "Morgen",
    toRecipient: "An",
    toWeight: "bis",
    transferTo: "Übertragen auf",
    tryAgain: "",
    unknown: "Unbekannt",
    untilDate: "bis zum",
    upload: "Hochladen",
    uploadFailed:
      "Ihr Upload ist fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an einen Vertreter von recare",
    websiteUrl: "https://www.recaresolutions.com",
    whatsNew: "Neues von Recare",
    yesterday: "Gestern",
    you: "Sie",
  },
  hintImportIE:
    "Der Import von Patientendaten ist nicht für Internet Explorer oder Microsoft Edge älter als Version 80 verfügbar. Bitte verwenden Sie einen aktuellen Browser wie Google Chrome, Mozilla Firefox oder Microsoft Edge (mindestens Version 80).",
  insuranceApp: {
    approveEligibility:
      "Sind Sie sicher, dass sie die Kostenübernahme des Patienten bestätigen möchten?",
    banners: {
      bannerStartPrereservation:
        "Sie sind für die Vorreservierung einer Klinik verantwortlich. Bitte starten Sie die Suche oder überprüfen Sie die Wunschklinik. Der AHB-Antrag wird Ihnen nach erfolgtem Eingriff nachgereicht.",
      bannerUploadForms:
        "Der Kostenträger benötigt den AHB-Antrag, um eine Entscheidung über die Kostenübernahme des Patienten zu treffen. Bitte füllen Sie die Anträge nach erfolgtem Eingriff aus, um den Prozess zu beschleunigen.",
      completeProfile: "Patientenprofil vervollständigen",
      patientEligibilityPending: "Kostenübernahme ausstehend",
      patientIsEligible: "Kostenübernahme bestätigt",
      patientNotEligible: "Kostenübernahme abgelehnt",
    },
    captionAssessment:
      "Der Kostenträger des Patienten ist ein Partner von Recare. Er wird die Suche übernehmen, sobald das Patientenprofil ausgefüllt ist und Sie die Suche übergeben haben.",
    captionHandOverToInsurance:
      "Der Kostenträger des Patienten ist ein Partner von Recare und übernimmt die Vorreservierung für Sie. Bitte klicken Sie auf die untere Schaltfläche, um die Suche an den Kostenträger zu übergeben.",
    captionInsuranceEditSearch:
      "Sie können hier einige der oben stehenden Suchkriterien ändern:",
    confirmDeleteModal: "Möchten Sie die Datei wirklich löschen?",
    confirmDeleteModalText:
      "Sie können jederzeit das Formular neu ausfüllen oder eine neue Datei hochladen.",
    deleteForm: "Formular löschen",
    eligibility: "Kostenübernahme",
    eligibilityEvents: {
      transferFailedCodeMissing:
        "Übermittlung der Anträge fehlgeschlagen - fehlende/r ICD-10 Code/s ",
      transferFailedErrorUnknown:
        "Übermittlung der Anträge fehlgeschlagen - Fehler unbekannt",
      transferSuccessful: "Anträge erfolgreich übermittelt",
    },
    eligibilityHeaderToBeProcessed:
      "Um den AHB-Antrag an den Kostenträger zu übermitteln, füllen Sie die Formulare aus oder laden diese als Datei hoch.",
    eligibilityTab: {
      eligibilityCard: {
        approve: "Bestätigen",
        captionTextProcessed:
          "Sobald die Anträge hochgeladen und an den Kostenträger übermittelt wurden, trifft dieser eine Entscheidung über die Kostenübernahme des Patienten.",
        clinicDidntSendYet:
          "Das Krankenhaus hat den Antrag auf AHB noch nicht übermittelt. Bei Fragen kontaktieren Sie bitte das Krankenhaus.",
        pleaseShareFormsInfo:
          "Bitte übermitteln Sie die Dokumente an den Kostenträger, sodass eine Entscheidung zur Kostenübernahme getroffen werden kann.",
        reject: "Ablehnen",
        rejectModalTitle:
          "Warum lehnen Sie die Kostenübernahme für diesen Patienten ab?",
        subtitleEligibilityNotSet:
          "Die Kostenübernahme wurde noch nicht bearbeitet",
        titleApproved: "bestätigt",
        titleAwaitsCostPayerPartOne: "Kostenübernahme",
        titleAwaitsCostPayerPartTwo:
          "wartet auf eine Entscheidung des Kostenträgers",
        titleClinicInformation: "Kontaktinformationen des Krankenhauses",
        titleCostPayerInformation: "Kontaktinformationen des Kostenträgers",
        titleHasBeen: "Kostenübernahme wurde",
        titleInProcessPartOne: "Kostenübernahme wartet auf",
        titleInProcessPartThree: "bitte teilen Sie diese hier mit",
        titleInProcessPartTwo: "eine Entscheidung",
        titleRejected: "abgelehnt",
        titleToBeProcessed:
          "Bitte teilen Sie die Entscheidung zur Kostenübernahme mit",
      },
      hintModalShareFroms:
        "Das Übermitteln der Anträge ist notwendig um die Überleitung des Patienten abzuschließen.",
      medicalFormName: "Ärztlicher Befundbericht",
      replySection: {
        newResponse: "Neue Antwort",
        noReplyInfo:
          "Nachdem Sie die Dokumente eingereicht haben, wird die Antwort der Krankenkasse hier für Sie abrufbar sein.",
        openedResponse: "Antwort erhalten",
        printDocument: "Dokument drucken",
        replySectionTitle: "Antwort von der Krankenkasse",
        viewDocument: "Dokument ansehen",
      },
      requestFormName: "Einheitlicher Antrag",
      sendDocuments: {
        cancelButton: "Abbrechen",
        confirmationSubtitle:
          "Dokumente können nach dem Absenden zurückgezogen werden.",
        confirmationTitle:
          "Sind Sie sicher, dass Sie die Dokumente an die Kranken Kasse senden möchten?",
        errorMessage: "Anträge konnten nicht übermittelt werden.",
        otherInsuranceLink: "Der Name der Krankenkasse ist nicht richtig",
        sendButton: "Jetzt senden",
        statusChipComplete: "Vollständig ausgefüllt",
        statusChipNotFilled: "Nicht ausgefüllt",
        submitFormButton: "Informationen an die Krankenkasse senden",
        subtitle:
          "Um einen Kostenantrag an die Krankenkasse zu senden, füllen Sie bitte die folgenden zwei Formulare aus und speichern Sie sie als PDF.",
        successMessage:
          "Die Anträge wurden erfolgreich an die Krankenkasse übermittelt.",
        title: "Dokumente an die Krankenkasse einreichen",
        toFormLink: "Zum Formular",
        tryAgain: "Erneut versuchen",
        withdrawButton: "Antrag zurückziehen",
        withdrawModal: {
          otherPlaceholder: "Erzählen Sie uns mehr (maximal 600 Zeichen)",
          otherReason: "Sonstiges",
          reasonFour: "Versicherte(r) ist verstorben",
          reasonOne: "Versicherte(r) verzichtet auf die Reha-Leistung",
          reasonThree: "Reha ist nicht erfolgversprechend",
          reasonTwo: "Versicherte(r) ist nicht rehabilitationsfähig",
          withdrawSubtitle:
            "Bitte wählen Sie den passenden Grund aus (Pflichtfeld). Der ausgewählte Grund wird an die Krankenkasse weitergegeben.",
          withdrawTitle: "Warum möchten Sie ihre Informationen zurückziehen?",
        },
      },
      sunsetEligibilityMessengerText:
        'Hier können Sie mühelos die relevanten Dokumente zur Kostenübernahme erstellen. Klicken Sie dazu einfach auf "Formular ausfüllen" beim jeweiligen Dokument. Bitte beachten Sie, dass in der Rubrik "Einheitlicher Antrag" sowie im Bereich "Ärztlicher Befundbericht" jeweils nur ein Dokument ausgewählt werden kann.',
      sunsetEligibilityMessengerTitle: "Kostenübernahme-Dokumente anlegen",
      titleEligibility: "Kostenübernahme für Anschlussrehabilitation",
      titleModalShareFroms:
        "Sie haben die Anträge fertiggestellt. Möchten Sie diese dem Kostenträger jetzt übermitteln?",
      tooltipEncryption:
        "Die Informationen des Antrags sind verschlüsselt und werden sicher übermittelt.",
    },
    formDeleteFailure:
      "Wenn das Formular nicht gelöscht werden konnte, versuchen Sie es erneut oder wenden Sie sich an einen Recare-Vertreter.",
    guidanceToNextSteps:
      "Um die Suche zu starten, gehen Sie zur {select:Suche}. Sie können auch den Antrag in {select:Kostenübernahme}  überprüfen um eine Entscheidung darüber zu treffen.",
    infoModalEditSearchCriteria:
      "Bitte bearbeiten Sie die Suchkriterien falls notwendig. Das Krankenhaus wird über die Änderungen informiert.",
    rejectEligibilityReasons: {
      otherReason: "Sonstiges",
      otherReasonLabel: "Bitte erläutern Sie",
    },
    requestCancelled: "Die Wunschklinik wurde vom Krankenhaus zurück gezogen",
    searchWasStopped:
      "Die Suche wurde vom Krankenhaus beendet. Falls nötig kann das Krankenhaus Ihnen den Fall wieder übergeben.",
    sunsetCostpayerFlow: {
      body: "Aufgrund von regulatorischen Änderungen beim DTA-Verfahren stellen wir unser Suchangebot für Kostenträger für AHB/Reha-Plätze ein. Je nach Kostenträger wird Ihre Suchfunktion und Ihr Recare-Zugang ab dem 01.04.2023 oder 01.05.2023 eingestellt (bitte kontaktieren Sie unseren Support falls Ihnen unklar ist, zu welchem Zeitpunkt Ihre Abschaltung erfolgt).  Alle laufenden Suchen stoppen wir automatisch vor Ablauf der Fristen. Bitte richten Sie sich darauf ein und informieren ggf. entsprechende Stellen. ",
      title:
        "Wichtige Information: Einstellung der Reha-Platz-Suche durch Kostenträger",
    },
    titleModalEditSearchCriteria: "Suchkriterien bearbeiten",
    undoEligibility:
      "Sind Sie sicher, dass sie die Kostenübernahme des Patienten rückgängig machen möchten?",
    yourPartner: "Dieser Versorger ist einer Ihrer Vertragspartner",
  },
  landmarks: {
    aside: "Ergänzende Inhalte",
    footer: "Fusszeile",
    header: "Kopfzeile",
    main: "Hauptinhalt",
    section: (args: { sectionName: string }) =>
      template("{{sectionName}}-Bereich", args),
  },
  legal: {
    agreedDpa:
      "Ich habe den Auftragsverarbeitungsvertrag gelesen und stimme diesem zu",
    agreedDpaLink:
      "Ich habe den {select:Auftragsverarbeitungsvertrag} gelesen und stimme diesem zu",
    agreedTermsAndConditions:
      "Ich habe die AGB zur Kenntnis genommen und akzeptiere diese",
    bannerCtaLegalSection: "Dokumente hier bestätigen",
    bannerDocumentsToSign:
      "Bitte beachten Sie, dass noch einige Vertragsdokumente Ihre Bestätigung benötigen. Sie sind ein bayerischer Versorgungsanbieter? Dann akzeptieren Sie die aktualisierten Allgemeinen Geschäftsbedingungen (AGB), um ab Januar 2024 zusätzlich Direktanfragen der Bürgerinnen und Bürger über den Pflegefinder Bayern zu erhalten.",
    bannerDocumentsToSignBcp:
      "Wichtige Vertragsdokumente benötigen noch Ihre Bestätigung. Um über den Pflegefinder Bayern zusätzlich Direktanfragen von Bürgerinnen und Bürgern zu erhalten, akzeptieren Sie bitte auch die aktualisierten Allgemeinen Geschäftsbedingungen (AGB). Die Bearbeitung dauert nur wenige Minuten.",
    bannerDocumentsToSignOnlyBToB:
      "Wichtige Vertragsdokumente benötigen noch Ihre Bestätigung. Bitte führen Sie diese jetzt durch – es dauert nur wenige Minuten.",
    bannerDocumentsToSignWeserEms:
      "Wichtige Vertragsdokumente benötigen noch Ihre Bestätigung. Bitte führen Sie diese jetzt durch – es dauert nur wenige Minuten.",
    cannotBeChanged: "Widerruf nur über unseren Kundenservice möglich",
    cannotBeChangedWithLink:
      "Widerruf nur über unseren {select:Kundenservice} möglich",
    churchOwnedQuestion:
      "Aus rechtlichen Gründen müssen wir Sie zunächst fragen, ob Ihre Einrichtung einen kirchlichen Träger hat.",
    clickOnLinkToAgree:
      "Klicken Sie auf den obigen Link, um dem AVV zuzustimmen",
    clickOnLinkToAgreeTC:
      "Klicken Sie auf den obigen Link, um den AGB zuzustimmen",
    differencesInTCVersions:
      "Recare hat eine Anpassung der Allgemeinen Geschäftsbedingungen vorgenommen. Damit wird für Nachversorger die Teilnahme an etwaigen künftigen Plattformen nicht nur für Krankenhäuser und institutionelle Kunden, sondern auch für Bürger und Bürgerinnen als Endnutzer ermöglicht. Alle anderen Regelungen der bisherigen AGB bleiben bestehen.",
    isChurchOwned: "unsere Einrichtung hat einen kirchlichen Träger.",
    isNotChruchOwned: "unsere Einrichtung hat keinen kirchlichen Träger.",
    legalAgreements: "Rechtliches",
    printableVersion: "Druckbare Version",
    readOnly: "Ansicht",
    toViewClickOnLink:
      "Bitte klicken Sie auf den folgenden Link um das Dokument zu öffnen",
    uploadedDateDPA: (args: { date: string }) =>
      template("Hochgeladen am {{date}}", args),
    uploadedOn: (args: { date: string }) =>
      template(
        "Hochgeladen am {{date}}. Um eine andere Datei hochzuladen, kontaktieren Sie bitte den Kundenservice.",
        args,
      ),
    viewFileInNewTab: "Dokument in neuem Reiter öffnen",
  },
  login: {
    biometricfailedAlert: {
      failed: "Fehler. Bitte Benutzername und Passwort verwenden.",
      falsePasswordLimitReached: (args: { timer: string }) =>
        template(
          "Ihr Konto wurde aufgrund von zu vielen falschen Anmeldeversuchen vorübergehend gesperrt. Bitte versuchen Sie es in {{timer}} Minuten erneut.",
          args,
        ),
      noCredentials: "",
      resetPasswordLimitReached: (args: { timer: string }) =>
        template(
          "Sie haben die maximale Anzahl von Versuchen zum Zurücksetzen des Passworts überschritten. Bitte versuchen Sie es in {{timer}} Minuten erneut.",
          args,
        ),
    },
    buttonSetNewPassword: "Neues Passwort festlegen",
    care: {
      changeToSender: "Sie möchten sich als Klinik anmelden,",
      loginTitle: "Anmeldung für Nachversorger",
    },
    challengeScreen: {
      infoText:
        "Bitte bestätigen Sie Ihr Konto. Wir haben Ihnen eine E-Mail mit einem Code geschickt. Bitte geben Sie diesen Code unten ein, um fortzufahren.",
      infoTextTimestamp: (args: { datetimestring: string }) =>
        template("Ihr Code wurde am {{datetimestring}} generiert.", args),
      placeholder: "Ihr Bestätigungscode",
      title: "Kontoüberprüfung",
      verificationInvalid:
        "Bestätigungscode ungültig - bitte geben Sie den Code aus Ihrer E-Mail ein.",
      verificationInvalidTimestamp: (args: { datetimestring: string }) =>
        template(
          "Bestätigungscode ungültig - bitte geben Sie den Code aus Ihrer E-Mail vom {{datetimestring}} ein.",
          args,
        ),
    },
    changeEnv: "",
    changePassword: "Passwort Ändern",
    clinic: {
      bannerDemo:
        "Dieser Login ist nur für die Test-Umgebung. Um auf Ihre echte Klinik-Umgebung zu kommen, klicken Sie bitte {select:hier}.",
      changeToReceiver: "Sie möchten sich als Nachversorger anmelden,",
      clickHere: "klicken Sie hier.",
      loginTitle: "Anmeldung für Kliniken",
      loginTitleDemo: "Anmeldung für Kliniken - Demo",
      loginTitlePreprod: "Anmeldung für Kliniken - Preprod",
    },
    confirmNewPasswordPlaceholder: "Geben Sie Ihr Passwort erneut ein",
    confirmYourPasswordTitle: "Bestätigen Sie Ihr neues Passwort",
    contactUrl: "https://www.recaresolutions.com/kontakt",
    contactUs: "Kontaktieren Sie uns",
    currentPassword: "Ihr derzeitiges Passwort",
    emailAddress: "Ihre E-Mail-Adresse",
    encryptionAccountWarning:
      "Es gibt derzeit kein anderes aktives Recare-Nutzerkonto in ihrer Organisation. Um zu gewährleisten, dass Sie nach dem Zurücksetzen des Passworts auf die verschlüsselten Patientendaten zugreifen können, stellen Sie sicher, dass es einen anderen aktiven Recare-Nutzerkonto gibt.",
    encryptionGeneralWarning:
      "Verschlüsselte Patientendaten sind möglicherweise für eine kurze Zeit nicht verfügbar.",
    enterNewPasswordPlaceholder: "Geben Sie Ihr Passwort ein",
    enterNewPasswordTitle: "Ihr neues Passwort",
    errorNewPasswordInvalid: "Bitte geben Sie ein gültiges Passwort ein.",
    errorPasswordMissing: "Bitte geben Sie Ihr Passwort ein",
    failedAlert: {
      text: "",
      title: "",
    },
    forgotPassword: "Passwort vergessen?",
    forgotPasswordDetails:
      "Geben Sie bitte Ihre E-Mail-Adresse ein. Wir werden Ihnen eine E-Mail senden, um Ihr Passwort zurücksetzen zu können.",
    forgotYourPassword: "Passwort vergessen?",
    haveAnAccountYet: "Sie haben noch kein Konto?",
    hintTemporaryLossOfEncryptedData:
      "Verschlüsselte Patientendaten können danach für kurze Zeit nicht verfügbar sein. Bitte haben Sie etwas Geduld. ",
    linkExpired: {
      activationExpiredReceiver:
        "Leider ist der Link zur Aktivierung Ihres Recare-Kontos nicht mehr gültig, da er aus Sicherheitsgründen nur 5 Tage aktiv bleibt. Bitte wenden Sie sich an die Ihnen bekannte Kontaktperson von Recare, um einen neuen Link zu erhalten.",
      activationExpiredSender:
        "Leider ist der Link zur Aktivierung Ihres Recare-Kontos nicht mehr gültig, da er aus Sicherheitsgründen nur 5 Tage aktiv bleibt. Bitte wenden Sie sich an den Nutzerverwalter Ihres Klinikums oder die Ihnen bekannte Kontaktperson von Recare, um einen neuen Link zu erhalten.",
      buttonToLogin: "Zur Login-Seite",
      linkExpiredTitle: "Dieser Link ist abgelaufen",
      passwordLinkExpiredReceiver:
        "Leider ist der Link zum Zurücksetzen Ihres Passworts nicht mehr gültig, da er aus Sicherheitsgründen nur 5 Tage aktiv bleibt. Bitte klicken Sie auf „Passwort vergessen?” auf der Login-Seite, um einen neuen Link zu erhalten. Alternativ können Sie die Ihnen bekannte Recare-Kontaktperson um Hilfe bitten.",
      passwordLinkExpiredSender:
        "Leider ist der Link zum Zurücksetzen Ihres Passworts nicht mehr gültig, da er aus Sicherheitsgründen nur 5 Tage aktiv bleibt. Bitte klicken Sie auf „Passwort vergessen?” auf der Login-Seite, um einen neuen Link zu erhalten. Alternativ können Sie  den Nutzerverwalter Ihres Klinikums oder die Ihnen bekannte Recare-Kontaktperson um Hilfe bitten.",
    },
    linkExpiredBackToLogin: "Zurück zum Login",
    linkExpiredExplanation:
      "Entschuldigung, der Aktivierungslink scheint abgelaufen zu sein, da er nur für 24 Stunden gültig ist. Bitte stellen Sie eine neue Anfrage für den Zugang zu Ihrem Konto, indem Sie sich an Ihren Benutzermanager oder die Kontaktperson bei Recare wenden.",
    linkExpiredTitle: "Der Aktivierungslink ist abgelaufen!",
    loading: {
      dontLeavePage:
        "Dies kann einige Sekunden dauern. Bitte verlassen oder aktualisieren Sie diese Seite nicht.",
      settingEnvironment: "Wir richten Ihre sichere Umgebung ein!",
    },
    loggedAs: "Angemeldet als",
    login: "Anmelden",
    loginPage: {
      accountVerificationInfo:
        "Bitte bestätigen Sie Ihr Konto, indem Sie den Code eingeben, den wir Ihnen per E-Mail zugesandt haben. Geben Sie den Code in das Feld unten ein, um fortzufahren.",
      challengeCodeValidation:
        "Bitte geben Sie den Bestätigungscode ein, der Ihnen per E-Mail zugesandt wurde. Bitte schließen Sie dieses Fenster in der Zwischenzeit nicht.",
      clickHere: "Klicken Sie hier",
      clinicInfo: "Kein Nachversoger?",
      clinicLink: "Als Klinik anmelden",
      confirmNewPasswordValidation:
        "Bitte geben Sie Ihr neues Passwort erneut ein, um es zu bestätigen.",
      contactInfo: "Noch kein Konto?",
      contactInfoLink: "Kontaktieren Sie uns",
      currentPasswordValidation: "Bitte geben Sie Ihr aktuelles Passwort ein.",
      demoInfoText:
        "Dieser Login ist nur für die Test-Umgebung. Um auf Ihre echte Klinik-Umgebung zu kommen, verwenden Sie diesen Link:",
      email: "E-Mail-Adresse",
      emailPlaceholder: "Geben Sie Ihre E-Mail-Adresse ein",
      emailValidation: "Bitte geben Sie Ihre gültige E-Mail-Adresse ein",
      encryptionWarning:
        "Verschlüsselte Patientendaten sind möglicherweise für kurze Zeit nicht verfügbar. Bitte haben Sie Geduld.",
      environmentInfo:
        "Sie melden sich in der Demoumgebung an. Um zur Produktionsumgebung zu wechseln,",
      errorInactiveAccount:
        "Das Passwort kann nicht zurückgesetzt werden, da das Konto inaktiv ist. Bitte wenden Sie sich an Ihren Nutzerverwalter oder an Ihre Recare-Kontaktperson.",
      errorInvalidMail: (args: { email: string }) =>
        template(
          "Die eingegebene E-Mail-Adresse {{email}} ist ungültig. Bitte überprüfen Sie Ihre Eingabe und versuchen es erneut.",
          args,
        ),
      errorLogInInactiveAccount:
        "Sie können sich nicht einloggen, da das Konto inaktiv ist. Bitte wenden Sie sich an Ihren Nutzerverwalter oder an Ihre Recare-Kontaktperson.",
      errorUnauthorisedAccount:
        "Dieses Konto ist nicht autorisiert. Bitte kontaktieren Sie Ihre IT-Abteilung.",
      forgotPassword: "Passwort vergessen?",
      hidePassword: "Passwort verbergen",
      loginButton: "Anmelden",
      logInNow: "Jetzt anmelden",
      newPasswordMatchValidation:
        "Die von Ihnen eingegebenen neuen Passwörter stimmen nicht überein. ",
      newPasswordValidation: "Bitte geben Sie ein neues Passwort ein.",
      password: "Passwort",
      passwordAttemptError:
        "Sie haben zu oft das falsche Passwort eingegeben. Versuchen Sie es später erneut.",
      passwordChangedInfo:
        "Ihr neues Passwort ist jetzt aktiv. Bitte melden Sie sich mit Ihren neuen Anmeldedaten an.",
      passwordChangedTitle: "Passwort erfolgreich geändert",
      passwordChangeError:
        "Ihr Konto wurde aufgrund zu vieler Passwortänderungen vorübergehend gesperrt. Bitte versuchen Sie es später erneut.",
      passwordChangeInfo:
        "Verwenden Sie mindestens 8 Zeichen, darunter Zahlen (0–9), Kleinbuchstaben (a–z) und Großbuchstaben (A–Z).",
      passwordIndicatorAriaLabel: "Indikator für die Passwortstärke",
      passwordMedium: "Mittel",
      passwordPlaceholder: "Geben Sie Ihr Passwort ein",
      passwordResetInfo:
        "Kein Problem! Wir senden Ihnen eine E-Mail zum Zurücksetzen. Bitte geben Sie die E-Mail-Adresse ein, mit der Sie sich bei Recare anmelden.",
      passwordResetWrongEmailReceiver:
        "Diese E-Mail-Adresse ist nicht mit einem Nachversorgerkonto verbunden.",
      passwordResetWrongEmailSender:
        "Diese E-Mail-Adresse ist nicht mit einem Klinikkonto verbunden.",
      passwordStrong: "Stark",
      passwordValidation: "Bitte geben Sie ihr aktuelles Passwort ein.",
      passwordVeryStrong: "Sehr stark",
      passwordVeryWeak: "Sehr schwach",
      passwordWeak: "Schwach",
      privacyPolicyInfo: "Hier machen wir keine Kompromisse:",
      productionLink: "Zur Klinik-Umgebung wechseln",
      receiverInfo: "Keine Klinik?",
      receiverInfoLink: "Als Nachversorger anmelden",
      saveNewPasswordButton: "Neues Passwort speichern",
      sendResetEmailButton: "E-Mail senden",
      setNewPasswordButton: "Neues Passwort festlegen",
      showPassword: "Passwort anzeigen",
      ssoErrorEmailMismatch:
        "Sie sind bereits mit einem anderen Konto in der Sitzung angemeldet. Bitte überprüfen Sie Ihre E-Mail-Adresse, da diese nicht mit der bei der Anmeldung verwendeten E-Mail-Adresse übereinstimmt. Verwenden Sie die gleiche E-Mail-Adresse und versuchen Sie es erneut. ",
      ssoErrorGeneric:
        "SSO-Login fehlgeschlagen, bitte versuchen Sie es erneut oder kontaktieren Sie Recare.",
      ssoInfo: "Single Sign-On aktiviert",
      titleAccountVerification: "Kontoverifizierung",
      titleAcp: "Login ACP",
      titleChangePassword: "Passwort ändern",
      titleChangePasswordFromEmail: "Neues Passwort festlegen",
      titleDemo: "demo",
      titleReceiver: "Login Nachversorger",
      titleResetPassword: "Passwort vergessen?",
      titleSender: "Login Klinik",
      toDashboardLink: "Zur Startseite",
      toggleIconAriaLabel:
        "Klicken Sie auf dieses Symbol, um das Passwort anzuzeigen",
      verificationCode: "Ihr Bestätigungscode",
      verificationCodePlaceholder: "Code hier eingeben",
      verificationCodeValidation:
        "Bitte geben Sie einen gültigen Bestätigungscode ein",
      verificationInfo:
        "Bitte bestätigen Sie Ihr Konto, indem Sie den Code eingeben, den wir Ihnen per E-Mail zugesandt haben. Geben Sie den Code in das Feld unten ein, um fortzufahren.",
      weakPasswordError:
        "Ihr Passwort ist zu schwach. Bitte stellen Sie sicher, dass es mindestens eine mittlere Stärke hat, indem Sie mindestens 8 Zeichen mit einer Mischung aus Buchstaben, Zahlen und Symbolen verwenden.",
    },
    loginSender: {
      contactInfo: "Noch kein Konto? Kontaktieren Sie uns",
      email: "E-Mail-Adresse:",
      emailPlaceholder: "Geben Sie Ihre E-Mail-Adresse ein",
      emailValidation: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      loginButton: "Anmelden",
      passwordValidation: "Bitte geben Sie ihr aktuelles Passwort ein",
      privacyPolicyInfo: "Hier machen wir keine Kompromisse: Datenschutz",
      ssoInfo: "Single Sign-On aktiviert",
      title: "Login Klinik",
    },
    loginTitle: "ANMELDEN",
    loginWithTouchId: "",
    mfa: {
      deviceVerified:
        "Ihr Gerät wurde bestätigt. Sie können sich jetzt anmelden.",
      invalidToken:
        "Der Verifizierungsversuch ist abgelaufen. Bitte melden Sie sich erneut an um sich zu verifizieren.",
      newDevice:
        "Sie melden sich gerade von einem neuen Gerät an. Zur Verifizierung der Anmeldung haben wir Ihnen eine E-Mail gesendet. ",
    },
    newPassword: "Ihr neues Passwort",
    newPasswordConfirmation: "Bestätigen Sie Ihr neues Passwort",
    password: "Ihr Passwort",
    passwordAlert: "",
    passwordChanged: "Das Passwort wurde schon geändert.",
    passwordChangeFailure: "Das derzeitige Passwort ist ungültig",
    passwordChangeSuccess: "Passwort erfolgreich geändert",
    passwordChangeSuccessLogin:
      "Passwort erfolgreich geändert, bitte loggen Sie sich erneut ein.",
    passwordConfirmation: "Bestätigen Sie Ihr neues Passwort",
    passwordForgottenSuccess:
      "Wenn ein Konto mit dieser E-Mail-Adresse existiert, wurde eine E-Mail an Sie gesendet. Bitte folgen Sie den Anweisungen in dieser E-Mail.",
    passwordForgottenTitle: "PASSWORT VERGESSEN",
    passwordRequirements: "Das Passwort muss",
    passwordResetError:
      "Bitte überprüfen Sie die eingegebene E-Mail-Adresse, da diese nicht als Nutzerkonto hinterlegt ist.",
    passwordResetSuccessfully:
      "Ihr neues Passwort ist nun aktiv. Bitte melden Sie sich mit Ihren Zugangsdaten an.",
    passwordsMustMatch: "Die Passwörter müssen übereinstimmen",
    passwordValidationLength: "Mind. 8 Zeichen lang sein",
    passwordValidationLowerCase: "Kleinbuchstaben (a-z) enthalten",
    passwordValidationNumeric: "Zahlen (0-9) enthalten",
    passwordValidationUpperCase: "Großbuchstaben (A-Z) enthalten",
    passwordVisibility: {
      hide: "Passwort verbergen",
      show: "Passwort anzeigen",
      ssoErrorGeneric: "",
    },
    privacyPolicy: "Datenschutzerklärung",
    sendResetEmail: "E-Mail senden",
    setNewPassword: (args: { email: string }) =>
      template("Geben Sie Ihr neues Passwort für {{email}} ein.", args),
    setNewPasswordEncryptionInformation:
      "Verschlüsselte Patientendaten können für kurze Zeit nicht verfügbar sein. Bitte haben Sie Geduld.",
    setNewPasswordInstructions:
      "Verwenden Sie mindestens 8 Zeichen, einschließlich Zahlen (0-9), Kleinbuchstaben (a-z) und Großbuchstaben (A-Z). Achten Sie darauf, dass beide Passwörter übereinstimmen.",
    setNewPasswordLoading: "Neues Password wird gespeichert",
    setNewPasswordNoMatch: "Die Passwörter stimmen nicht überein.",
    setNewPasswordTitle: "Neues Passwort festlegen",
    ssoEnabled: "Single Sign-On aktiviert",
    toastInactiveProvider:
      "Ihr Benutzerkonto ist nicht mit einer aktiven Einrichtung verknüpft - bitte kontaktieren Sie Recare.",
    tokenExpired: "Dieser Link ist nicht mehr gültig.",
    tokenInvalid: "Der Link ist ungültig.",
    tokenInvalidContactRecare:
      "Dieser Link ist nicht mehr gültig. Bitte kontaktieren Sie Recare, um einen neuen Link zu erhalten: +49 30 229 571 50",
    tooManyAttempts: (args: { time: string }) =>
      template("Zu viele Versuche, versuchen Sie es erneut in {{time}}.", args),
    welcome: "Willkommen bei Recare!",
    wrongEmailPassword: "Falsche Email oder Passwort",
  },
  menu: {
    addPatientButton: "Patient anlegen",
    ariaLabelBellIcon: "Es gibt neue Anfragen für diesen Anbieter",
    faq: "FAQ",
    more: "Mehr",
    navBarSender: {
      ariaLabelMainNavigation: "Hauptnavigation",
      ariaLabelTopLevelNavigation: "Sekundäre Navigation",
      selectCareproviderLabel: "Versorgungsanbieter auswählen",
      selectCareseekerLabel: "Krankenhaus auswählen",
      subtitleGateway: "Placeholder subtitle Gateway",
      subtitleReport: "Subtitle placeholder report",
      subtitleSearches: "Placeholder subtitle dashboard",
      subtitleUserManagement: "Subtitle user management placeholder",
      titleGateway: "Gateway",
      titleReport: "Bericht",
      titleSearches: "Dashboard",
      titleUserManagement: "Nutzerverwaltung",
    },
    newsletterSubscription: "Newsletter",
    onPremise: "Gateway Verwaltung",
    planning: "Planer",
    privacyPolicy: "Datenschutzerklärung",
    providerTraining: "Nachversorger-Schulung",
    rehabproviderTraining: "Rehabilitationskliniken-Schulung",
    report: "Bericht",
    searchBar: "Patient suchen",
    termsAndConditions: "",
    users: "Nutzerverwaltung",
  },
  messenger: {
    accessDeniedNeedAccess: (args: { name: string }) =>
      template(
        "Der Inhalt kann derzeit nicht angezeigt werden, da sich ein weiterer Benutzer der Einrichtung {{name}} anmelden muss, um den Zugang zu ermöglichen. Für weitere Informationen wenden Sie sich bitte an Recare.",
        args,
      ),
    accessibility: {
      emojiButtonLabel: "Emojis öffnen",
      messageInputFieldLabel: "Eine Nachricht eingeben",
      messageLabel: "mit der Nachricht",
      messageSeenNotification: "Ihre Nachricht wurde gelesen",
      rejectionInputFieldLabel: "Ablehnungsgründe eingeben",
      responseRejected: "Schaltfläche „Rückgängig machen“.",
    },
    careStartDateIsInThePastBannerMessage:
      "Wenn eine Aufnahme zu einem späteren Datum möglich ist, klicken Sie bitte auf ANNEHMEN und tragen Sie den gewünschten Aufnahmetermin ein.",
    fileReceiverHint: (args: { facility: string }) =>
      template("Ihre Datei wird an {select:{{facility}}} gesendet", args),
    fileReceiversHint: (args: { facility: string; secondFacility: string }) =>
      template(
        "Ihre Datei wird an {select:{{facility}}} und {select:{{secondFacility}}} gesendet",
        args,
      ),
    fileTransferEncrypted:
      "Dateien können sicher versendet werden. Die Übertragung ist verschlüsselt.",
    messageReceiverHint: (args: { facility: string }) =>
      template("Ihre Nachricht wird an {select:{{facility}}} gesendet", args),
    messageReceiversHint: (args: {
      facility: string;
      secondFacility: string;
    }) =>
      template(
        "Ihre Nachricht wird an {select:{{facility}}} und {select:{{secondFacility}}} gesendet",
        args,
      ),
    needToRequestAccess:
      "Derzeit können diese Inhalte nicht angezeigt werden. Um den Nachrichtenverlauf zu sehen, fordern Sie bitte hier den Zugang an",
    notAvailableForTransport: "Nicht verfügbar für Transportanfragen",
    notEncryptedHint:
      "Übermitteln Sie keine sensible Patientendaten. Die Nachrichten sind nicht verschlüsselt.",
    notSeen: "noch nicht gelesen",
    receiver: {
      commissionFee: (args: { price: string }) =>
        template("Nutzungsgebühr: {select:{{price}}} EUR", args),
      commissionInfo:
        "Kostenfreie Testphase: Bis zum 31.12.2022 berechnen wir keine Nutzungsgebühren!",
      commissionTooltip:
        "Kostenfreie Testphase: Bis zum 31.12.2022 berechnen wir keine Nutzungsgebühren!",
      fileRestrictionHint:
        "Dieses Krankenhaus erlaubt es nicht, Dateien zu empfangen. Für Textinformationen nutzen Sie bitte die Chatfunktion.",
      solutionsOffered:
        "Bitte wählen Sie aus, welche Art der Versorgung Sie diesem Patienten anbieten möchten:",
    },
    receiverHint: "",
    requestAccess: "Zugang anfordern",
    seen: "gelesen",
    sender: {
      chooseSolution: (args: { careproviderName: string }) =>
        template(
          "{{careproviderName}} bietet Ihnen folgende Leistungen an. Bitte wählen Sie die passende Versorgung aus: ",
          args,
        ),
      clinic: "Krankenhaus",
      costPayer: "Kostenträger",
      fileRestrictionHint:
        "Ihre Organisation hat den Empfang von Dateien gesperrt",
      geriatrics: "Krankenhaus",
      provider: "Anbieter",
      rehabClinic: "Reha-Einrichtung",
    },
    solutionsLabel: "Bitte wählen Sie eine Betreuungsform aus (Pflichtfeld)",
    yourChoice: "Ihre Auswahl (Pflichtfeld) *",
  },
  nativeApp: {
    contactUs: "Recare kontaktieren",
    errorCode: "Fehlercode",
    menu: {
      archived: "",
      attributed: "",
      dashboard: "",
      details: "",
      messages: "",
      pending: "",
      settings: "",
    },
    messages: {
      dateAlert: "",
      downloadAlert: "",
      inputPlaceholder: "",
      messageAlert: "",
      timeAlert: "",
    },
    pushNotifications: {
      attribution: {
        body: (args: { message: string }) => template("{{message}}", args),
        title: (args: { patientId: string }) =>
          template("Patient {{patientId}} wurde Ihnen zugewiesen", args),
      },
      attributionCancelled: {
        body: (args: { message: string }) => template("{{message}}", args),
        title: () => "",
      },
      availableAgain: {
        title: (args: { patientId: string }) =>
          template("Patient {{patientId}} ist wieder verfügbar", args),
      },
      newFile: {
        body: (args: { file: string }) =>
          template("Dokumentenbezeichnung: {{file}}", args),
        title: () => "",
      },
      newMessage: {
        body: (args: { message: string }) => template("{{message}}", args),
        title: (args: { patientId: string }) =>
          template("Neue Nachricht für {{patientId}}", args),
      },
      newMessageForClinic: {
        body: (args: { careprovider: string }) =>
          template(" {{careprovider}} hat Ihnen eine Nachricht gesendet", args),
      },
      newRequest: {
        body: (args: { transferDate: string; diagnosis: string }) =>
          template(
            "Transferdatum:  {{transferDate}}\nDiagnose: {{diagnosis}}",
            args,
          ),
        title: (args: { patientId: string; clinic: string }) =>
          template("Neue Anfrage {{patientId}} von {{clinic}}", args),
      },
      notAvailableAnymore: {
        title: (args: { patientId: string }) =>
          template("Patient {{patientId}} is not available anymore", args),
      },
      notAvailableAttribution: {
        body: "",
        title: () => "",
      },
      notAvailableSearchStopped: {
        body: () => "",
        title: () => "",
      },
      profileEdited: {
        title: (args: { patientId: string }) =>
          template("Profil des Patienten {{patientId}} wurde bearbeitet", args),
      },
    },
    settings: {
      capacity: {
        capacityAvailable: "Kapazitäten zurzeit verfügbar",
        capacityNotAvailable: (args: { capacityDate: string }) =>
          template("Kapazitäten wieder verfügbar ab {{capacityDate}}", args),
        detailPage: {
          ableToAcceptPatients: "Wir können neue Patienten aufnehmen ab dem:\n",
          capacityAvailableInfo: "Wir haben derzeit Kapazität\n",
          hintText: {
            capacityAvailable:
              "Aufgrund Ihrer Kapazität erhalten Sie zurzeit neue Patientenanfragen.",
            noCapacityWithoutTimeSelect:
              "Ihre Kapazitäten werden bis zum gewählten Datum blockiert, Sie erhalten keine weiteren Anfragen und alle Anfragen bis zum eingegebenen Datum werden abgelehnt. Sie können Ihre Kapazität in den Einstellungen jederzeit ändern.\n",
            noCapacityWithTimeSelect:
              "Ihre Kapazitäten werden bis zum gewählten Datum blockiert, Sie erhalten keine weiteren Anfragen und alle Anfragen bis einschließlich des Datums werden abgelehnt. Sie können Ihre Kapazität in den Einstellungen jederzeit ändern",
          },
          title: "Ihre Kapazitäten",
          toggle: "Nein / Ja",
          toggleNoYes: "Nein / Ja",
        },
        title: "Kapazität",
        toastSuccess: "Erfolgreich gespeichert",
      },
    },
    somethingWentWrong: "Etwas ist schief gelaufen",
    updateNeeded: {
      pleaseUpdate: "Bitte aktualisieren Sie die App",
      skipVersion: "Überspringe diese Version",
      updateInfo:
        "Wir haben unsere App und den Datenschutz verbessert. Um die neueste Version zu erhalten und Recare weiterhin auf diesem Gerät nutzen zu können, klicken Sie auf aktualisieren.",
    },
  },
  navigations: {
    patient: "Patientennavigation",
    primary: "Primärnavigation",
  },
  networkChecker: {
    retry: "Erneut versuchen",
    retryingMessage: (args: { count: string }) =>
      template(
        "Es gibt ein Netzwerkproblem. Erneuter Versuch in {{count}}...",
        args,
      ),
  },
  newOnboarding: {
    account: {
      caption: "Ihr Konto",
      dontEnterCentralEmailCaption:
        "Hier sollte nicht die zentrale E-Mail-Adresse für Anmeldungen (z.B. info@ oder kontakt@), sondern die persönliche E-Mail-Adresse für ein Benutzerkonto eingegeben werden.",
      errorEmailDoesntExist:
        "Diese E-Mail-Adresse ist noch keinem Benutzerkonto zugewiesen. Bitte registrieren Sie ein neues Benutzerkonto weiter unten.",
      errorEmailExists:
        "Diese E-Mail-Adresse ist schon einem Benutzerkonto zugewiesen. Bitte melden Sie sich im oberen Bildschirmrand an.",
      loginCaption:
        "Bitte kontaktieren Sie Recare unter der ‭+49 30 56 79 60 57‬, wenn Sie Hilfe bei der Anmeldung benötigen.",
      title: "Ihre Kontaktdaten",
      titleNoAccount:
        "Wenn Sie noch kein Benutzerkonto haben, füllen Sie bitte Ihre Kontaktdaten aus:",
      titleWithAccount:
        "Haben Sie schon ein Recare Benutzerkonto? Bitte melden Sie sich hier an:",
    },
    activateAccountTitle: "Aktivieren Sie Ihr Konto",
    company: {
      caption: "Ihre Einrichtung",
      title: "Allgemeine Daten",
    },
    congratulations: {
      benefits:
        "Als registriertes Recare-Mitglied können Sie nun alle Vorteile von Recare nutzen. Wir freuen uns Sie in Ihrem Alltag zu unterstützen!",
      contactUs:
        "Sollten Sie weitere Fragen haben, zögern Sie bitte nicht uns unter der +49 30 56 79 60 57 anzurufen.",
      goToProfile: "Zu meinem Profil",
      thankYou:
        "Vielen Dank, wir freuen uns auf eine erfolgreiche Zusammenarbeit!",
      title: "Herzlichen Glückwunsch!",
      yourTeam: "Ihr Recare-Team",
    },
    password: {
      caption: "Ihr Passwort",
      cardTitle: "Bitte erstellen Sie Ihr Passwort",
      checkInbox:
        "Bitte überprüfen Sie Ihren E-Mail-Posteingang und folgen Sie den letzten Schritten um Ihre Registrierung abzuschließen.",
      didOpenSetPassword:
        "Danke, dass Sie den Link zur Anmeldung geöffnet haben. Sie können dieses Fenster nun schließen.",
      emailSent: (args: { email: string }) =>
        template("Wir haben eine E-Mail an {select:{{email}}} gesendet.", args),
      goBack: "vorherige Seite",
      title: "Sie haben eine E-Mail erhalten",
      wrongEmail:
        "Wurde eine falsche E-Mail-Adresse angegeben? Dann gehen Sie bitte auf die",
    },
    personalOnboarding: {
      newsletterSubscription:
        "Ja, ich möchte den monatlichen Newsletter abonnieren und somit exklusive Einblicke in die digitale Welt von Recare, Informationen aus der Gesundheitsbranche und vieles mehr erhalten.",
      title: "Erstellen Sie Ihr Recare Konto für",
    },
    services: {
      caption: "Ihre Leistungen",
    },
    testrequest: {
      caption: "Test-Anfrage",
    },
    welcome: {
      getStarted: "Starten",
      recareIsFreeEnd: "oder rufen Sie uns unter der",
      recareIsFreeHere: "hier",
      recareIsFreeNumber: "+49 30 56 79 60 57 an.",
      recareIsFreeStart:
        "Recare ist komplett kostenlos für Sie. Für mehr Informationen klicken Sie bitte",
      thanksToRecare: "Dank Recare werden Sie:",
      title: "Herzlich willkommen!",
      updateInformation:
        "Sie können Ihre Informationen jederzeit in Ihren Einstellungen ändern.",
      willAccept: "Patientenanfragen mit einem Klick annehmen oder ablehnen",
      willCommunicate:
        "sichere Nachrichten mit Krankenhausmitarbeitern austauschen, um Patientenanfragen zu besprechen",
      willReceive:
        "passende Patientenanfragen von Kliniken in Ihrer Umgebung erhalten",
    },
    welcomeToRecareTitle: "Willkommen bei Recare",
    welcomeToTestTitle:
      "Willkommen zur Test-Anfrage! Um Ihre Registrierung abzuschließen, klicken Sie bitte auf ANNEHMEN oder ABLEHNEN.",
  },
  newOnboardingTwo: {
    account: {
      alreadyHaveAccount:
        "Haben Sie bereits ein Recare-Konto? {select:Klicken Sie hier}, um sich anzumelden",
      dontEnterCentralEmailCaption:
        "Hier sollte nicht die zentrale E-Mail-Adresse für Anmeldungen (z.B. info@ oder kontakt@), sondern die persönliche E-Mail-Adresse für ein Benutzerkonto eingegeben werden.",
      dontHaveAccount:
        "Sie haben noch kein Konto? {select: Klicken Sie hier}, um sich anzumelden.",
      errorEmailDoesntExist:
        "Diese E-Mail-Adresse ist noch keinem Benutzerkonto zugewiesen. Bitte legen Sie ein neues Konto an.",
      errorEmailExists:
        "Diese E-Mail-Adresse ist bereits mit einem Konto verknüpft. Bitte melden Sie sich oben auf der Seite an, wählen Sie eine andere E-Mail-Adresse aus oder {select:setzen das Passwort für dieses Konto zurück}.",
      genericError: "Etwas ist schief gelaufen, bitte kontaktieren Sie Recare",
      goToLogin: "Zum Login",
      goToRegister: "Zur Kontoerstellung",
      hintAlreadyHaveAnAccount: "Haben Sie bereits ein Recare-Konto?",
      hintDontHaveAccount: "Sie haben noch kein Konto? ",
      loginToAccount: "Loggen Sie sich in Ihr Konto ein",
      title: "Persönliche Angaben",
    },
    additionalnformation: {
      facilitySize: {
        employees: "1-5 Mitarbeiter",
        facilitySizeTitle: "Wie groß ist Ihre Einrichtung?",
        large: "20+ Mitarbeiter",
        medium: "5-20 Mitarbeiter",
      },
      informationSystem:
        "Mit welchem Informationssystem arbeitet Ihre Einrichtung?",
      introductionText:
        "Um die Nutzung der Plattform für Ihre Einrichtung optimal zu gestalten, haben Sie hier die Möglichkeit, weitere Informationen zu Ihrer Einrichtung anzugeben. Alle Angaben sind freiwillig. Sie werden keinen Einfluss auf Ihre Versorgungsmöglichkeiten haben und weder für Kliniken, noch für andere Einrichtungen sichtbar sein. Unterstützen Sie uns mit Ihren Angaben zum Zweck einer allgemeinen Auswertung, damit wir die Plattform bei aller Verschiedenheit von Einrichtungen möglichst nutzerfreundlich gestalten können.",
      newFacilityOpened:
        "Hat Ihre Einrichtung in den letzten 6 Monaten neu eröffnet?",
      responsiblePerson: {
        lessThanTwo: "Weniger als 2",
        moreThanTwo: "Mehr als 2",
        none: "Keiner",
        responsiblePersonTitle:
          "Wie viele Personen sind in Ihrem Unternehmen für die Patienten Aufnahmen zuständig?",
      },
      useOfWaitlists: "Arbeiten Sie in Ihrer Einrichtung mit Wartelisten?",
    },
    companyDetails: {
      disclaimerLegalDocuments:
        "Bitte beachten: Eine Bestätigung der Dokumente darf nur durch Mitarbeiter erfolgen, die dazu die interne Berechtigung besitzen.",
      errorRequiredDataProcessingAgreement:
        "Bitte akzeptieren Sie den Auftragsverarbeitungsvertrag",
      title: "Allgemeine Informationen",
    },
    congratulations: {
      benefits:
        "Als registriertes Recare-Mitglied können Sie nun alle Vorteile von Recare nutzen. Wir freuen uns Sie in Ihrem Alltag zu unterstützen!",
      contactUs:
        "Sollten Sie weitere Fragen haben, zögern Sie bitte nicht uns unter der +49 30 56 79 60 57 anzurufen.",
      getStarted: "Zum Profil",
      thankYou:
        "Vielen Dank, wir freuen uns auf eine erfolgreiche Zusammenarbeit!",
      title: "Herzlichen Glückwunsch!",
      yourTeam: "Ihr Recare-Team",
    },
    errors: {
      invalidToken: "Fehler: ungültiges Token",
      sealdError: "Fehler: externer Seald-Server-Fehler",
      unknown: "Fehler: unbekannt",
    },
    password: {
      errorRequiredTermsAndConditions:
        "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen.",
      termsAndConditions:
        "Ich habe die {select:Allgemeinen Geschäftsbedingungen} gelesen und stimme ihnen zu",
      title: "Erstellen Sie Ihr Passwort",
    },
    receivedMail: {
      checkInbox:
        "Bitte überprüfen Sie Ihren E-Mail-Posteingang und folgen Sie den letzten Schritten um Ihre Registrierung abzuschließen.",
      didOpenSetPassword:
        "Danke, dass Sie den Link zur Anmeldung geöffnet haben. Sie können dieses Fenster nun schließen.",
      emailSent: (args: { email: string }) =>
        template("Wir haben eine E-Mail an {select:{{email}}} gesendet.", args),
      goBack: "vorherige Seite.",
      title: "Sie haben eine E-Mail erhalten!",
      wrongEmail:
        "Wurde eine falsche E-Mail-Adresse angegeben? Dann gehen Sie bitte auf die",
    },
    selfOnboardingEmployee: {
      account: {
        providerInfo: "Sie erstellen einen Account für {select: providerName}",
        title: "Erstellen Sie Ihr Recare-Konto",
      },
    },
    services: {
      title: "Ihr Leistungsspektrum",
    },
    stepper: {
      account: "Ihr Konto",
      additionalInformation: "Zusätzliche Informationen",
      additionalinformation: "Zusätzliche Informationen",
      company: "Details zum Unternehmen",
      password: "Ihr Passwort",
      services: "Angebotene Leistungen",
      step: "Schritt",
      testrequest: "Test-Anfrage",
      yourcompany: " Ihre Einrichtung",
      yourteam: "Ihr Team",
    },
    welcome: {
      getStarted: "Starten",
      recareIsFree:
        "Recare ist komplett kostenlos für Sie. Für mehr Informationen klicken Sie bitte {select:hier} oder rufen Sie uns unter der +49 30 56 79 60 57.",
      thanksToRecare: "Dank Recare werden Sie:",
      title: "Herzlich Willkommen!",
      updateInformation:
        "Sie können Ihre Informationen jederzeit in Ihren Einstellungen ändern.",
      willAccept: "Patientenanfragen mit einem Klick annehmen oder ablehnen",
      willCommunicate:
        "sichere Nachrichten mit Krankenhausmitarbeitern austauschen, um Patientenanfragen zu besprechen",
      willReceive:
        "passende Patientenanfragen von Kliniken in Ihrer Umgebung erhalten",
    },
  },
  notification: {
    error: "Fehler",
    info: "Info",
    success: "Erfolg",
  },
  npsSurvey: {
    npsCommentPlaceholder: "Bitte erläutern Sie",
    npsCommentSend: "Absenden",
    npsCommentTitleDetractors:
      "Wie können wir Ihre Zufriedenheit bei der Nutzung von Recare verbessern?",
    npsCommentTitlePromoters:
      "Was gefällt Ihnen am besten/wenigsten an Recare?",
    npsGoal:
      "Unser Ziel ist es, Recare kontinuierlich für Sie weiter zu verbessern. Ihre Gedanken, Ideen und Vorschläge spielen dabei eine wichtige Rolle, denn diese helfen uns, uns stetig weiterzuentwickeln.",
    npsSurveyScoreHigh: "Sehr wahrscheinlich",
    npsSurveyScoreLow: "Unwahrscheinlich",
    npsSurveyTitle:
      "Wie wahrscheinlich ist es, dass Sie Recare Ihren Kolleginnen und Kollegen empfehlen?",
    npsThankYou: "Vielen Dank für Ihr Feedback.",
  },
  onpremSetup: {
    connectionSuccessfull: "Verbindung zu Gateway erfolgreich ",
    currentVersion: "installierte Version",
    installation: {
      dockerVersion: "Gateways als Docker Container",
      documentation: "Dokumentation",
      downloadBinary: "Installationsdatei als zip herunterladen",
      downloadCompose: "Docker-Compose Datei herunterladen",
      gatewayInstallation: "Gateway Installation",
      latestVersion: "neueste Version",
      windowsVersion: "Gateway als Installationsdatei für Windows",
    },
    logs: {
      acpCta: "Protokolle senden",
      acpDesc: "Senden Sie die heutige Protokollzusammenfassung an Datadog",
      acpTitle: "Protokolle an Datadog senden",
      errorNoLogs: "In den letzten 48 Stunden wurden keine Logs erfasst",
      message: "Nachricht",
      timestamp: "Zeitstempel",
      title: "Protokolle",
    },
    newVersionAvailable: "Neue Version ist verfügbar",
    pastImports: {
      globalStats: (args: { num: string }) =>
        template("Globale Statistik (letzte {{num}} Importe)", args),
      imports: "Vergangene Importe",
    },
    testImport: {
      explanationGetFiles:
        'Hier können Sie alle derzeit auf dem Gateway gespeicherten Dateien einsehen. Um sich die Dateien anzeigen zu lassen, klicken Sie bitte auf "Vorhandene Dokumente anzeigen".',
      explanationImportPatient:
        'Bitte geben Sie zunächst die Fallnummer des zu testenden Falls ein und klicken Sie dann auf "Importieren". Anschließend werden Ihnen die importierten Datenfelder unten auf der Seite angezeigt. Zudem besteht die Möglichkeit, die Dateiverbindungen für diesen Patienten zu testen.',
      explanationLastMessages:
        "Hier werden die zuletzt zu diesem Fall empfangenen HL7v2-Nachrichten angezeigt (max. 5).\nDiese werden hier nur angezeigt, sie werden nicht an Recare übertragen.",
      explanationSendFiles:
        'Um zu überprüfen, ob die Verbindung vom Gateway zu Ihrem KIS funktioniert, können Sie hier Testdateien an das KIS senden. Klicken Sie dazu auf "Testdateien ans KIS senden".',
      filesSection: "Dokumentenübertragung Gateway <> Web App",
      getFiles: "Vorhandene Dokumente anzeigen",
      guardians: "Gesetzliche Betreuer",
      importPatient: "Patient importieren",
      insuranceMapping: "Mapping in der App",
      lastMessages: "Letzte Nachrichten",
      mapping: "Mapping in der Konfiguration",
      mappingFailed: "Mapping fehlgeschlagen",
      missingData: "keine Daten empfangen",
      noDataProvided: "keine Daten empfangen",
      noMappingConfig: "kein Mapping in der Konfiguration",
      patientContact: "Kontaktinfo des Patienten",
      relatives: "Verwandte",
      sendFile: "Testdateien ans KIS senden",
      testHisImport: "Import aus dem KIS - Test",
    },
  },
  ontologies: {
    academicTitle: {
      key: "Titel",
      values: {
        doctor: "Dr.",
        doctorDoctorMedicine: "Dr. Dr. med.",
        doctorMedicine: "Dr. med.",
        doctorMultiplex: "Dr. mult.",
        professor: "Prof.",
        professorDoctor: "Prof. Dr.",
        professorOrdinarius: "Univ. Prof.",
      },
    },
    accountRole: {
      key: "Role",
      values: {
        accountManager: "ACCOUNT MANAGER",
        admin: "ADMIN",
        adminEditCareprovider: "NV BEARBEITEN",
        adminEditCareseeker: "KH BEARBEITEN",
        adminEditConsultants: "Pflegestellen bearbeiten",
        adminReadOnly: "READ ONLY",
        adminTranslations: "TRANSLATIONS",
        admissions: "ADMISSIONS",
        analytics: "ANALYTICS",
        care: "PFLEGE",
        careseekerIT: "IT",
        careseekerSSOUserManagement: "SSO NUTZERVERWALTUNG",
        careseekerUserManagement: "NUTZERVERWALTUNG",
        costApprover: "Cost Approver",
        edminEditConsultants: "Berater bearbeiten",
        homeCare: "Homecare",
        hospital: "KRANKENHAUS",
        it: "IT ANSPRECHPERSON",
        jourFixe: "JOUR FIXE ANSPRECHPERSON",
        management: "VERWALTUNG",
        medicalSupplies: "HILFSMITTEL+ HOMECARE",
        projectManager: "PROJECT MANAGER",
        protectedPatient: "Geschützter Patient",
        provider: "ZUGRIFF",
        providerContact: "KONTAKT",
        rehab: "REHA",
        report: "REPORT",
        sales: "SALES",
        senderApi: "Sender API",
        transport: "TRANSPORT",
        voice: "VOICE",
      },
    },
    accountRoleShort: {
      key: "RoleShort",
      values: {
        accountManager: "ACCOUNT MANAGER",
        admin: "ADMIN",
        adminEditCareprovider: "NV BEARBEITEN",
        adminEditCareseeker: "KH BEARBEITEN",
        adminReadOnly: "READ ONLY",
        adminTranslations: "TRANSLATIONS",
        admissions: "ADMISSIONS",
        analytics: "ANALYTICS",
        care: "PFLEGE",
        careseekerSSOUserManagement: "SSO NUTZERVERWALTUNG",
        careseekerUserManagement: "NUTZERVERWALTUNG",
        costApprover: "Cost Approver",
        edminEditConsultants: "BERATER BEARBEITEN",
        homeCare: "Homecare",
        hospital: "KRANKENHAUS",
        it: "IT",
        jourFixe: "JF",
        management: "VW",
        medicalSupplies: "HILFSMITTEL + HOMECARE",
        projectManager: "PM",
        protectedPatient: "Geschützter Patient",
        provider: "ZUGRIFF",
        providerContact: "KONTAKT",
        rehab: "REHA",
        report: "REPORT",
        sales: "SALES",
        senderApi: "API Sender",
        transport: "TRANSPORT",
      },
    },
    accountStatus: {
      key: "Status",
      values: {
        active: "Aktiv",
        created: "Erstellt",
        inactive: "Inaktiv",
      },
    },
    accountType: {
      key: "Account Type",
      values: {
        api: "External",
        bToC: "B2C",
        callcenter: "Callcenter",
        careprovider: "Careprovider",
        clinic: "Clinic",
        external: "External",
        staff: "Recare staff",
        tool: "Tool",
      },
    },
    ageOfPatient: {
      key: "Alter der Patienten",
      values: {
        children: "Kinder und Jugendliche",
        patientsBelow70: "Patienten < 70 Jahre alt",
        patientsOver70: "Patienten > 70 Jahre alt",
      },
    },
    blacklistReceiverRules: {
      key: "Sender Receiver Rules",
      values: {
        age: "Age",
        blacklistedInsurance: "Blacklisted Insurance",
        capacityBackoff: "Capacity Backoff",
        capacityStartDate: "Capacity Start Date ",
        contacted: "Contacted",
        distance: "Distance",
        filterContacted: "Contacted",
        noBedAvailable: "No Bed Available",
        prerequisite: "Prerequisite",
        receiverBlacklisted: "Receiver Blacklisted",
        removedSolution: "Removed Solution",
        senderBlacklisted: "Sender Blacklisted",
        service: "Service",
        solution: "Solution",
        specialization: "Specialization",
        status: "Status",
        throttle: "Throttle",
        zipcodes: "Zipcodes",
      },
    },
    candidatesProgress: {
      key: "Fortschritt der Kandidatenberechnung",
      values: {
        failed: "Kandidatenberechnung fehlgeschlagen",
        inProgress: "Ermittlung potentieller Empfänger",
        notStarted: "Kandidatenberechnung nicht gestartet",
        success: "Kandidatenberechnung erfolgreich",
      },
    },
    cardiovascularExploration: {
      key: "Kardiovaskuläre Untersuchung",
      values: {
        cardiacMri: "Kardiales MRT",
        coronarography: "Koronarangiographie",
        coronaryScanner: "Herz-CT-Scan",
        exerciseTest: "Kardialer Stresstest",
        holterEcg: "Langzeit-EKG",
        rhythmology: "Rhythmologie",
        toe: "TOE",
        tte: "TTE",
      },
    },
    careDurations: {
      key: "Care durations",
      values: {
        fiveMonths: "5 Monate",
        fourMonths: "4 Monate",
        oneDay: "1 Tag",
        oneMonth: "1 Monat",
        oneWeek: "1 Woche",
        sixMonthsPlus: "6+ Monate",
        threeMonths: "3 Monate",
        threeWeeks: "3 Wochen",
        twoMonths: "2 Monate",
        twoWeeks: "2 Wochen",
        unknown: "Unbekannt",
      },
    },
    carelevel: {
      key: "Pflegegrad",
      values: {
        five: "5",
        four: "4",
        none: "Keiner",
        one: "1",
        three: "3",
        two: "2",
        unknown: "unbekannt",
      },
    },
    careproviderStatus: {
      key: "System Status",
      values: {
        active: "Active",
        created: "Created",
        duplicate: "Duplicate",
        filledProfile: "Filled profile",
        inactive: "Inactive",
        invalid: "Invalid",
        notAnswered: "nicht beantwortet",
        pendingAccountActivation: "Pending password activation",
        test: "test",
        testNotAnswered: "Test not answered",
        testSent: "Test sent",
        testtest: "prüfen",
        trash: "Trash",
      },
    },
    careseekerModuleStatus: {
      key: "Careseeker - Module Status",
      values: {
        active: "Activ",
        created: "Angelegt",
        inactive: "Inactiv",
        onboarding: "Onboarding",
        test: "Testphase",
      },
    },
    careseekerPriority: {
      key: "Priority",
      values: {
        high: "High",
        low: "Low",
        medium: "Medium",
      },
    },
    careseekerStatus: {
      key: "Status",
      values: {
        active: "Activ",
        created: "Angelegt",
        duplicate: "Duplicate",
        inactive: "Inaktiv",
        lost: "Lost",
        notAnswered: "Nicht beantwortet",
        onboarding: "Onboarding",
        profileFilled: "Profil gefüllt",
        salesa: "Sales",
        test: "Testphase",
        trash: "Trash",
      },
    },
    communication: {
      key: "Kommunikation des Patienten",
      values: {
        bad: "Schlecht",
        good: "Gut",
        moderate: "Mäßig",
      },
    },
    companyStatus: {
      key: "Company status",
      values: {
        created: "Created",
        duplicate: "Duplicate",
        inactive: "Inactive",
        inProgress: "In Progress",
        missingInformation: "Fehlende Informationen",
        no: "Invalid",
        notReached: "Nicht erreicht",
        yes: "Yes/Go",
      },
    },
    companyType: {
      key: "Company Status",
      values: {
        facility: "Facility",
        organization: "Organization",
      },
    },
    compressionType: {
      key: "Art der Kompressionsstrümpfe",
      values: {
        bandage: "Verbände",
        socks: "Strümpfe",
      },
    },
    confession: {
      key: "Konfession",
      values: {
        catholic: "Katholisch",
        jewish: "Jüdisch",
        muslim: "Muslimisch",
        protestant: "Evangelisch",
      },
    },
    consultantStatus: {
      key: "Beraterstatus",
      values: {
        active: "Aktiv",
        created: "Erstellt",
        inactive: "Inaktiv",
      },
    },
    declinedReason: {
      key: "Declined reason",
      values: {
        geographicallyUnattractive: "nicht in Einzugsgebiet",
        noCapacity: "keine Kapazitäten",
        noCapacityForPatient: "keine Kapazitäten für diesen Patienten",
        other: "Ablehnungsgrund",
        payer: "Wir lehnen aufgrund des Kostenträgers ab",
      },
    },
    dependencyLevel: {
      key: "Hilfsbedarf bei den Aktivitäten des täglichen Lebens",
      values: {
        aLot: "Viel",
        none: "Keiner",
        some: "Wenig",
      },
    },
    digestiveEndoscopy: {
      key: "Verdauungsendoskopie",
      values: {
        colonoscopy: "Kolonoskopie",
        endoscopicUltrasound: "Endoskopischer Ultraschall",
        ercp: "ERCP",
        gastroscopy: "Gastroskopie",
      },
    },
    eligibilityRejectReason: {
      key: "Ausschlusskriterien",
      values: {
        anotherFormRehaSought: "Eine andere Form der AHB wird gesucht",
        differentCostPayerResponsible: "Anderer Kostenträger ist zuständig",
        otherReason: "Sonstiges",
        patientNotEligible: "Patient ist nicht AHB-fähig",
      },
    },
    emailType: {
      key: "Mail type",
      values: {
        accountAdded: "Receiver: Account added",
        accountInvited: "Receiver: Account invited",
        assigneeChanged: "Patient assignee changed",
        attributionCancellation: "Attribution cancellation",
        attributionConfirmation: "Attribution confirmation",
        auctionRequest: "Auction Request",
        encryptedMessage: "Encrypted message sent",
        feedbackSurvey: "Feedback survey",
        newAnswer: "Sender: Accept notification",
        newMessageFromClinic: "Receiver: Message from clinic",
        onboarding: "Self-onboarding email",
        passwordChanged: "Password changed",
        profileEdited: "Patient profiled edited",
        receiverAccepted: "Provider: Accept confirmation",
        receiverRejected: "Receiver: Rejection notification",
        resetPassword: "Passwort reset",
        searchReminder: "Search reminder",
        senderRejectionConfirmation: "Sender: Rejection confirmation",
        testRequest: "Test request",
        testValidation: "Test validation",
        welcomeEmail: "Welcome email",
      },
    },
    employmentStatus: {
      key: "Beschäftigungsstatus",
      values: {
        civilServant: "Beamter",
        employee: "Angestellter",
        masterForeman: "Meister, Polier",
        notEmployed: "Nicht erwerbstätig",
        selfEmployed: "Selbstständiger",
        semiSkilledWorker: "Angelernter Arbeiter",
        skilledWorker: "Facharbeiter",
        trainee: "Auszubildender",
        unskilledEmployee: "Ungelernter Arbeiter",
      },
    },
    facility: {
      key: "Art der Einrichtung",
      values: {
        ambulance: "KTW",
        ambulanceCarryChair: "KTW - Tragestuhl",
        ambulanceLyingDown: "KTW für Liegendtransporte",
        ambulanceWheelchair: "KTW - Rollstuhl",
        assistedLiving: "Betreutes Wohnen",
        companionship: "Hauswirtschaftliche Versorgung",
        dailyLivingSupportServices: "Angebote zur Unterstützung im Alltag",
        dayCare: "Tagespflege",
        dayHospitalization: "Tagesklinik",
        fullHospitalization: "Vollstationär",
        fullTimeCompanionship: "24-Stunden-Betreuung (ambulant)",
        fullTimeMobileCare: "24-Stunden-Intensivpflege (ambulant)",
        hospice: "Hospizverein",
        hospiceMobile: "Ambulantes Hospiz",
        hospicePartiallyMobile: "Teilstationäres Hospiz",
        hospiceStatic: "Vollstationäres Hospiz",
        medicalSupplies: "Hilfsmittel",
        medicalSuppliesHomeCare: "Homecare",
        mobileCare: "Ambulanter Pflegedienst",
        nightCareMobile: "Ambulante Nachtpflege",
        nightCareStatic: "Stationäre Nachtpflege",
        pureIntensiveCare: "Reiner Intensivpflegedienst",
        rehabDayHospitalization: "Ambulant",
        rehabDayHospitalizationWithTransport: "Teilstationär",
        rehabFullHospitalization: "Stationär",
        rehabMobile: "Mobil",
        sharedFlat: "Wohngemeinschaft",
        shortTermStaticCare: "Stationäre Kurzzeitpflege",
        staticCare: "Stationäre Pflege",
        taxi: "Taxi / Mietwagen ohne Sonderleistung\n",
        taxiCarryChair: "Taxi / Mietwagen - Tragestuhl\n",
        taxiLyingDown: "Taxi / Mietwagen für Liegendtransporte\n",
        taxiWheelchair: "Taxi / Mietwagen - Rollstuhl\n",
      },
    },
    fileCategory: {
      key: "Type of file category",
      shortValues: {
        ad: "AD",
        and: "AND",
        apf: "APF",
        bix: "BIX",
        btr: "BB",
        cc: "KÜ",
        ctb: "PÜB",
        ecg: "EKG",
        err: "NAB",
        fso: "FSO",
        gi: "KB",
        img: "AN",
        iso: "ISO",
        mdl: "ÄEB",
        mp: "MP",
        mr: "ÄBB",
        msr: "HLV",
        pcr: "PCR",
        pr: "VB",
        pre: "REZ",
        rba: "AHB",
        rer: "HMV",
        sn: "SIN",
        wp: "WP",
      },
      values: {
        ad: "Administratives Dokument",
        and: "Andere",
        apf: "Anschreiben Pflegeheim",
        bix: "Barthelindex",
        btr: "Blutbefund",
        cc: "Kostenübernahme",
        ctb: "Pflegeüberleitungsbogen",
        ecg: "EKG",
        err: "Notaufnahmebericht",
        fso: "Formular Sozialstation",
        gi: "Keimbefall",
        img: "Med. Aufnahmen/Röntgenbilder",
        iso: "Information Sozialstation",
        mdl: "Ärztlicher Entlassbrief",
        mp: "Medikationsplan",
        mr: "Ärztlicher Befundbericht",
        msr: "Hilfsmittelverordnung (Muster 16)",
        pcr: "PCR COVID",
        pr: "Vorheriger Bericht",
        pre: "Rezept (Muster 16)",
        rba: "Antrag auf Anschlussheilbehandlung",
        rer: "Heilmittelverordnung (Muster 13)",
        sn: "SINGER",
        wp: "Wundprotokoll",
      },
    },
    fileError: {
      key: "File Error",
      values: {
        fileSize: "Ungültige Dateigröße",
        fileType: "Ungültiger Dateityp",
      },
    },
    fileShareMode: {
      key: "File share mode",
      values: {
        all: "Alle potenziellen Empfänger",
        none: "Niemand",
        selected: "Ausgewählte Empfänger",
      },
    },
    foodHelp: {
      key: "Art der Ernährung",
      values: {
        gastrointestinalProbe: "Ernährung mit PEG Sonde",
        helpWithEating: "Hilfe bei der Nahrungsaufnahme",
        mealPreparation: "Nahrungszubereitung",
      },
    },
    formYesNo: {
      key: "Antwort wählen",
      values: {
        no: "nein",
        yes: "ja",
      },
    },
    gender: {
      key: "Geschlecht",
      values: {
        diverse: "Divers",
        female: "Weiblich",
        male: "Männlich",
      },
    },
    genderExtended: {
      key: "Gender",
      values: {
        diverse: "Divers",
        female: "weiblich",
        male: "männlich",
        withoutEntry: "ohne Eintrag",
      },
    },
    genderLetter: {
      key: "GenderLetter",
      values: {
        female: "W",
        male: "M",
        other: "D",
      },
    },
    grantStatus: {
      key: "Grant status",
      unselectedItems: "Nicht ausgewählt:",
      values: {
        applied: "Beantragt",
        exists: "Bestätigt",
        notApplied: "Noch nicht beantragt",
      },
    },
    grantsType: {
      key: "Pflegeversicherungsbezug",
      values: {
        combinationOfBoth: "Kombinationsleistungen",
        fixedPayout: "Pflegegeld",
        serviceBasedPayout: "Pflegesachleistungen",
      },
    },
    healthInsuranceType: {
      key: "Versicherung der Patienten",
      values: {
        private: "Privat versichert",
        public: "Gesetzlich versichert (Kassenzulassung vorhanden)",
      },
    },
    imagery: {
      key: "Bildgebendes Verfahren",
      values: {
        ctScan: "Computertomographie",
        dopplers: "Doppler-Ultraschall",
        interventionalRadiology: "Interventionelle Radiologie",
        mri: "MRT",
        ultrasound: "Ultraschall",
        xRay: "Röntgen",
      },
    },
    incontinenceHelpType: {
      key: "Art der Inkontinenzhilfe",
      values: {
        diapers: "IK Material",
        katheter: "Katheter",
        stoma: "Stoma",
      },
    },
    infectionAndGerms: {
      key: "Keimbesiedlung/Aktuelle Infektionen",
      values: {
        clostridien: "Clostridien",
        four_mrgn: "4MRGN",
        mrsa: "MRSA",
        requires_isolation: "Isolationsbedarf",
        three_mrgn: "3MRGN",
        vre: "VRE",
      },
    },
    informationSystems: {
      key: "Information system",
      values: {
        connextVivendi: "Connext Vivendi",
        curasoft: "Curasoft",
        danProdukte: "Dan Produkte",
        godoSystems: "GODO Systems",
        medifox: "Medifox",
        myneva: "myneva",
        noventiCare: "NOVENTIcare",
        other: "Andere/s",
        senso: "SENSO",
        sinfonie: "Sinfonie",
        snap: "snap",
      },
    },
    injectionType: {
      key: "Art der Injektion",
      values: {
        muscular: "Intramuskulär",
        subcutunae: "Subkutan",
      },
    },
    invoiceStatus: {
      key: "Status",
      values: {
        invoiceCreated: "Erstellt",
        invoiceInvalidated: "Ungültig",
        invoicePaid: "Bezahlt",
        invoiceSent: "Gesendet",
      },
    },
    invoiceType: {
      key: "Art",
      values: {
        remoteWorkshop: "Fernschulung",
        services: "Leistungen",
        servicesAdvance: "Leistungen",
        setupFee: "Bereitstellung",
        workshop: "Workshop",
      },
    },
    language: {
      key: "Sprachen",
      values: {
        afghan: "Afghanisch",
        albanian: "Albanisch",
        amharic: "Amharisch",
        arabic: "Arabisch",
        azerbaijani: "Aserbaidschanisch",
        bengal: "Bengalisch",
        bosnian: "Bosnisch",
        bulgarian: "Bulgarisch",
        cantonese: "Kantonesisch",
        croatian: "Kroatisch",
        czech: "Tschechisch",
        danish: "Dänisch",
        dutch: "Niederländisch",
        english: "Englisch",
        finnish: "Finnisch",
        french: "Französisch",
        georgian: "Georgisch",
        german: "Deutsch",
        greek: "Griechisch",
        hebrew: "Hebräisch",
        hindi: "Hindi",
        hungarian: "Ungarisch",
        indonesian: "Indonesisch",
        italian: "Italienisch",
        japanese: "Japanisch",
        korean: "Koreanisch",
        kurdish: "Kurdisch",
        lithuanian: "Lettisch",
        mandarin: "Mandarin",
        norwegian: "Norwegisch",
        persian: "Persisch/Farsi",
        polish: "Polnisch",
        portuguese: "Portugiesisch",
        romanian: "Rumänisch",
        russian: "Russisch",
        serbian: "Serbisch",
        sign: "Gebärdensprache",
        slovenian: "Slowenisch",
        spanish: "Spanisch",
        swahili: "Swahili",
        swedish: "Schwedisch",
        thai: "Thailändisch",
        tigrinya: "Tigrinisch/ Eritreisch",
        turkish: "Türkisch",
        ukrainian: "Ukrainisch",
        urdu: "Urdu",
        vietnamese: "Vietnamesisch",
      },
    },
    livingSituation: {
      key: "Wohnsituation",
      values: {
        livingSituationAlone: "Alleinlebend",
        livingSituationCarehome: "In einem Alten- oder Pflegeheim",
        livingSituationInElderlyHome: "In einem Altenheim",
        livingSituationOther: "Andere",
        livingSituationSharedFlat:
          "In einer Wohngemeinschaft/Im betreuten Wohnen",
        livingSituationWithFamily: "Mit Angehörigen",
      },
    },
    maritalStatus: {
      key: "Familienstand",
      values: {
        divorced: "Geschieden",
        married: "Verheiratet",
        single: "Ledig",
        widowed: "Verwitwet",
      },
    },
    meals: {
      key: "Tageszeit",
      values: {
        breakfast: "Frühstück",
        dinner: "Abendessen",
        lunch: "Mittagessen",
      },
    },
    mentalHealthCommunication: {
      key: "Kommunikation des Patienten",
      value: {
        bad: "Schlecht",
        good: "Gut",
        moderate: "Mäßig",
      },
      values: {
        bad: "bad",
        placeholder: "placeholder",
      },
    },
    mobility: {
      key: "Mobilität des Patienten",
      values: {
        crutch: "Unterarmstütze",
        medicalisedBed: "Bettlägerig",
        rollator: "Rollator",
        walkingFrame: "Gehgestell",
        walkingStick: "Gehstock",
        walkWithHelp: "Gehen mit Hilfe möglich",
        walkWithoutHelp: "Gehen ohne fremde Hilfe möglich",
        wheelChair: "Rollstuhl",
      },
    },
    motivation: {
      key: "Motivation des Patienten",
      values: {
        bad: "Schlecht",
        good: "Gut",
        moderate: "Mäßig",
      },
    },
    onboardingStatus: {
      key: "Status",
      values: {
        administrationPhase: "Proposal Sent",
        callBackLater: "Zurückrufen am",
        chain: "Zentrale zuständig",
        complicated: "Kompliziert",
        considerationPhase: "Überlegungsphase",
        infoPhase: "To Call",
        later: "Späteres Interesse",
        manuallyAdded: "Manuell vom Sender hinzugefügt",
        missingInformation: "Fehlende Informationen",
        noActionFromCallcenter: "Keine Handlung vom Callcenter",
        noComment: "Keine Auskunft",
        noInterest: "Kein Interesse",
        notReached: "Nicht erreicht",
        notReachedCallCenter: "Dauerhaft nicht erreicht",
        other: "Andere",
        preOnboarding: "Leads",
        profileFilled: "Formular ausgefüllt",
        resendOnboardingEmail: "Info-Mail erneut senden",
        selfOnboarding: "Selbstanmeldung",
        technicalIssues: "Technische Probleme",
        toCall: "To Call",
      },
    },
    orientation: {
      key: "Orientierung des Patienten",
      values: {
        disoriented: "Desorientiert",
        limited: "Eingeschränkt orientiert",
        orientated: "Orientiert",
      },
    },
    otherTechnicalEquipment: {
      key: "Verfügbar",
      values: {
        dialysis: "Dialyse",
        monitoringBreathing: "Monitoring: Beatmung",
        monitoringCirculation: "Monitoring: Kreislauf",
        monitoringMetabolism: "Monitoring: Stoffwechsel",
      },
    },
    paginationResultCount: {
      key: "Pagination count",
      values: {
        fifty: "50",
        ten: "10",
        thirty: "30",
        twenty: "20",
      },
    },
    patientType: {
      key: "PatientType",
      values: {
        care: "PFLEGE",
        homeCare: "HOMECARE",
        hospital: "KRANKENHAUS",
        medicalSupplies: "HILFSMITTEL",
        rehab: "REHA",
        transport: "TRANSPORT",
      },
    },
    position: {
      key: "position",
      values: {
        accountManagement: "Account Management",
        aufnahmemanagement: "Aufnahmemanagement",
        belegungsmanagement: "Belegungsmanagement",
        casemanagement: "Casemanagement",
        chefarztIn: "Chefarzt*in",
        chefarztsekretariat: "Chefarztsekretariat",
        controlling: "Controlling",
        direktorIn: "Direktor*in",
        disposition: "Disposition",
        einrichtungsleitung: "Einrichtungsleitung",
        einsatzleitung: "Einsatzleitung",
        einzugsberatung: "Einzugsberatung",
        fachbereichsleitungOrRegionalleitung:
          "Fachbereichsleitung/Regionalleitung",
        geschaftsfuhrung: "Geschäftsführung",
        gesellschafter: "Gesellschafter",
        inhaber: "Inhaber",
        it: "IT",
        keyAccountManagement: "Key Account Management",
        oberarztIn: "Oberarzt*in",
        patientenaufnahme: "Patientenaufnahme",
        pflegedienstleitung: "Pflegedienstleitung",
        pflegefachkraft: "Pflegefachkraft",
        projektManager: "Projekt Manager",
        qualitatsmanagement: "Qualitätsmanagement",
        rehaplanung: "Rehaplanung",
        sachbearbeiterIn: "Sachbearbeiter*in",
        sozialdienst: "Sozialdienst",
        stellvPflegedienstleitung: "stellv. Pflegedienstleitung",
        teamleitung: "Teamleitung",
        vertriebOrVertriebsleitungOrMitarbeiterVertrieb:
          "Vertrieb/Vertriebsleitung/Mitarbeiter Vertrieb",
        verwaltung: "Verwaltung",
        wohnbereichsleitung: "Wohnbereichsleitung",
        zentraleAufnahme: "Zentrale Aufnahme",
      },
    },
    priorEmploymentStatus: {
      key: "Arbeit vor Antragstellung oder vor aktueller Arbeitsunfähigkeit",
      values: {
        fullTimeNoShifts:
          "Ganztagsarbeit ohne Wechselschicht / Akkord / Nachtschicht",
        fullTimeWithShifts: "Ganztagsarbeit mit Wechselschicht / Akkord",
        householdCare: "Ausschließlich Tätigkeit als Hausfrau / Hausmann",
        notEmployedRegistered: "Arbeitslos gemeldet",
        notWorking:
          "Nicht erwerbstätig (nicht ankreuzen, wenn 6 oder 7 zutrifft)",
        partTimeLessThanHalfDay:
          "Teilzeitarbeit, weniger als die Hälfte der üblichen Arbeitszeit",
        partTimeMoreThanHalfDay:
          "Teilzeitarbeit, mindestens die Hälfte der üblichen Arbeitszeit",
        wihtNightShifts: "Ganztagsarbeit mit Nachtschicht",
        workAtHome: "Heimarbeit",
        workshopForDisabledPeople:
          "Beschäftigung in einer Werkstatt für behinderte Menschen",
      },
    },
    productFeature: {
      key: "Product Feature",
      values: {
        recareAdmissions: "Recare Admissions",
        recareAnalytics: "Recare Analytics",
        recareVoice: "Recare Voice",
        transitionalCare: "Übergangspflege",
      },
    },
    providerSearchNavTitle: {
      key: "providerSearchNavTitle",
      values: {
        bavaria: "Pflegefinder Bayern",
      },
    },
    providerSearchRequestStatus: {
      key: "Provider search request status",
      values: {
        accepted: "Vom Anbieter akzeptiert",
        attributed: "Bestätigt",
        created: "Ausstehend",
        declined: "Abgelehnt",
        rejected: "Storniert",
        sent: "Ausstehend",
        unavailable: "Storniert",
      },
    },
    receiverInactiveReason: {
      key: "Inactive Reason",
      values: {
        asklepiosInhouseSolution: "Asklepios Inhouse Solution",
        churned: "war aktiv - jetzt nicht mehr",
        closed: "Einrichtung geschlossen",
        higherLevelDecision: "Übergeordneter Entscheid",
        medianInhouseSolution: "Median Inhouse Solution",
        neverReachedAgain: "Nie erreicht",
        noAccount: "No Account",
        noInterestDoNotContact: "Kein Interesse - nicht wieder kontaktieren",
        noInterestMaybeLater: "jetzt kein Interesse, vielleicht später",
        temporaryClosed: "vorübergehend geschlossen/ pausieren",
        unneededChainCenter: "Zentrale inaktiv - Filialen aktiv",
        unneededChainSubsidiary: "Zentrale aktiv - Filialen inaktiv",
        unsupportedFacility: "nicht unterstützte Einrichtung",
      },
    },
    receiverTypePlural: {
      key: "Receiver Type (Plural)",
      values: {
        care: "Nachversorger",
        homeCare: "Versorger",
        hospital: "Krankenhäuser",
        medicalSupplies: "Versorger",
        rehab: "Kliniken",
        transport: "Transportunternehmen",
      },
    },
    receiverTypePluralPreferred: {
      key: "Receiver Type (Plural)",
      values: {
        care: "präferierte Nachversorger",
        homeCare: "bevorzugte Versorger",
        hospital: "präferierte Krankenhäuser",
        medicalSupplies: "bevorzugte Versorger",
        rehab: "Wunschkliniken",
        transport: "präferierte Transportunternehmen",
      },
    },
    receiverTypeSingular: {
      key: "Receiver Type (Plural)",
      values: {
        care: "Versorgungsanbieter",
        homeCare: "Versorger",
        hospital: "Krankenhaus",
        medicalSupplies: "Versorger",
        rehab: "Klinik",
        transport: "Transportunternehmen",
      },
    },
    receiverTypeSingularPreferred: {
      key: "Receiver Type (Plural)",
      values: {
        care: "präferierter Nachversorger",
        careAb: "Patientenwunsch",
        homeCare: "präferierter Versorger",
        hospital: "präferiertes Krankenhaus",
        medicalSupplies: "präferierter Versorger",
        rehab: "Wunschklinik",
        transport: "präferiertes Transportunternehmen",
      },
    },
    rehabilitationPotential: {
      key: "Rehabilitationspotential",
      values: {
        evaluationExtremelyNegative: "Sehr schlecht",
        evaluationExtremelyPositive: "Sehr gut",
        evaluationModerate: "Mäßig",
        evaluationNegative: "Schlecht",
        evaluationPositive: "Gut",
      },
    },
    reliefServices: {
      key: "Entlastungsleistungen",
      values: {
        paragraphA: "Nach §45a SGB XI",
        paragraphB: "Nach §45b SGB XI",
      },
    },
    roomType: {
      key: "Zimmertyp",
      values: {
        accompanyingChild: "Erwachsenen-Reha: Kinder",
        accompanyingPerson: "Zimmer für Begleitpersonen geeignet",
        accompanyingSibling: "Kinder-Reha: Geschwister",
        apartment: "Apartment",
        barrierFree: "Barrierefreie Zimmer",
        double: "Doppelzimmer",
        no_preference: "Keine Präferenz",
        single: "Einzelzimmer",
      },
    },
    rowsPerPage: {
      key: "Rows per page",
      values: {
        fifteenRows: "15 pro Seite",
        fiftyRows: "50 pro Seite",
        tenRows: "10 pro Seite",
        thirtyRows: "30 pro Seite",
      },
    },
    salutation: {
      key: "Anrede",
      values: {
        madame: "Frau",
        monsieur: "Herr",
        other: "*",
      },
    },
    searchPageTableTitle: {
      key: "Table titles",
      values: {
        accepted: "Angenommen",
        contacted: "Kontaktiert",
        declined: "Treatment not possible",
        filtered: "Filtered",
        potential: "Potential",
        rejected: "Rejected",
        validated: "Nachversorger wurde ausgewählt",
      },
    },
    searchTableStatusChips: {
      key: "searchTableStatusChips",
      values: {
        accepted: "Angenommen",
        faxSent: "Fax versendet",
        newFile: "Neues Dokument",
        newMessage: "Neue Nachricht",
        notSeen: "Nicht gelesen",
        ongoingChat: "Laufendes Gespräch",
        providerInactive: "Inaktiv",
        requestNotSeen: "Anfrage nicht gesehen",
        requestSeen: "Anfrage gesehen",
        seen: "Gelesen",
        sentMessageNotSeen: "Nachricht nicht gesehen",
        sentMessageSeen: "Nachricht gesehen",
      },
    },
    service: {
      key: "Leistungen",
      values: {
        basicCare: "Grundpflege (SGB XI)",
        basicMedicalCare: "Behandlungspflege (SGB V)",
        children: "Kinder und Jugendliche",
        closedArea: "Geschlossene Station",
        companionship: "Betreuung",
        corona: "Corona",
        dementia: "Demenzbetreuung",
        dialysis: "Dialysepatienten",
        enteralNutrition: "Enterale Ernährung",
        hearingImpaired: "Für Gehörlose geeignet",
        helpToBreathe: "Tracheostoma",
        helpWithEating: "Hilfe bei der Nahrungsaufnahme",
        invasiveVentilation: "Invasive Beatmung",
        monitoringBreathing: "Monitoring: Atmung",
        monitoringCirculation: "Monitoring: Kreislauf",
        monitoringMetabolism: "Monitoring: Stoffwechsel",
        noneOfTheFollowingServices: "Keine der aufgeführten Zusatzleistungen",
        nonInvasiveVentilation: "Nicht invasive Beatmung",
        obesity: "Adipositas",
        oxygenTherapy: "Sauerstofftherapie",
        palliativeCare: "Palliativpflege",
        palliativeCertificate:
          "Spezialisierte ambulante Palliativversorgung (SAPV)",
        parenteralNutrition: "Parenterale Ernährung",
        patientsBelow70: "Patienten < 70 Jahre alt",
        patientsOver70: "Patienten > 70 Jahre alt",
        peopleWhoAreDangerous:
          "Für Patienten mit Eigen- oder Fremdgefährdung geeignet",
        peopleWithAddictions: "Für Patienten mit Suchterkrankungen geeignet",
        peopleWithOrientationDisorder:
          "Für Patienten mit Orientierungsstörungen geeignet",
        protectedArea: "Beschützender Bereich",
        protectedAreaCare: "Beschützender Bereich",
        psychiatricCare: "Psychiatrische Versorgung",
        specialisedIntensiveNurse: "Intensivpflege",
        staticCareDisabledPatients:
          "Vollstationäre Einrichtungen der Hilfe für Behinderte (Binnendifferenzierung)",
        takingOverBodyCare: "Übernahme der Körperpflege",
        visuallyImpaired: "Für Sehbehinderte geeignet",
      },
    },
    serviceExplanations: {
      key: "Service-Erklärungen",
      values: {
        basicCare:
          "Grundpflege wird von Pflegekräften ausgeführt, wenn Menschen ihre alltäglichen Aufgaben teilweise nicht mehr selbständig bewältigen können. Darunter fallen die Bereiche Körperpflege (z. B. Duschen, Zahnpflege, Toilettengang), Ernährung (z.B. mundgerechtes Zubereiten und Aufnahme der Nahrung) und Mobilität (z. B. Aufstehen, Ankleiden, Gehen).",
        basicMedicalCare:
          "Behandlungspflege beinhaltet alle Pflegeleistungen, die ärztlich verordnet und durch eine Pflegekraft durchgeführt werden. Klassische Beispiele sind: Medikamentengabe, Anlegen und Wechseln von Verbänden, Verabreichung von Injektionen oder Blutzuckermessungen.",
        closedArea:
          "Für Menschen, die aufgrund eines richterlichen Beschlusses vorübergehend oder dauerhaft in einer geschlossenen Einrichtung untergebracht werden müssen – meist bei akuter Selbst- oder Fremdgefährdung im Rahmen einer psychischen Erkrankung. Die Station ist vollständig gesichert, sodass Bewohner sie nicht eigenständig verlassen können.",
        companionship:
          "Der Begriff Betreuung ist breit gefächert und das genaue Angebot kann unter den Versorgungsanbietern stark variieren. Kontaktieren Sie bei spezifischen Fragen zum Betreuungsangebot bitte den Anbieter direkt oder stellen Sie mit Ihrer Anfrage eine Frage in den Chat ein.",
        corona:
          "Hier können Patienten mit aktuellem positivem Test auf das Coronavirus (SARS-CoV-2) aufgenommen werden.",
        dementiaCare:
          "Demenzbetreuung meint verschiedene Hilfestellungen für Menschen mit einer Demenzerkrankung. Sie reichen von anleitenden bis zu pflegerischen Tätigkeiten bis hin zu einer 24-Stunden-Demenzbetreuung für Senioren.",
        intensiveCare:
          "Intensivpflege ist eine 24-Stunden-Versorgung von Menschen mit lebensbedrohlichen oder lebensverkürzenden Erkrankungen. Die Versorgung durch speziell geschulte Pflege(fach)kräfte kann sowohl ambulant als auch stationär erfolgen.",
        palliativeCare:
          "Die Palliativpflege begleitet schwerstkranke und sterbende Menschen am Ende ihres Lebens und verbessert ihre Lebensqualität. Sie ermöglicht eine würdevolle Begleitung bis zum Lebensende ohne Angst und Schmerzen.",
        protectedArea:
          'Für Menschen mit Unterbringungsbeschluss (z.B. bei Hin- und Weglaufgefährdung bei Demenz, psychischen Erkrankungen mit Selbst- und Fremdgefährdung), die zu ihrer eigenen Sicherheit bestimmte Wohnbereiche nicht eigenständig verlassen dürfen. Diese Bereiche sind speziell auf die Bedürfnisse der Bewohner zugeschnitten und werden daher auch „Beschützender Wohnbereich" genannt.',
        psychiatricCare:
          "Psychische Erkrankungen bzw. schwere Erkrankungen erfordern eine besondere Art der Pflege. Die psychiatrische Pflege ermöglicht psychisch beeinträchtigten Menschen, im Rahmen ihrer Möglichkeiten in ihrem Zuhause zu leben und ihr Leben bestmöglich selbst zu gestalten. So können Krankenhausaufenthalte vermieden werden.",
        sapv: "Die spezialisierte ambulante Palliativversorgung (SAPV) kann eine Krankenhauseinweisung ersetzen und es Patienten ermöglichen, in Frieden dort zu sterben, wo sie gelebt haben. Die hierfür erforderlichen Teams bestehen in der Regel aus ärztlichem Personal, Pflege(fach)kräften und therapeutischem Personal und haben eine entsprechende Zusatzausbildung.",
        staticCareForDisabledPatients:
          "Einrichtungen mit dieser Leistung sind speziell auf pflegebedürftige Menschen mit Behinderung ausgerichtet.",
        tracheostomy:
          "Ein Tracheostoma ist eine künstliche Öffnung der Luftröhre am Hals, die durch eine Operation geschaffen wird. Über diese Öffnung wird ein Patient entweder vollständig beatmet oder beim Atmen unterstützt. Atemluft gelangt so in die Lunge, während Speichel oder Nahrung von der Luftröhre ferngehalten werden. Ein Tracheostoma ist für betroffene Patienten überlebenswichtig.",
      },
    },
    shareModes: {
      key: "Share mode",
      values: {
        all: "",
        none: "",
        selected: "",
      },
    },
    shortTermGrant: {
      key: "Short Term Grants",
      values: {
        combination: "Andere: z.B. kombinierte Verhinderungspflege und KZP",
        paragraph39: "Nach §39c SGB V",
        paragraph42: "Nach §42 SGB XI",
      },
    },
    solution: {
      key: "Art der Überleitung",
      shortValues: {
        ambulance: "KTW",
        ambulanceCarryChair: "KTW - Tragestuhl",
        ambulanceLyingDown: "KTW für Liegendtransporte",
        ambulanceWheelchair: "KTW - Rollstuhl",
        assistedLiving: "Betr. Wohnen",
        dailyLivingSupportServices: "AUA",
        dayCare: "Tagespflege",
        dayHospitalization: "Tagesklinik",
        fullHospitalization: "Vollstationär",
        fullTimeMobileCare: "24-Stunden Pflege",
        helpAtHome: "Hausw. Versorgung",
        homeCare: "Homecare",
        hospice: "Hospizverein",
        hospiceMobile: "Amb. Hospiz",
        hospicePartiallyMobile: "Teilstat. Hospiz",
        hospiceStatic: "Vollstat. Hospiz",
        medicalSupplies: "Hilfsmittel",
        mobileCare: "Amb. Pflege",
        nightCareMobile: "Amb. Nachtpflege",
        nightCareStatic: "Stat. Nachtpflege",
        rehabDayHospitalization: "Amb. Reha",
        rehabDayHospitalizationWithTransport: "Teilstat. Reha",
        rehabFullHospitalization: "Stat. Reha",
        rehabMobile: "Mobile Reha",
        sharedFlat: "Wohngemeinschaft",
        shortTermStaticCare: "KZP",
        staticCare: "Stat. Pflege",
        taxi: "Taxi ohne Sonderleistung",
        taxiCarryChair: "Mietwagen - Tragestuhl",
        taxiLyingDown: "Mietwagen für Liegendtransport",
        taxiWheelchair: "Mietwagen - Rollstuhl",
      },
      values: {
        ambulance: "KTW",
        ambulanceCarryChair: "KTW - Tragestuhl",
        ambulanceLyingDown: "KTW für Liegendtransporte",
        ambulanceWheelchair: "KTW - Rollstuhl",
        assistedLiving: "Betreutes Wohnen",
        dailyLivingSupportServices: "Angebote zur Unterstützung im Alltag",
        dayCare: "Tagespflege",
        dayHospitalization: "Tagesklinik",
        fullHospitalization: "Vollstationär",
        fullTimeMobileCare: "24-Stunden Pflege",
        helpAtHome: "Hauswirtschaftliche Versorgung",
        homeCare: "Homecare",
        hospice: "Hospizverein",
        hospiceMobile: "Ambulantes Hospiz",
        hospicePartiallyMobile: "Teilstationäres Hospiz",
        hospiceStatic: "Vollstationäres Hospiz",
        medicalSupplies: "Hilfsmittel",
        mobileCare: "Ambulante Pflege",
        nightCareMobile: "Ambulante Nachtpflege",
        nightCareStatic: "Stationäre Nachtpflege",
        rehabDayHospitalization: "Ambulante Reha",
        rehabDayHospitalizationWithTransport: "Teilstationäre Reha",
        rehabFullHospitalization: "Stationäre Reha",
        rehabMobile: "Mobile Reha",
        sharedFlat: "Wohngemeinschaft",
        shortTermStaticCare: "Stationäre Kurzzeitpflege",
        staticCare: "Stationäre Pflege",
        taxi: "Taxi ohne Sonderleistung",
        taxiCarryChair: "Taxi - Tragestuhl\n",
        taxiLyingDown: "Taxi - Liegendtransport",
        taxiWheelchair: "Taxi - Rollstuhl",
      },
    },
    solutionBToC: {
      key: "Solutions",
      values: {
        helpAtHome: "Hausw. Versorgung",
        mobileCare: "Ambulante Pflege",
        shortTermStaticCare: "Kurzzeitpflege",
        staticCare: "Stationäre Pflege",
      },
    },
    specializations: {
      key: "Fachabteilungen",
      values: {
        acuteGeriatrics: "Akutgeriatrie",
        addiction: "Suchtentwöhnung",
        addictology: "Suchtmedizin",
        adipositas: "Adipositas",
        allergology: "Allergologie",
        anesthesiology: "Anästhesiologie und Intensivmedizin",
        angiology: "Angiologie",
        cardiologicalEarlyRehabilitation: "Kardiologische Frührehabilitation",
        cardiology: "Kardiologie",
        cognitiveBehavioralUnit: "CBT",
        cognitiveBehavioural: "Kognitive Verhaltenstherapie",
        comaVigil: "Wachkoma",
        compressionTherapy: "Kompressionstherapie",
        decubitusProphylaxis: "Dekubitusprophylaxe",
        dermatology: "Dermatologie",
        diabeticFoot: "Diabetischer Fuss",
        diabetology: "Diabetologie",
        dialysis: "Dialyse",
        digestiveSurgery: "Viszeralchirurgie",
        earlyRehab: "Früh Reha",
        endocrinology: "Endokrinologie/Stoffwechselerkrankungen",
        ent: "HNO",
        enteralNutrition: "Enterale Ernährung",
        gastroenterology: "Gastroenterologie",
        generalSurgery: "Allgemeinchirurgie",
        geriatrics: "Geriatrie",
        gynaecology: "Gynäkologie",
        haematology: "Hämatologie",
        hepatology: "Hepatologie",
        homecareOncology: "Onkologie",
        icuCardiology: "ICU Kardiologie",
        icuCorona: "ICU - Low Care",
        icuECMOCorona: "ICU - ECMO",
        icuGastrology: "ICU Gastrologie",
        icuHighCorona: "ICU - High Care",
        icuNeurovascular: "Neurovaskuläre Intensivstation",
        incontinence: "Inkontinenz aufsaugend",
        incontinenceDischarging: "Inkontinenz ableitend",
        infectiousDisease: "Infektionskrankheit",
        infusionTherapy: "Infusionstherapie",
        internalMedicalEarlyRehabilitation: "Internistische Frührehabilitation",
        internalMedicine: "Innere Medizin",
        laryngectomyTherapy: "Laryngektomietherapie",
        maxillofacialSurgery: "Kiefer- und Gesichtschirurgie",
        medicalReanimation: "Intensivstation",
        monitoring: "Monitoring",
        nephrology: "Nephrologie",
        neurology: "Neurologie",
        neuroPediatry: "Neuropädiatrie",
        neurosurgery: "Neurochirurgie",
        normalStation: "Normalstation",
        nuclearMedicine: "Nuklearmedizin",
        obesity: "Adipositas",
        oncology: "Onkologie",
        oncologyBreastGlands: "Onkologie - Brustdrüse",
        oncologyCentralNervousSystem: "Onkologie - Zentrales Nervensystem",
        oncologyDigestiveOrgans: "Onkologie - Verdauungsorgane",
        oncologyFemaleGenital: "Onkologie - Weibliches Genital",
        oncologyLocomotorOrgans: "Onkologie - Bewegungsorgane",
        oncologyMalignantSystemicDiseases:
          "Onkologie - Maligne Systemerkrankungen",
        oncologyPharync: "Onkologie - Pharynx, Larynx (Rachen, Kehlkopf)",
        oncologyRespiratoryOrgans: "Onkologie - Atmungsorgane",
        oncologySkin: "Onkologie - Haut",
        oncologyThyroid: "Onkologie - Schilddrüse",
        oncologyUrinaryKidneyMaleGenitals:
          "Onkologie - Niere, abl. Harnwege und männl. Genitale",
        ophthalmology: "Augenheilkunde",
        orthogeriatrics: "Orthogeriatrie",
        orthopedics: "Orthopädie",
        orthopedicsLowerLimbs: "Orthopädie - Untere Gliedmaßen",
        orthopedicsSosMains: "Orthopädie - SOS Hände",
        orthopedicsSpine: "Orthopädie - Wirbelsäule",
        orthopedicsUpperLimbs: "Orthopädie - Obere Gliedmaßen",
        oxygen: "Sauerstoff",
        paediatrics: "Pädiatrie",
        painMedicine: "Schmerzheilkunde",
        painTherapy: "Schmerz Therapie",
        palliativeCare: "Palliativversorgung",
        parenteralNutrition: "Parenterale Ernährung",
        parentsAndChildRehab: "Mutter/Vater-Kind-Reha",
        pediatrics: "Pädiatrie",
        phaseB: "Neurologie Phase B",
        phaseC: "Neurologie Phase C",
        phaseCplus: "Neurologie Phase C+",
        phaseD: "Neurologie Phase D",
        physicalMedicine: "Physikalische Medizin und Rehabilitation",
        plasticSurgery: "Plastische Chirurgie",
        pneumologicalEarlyRehabilitation: "Pneumologische Frührehabilitation",
        pneumology: "Pneumologie",
        psychiatry: "Psychiatrie & Psychotherapie",
        psychosomatic: "Psychosomatik",
        radiology: "Radiologie",
        respiratoryTherapy: "Atemtherapie",
        rheumatism: "Rheuma",
        rheumatology: "Rheumatologie",
        rhythmology: "Rhythmologie",
        stoma: "Stoma",
        surgicalResuscitation: "Chirurgische Intensivstation",
        thoracicSurgery: "Thoraxchirurgie",
        tracheostomyTherapy: "Tracheostoma- / Laryngektomietherapie ",
        traumaSurgery: "Unfallchirurgie",
        urology: "Urologie",
        vascularSurgery: "Gefäßchirurgie",
        ventilationOxygen: "Beatmung ",
        weaning: "Weaning",
        woundCare: "Wundversorgung",
      },
    },
    tableSorting: {
      key: "tableSorting",
      values: {
        sortByDefault: "Standard",
        sortByDistance: "Entfernung",
        sortByName: "Name",
        sortByStatus: "Status",
      },
    },
    technicalEquipment: {
      cardiovascularExploration: {
        cardiacMri: "Kardiales MRT",
        coronaryScanner: "Herz-CT-Scan",
        exerciseTest: "Kardialer Stresstest",
        holterEcg: "Langzeit-EKG",
        rhythmology: "Rhythmologie",
        toe: "TOE",
        tte: "TTE",
      },
      digestiveEndoscopy: {
        colonoscopy: "Kolonoskopie",
        endoscopicUltrasound: "Endoskopischer Ultraschall",
        ercp: "ERCP",
        gastroscopy: "Gastroskopie",
      },
      imagery: {
        ctScan: "Computertomographie",
        dopplers: "Doppler-Ultraschall",
        interventionalRadiology: "Interventionelle Radiologie",
        mri: "MRT",
        ultrasound: "Ultraschall",
        xRay: "Röntgen",
      },
      key: "key",
      otherAvailableEquipment: {
        dialysis: "Dialyse",
        monitoringBreathing: "Monitoring: Beatmung",
        monitoringCirculation: "Monitoring: Kreislauf",
        monitoringMetabolism: "Monitoring: Stoffwechsel",
      },
      values: {
        test: "-1",
      },
    },
    trafficLight: {
      key: "Ampel",
      values: {
        green: "Grün",
        orange: "Orange",
        red: "Rot",
      },
    },
    transitionalCareRequestStatus: {
      key: "Anforderungsstatus",
      values: {
        accepted: "Zusage",
        declined: "Ablehnung",
        rejected: "Absage",
        sent: "Keine Rückmeldung",
      },
    },
    transportType: {
      key: "Verkehrsmittel",
      values: {
        ambulanceLyingDown: "Krankentransport liegend",
        ambulanceSitting: "Krankentransport sitzend",
        emergencyVehicle: "Rettungswagen",
        helicopter: "Helikopter",
        personalCar: "PKW",
        publicTransport: "ÖPNV",
        taxi: "Taxi",
      },
    },
    universalSearch: {
      key: "Ressource",
      values: {
        account: "Konto",
        company: "Firma",
        consultant: "Berater",
        receiver: "Receiver",
        sender: "Sender",
      },
    },
    vigilance: {
      key: "Vigilanz",
      values: {
        agitated: "Agitiert",
        awake: "Wach",
        calm: "Ruhig",
        comatose: "Komatös",
        responsive: "Ansprechbar",
        somnolent: "Somnolent",
        soporific: "Soporös",
      },
    },
  },
  patient: {
    abortCreation: {
      actionButton: "Erstellung abbrechen",
      modalSubtitle:
        "Sie können den Patienten entweder archivieren oder als angelegten Entwurf speichern, den Sie später wieder bearbeiten können.",
      modalTitle: (args: { patientId: string }) =>
        template(
          "Sind Sie sicher, dass Sie die Erstellung des Patienten {{patientId}} abbrechen möchten?",
          args,
        ),
      quitAndArchive: "Beenden und Archivieren",
      saveAsDraft: "Als Entwurf speichern",
      test: "",
    },
    accompanyingPerson: "Begleitperson",
    addCareprovider: {
      addTitle: (args: { receiverTypeSingular: string }) =>
        template("Weitere {{receiverTypeSingular}} hinzufügen", args),
      contactAll: "alle kontaktieren",
      contactingByFax:
        "Wenn Sie diese Einrichtung auswählen, versucht Recare die Anfrage per Fax an diese Einrichtung zu senden, falls uns die Faxnummer bekannt ist. Der Empfänger ist noch nicht aktiver Teil der Plattform.\n\n",
      distance: "Entfernung zum Patienten",
      enterNamePlaceholder: {
        care: "Name des Nachversorgers eingeben",
        geriatrics: "Adresse oder Name der Wunschklinik eingeben",
      },
      errorContactRecare: "Bitte kontaktieren Sie einen Mitarbeiter von Recare",
      errorNoSolution: (args: { solution: string }) =>
        template("Bietet keine {{solution}}", args),
      errorProviderCannotBeContacted:
        "Nach unserem Wissen ist der Nachversorger nicht mehr aktiv",
      errorProviderCreated: "Nicht Teil des Recare Netzwerks",
      errorProviderNotActive: "Nicht Teil des Recare Netzwerks",
      errorRequestAccepted: "Diese Anfrage wurde angenommen.",
      errorRequestDeclined: (args: { message: string }) =>
        template('Diese Anfrage wurde abgelehnt: "{{message}}"', args),
      errorRequestRejected:
        "Wurde abgelehnt\nBitte gehen Sie zur Antwort und machen Sie Ihre Ablehnung rückgängig",
      errorRequestSent: (args: { date: string }) =>
        template(
          "Eine Anfrage wurde am {{date}} gesendet, aber noch nicht beantwortet",
          args,
        ),
      errorRequestSentAutomaticSearch:
        "Eine Anfrage wurde gesendet, aber noch nicht beantwortet.",
      errorRequestWaitingResponse: "Sie haben eine Nachricht erhalten",
      errorWhitelisted: "Dieser Versorger ist noch nicht ",
      explanation:
        "Sie können nach dem Einrichtungsnamen oder der Adresse suchen",
      explanationSearchStopped: (args: { receiverTypeSingular: string }) =>
        template(
          "{{receiverTypeSingular}} kann hier hinzugefügt werden. Es empfiehlt sich, zuerst nach der Adresse zu suchen.",
          args,
        ),
      form: {
        explanation: (args: { receiverTypeSingular: string }) =>
          template(
            "{{receiverTypeSingular}} konnte leider nicht in unserem System gefunden werden. Bitte geben Sie uns ausreichende Informationen, um die Einrichtung so schnell wie möglich in unserem System aufzunehmen.",
            args,
          ),
        title: (args: { receiverTypeSingular: string }) =>
          template(
            "{{receiverTypeSingular}}daten werden benötigt. Bitte geben Sie diese an",
            args,
          ),
      },
      formExplanation: {
        care: "Leider konnten wir den Nachversorger nicht in unserem System finden.\nBitte geben Sie uns ausreichende Informationen, um diese so schnell wie möglich in unserem System aufzunehmen.",
        geriatrics:
          "Leider konnten wir die Klinik nicht in unserem System finden.\nBitte geben Sie uns ausreichende Informationen, um diese so schnell wie möglich in unserem System aufzunehmen.",
      },
      formTitle: {
        care: "Bitte geben Sie die Daten des Nachversorgers an",
        geriatrics: "Bitte geben Sie die Daten der Klinik ein",
      },
      goBack: "Zurück zur Suche",
      goToAnswer: "Zur Antwort",
      insuranceBlacklisted:
        "Der Empfänger nimmt keine Patienten mit dem angegebenen Kostenträger an",
      lookForAdditionalSpecialsations:
        "Nach zusätzlichen Fachabteilungen suchen",
      medicalCare: "",
      noBedsAvailable: "Keine Betten verfügbar",
      notFound: {
        care: "Können Sie den Nachversorger nicht finden?",
        geriatrics: "Können Sie die Klinik nicht finden?",
      },
      placeholderEnterName: "Suche nach Name oder Adresse:",
      pleaseGoToAnswer:
        "Bitte gehen Sie zur Antwort und nehmen Sie den Nachversorger an",
      providerAddedExplanation:
        "Wir haben die von Ihnen angegeben Informationen erhalten.",
      providerAddedExplanation1: {
        care: "Wir haben die Informationen zu Ihrem präferierten Nachversorger erhalten.",
        geriatrics:
          "Wir haben die Informationen zu Ihrer Wunschklinik erhalten.",
      },
      providerAddedExplanation2: {
        care: "Recare wird diesen Nachversorger so schnell wie möglich im System aufnehmen. Es wird noch keine Anfrage versendet.",
        geriatrics:
          "Recare wird diese Klinik so schnell wie möglich im System aufnehmen. Es wird noch keine Anfrage versendet.",
      },
      providerAddedExplanationBis: (args: { receiverTypeSingular: string }) =>
        template(
          "{{receiverTypeSingular}} wird so schnell wie möglich von Recare ins System aufgenommen. Es wird noch keine Anfrage versendet.",
          args,
        ),
      providerAddedTitle: {
        care: "Der Nachversorger wurde gespeichert",
        geriatrics: "Die Klinik wurde gespeichert",
      },
      providerNotFound: (args: { receiverTypeSingular: string }) =>
        template(
          "{{receiverTypeSingular}} konnte von Ihnen nicht gefunden werden?",
          args,
        ),
      providerSavedTitle: (args: { receiverTypeSingular: string }) =>
        template("{{receiverTypeSingular}} wurde gespeichert", args),
      providersFiltered: "Versorger ausgeschlossen",
      providerThrottled: "Empfänger hat zu viele Anfragen erhalten",
      reason: "Ausschlusskriterien",
      rejectedByCostPayer:
        "Diese Reha-Einrichtung wurde bereits vom Kostenträger abgelehnt",
      requestDate: "Datum",
      requestsSent: "Anfragen verschickt",
      searchBarPlaceholder: "Adresse oder Name des Nachversorgers eingeben",
      searchCareproviderExplanation: {
        care: "Fügen Sie hier bitte Ihre gewünschten Nachversorger hinzu. Es empfiehlt sich, zuerst nach der Adresse des Nachversorgers zu suchen.",
        geriatrics:
          "Fügen Sie hier bitte Ihre Wunschklinik hinzu. Es empfiehlt sich, zuerst nach der Adresse der Wunschklinik zu suchen.",
      },
      searchCareproviderExplanationSearchStopped: {
        care: "Fügen Sie hier bitte Ihre gewünschte Einrichtung hinzu. Es empfiehlt sich, zuerst nach der Adresse der Einrichtung zu suchen.",
        geriatrics:
          "Fügen Sie hier bitte Ihre gewünschte Einrichtung hinzu. Es empfiehlt sich, zuerst nach der Adresse der Einrichtung zu suchen.",
      },
      searchNotStartedHint:
        "Die Suche wurde noch nicht gestartet. Wenn Sie alle verfügbaren Anbieter kontaktieren möchten, klicken Sie auf alle kontaktieren oder nur diesen Anbieter, klicken Sie auf Auswählen",
      selectProviderNotStartedSearch:
        'Die Suche wurde noch nicht gestartet. Mit Klick auf "Auswählen" wird die Anfrage an diese Einrichtung geschickt und die Suche gestartet.',
      selectProviderToSendRequest:
        'Bitte klicken Sie auf "Auswählen", um diese Einrichtung zu Ihrer Suche hinzuzufügen.',
      selectSolutionExplanation:
        "Bitte wählen Sie die gewünschte Versorgungsform.",
      sendRequest: "Anfrage senden",
      smartSuggestionsWarningHint:
        'Wenn Sie diese Einrichtung trotzdem zu Ihrer Suche hinzufügen möchten, klicken Sie auf "Auswählen".',
      stopSearchWarningHint:
        'Wenn Sie diesen Anbieter trotz dieser Informationen zuordnen möchten, klicken Sie unten auf "Bestätigen".',
      title: {
        care: "Präferierten Nachversorger auswählen",
        geriatrics: "Wunschklinik hinzufügen",
        warningWeight: (args: { maxWeight: string }) =>
          template(
            "Dieser Versorger nimmt nur Patienten mit einem Gewicht von bis zu {{maxWeight}} kg auf.",
            args,
          ),
      },
      warningAge: (args: { minAge: string; maxAge: string }) =>
        template(
          "Dieser Versorger nimmt nur Patienten im Alter von {{minAge}} bis {{maxAge}} Jahren auf",
          args,
        ),
      warningBlacklist: "Versorger nicht kontaktiert",
      warningDistance: "Versorger liegt nicht innerhalb des Suchradius",
      warningDistanceCareproviderRadius:
        "Suche außerhalb des Behandlungsradius des Anbieters",
      warningNoCapacity: (args: { startDate: string }) =>
        template("Keine Kapazitäten bis zum {{startDate}}", args),
      warningNoService: (args: { service: string }) =>
        template(
          "Folgende Leistungen werden nicht angeboten: {{service}}",
          args,
        ),
      warningNotCatchment:
        "Die angegebene Adresse liegt ausserhalb des Einzugsgebietes",
      warningPrerequisite: (args: { service: string }) =>
        template("Voraussetzung für Patientenaufnahme: {{service}}", args),
      warningSpecialisation:
        "Diese Einrichtung nimmt Patienten eines anderen Fachbereichs auf",
      warningWhitelist: "Versorger ist nicht Teil der Platform",
      willRestartSearchHint:
        "Beim Senden an diese Einrichtung wird die Suche neu gestartet.",
      willStartSearchHint: "Das Senden an diese Einrichtung startet die Suche.",
    },
    additionalExaminations: "Zusätzliche Untersuchungen notwendig",
    additionalInformation: "Zusätzliche Informationen",
    admissionDate: "Aufnahmedatum",
    age: "Alter",
    agitationOrientation: "Patient ist desorientiert",
    alertTitle: "",
    autonomy: "Eigenständigkeit des Patienten",
    backfillStationNumber: {
      hint: "Bitte tragen Sie die korrekte Stationsrufnummer ein",
      link: "Nummer fehlt oder ist inkorrekt?",
      placeholder: "Stationsrufnummer",
      title: (args: { station: string }) =>
        template(
          "Bitte bearbeiten Sie die Stationsrufnummer von {{station}}",
          args,
        ),
    },
    barrierFreeRoom: "Barrierefreies Zimmer",
    candidates: "Potentielle Empfänger",
    careDuration: "Behandlungsdauer",
    carelevel: "Pflegegrad",
    careNeeds: {
      accessDrainages: {
        accessAndDrainages: "Zugänge und Drainagen",
        centralVenousCatheter: "Zentraler Venenkatheter (ZVK)",
        drainage: "Drainage",
        gastricTube: "PEG, PEJ, Magensonde",
        inputPlaceholder:
          "z.B. seit wann, wann zuletzt gewechselt, Art und Größe, etc.",
        other: "Andere",
        otherAccessDrainages: "Andere",
        otherPlaceholder: "Bitte erläutern Sie",
        peripheralVenousCatheter: "Peripherer Venenkatheter (PVK, Viggo)",
        permanentCatheter: "Dauerkatheter (DK)",
        suprapubicCatheter: "Suprapubischer Blasenkatheter (SPK)",
        trachealCannula: "Trachealkanüle",
      },
      baseCare: "Grundpflege",
      baseCarePlaceholder:
        "z.B. Frequenz, Tageszeit, Vorbereitung für Tagespflege, Körperpflege, Ernährung, etc.",
      breathing: "Beatmung",
      carePaidByHealthInsurance: "§37.1 SGB V",
      circulation: {
        label: "Kreislauf",
        placeholder: "z.B. Blutdruck, EKG",
      },
      companionship: "Betreuung",
      companionshipGrant: "§45b SGB XI",
      companionshipPlaceholder:
        "z.B. Begleitung bei Arztbesuchen, Anregung und Unterstützung bei sozialen Kontakten, etc.",
      compression: "Kompressionstherapie",
      compressionPlaceholder: "z.B. Frequenz, Strümpfe, Verbände, etc.",
      dialysis: "Dialyse",
      dysphagia: {
        label: "Schluckstörung",
        placeholder:
          "z.B. Art der Kost, Hilfsbedarf bei der Nahrungsaufnahme, etc.",
      },
      enteralNutrition: {
        label: "Enterale Ernährung",
        placeholder: "z.B. Frequenz, PEG, PEJ, Nahrungshersteller, etc.",
      },
      fecalIncontinence: "Stuhlinkontinenz",
      fecalIncontinencePlaceholder: "z.B. Kolostomie, Anus Praeter, etc.",
      helpAtHome: "Hauswirtschaftliche Versorgung",
      helpAtHomePlaceholder:
        "z.B. Einkaufen, Mahlzeiten zubereiten, Geschirr spülen, Bettwäsche wechseln, etc.",
      hemodialysis: {
        label: "Hämodialyse",
        placeholder: "z.B. Frequenz",
      },
      incontinence: "Inkontinenz",
      injection: "Injektionen",
      injectionPlaceholder:
        "z.B. Frequenz, Uhrzeit, subkutan, intramuskulär, etc.",
      medicalCare: "Behandlungspflege",
      medicalCarePlaceholder:
        "z.B. Medikamentengabe, Insulingabe, Wundbehandlung",
      medication: "Medikation",
      medicationPlaceholder:
        "z.B. Frequenz, Medikamente werden wöchentlich gestellt, etc.",
      metabolism: {
        label: "Stoffwechsel",
        placeholder: "z.B. Blutzucker",
      },
      monitoring: "Monitoring",
      needSupport: "Patient benötigt Unterstützung",
      nutrition: "Ernährung",
      oxygenTherapy: {
        label: "Sauerstofftherapie",
        placeholder:
          "z.B. Sauerstoffgerät wurde verordnet und bestellt, High Flow Sauerstofftherapie, etc.",
      },
      parenteralNutrition: {
        label: "Parenterale Ernährung",
        placeholder: "z.B. Frequenz, Nahrungshersteller, etc.",
      },
      peritonealDialysis: {
        label: "Peritonealdialyse",
        placeholder: "z.B. Frequenz",
      },
      placeholderImportantInformationCare:
        "z.B. Häufigkeit der Versorgung, Tätigkeiten",
      psychiatric_care: {
        label: "Psychiatrische Pflege",
        placeholder: "zB. Psychotische Erkrankung, Borderline, etc.",
      },
      remarks: "Zusätzliche Informationen",
      respiration: {
        label: "Atmung",
        placeholder: "z.B. CO2 Gehalt der Atemluft",
      },
      stepperTitle: "Zustand des Patienten",
      stepperTitleCare: "Pflegebedarf",
      takesChemoTherapy: "Chemotherapie-Medikamente",
      takesExpensiveMedication: "Wertteure/spezielle Medikamente",
      titleImportantInformationCare:
        "Wichtige Informationen für die Versorgung",
      tracheostomy: {
        label: "Tracheostoma",
        placeholder: "zB. Organisation der Hilfsmittelversorgung, etc.",
      },
      urinaryIncontinence: "Harninkontinenz",
      urinaryIncontinencePlaceholder: "z.B. Schutzhose, Blasenkatheter, etc.",
      withBodyCare: "Bei der Körperpflege",
      withDressing: "Beim An- und Ausziehen",
      withEating: "Bei der Nahrungsaufnahme",
      woundCare: "Wundversorgung/Dekubitus",
      woundCarePlaceholder:
        "z.B. Lokalisation, Größe, Therapie, Versorgungsfrequenz, etc.",
    },
    careNeedsRequired: "Zusätzliche Informationen",
    caseIdTitle: "Bitte geben Sie die Krankenhausfallnummer des Patienten an",
    closedAreaNeeded: "Geschlossene Station notwendig",
    complexityPatient: "Komplexität des Patientenprofils",
    comprehensiveRehabPatientProfile: "Ausführliches Patientenprofil notwendig",
    coronaWarning:
      "Aufgrund der aktuellen Verbreitung von COVID-19 stehen die meisten Kurzzeitpflegeplätze nicht mehr zur Verfügung. Wir raten daher eindringlich (zumindest zeitgleich) nach einer ambulanten Einrichtung zu suchen, um eine weitere Ausbreitung einzudämmen",
    costPayerPartnerInfoBox: {
      bodyAvailable:
        "Dieser Kostenträger übernimmt die Suche nach einem Reha-Platz (ausser bei Neurologie Phase B) nachdem Sie das Patientenprofil angelegt und die Suche übergeben haben. \nNachrichten, Dokumente, Befunde etc. können digital direkt mit dem Kostenträger und der Reha-Klinik über Recare geteilt werden.  Zusätzlich haben Sie die Möglichkeit die Wunschklinik auszuwählen und mit dieser in den direkten Austausch zu treten. ",
      bodyNotAvailable:
        "Leider ist dieser Kostenträger noch nicht Teil der Recare-Plattform. Sie starten die Suche selbst und können digital Nachrichten und Dokumente mit der Reha-Klinik austauschen und eine Vorreservierung tätigen. Das Teilen der Dokumente mit dem Kostenträger muss auf herkömmlichem Wege passieren.",
      linkToFaq:
        "Für mehr Informationen über unsere Partner-Kostenträger, die bereits an Bord sind, {select:besuchen Sie unsere FAQs}.",
      titleAvailable: "Kostenträger ist Partner von Recare",
      titleNotAvailable: "Kostenträger ist kein Partner von Recare",
    },
    costpayerSunsetInfoBox: {
      body: "Wir stellen zum 01.03.2023 die ergänzende Möglichkeit ein, AHB-/Reha-Platz-Suchen an Kostenträger zu übergeben. Vor diesem Datum können Suchen weiterhin wie gewohnt gestartet werden. Für alle laufenden Suchen wird bis zum 31.03.2023 ein Platz gesucht; ab diesem Datum werden alle an Kostenträger übergebene Suchen automatisch gestoppt. Bitte richten Sie sich darauf ein und informieren Sie ggf. entsprechende Stellen. Die Möglichkeit der Suche nach einem AHB-/Reha-Platz ohne Übergabe an Kostenträger steht Ihnen weiterhin wie gewohnt zur Verfügung.",
      title: "Wichtige Information zur Suchübergabe an Kostenträger",
    },
    countrySearch: "Deutschlandweite Suche",
    covidPositive: "Covid-19 positiv",
    created: "erstellt",
    createPatient: "Neuen Patienten anlegen",
    createPatientConfirmation:
      "Bitte notieren Sie die Identifikationsnummer in Ihrer Akte oder Ihrem System",
    createPatientDatesError:
      "Das Aufnahmedatum kann nicht vor dem geplanten Entlassdatum sein.",
    createPatientError: "Profil konnte nicht erstellt werden",
    createPatientLoading:
      "Wir bitten um Geduld während der Patientenerstellung",
    dateOfBirth: "Geburtsdatum",
    dateOfBirthFormat: "TT.MM.JJJJ",
    declarationOfConsent: "Patient hat zugestimmt",
    deleteAuction: {
      stopSearch: "Suche beenden",
    },
    deletePatient: {
      confirmText: (args: { patientName: string }) =>
        template(
          "Möchten Sie Patient {{patientName}} wirklich archivieren?",
          args,
        ),
      title: "Patient archivieren",
    },
    dementia: "Demenz",
    dichargeDate: "Entlassdatum",
    diffFields: {
      careNeeds: {
        care: "Pflegebedarf",
        geriatrics: "Zustand des Patienten",
      },
      diagnosis: "Medizinische Informationen",
      mentalHealth: "Psychische Verfassung",
      mobility: "Mobilität",
      patientCondition: "Zustand des Patienten",
      profile: "Patientendaten",
      searchRadius: "Suchradius",
      socialWorker: "Zuständiger Mitarbeiter",
      solutions: "Art der Überleitung",
      station: "Station",
      transport: "Transport",
    },
    directTransfer: "Direktverlegung",
    directTransferNecessary: "Direktverlegung erforderlich",
    dischargeConfirmation: {
      centralEmail: "Zentrale E-Mail-Adresse",
      contactInformation: "Kontakt Informationen",
      footer: {
        confirmation:
          "Vereinbaren Sie jetzt einen Termin unter https://www.recaresolutions.com/evb für eine kostenfreie Beratung durch Recare.\n\nRecare ist unser Partner für Koordination und Organisation von Leistungen nach der Krankenhausentlassung, die über das vom Krankenhaus angebotene gesetzlich vorgegebene Entlassmanagement hinausgehen.\n\nBeispielsweise hilft Recare Ihnen sinnvolle Ansprüche gegenüber der Pflegekasse wie z.B. Treppenlifte, Badumbau, Essen auf Rädern oder kostenfreie Pflegehilfsmittel mit minimalem Aufwand zu beantragen.\n\nDie Beratung durch Recare ist keine dem Krankenhaus nach § 39 Abs. 1a SGB V obliegende Aufgabe des Entlassmanagements. Die Nutzung von einer Beratung durch Recare ist optional und zusätzlich möglich.",
        confirmationOther:
          "Vereinbaren Sie jetzt einen Termin unter https://www.recaresolutions.com/evb und erhalten Sie kostenlose Unterstützung durch Recare.\n\nRecare ist unser Partner für Koordination und Organisation von Leistungen nach der Krankenhausentlassung, die über das vom Krankenhaus angebotene gesetzlich vorgegebene Entlassmanagement hinausgehen.\n\nBeispielsweise hilft Recare Ihnen mit minimalem Aufwand sinnvolle Versorgung wie z.B. Essen auf Rädern oder stundenweise Betreuung für Zuhause zu erhalten.\n\nDie Beratung durch Recare ist keine dem Krankenhaus nach § 39 Abs. 1a SGB V obliegende Aufgabe des Entlassmanagements. Die Nutzung von einer Beratung durch Recare ist optional und zusätzlich möglich.",
        title: "Brauchen Sie Hilfe im Umgang mit der Pflegebedürftigkeit?",
        titleOther: "Benötigen Sie Hilfe mit der Anschlussversorgung?",
      },
      startDate: "Startdatum",
      transferConfirmation: (args: {
        patientId: string;
        providerName: string;
      }) =>
        template(
          "Überleitungsbestätigung für {select:Patient {{patientId}}} an {select:{{providerName}}}",
          args,
        ),
    },
    distanceFromYou: (args: { distance: string }) =>
      template("{{distance}} von Ihnen entfernt", args),
    doctorInCharge: "Behandelnder Arzt",
    eatAlone: "Kann eigenständig essen",
    editAssessmentMessage: "Angaben wurden erfolgreich gespeichert",
    editAssessmentMessageGeneric:
      "Änderungen erfolgreich gespeichert. Bitte beachten Sie, dass Änderungen Auswirkungen auf die Liste der zu kontaktierenden Einrichtungen haben können.",
    editSearchProviderListUpdateBody:
      "Bitte beachten Sie, dass diese Änderungen Auswirkungen auf die Nachversorger haben können, die kontaktiert werden können",
    editSearchProviderListUpdateTitle: "Änderungen im Patientenprofil",
    editSearchWarning:
      "Die Änderungen am Patientenprofil führen dazu, dass die folgenden Empfänger von der Suche ausgeschlossen werden:",
    electivePatientNotInHospitalYet:
      "Elektiver Patient - Patient ist noch nicht im Krankenhaus",
    eligibilityTitle: {
      eligible: "Die Kostenübernahme wurde bestätigt",
      inProcess: "Die Kostenübernahme ist in Bearbeitung",
      rejected: "Die Kostenübernahme wurde abgelehnt",
      toBeProcessed: "Die Kostenübernahme wurde noch nicht bearbeitet",
    },
    encryptedFieldSecure:
      "Diese Information ist verschlüsselt. Sie wird für den Empfänger erst einsehbar, wenn Sie ihnen die Stammdaten freigegeben haben.",
    encryptionWithIE:
      "Die optionale Eingabe verschlüsselter Stammdaten ist nicht für den Internet Explorer verfügbar. Bitte verwenden Sie einen aktuelleren Browser wie Google Chrome, Mozilla Firefox oder Microsoft Edge.",
    enterGermanPensionInsuranceName:
      "Name der Deutschen Rentenversicherung eingeben",
    enterHealthInsuranceName: "Name der Krankenkasse eingeben",
    errorLoading: "Profil konnte nicht geladen werden",
    errorLoadingUrlChanges: "Anfrage konnte nicht ausgeführt werden",
    errorSaveAuction: "Konnte nicht gespeichert werden",
    expectedAdmitDate: "Geplantes Aufnahmedatum",
    expectedDischargeDate: "Geplantes Entlassdatum",
    expectedStartDate: "Geplanter Start",
    expectedStartDateBeforeDischargeDateError:
      "Geplantes Entlassdatum kann nicht vor dem geplanten Start der Versorgung liegen",
    expectedStartDateBeforeDischargeDateErrorForStartDate:
      "Der geplanter Start kann nicht vor dem geplantem Entlassdatum liegen.",
    expeditedRequest: "Im Eilverfahren",
    externalId: "Krankenhausfallnummer",
    facilityType: "Art der Einrichtung",
    firstName: "Vorname",
    flexible: "(flexibel)",
    flexibleDate: "Flexibler Start der Versorgung möglich",
    formGeneration: {
      alreadySent: "Bereits versendet",
      alreadySentModal: {
        hint: "Diese Information wird dem Kostenträger mitgeteilt.",
        title:
          "Bitte erläutern Sie wann und auf welchem Wege das Dokument versendet worden ist",
      },
      bedOverlength: "Bettüberlänge",
      beforeRetirementPension:
        "Es werden regelmäßig Leistungen bis zum Beginn einer Altersrente bezogen",
      beforeRetirementPensionTypeOfService: "Art der Leistung",
      birthDate: "Geburtsdatum",
      birthDateDateFormat: "(TT.MM.JJJJ)",
      cancelModal: {
        areYouSure: "Sind Sie sicher, dass Sie die Seite verlassen möchten?",
        changesWontBeSaved:
          "Alle Änderungen die Sie gemacht haben werden nicht automatisch gespeichert.",
        confirmNo: "Zurück zur Seite",
        confirmYes: "Verlassen",
        content: "Ihre Änderungen werden nicht automatisch gespeichert.",
        title: "Sind Sie sich sicher, dass Sie diese Seite verlassen möchten?",
      },
      catheter: "Blasenkatheter",
      chemiotherapy: "Chemotherapie",
      complicationCauseCardiovascular: "Kardiovaskuläre Komplikationen",
      complicationCauseCardiovascularLabel: "z.B. Herzinfarkt, Embolie",
      complicationCauseMaindiagnosis:
        "Die Komplikationen waren im direkten Zusammenhang mit der Anschlussrehabilitation führenden Diagnose",
      complicationCauseMaindiagnosisLabel:
        "z.B. postoperative Luxation der Endoprothese",
      complicationCauseOther: "Sonstige Komplikationen",
      complicationCauseOtherLabel: "z.B. Pneumonie",
      complications: "Komplikationen im Behandlungsverlauf",
      complicationsAndRiskFactors:
        "Krankheitsverlauf, Komplikationen, Risikofaktoren",
      contributionsToForeignInsurance: "Beiträge zur Sozialversicherung",
      contributionsToForeignInsuranceInfo:
        "Es wurden Beiträge zur Sozialversicherung im Ausland gezahlt",
      contributionsToForeignInsuranceInfoFromTo: "Von - Bis",
      contributionsToForeignInsuranceInfoState: "Staat",
      currentTherapy:
        "Derzeitige Therapien, Ergebnisse (bei onkologischen Erkrankungen Remissionsstatus)",
      currentTherapyDkg: "Weitere Behandlungen",
      degree: "Titel",
      degreeDoctor: "Titel",
      downloadForm: "Antrag herunterladen",
      editForm: "Antrag bearbeiten",
      fieldsNotFromAssessmentModalTitle:
        "Bitte geben Sie folgende Informationen an, um den Antrag vollständig zu generieren.",
      formGenerationErrorMessage:
        "Ein Fehler ist aufgetreten, der Antrag konnte nicht als PDF generiert werden. Bitte versuchen Sie es noch einmal.",
      furtherTherapeuticDiagnosticMeasures:
        "Weitere erforderliche therapeutische oder diagnostische Maßnahmen",
      furtherTherapeuticDiagnosticMeasuresDescription: "Beschreibung",
      furtherTherapeuticDiagnosticMeasuresFinishedOn: "Beendet am",
      furtherTherapeuticDiagnosticMeasuresFrom: "Von",
      furtherTherapeuticDiagnosticMeasuresTo: "Bis",
      generateFormButton: "Antrag generieren",
      generateFormTitle: "Antrag für den Kostenträger als PDF generieren:",
      generateMedicalFormButton: "Ärztlichen Befundbericht generieren",
      generateMedicalFormTitle:
        "Ärztlichen Befundbericht für den Kostenträger als PDF erstellen:",
      guardianIsAurhorizedRepresentative: "Bevollmächtigter",
      guardianIsBetreuer: "Betreuer",
      guardianIsCustodian: "Vormund",
      guardianIsLegalRepresentative: "Gesetzlicher Vertreter",
      guardianPhoneNumber: "Telefonnummer",
      guardianPostalCode: "Postleitzahl",
      guardianStreetHouseNumber: "Straße, Hausnummer",
      guardianTelefaxEmail: "Telefax, E-Mail",
      guardianTown: "Ort",
      height: "Größe",
      indicationCardiology: "Kardiologie",
      indicationGeriatrics: "Geriatrie",
      indicationMusculoskeletalDiseases: "Muskuloskelettale Erkrankungen",
      indicationNeurology: "Neurologie",
      indicationOther: "Sonstige Indikationen",
      indicationOtherLabel: "z.B. Gastroenterologische Erkrankungen, etc",
      interimProsthesisFitted: "Anpassung einer Interimsprothese ist erfolgt",
      jobCareRelatives: "Pflege von Angehörigen",
      jobChildRaising: "Kindererziehungszeiten",
      jobMinijob: "Minijob",
      jobPensionAdjustment: "Versorgungsausgleich",
      jobStatutoryPensionFarmersFrom: "ggf. auf Grund von",
      kisConnection: {
        addFromRecare: "Aus Recare einfügen",
        addManually: "Manuell eintragen",
        buttonCancel: "Abbrechen",
        buttonImport: "Importieren",
        importError:
          "Die Antragsdaten konnten nicht importiert werden. Bitte geben geben Sie die Daten manuell ein oder versuchen Sie es erneut.",
        importFormData: "Antragsdaten importieren",
        infoAssessmentNotUpdated:
          "Patientendaten im Recare Patientenprofil werden nicht aktualisiert",
        modalTitleNotPossibleToConnect:
          "Es ist derzeit nicht möglich, eine Verbindung zu Ihrem KIS herzustellen. Sie können Patientendaten manuell oder aus dem Recare Patientenprofil einfügen.",
        placeholderExternalID: "Bitte geben Sie die Krankenhausfallnummer ein",
        subtitleImportFormData:
          "Alle verfügbaren Antragsdaten werden automatisch aus dem KIS übernommen und vorausgefüllt.",
        titleImportFormData: "Übernahme der Antragsdaten aus dem KIS",
        titleImportForms:
          "Möchten Sie die Daten vom AHB Antrag aus Ihrem KIS importieren?",
        toastErrorImport:
          "Die Antragsdaten konnten nicht importiert werden. Bitte geben geben Sie die Daten manuell ein oder versuchen Sie es erneut.",
        tooltipConnected:
          "Recare ist mit Ihrem KIS verbunden. Sie können Patientendaten automatisch importieren.",
        tooltipNotConnected:
          "Recare kann aktuell keine Verbindung zu Ihrem KIS herstellen. Sie können jedoch die Antragsdaten manuell einfügen.",
      },
      lastEmployment: "Zuletzt ausgeübte Erwerbstätigkeit/ausgeübter Beruf",
      latestAssessmentTitle:
        "Überprüfen oder fügen Sie das letzte Assessment hinzu",
      legProthesis: "OS/US-Prothese",
      maxLettersValidation: (args: { max: string }) =>
        template("Höchstens {{max}} Zeichen", args),
      namePhoneFax: "Name, Vorname, Telefonnummer, Fax",
      nationality: "Nationalität",
      needsPegTube: "PEG",
      patientBankAccountHolder: "Kontobesitzer",
      patientBankIban: "IBAN",
      patientBankIbanInvalid:
        "Dieser IBAN ist unzulässig: bitte geben Sie einen deutschen IBAN ein, der mit DE beginnt und von 20 Ziffern gefolgt wird.",
      patientBankIbanPlaceholder:
        "Deutscher IBAN (22 Stellen) z.B. DE09 0909 0909 0909 9900 00",
      patientBankName: "Bankname",
      patientFirstName: "Vorname",
      patientHouseNumber: "Straße und Hausnummer",
      patientInsuranceNoResults: (args: { emailMessage: string }) =>
        template(
          "Keine Ergebnisse? Sollte die Versicherung fehlen, {select:{{emailMessage}}}",
          args,
        ),
      patientInsuranceNoResultsLink: "senden Sie uns bitte eine E-Mail",
      patientInsuranceNumber: "KV-Nummer",
      patientLastName: "Nachname",
      patientName: "Name, Vorname",
      patientPhoneNumber: "Telefon",
      patientStatutoryPensionInsuranceNumber: "Versicherungsnummer",
      patientUnableToWorkBeforeHospitalization:
        "Bereits vor der Krankenhausbehandlung arbeitsunfähig",
      patientUnableToWorkBeforeHospitalizationSince: "Seit:",
      postalCodeDoctor:
        "Postleitzahl der behandelnden Ärztin/ des behandelnden Arzt",
      readyForm: "Bereit zum Teilen",
      recommendedRehaTypeAmbulant: "ambulant",
      recommendedRehaTypeAmbulantMobile: "ambulant mobil",
      recommendedRehaTypeAmbulantMobileLabel:
        "Begründung, z. B. schwere Orientierungsstörung, Wanderungstendenz",
      recommendedRehaTypeStationary: "stationär",
      recommendedRehaTypeStationaryLabel:
        "Begründung, z. B. Immobilität, Defizite bei der Selbstversorgung",
      rehabReasoLastAntineoplasticTherapy: "Letzte antineoplastische Therapie",
      rehabReason: "AHB-begründendes Ereignis",
      rehabReasonCausedByThirdParty: "Mögliche Schädigung durch Dritte",
      rehabReasonEventDate: "AHB-begründendes Ereignis Datum",
      rehabReasonLastRadiation: "Letzte Bestrahlung",
      rehabReasonNone: "Entfällt",
      rehabReasonOtherIncident: "Sonstiges Akut-Ereignis",
      rehabReasonWorkAccident: "Arbeitsunfall, Berufskrankheit",
      rehaCapacityFull: "voll belastbar",
      rehaCapacityOther:
        "weitere Besonderheiten im Hinblick auf die Belastbarkeit",
      rehaCapacityPartial: "teilbelastbar",
      rehaCapacityPartialLabel: "teilbelastbar mit?",
      relativeCare: "Mitaufnahme pflegebedürftiger Angehöriger",
      requirementArtificialRespiration: "Beatmung",
      requirementImmunosuppressants: "Immunsuppressiva",
      requirementOther: "Sonstige Anforderungen",
      requirementOtherLabel: "z.B. Mitaufnahme von Haustieren, etc",
      requirementRehaClinic:
        "Es werden besondere Anforderungen an die Rehabilitationseinrichtung gestellt",
      requirementRehaClinicLabel: "und zwar wegen:",
      retirementEarningCapacityCompany:
        "Rentenversicherungsträger der Erwerbsminderungsrente",
      retirementPensionApplied: "Eine Rente wurde beantragt",
      retirementPensionAppliedDkg: "Altersrente wurde beantragt",
      retirementPensionCarrierName: "Name des Rentenversicherungsträgers",
      retirementPensionEarningCapacity:
        "Rente wegen Erwerbsminderung wird bezogen",
      retirementPensionEarningCapacityApplied:
        "Rente wegen Erwerbsminderung wurde beantragt",
      retirementPensionInsuranceCompanyName:
        "Name and Anschrift des Leistungsträger",
      retirementPensionInsuranceCompanyNameDKG:
        "Rentenversicherungsträger der Altersrente",
      retirementPensionReceived:
        "Es wird eine Rente aus der gesetzlichen Rentenversicherung bezogen",
      retirementPensionReceivedDkg: "Altersrente wird bezogen",
      selectForm: "Formular auswählen",
      severeSenseImpairment: "gravierende Hör-, Seh- und Sprechbehinderung",
      shareWithInsurance: "Antrag übermitteln",
      stableDuringExercise: "übungsstabil",
      streetHouseNumberDoctor:
        "Straße, Hausnummer der behandelnden Ärztin/ des behandelnden Arzt",
      threats:
        "Es drohen oder bestehen längerfristige (>6 Monate) alltagsrelevante Beeinträchtigungen (Beurteilung zum Zeitpunkt der Befundung)",
      threatsAreasLife: "Bedeutende Lebensbereiche",
      threatsCommunication: "Kommunikation",
      threatsCommunicationLabel: "z.B. beim Sprechen, etc.",
      threatsCommunityLife: "Gemeinschaftsleben",
      threatsCommunityLifeLabel:
        "z.B. bei der Teilnahme an sportlichen, kulturellen und religiösen Angeboten, etc",
      threatsDomesticLife: "Häusliches Leben",
      threatsDomesticLifeLabel:
        "z.B. beim Einkaufen, Mahlzeiten vorbereiten, Hausarbeiten erledigen, etc",
      threatsGeneralTasks: "Allgemeine Aufgaben",
      threatsGeneralTasksLabel: "z.B. in der täglichen Routine, etc",
      threatsLearning: "Lernen und Wissensanwendung",
      threatsLearningLabel:
        "z.B. Beeinträchtigungen beim Zuhören/Zuschauen, Aneignen von Fertigkeiten, etc",
      threatsMobility: "Mobilität",
      threatsMobilityLabel:
        "z.B. beim Laufen längerer Strecken oder beim Hand- und Armgebrauch, etc",
      threatsRelationships: "Interpersonelle Interaktionen und Beziehungen",
      threatsRelationshipsLabel:
        "z.B. beim Aufbau und der Aufrechterhaltung von Familienbeziehungen/intimen/informellen und formellen sozialen Beziehungen, etc",
      threatsSelfSufficiency: "Selbstversorgung",
      threatsSelfSufficiencyLabel: "z.B. beim Waschen, An- und Auskleiden, etc",
      townDoctor: "Ort der behandelnden Ärztin/ des behandelnden Arzt",
      tracheostoma: "Tracheostoma",
      transferReason: "Grund für das Verlegungsdatum",
      vtwo: {
        alreadySentTitle: {
          medicalForm: "Der medizinische Befundbericht wurde bereits versendet",
          requestForm: "Der einheitliche Antrag wurde bereits versendet",
        },
        complete: "Fertigstellen",
        formsCanBeUpdatedInfo:
          "Das Krankenhaus kann jederzeit Änderungen an den Dokumenten vornehmen. Sie werden durch das Nachrichtenfenster informiert. Sollten Sie keine Dokumente erhalten haben, kontaktieren Sie das Krankenhaus.",
        formsNotReceived: "Die Formulare sind noch nicht eingegangen",
        formsReceived: {
          medicalForm: "Der ärztliche Befundbericht ist eingegangen",
          requestForm: "Der einheitliche Antrag ist eingegangen",
        },
        generateButtons: {
          medicalForm: "Ärztlicher Befundbericht",
          requestForm: "Einheitlicher Antrag",
        },
        herebyConfirm: {
          formValidationConfirm:
            "Bitte klicken Sie das Feld an, um das Formular fertigzustellen.",
          medicalForm:
            "Hiermit bestätige ich die Richtigkeit der von mir gemachten Angaben.",
          requestForm:
            "Hiermit bestätige ich, dass der Antrag auf Anschlussrehabilitation und die darin gemachten Angaben in Absprache mit dem Patienten/der Patientin erfolgt sind.",
        },
        lockIcon: {
          costPayer: "Die Informationen in den Formularen sind verschlüsselt",
          dataProtection:
            "Sensible Patientendaten können sicher ausgetauscht werden. Alle Nachrichten sind verschlüsselt.",
          patientCreator:
            "Die Informationen in den Dokumenten sind verschlüsselt und können sicher an den Kostenträger übermittelt werden.",
        },
        markAsSent: "Als versendet markieren",
        pleaseCompleteTitle: {
          medicalForm: "Bitte stellen Sie den ärztlichen Befundbericht fertig",
          requestForm: "Bitte stellen Sie den einheitlichen Antrag fertig",
        },
        postShareTooltip:
          "Alle neuen Änderungen in den Dokumenten werden nun automatisch dem Kostenträger übermittelt",
        readyTitle: {
          medicalForm:
            "Der folgende medizinische Befundbericht kann übermittelt werden",
          requestForm:
            "Der folgende einheitliche Antrag kann übermittelt werden",
        },
        saveAsDraft: "Als Entwurf speichern",
        shareWithInsuranceDisabledTooltip:
          "Um Informationen an den Kostenträger zu übermitteln, müssen die Dokumente fertiggestellt oder hochgeladen werden",
        shareWithInsuranceDisabledTooltipBeforeHandedover:
          "Um Informationen an den Kostenträger zu übermitteln, bitte übergeben Sie zuerst die Suche an den Kostenträger.",
      },
      weaning: "Weaning",
      weight: "Gewicht",
      woundClosed: "Die Wunde ist reizlos / geschlossen",
      zipcodeState: "Postleitzahl, Wohnort",
    },
    gender: "Geschlecht",
    grants: "Finanzierung",
    hasAppliedforCarelevel: "Pflegegrad wurde beantragt",
    hasAppliedforHigherCarelevel: "Erhöhung wurde beantragt",
    hasHealthInsurance: "Patient ist krankenversichert",
    hasHelpAtHome:
      "Häusliche Hilfeleistung vorhanden (häusliche KP, soziale Dienste)",
    hasNoHealthInsurance: "Patient hat keine Krankenversicherung",
    hintAdditionalExaminations: "z.B. Bluttests, Scans",
    hintIntravenousInjection: "Bitte spezifizieren Sie das Medikament",
    hintIsNotBarrierFree:
      "z.B. Treppen im Innen- und Außenbereich, Einstieg in Badewanne nicht möglich",
    hintMedicalHistory:
      "Bitte spezifizieren Sie die wichtigsten Informationen für den Empfänger ",
    hintRehabIE:
      "Dieses Modul ist nicht für den Internet Explorer verfügbar. Bitte verwenden Sie einen anderen Browser wie Google Chrome oder Mozilla Firefox.",
    hintSpecialisedAdvices: "Sie können bis zu drei wählen",
    hintTakesBloodThinner: "Bitte nennen Sie das Medikament",
    hospitalStay: "Krankenhausaufenthalt",
    insuranceNumber: "Versichertennummer",
    insuranceProvider: "Krankenkasse",
    insuranceShortProfile: "Kurzes Patientenprofil",
    integrationMissingFieldsModal: {
      body: "Bitte füllen Sie die folgenden Pflichtfelder im Patientenprofil aus, um die Anträge versenden zu können.",
      editPatient: "Patientenprofil bearbeiten",
      title: "Fehlende Angaben im Patientenprofil",
    },
    interestedStayLongTerm:
      "Der Patient ist an einem längerfristigen Aufenthalt interessiert",
    interestedStayLongTermRequest: "am längerfristigen Aufenthalt interessiert",
    intravenousInjection: "Spritzenpumpe",
    isNotBarrierFree: "Wohnsituation is nicht barrierefrei",
    kis: {
      addPatientTooltip:
        "Alle verfügbaren Patientendaten werden automatisch aus dem KIS übernommen und vorausgefüllt.",
      getFilesError:
        "Dateien können nicht abgerufen werden - prüfen Sie, ob die interne Patienten-ID korrekt ist und Dateien vorhanden sind",
      headlineImportPatientData: "Übernahme der Patientendaten aus dem KIS",
      headlineReimportPatientData:
        "Möchten Sie die neusten Patientendaten aus dem KIS importieren?",
      idPlaceholder: "Bitte geben Sie die Krankenhausfallnummer ein",
      importError:
        "Die Patientendaten konnten nicht importiert werden. Bitte geben Sie die Krankenhausfallnummer erneut an oder kontaktieren Sie Recare.",
      importErrorPopup:
        "Der Import wurde abgebrochen. Bitte erlauben Sie das Öffnen von Pop-ups in den Browsereinstellungen, damit die Patientendaten importiert werden können oder kontaktieren Sie Recare.",
      importPatientData: "Patientendaten importieren",
      importSuccessfulToast: "Der Import war erfolgreich.",
      noDataForInternalId: "Keine Daten zur Krankenfallhausnummer gefunden.",
      reimportPatientData: "Import wiederholen",
      reimportPatientDataHint:
        "Die sich derzeit im Patientenprofil befinden Informationen, werden durch die importierten Patientendaten überschrieben. Angaben die nicht importiert werden können, werden nicht ersetzt.",
      tooltipConnected:
        "Recare ist mit Ihrem KIS verbunden. Sie können Patientendaten automatisch importieren.",
      tooltipNotConnected:
        "Recare kann aktuell keine Verbindung zu Ihrem KIS herstellen. Sie können jedoch manuell Patientendaten eingeben.",
      tooltipNotConnectedReimport:
        "Recare kann aktuell keine Verbindung zu Ihrem KIS herstellen. Sie können jedoch manuell Patientendaten bearbeiten.",
    },
    lastName: "Nachname",
    loadingError: "Konnte nicht auf den Patienten zugreifen",
    mainSpecializations: "Fachabteilung der beantragten Leistung",
    map: {
      mapButton: "Karte anzeigen",
      mapReceiverText:
        "Klicken Sie auf „Karte anzeigen“, um den Standort zu sehen.",
      mapSenderText:
        "Klicken Sie  „Karte anzeigen“, um den Standort des Nachversorgers zu sehen.",
      title: "Karte",
    },
    medicalDiagnosis: {
      addictions: "Abhängigkeiten",
      allergies: "Allergien",
      assessmentDate: "Bewertungsdatum",
      assessmentDateValidation:
        "Bitte wählen Sie ein Bewertungsdatum in der Vergangenheit",
      backToProfile: "ZURÜCK ZUM PROFIL",
      barthelDate: "Datum:",
      barthelHeadingResultPage: (args: {
        patientName: string;
        recareId: string;
      }) => template("Barthel-Index: {{patientName}} {{recareId}}", args),
      barthelIndexPlaceholder: "Barthel-Index-Ergebnis",
      barthelLink: "Übersichtsseite des Barthel-Index öffnen\n",
      barthelNoValue:
        "Es gibt noch kein Ergebnis, das hier angezeigt werden kann. Gehen Sie zurück zum Patientenprofil und füllen Sie den Barthel-Index aus, um Ergebnisse anzuzeigen.",
      barthelScore: "Gesamtscore:",
      barthelSubtitle:
        "Bitte wählen Sie alle zutreffenden Optionen aus, um den Gesamtscore zu berechnen",
      barthelValidation: "Bitte geben Sie eine Zahl zwischen 0 und 100 ein",
      chronicPains: "Chronische Schmerzen",
      conductedEarlyRehab:
        "Es erfolgte bereits eine frührehabilitative Komplexbehandlung im Krankenhaus",
      conductedEarlyRehabLabel:
        "z.B. wann und wo oder auch in welche Phase die Behandlung stattgefunden hat",
      currentInfectionsError: "Bitte wählen Sie eine Option",
      dependencyLevel: "Barthel-Index",
      details: "Details",
      diagnosis: "Diagnose",
      diagnosisType: {
        admissionDiagnosis: "Aufnahmediagnose",
        admissionDiagnosisDepartment: "Fachabteilung Aufnahmediagnose",
        ambulanceDiagnosis: "Ambulanzdiagnose",
        ambulantDischargeDiagnosis: "Entlassdiagnose ambulant",
        anaesthesiaDiagnosis: "Anästhesiediagnose",
        anamnesis: "",
        careDiagnosis: "Pflegediagnose",
        causeOfDeath: "Todesursache",
        childBirthDiagnosis: "Geburtsdiagnose Kind",
        childDischargeTransferDiagnosis: "Entl./Verl. Diagnose Kind",
        childMorbidity: "Morbidität des Kindes",
        conservativeDiagnosis: "Konserv. Diagnose",
        cumulativeProcedures: "Kumulierte Prozeduren",
        departmentDiagnosis: "Fachbereichsdiagnosen",
        departmentMainDiagnosis: "Fachabteilungshauptdiagnose",
        dischargeDiagnosis: "Entlassdiagnose",
        dischargeDiagnosisDepartment: "Fachabteilung Entlassdiagnose  ",
        evolution: "",
        examinationDiagnoses: "Untersuchungsdiagnosen",
        hospitalMainDiagnosis: "Krankenhaus Hauptdiagnose",
        infectionControlDiagnosis: "Infektionsschutzdiagnose",
        insuranceExtensionDiagnosis: "Verlängerungsdiagnose (Kasse)",
        invoicingDiagnosis: "Abrechnungsdiagnose",
        medicalInstructions: "",
        operationDiagnosis: "Operation - Diagnose",
        otherDiagnosis: "Andere Diagnose",
        postoperativeDiagnosis: "Postoperative Diagnose ",
        preoperativeDiagnosis: "Präoperative Diagnose ",
        principleDRG: "Primär-DRG",
        recommendedRoutine: "",
        referralDiagnosis: "Einweisungsdiagnose",
        rehabMeasures: "Reha-Maßnahme",
        secondaryDiagnosis: "Nebendiagnose",
        secondaryDRG: "Sekundär-DRG",
        transferDiagnosis: "Verlegungsdiagnose",
        treatmentDiagnosis: "Behandlungsdiagnose",
        treatmentDiagnosisDepartment: "Fachabteilung Behandlungsdiagnose",
      },
      doctorInChargeInHospital: "Name des behandelnden Arztes",
      doctorInChargeInHospitalPhone: "Tel.-Nr. d. behandelnden Arztes/Station",
      earlyRehabBarthelIndexPlaceholder: "Frühreha-Index-Ergebnis",
      earlyRehabDateWarning: "Bitte geben Sie ein Datum ein",
      earlyRehabForm: "Frühreha-Index",
      earlyRehabIndex: {
        behaviouralDisorder:
          "Beaufsichtigungspflichtige Verhaltensstörung (mit Eigen- und/oder Fremdgefährdung)",
        communicationDisorder: "Schwere Verständigungsstörung",
        dateTitle: "Datum:",
        earlyRehabLink: "Übersichtsseite des Frühreha-Index öffnen\n",
        earlyRehabValidation:
          "Bitte geben Sie eine Zahl zwischen -325 und 0 ein",
        headingResultPage: (args: { patientName: string; recareId: string }) =>
          template("Frühreha-Index: {{patientName}} {{recareId}}", args),
        intensiveMonitoring:
          "Intensivmedizinischer überwachungspflichtiger Zustand",
        intermittentVentilation: "Intermittierende Beatmung",
        noValue:
          "Es gibt noch kein Ergebnis, das hier angezeigt werden kann. Gehen Sie zurück zum Patientenprofil und füllen Sie den Frühreha-Index aus, um Ergebnisse anzuzeigen.",
        orientationConfusion:
          "Beaufsichtigungspflichtige Orientierungsstörung (Verwirrtheit)",
        scoreTitle: "Gesamtscore:",
        subtitle:
          "Bitte wählen Sie alle zutreffenden Optionen aus, um den Gesamtscore zu berechnen",
        swallowingDisorder: "Beaufsichtigungspflichtige Schluckstörung",
        tracheostoma: "Absaugpflichtiges Tracheostoma",
      },
      form: "Beurteilung",
      hintSecondaryDiagnoses: "relevante Nebendiagnosen",
      hospitalStay: "Krankenhausaufenthalt ",
      icdCodeMainPatientInfoLabel: "Rehabilitationsrelevante Hauptdiagnose",
      icdCodes: "ICD‌-10-GM",
      icdCodesAdditional:
        "Bis zu 5 weitere ICD‌-10, die für die Versorgung relevant sind",
      icdCodesAdditionalPatientInfoLabel: "Weitere ICD‌-10",
      icdCodesMain: "ICD‌-10 der rehabilitationsrelevanten Hauptdiagnose",
      infections: "Aktuelle Infektionen/Keimbesiedlung",
      infectionsClostridien: "Clostridien",
      infectionsFourMRGN: "4MRGN",
      infectionsHint: "Bitte erläutern Sie",
      infectionsMRSA: "MRSA",
      infectionsOther: {
        errorMissing: "Bitte angeben",
        label: "Sonstige Keime",
        placeholder: "z.B. ESBL, Norovirus, etc.",
      },
      infectionsRemarks:
        "Bemerkungen wie zB. Lokalisation, Sanierung begonnen, etc.",
      infectionsRequiresIsolation: "Isolationsbedarf",
      infectionsThreeMRGN: "3MRGN",
      infectionsVRE: "VRE",
      limitations: "Einschränkungen",
      limitedCapacity:
        "Eingeschränkte Belastbarkeit, um aktiv an der Therapie teilzunehmen",
      limitedCapacityOther: "Sonstige",
      limitedCognitiveCapacity: "Eingeschränkte kognitive Belastbarkeit",
      limitedPsychicalCapacity: "Eingeschränkte körperliche Belastbarkeit",
      limitedPsychologicalCapacity: "Eingeschränkte psychische Belastbarkeit",
      limitedSocialBehaviour: "Eingeschränktes Sozialverhalten",
      mainDiagnosis: {
        infectionsHint: "Bitte erläutern Sie",
        label: "Hauptdiagnose",
        placeholder:
          "z.B. Pneumonie, Oberschenkelfraktur, akute Niereninsuffizienz, etc. ",
        validationError:
          "Bitte wählen Sie entweder den ICD-10-Code aus oder geben Sie die Hauptdiagnose im Textfeld an.",
        validationErrorMainOnly:
          "Bitte geben Sie die Hauptdiagnose in das Textfeld ein. ",
      },
      multiMorbidity: "Multimorbidität",
      multiMorbidPatient: "Multimorbider Patient",
      operationDate: "AHB-begründendes Ereignis",
      operationDescription:
        "z.B. Operation, Akut-Ereignis, Letzte Bestrahlung, Arbeitsunfall, … ",
      palliativeCertificateNeeded: "SAPV",
      rehabilitationGoals: "Rehaziele/Behandlungsziele",
      rehabilitationPotential: "Rehabilitationspotential",
      rehabilitationPotentialDescription:
        "Beschreibung des Rehabilitationspotentials",
      remarks: "Zusätzliche Informationen",
      secondaryDiagnosesCostPayer: "Nebendiagnosen für Kostenträger",
      secondaryDiagnosis: "Nebendiagnosen",
      shortTerm: "Baldige Entlassung ins Häusliche möglich",
      singerIndexPlaceholder: "SINGER-Patientenprofil-Ergebnis",
      singerPatientProfil: "SINGER Patientenprofil",
      singerPatientProfile: {
        assessmentDatePlaceholder: "Bewertungsdatum",
        assessmentDateValidation:
          "Bitte wählen Sie ein Bewertungsdatum in der Vergangenheit",
        buttonClearContent: "Eingaben zurücksetzen",
        buttonDelete: "Löschen",
        buttonEdit: "Bearbeiten",
        buttonFill: "Beurteilung ausfüllen",
        communicationListening: "Hörverstehen",
        communicationTalk: "Sprechen",
        confirmationDelete:
          "Sind Sie sicher, dass Sie die Beurteilung löschen wollen? Alle Eingaben der Beurteilung werden dabei zurückgesetzt.",
        creationDate: "Bewertungsdatum",
        creationDateError: "Bitte wählen Sie ein vergangenes Datum",
        dateTitle: "Datum:",
        detailedForm: "Detaillierte Beurteilung angeben",
        domesticLifeHousehold: "Haushaltsführung",
        headingResultPage: (args: { patientName: string; recareId: string }) =>
          template("SINGER Index: {{patientName}} {{recareId}}", args),
        inputValidation: "Wählen Sie einen Wert",
        learnRead: "Lesen und verstehen",
        learnWrite: "Schreiben",
        mobilityStairs: "Treppensteigen",
        mobilityTransfer: "Transfer",
        mobilityWalk: "Gehen",
        mobilityWheelchair: "Rollstuhlnutzung",
        noValues:
          "Es gibt noch kein Ergebnis, das hier angezeigt werden kann. Gehen Sie zurück zum Patientenprofil und füllen Sie das SINGER-Patientenprofil aus, um Ergebnisse anzuzeigen.",
        scoreIndependent: "Selbstständig",
        scoreIndependentFive: "Ohne HiMi (5 Punkte)",
        scoreIndependentFour: "Mit HiMi (4 Punkte)",
        scorePoints: "Punkte",
        scoreProfessionalHelp: "Professionelle Hilfe",
        scoreProfessionalHelpOne: "Kontakthilfe (1 Punkt)",
        scoreProfessionalHelpZero: "Total (0 Punkte)",
        scoreSupporter: "Unterstützer",
        scoreSupporterThree: "Assistenz (3 Punkte)",
        scoreSupporterTwo: "Kontakthilfe (2 Punkte)",
        scoreTitle: "Gesamtscore:",
        scoreTotalScore: "Gesamtscore",
        selfsufficiencyDress: "An- und Ausziehen",
        selfsufficiencyEat: "Essen, Trinken",
        selfsufficiencyHygiene: "Persönliche Hygiene",
        selfsufficiencyShower: "Waschen, Duschen",
        selfsufficiencyStool: "Stuhlkontrolle",
        selfsufficiencyToilet: "Toilettengang",
        selfsufficiencyUrine: "Harnkontrolle",
        singerDomainCommunication: "Kommunikation",
        singerDomainDomesticLife: "Häusliches Leben",
        singerDomainLearn: "Lernen und Wissen anwenden",
        singerDomainMobility: "Mobilität",
        singerDomainSelfsufficiency: "Selbstversorgung",
        singerDomainSocialInteraction: "Sozial Interaktion",
        singerDomainTask: "Allgemeine Aufgaben und Anforderungen",
        singerLink: "Übersichtsseite des SINGER-Patientenprofils öffnen",
        singerSum: "SINGER-Ergebnis",
        singerSumValidation: "Bitte geben Sie eine Zahl zwischen 0 und 100 ein",
        socialInteraction: "Soziales Verhalten",
        subtitle:
          "Bitte wählen Sie für alle Rubriken die passende Punktezahl aus, um die Summe/Gesamtscore zu berechnen.",
        taskConcentration: "Dauerkonzentration",
        taskOrientation: "Orientierung/Gedächtnis",
        taskPlanning: "Planen und Probleme lösen",
        title: "SINGER-Patientenprofil",
      },
      stepperTitle: "Medizinische Informationen",
      totalScore: "Gesamtscore",
    },
    medicalHistory: "Anamnese",
    medicalHistoryPlaceholder: "",
    medicalSupplies: {
      existingMedicalSupplies: "Hilfsmittel sind bereits vorhanden",
      neededMedicalSupplies: "Benötigte Hilfsmittel",
      prescribedMedicalSupplies: "Im Krankenhaus verordnete Hilfsmittel",
      specificMedicalSupplies: "Bitte erläutern Sie",
    },
    mentalHealth: "Psychische Verfassung",
    mentalState: {
      aggressivity: "Aggressivität",
      anxiety: "Angstzustände",
      closed_area_needed: {
        label: "Geschlossene Station notwendig",
        placeholder: "z.B. mehrmals von zu Hause weggelaufen, etc.",
      },
      closedStationRequired:
        "Geschützter Bereich / Geschlossene Station erforderlich",
      dangerous: "Eigen- oder Fremdgefährdung",
      dementia: {
        label: "Demenz",
        placeholder: "z.B. Anleitung erforderlich, gut führbar, etc.",
      },
      depression: "Depressionen",
      facilityCharacteristics: "Einrichtungsmerkmale",
      fixationRequired: "Fixierung erforderlich",
      mentalIssues: "Psychische Erkrankungen",
      mentalIssuesOther:
        "Andere psychische Erkrankungen und weitere Informationen",
      nightwatch: {
        label: "Sitzwache",
        placeholder: "z.B. Lage des Patienten, etc.",
      },
      protectedAreaNeeded: {
        label: "Beschützender Bereich notwendig",
        placeholder: "z.B. aufgrund von Demenz",
      },
      stepperTitle: "Psychische Verfassung",
    },
    menuAssigneePlaceholder: "Mitarbeiter auswählen",
    mobileShort: {
      stepTitle: "Kurzprofil",
    },
    mobility: {
      assistiveEquipment: "Hilfsmittel",
      auditive_impairment: {
        label: "Schwerhörigkeit",
        placeholder: "z.B. Hörgerät vorhanden, etc.",
      },
      orthese: {
        label: "Orthese",
        placeholder: "zB. Körperteil, seit wann, etc.",
      },
      prothese: {
        label: "Prothese",
        placeholder: "zB. Körperteil, seit wann, etc.",
      },
      stepperTitle: "Mobilität",
      visual_impairment: {
        label: "Sehschwäche",
        placeholder: "z.B. Brille, Glasauge, etc.",
      },
    },
    needsHelpEating: "Patient benötigt Unterstützung bei der Nahrungsaufnahme ",
    needsHelpWalking: "Patient benötigt Unterstützung beim Gehen",
    newResponsibleMessagePlaceholder:
      "Ihre Nachricht an die ausgewählte Person",
    noAuctionsError:
      "Es gab ein Problem bei der Patientenerstellung, bitte versuchen Sie es erneut",
    noCandidates:
      "Anhand der eingegebenen Daten wurden bereits alle potentiellen Empfänger kontaktiert.",
    nonDeclarationOfConsent:
      "Patient hat nicht zugestimmt oder es wurden keine Information dazu angegeben",
    noPersonalDataWarning:
      "Bitte geben Sie personenbezogene Informationen nur in die verschlüsselten Textfelder ein.",
    notifySocialWorker: {
      assigneeChangeFailed:
        "Ihre Anfrage zum Ändern des Empfängers dieser Patientenanfrage ist fehlgeschlagen. Bitte aktualisieren Sie sie und versuchen Sie es erneut.",
      title: (args: { assignedName: string }) =>
        template(
          "Der zuständige Mitarbeiter wurde erfolgreich geändert. Möchten Sie {{assignedName}} über die Zuweisung per E-Mail informieren?",
          args,
        ),
    },
    palliativeCare: {
      label: "Palliativpflege",
      placeholder: "z.B. Verschlechterung, psychische Betreuung, etc.",
    },
    patient: "Patient",
    patientAlreadyInHospital: "Patient befindet sich bereits im Krankenhaus",
    patientId: "Recare ID",
    patientInformation: {
      acceptsPrivatePayment: "Private Zahlung möglich",
      addictionsPlaceholder: "z.B. Art der Abhängigkeit, Behandlung, etc.",
      allergiesPlaceHolder: "z.B. Typen, Behandlung, etc.",
      chronicPainsPlaceHolder: "z.B. Lokalisation, Behandlung, etc.",
      civilServantsAid: "Beihilfe (Beamte)",
      civilServantsAidDescription: "Beihilfe (Beamte) Details",
      civilServantsAidPlaceholder: "z.B. zuständige Beihilfestelle",
      contactIsPatient: "Der Patient selbst ist der Ansprechpartner",
      contactIsPatientPlaceholder:
        "z. B. Kontaktinformationen wie Telefonnummer oder E-Mail-Adresse",
      currentDomesticSituation:
        "Die häusliche Versorgung ist aktuell sichergestellt",
      decisionsLanguages: "Ansprechpartner und Kommunikation",
      encryptionExplanation:
        "Diese Informationen sind verschlüsselt. Fragen Sie die Freigabe der Stammdaten bei der Klinik an.",
      financing: "Finanzierung",
      financingNotSelected: "Es wurden keine Finanzierungsoptionen ausgewählt.",
      floor: "Etage",
      freeFromSupplementaryPayment: "Zuzahlungsbefreit",
      generalInformation: "Allgemeine Daten",
      generalPractitionerContactDetailsPlaceholder:
        "Kontaktdaten des Hausarztes",
      generalPractitionerNamePlaceholder: "Name des Hausarztes",
      generalPractitionerPlaceholder:
        "z. B. Fachgebiet, Telefonnummer, Adresse, etc.",
      generalPractitionerTitle: "Hausarzt",
      grantsMoreInformation:
        "Weitere Informationen zur Finanzierung, z.B. Eigenanteil i.H.v.",
      guardianContactInformation: "z.B. Kontaktdaten des Vertreters/Betreuers",
      guardianRequested: "Betreuung wurde angeregt",
      hasGuardian: "Vertreter/Betreuer vorhanden oder wird benötigt",
      hasGuardianPlaceholder:
        "e.g. guardian or legal representative for specific areas",
      insurance: "Versicherung",
      isLiftAvailable: "Ein Fahrstuhl ist vorhanden",
      liftAvailable: "Fahrstuhl vorhanden",
      livingSituation: "Wohnsituation",
      mobilityPlaceholder:
        "Weitere Informationen zur Mobilität, wie z.B. Gangunsicherheit, Gehstrecke, Belastung, etc.",
      monitoringNeeds: "Benötigte Patientenüberwachungsgeräte",
      needsOxygen: "Der Patient benötigt Sauerstoff",
      patientCannotCommunicateInGerman: {
        label: "Kommunikation ist auf Deutsch nicht möglich",
        placeholder: "z.B. welche Sprache, ist ein Dolmetscher vorhanden, etc.",
      },
      patientIsLiving: "Patient lebt",
      patientUsesSignInLanguage: {
        label: "Patient kommuniziert ohne Lautsprache",
        placeholder:
          "z.B. ein Gebärdendolmetscher ist vorhanden, Kommunikationshilfe wird benötigt, etc.",
      },
      payers: "Kostenträger",
      payersInsurance: "Krankenkasse (Gesetzliche oder Private)",
      payersMoreInformations: "Weitere Informationen",
      payersOther: "Sonstige Kostenträger",
      payersOtherDescription: "Sonstige Kostenträger Details",
      payersOtherPlaceholder: "Bitte erläutern Sie",
      payout: "Pflegeversicherungsbezug",
      placeholder: "Bitte erläutern Sie",
      placeholderCurrentDomesticSituationUnknown: "Bitte erläutern Sie",
      placeholderLiftAvailableNo: "z.B. Stockwerk, Treppenstufen im häuslichen",
      placeholderLiftAvailableUnknown: "Bitte erläutern Sie",
      publicAccidentInsurance: "Gesetzliche Unfallversicherung",
      publicAccidentInsuranceDescription:
        "Gesetzliche Unfallversicherung Details",
      publicAccidentInsurancePlaceholder:
        "z.B. zuständige Berufsgenossenschaft",
      publicPension: "Gesetzliche Rentenversicherung",
      publicPensionDescription: "Gesetzliche Rentenversicherung Details",
      relativesAvailable: "Angehörige / weitere Ansprechpartner sind verfügbar",
      relativesContactInformation:
        "z.B. Name, Beziehung zum Patienten, Telefonnummer, Adresse",
      relativesLookingSameTime:
        "Angehörige suchen zur gleichen Zeit nach einem Pflegeplatz",
      selfPayer: "Selbstzahler",
      selfPayerDescription: "Selbstzahler Details",
      selfPayerPlaceholder: "z.B. Zahlung in maximaler Höhe von...",
      socialServiceDepartment: "Sozialamt",
      socialServiceDepartmentDescription: "Sozialamt Details",
      socialServiceDepartmentPlaceholder: "z.B. zuständiges Sozialamt",
      specialRequirements: "Wahlleistungen",
      stepperTitle: "Patientendaten",
      supplementaryInsurance: "Zusatzversicherung",
      supplementaryInsuranceDescription: "Zusatzversicherung Details",
      supplementaryInsurancePlaceholder: "z.B. Name der Zusatzversicherung",
      supplementaryPayment: "Zuzahlung",
      supplementaryPaymentDescription: "Zuzahlung Details",
      supplementaryPaymentPlaceholder: "z.B. iHv 10€/Tag ist noch zu leisten",
      unsafeCurrentDomesticSituation:
        "Häusliche Versorgung ist aktuell nicht sichergestellt",
      unsafeCurrentDomesticSituationPlaceholder:
        "z.B. Haushalt kann nicht selbst geführt werden",
      visualImpairment: "Der Patient ist sehbehindert",
    },
    patientProfile: "Patientenprofil",
    patientSearchLabel: "Recare id/ Fallnummer",
    patientType: "Art der Überleitung",
    payers: "Kostenträger",
    personalId: "Patienten ID",
    physicalHealth: "Mobilität",
    placeholderBarrierFreeUnknown: "Bitte erläutern Sie",
    pleaseNoteId: (args: { patientId: string }) =>
      template(
        "Bitte notieren Sie die Recare-ID ({{patientId}}) in Ihren Akten / Ihrem System als primäre Referenz",
        args,
      ),
    preventativeCare: "Verhinderungspflege",
    profile: "Profil",
    profileBanners: {
      noProviderFound: {
        subtitle: "Empfänger können manuell zur Suche hinzugefügt werden.",
        title: "Es konnten keine potentiellen Empfänger gefunden werden.",
      },
      profileComplete: {
        subtitle: "Sie können die Suche starten.",
        title: "Patientenprofil ist vollständig.",
      },
      profileIncomplete: {
        subtitle: "Bitte Daten bearbeiten.",
        title: "Patientenprofil ist unvollständig.",
      },
    },
    publicCareInsuranceStatus:
      "Es werden Leistungen aus der gesetzlichen Pflegeversicherung bezogen",
    reimbursmentForHelpAtHome:
      "Kostenübernahme für hauswirtschaftliche Versorgung",
    reimbursmentSecured: "Kostenübernahme für Behandlungspflege",
    reliefServices: "Entlastungsleistungen",
    responsiblePerson: "Zuständiger Mitarbeiter",
    savedWithSolutionChange: (args: { listOfSolutions: string }) =>
      template(
        "Änderungen erfolgreich gespeichert. Sie suchen jetzt nach {{listOfSolutions}}.",
        args,
      ),
    savedWithSolutionChangeGeneric: (args: { listOfSolutions: string }) =>
      template(
        "Änderungen erfolgreich gespeichert. Sie suchen jetzt nach {{listOfSolutions}}. Bitte beachten Sie, dass Änderungen Auswirkungen auf die Liste der zu kontaktierenden Einrichtungen haben können.",
        args,
      ),
    searchOnlyApplyToSolutions: (args: { solutions: string }) =>
      template("Dieser Filter wird nur für {{solutions}} angewandt", args),
    searchPausedAutomaticallyWarning:
      "Aufgrund von Verbesserungen an der Suchfunktion ist es ab sofort nicht mehr möglich, eine Suche zu pausieren. Ihre pausierte Suche wurde automatisch gestoppt. Klicken Sie auf “Suche erneut starten”, um diese fortzusetzen.",
    searchRadius: "Umkreissuche",
    searchType: "Patiententyp",
    shortenedRehabPatientProfile: "Kurzes Patientenprofil ausreichend",
    shortTermCareGrant: "Kurzzeitpflege",
    singerIndex: {
      dressingUndressing: "An- und Ausziehen",
      eatingDrinking: "Essen, Trinken",
      personalHygiene: "Persönliche Hygiene",
      selfSufficiency: "Selbstversorgung\n\n\n\nSelbstversorgung\n\n",
      stoolControl: "Stuhlkontrolle",
      urineControl: "Harnkontrolle",
      washingShowering: "Waschen, Duschen",
    },
    socialHelpRecipient: "Sozialhilfeempfänger",
    socialWorkerName: "Zuständiger Mitarbeiter",
    solutionLabel: "Art der Versorgung",
    specialisedAdvices: "Zusätzliche Fachauskunft notwendig",
    specialistDoctor: "Chefarzt",
    station: "Station",
    stationPhoneNumber: "Stationsrufnummer",
    stopAuction: {
      automaticallyStopped:
        "Die Suche wurde automatisch beendet, da seit mehr als 14 Tagen keine Aktivität stattgefunden hat.",
      dischargeDelayed: "Die Entlassung verzögert sich",
      dischargedWithoutProvider: {
        care: "Patient wurde ohne Nachversorger entlassen",
        geriatrics: "Es konnte keine passende Klinik gefunden werden",
      },
      foundWithoutRecare: (args: { receiverTypeSingular: string }) =>
        template(
          "{{receiverTypeSingular}} wurde über einen anderen Weg gefunden oder per Telefon/Fax bestätigt",
          args,
        ),
      insuranceWorkerOrganised: "Vom Kostenträger",
      internalTransfer: "Interner Transfer",
      notNeeded: (args: { receiverTypeSingular: string }) =>
        template("{{receiverTypeSingular}} wird nicht mehr benötigt", args),
      options: {
        foundWithoutRecare: (args: { receiverTypeSingular: string }) =>
          template(
            "{{receiverTypeSingular}} wurde über einen anderen Weg gefunden oder per Telefon/Fax bestätigt",
            args,
          ),
        subtitle:
          "Geben Sie den Grund so genau wie möglich an, und helfen Sie uns, die Platform zu verbessern",
        transferNotNeeded: (args: { receiverTypeSingular: string }) =>
          template("{{receiverTypeSingular}} wird nicht mehr benötigt", args),
      },
      other:
        "um den Patienten aus einem anderen Grund zu archivieren und die Suche zu beenden",
      otherReason: "Sonstiges",
      patientDied: "Der Patient ist verstorben",
      patientTransfered: {
        care: "Patient wurde verlegt (z.B. Akutgeriatrie)",
        editProfile:
          "Wenn Sie das Patientenprofil während einer laufenden Suche bearbeiten und aktualisieren möchten, {select:klicken Sie bitte hier}.",
        geriatrics: "Verlegung wird nicht mehr benötigt",
      },
      providerFound: {
        care: "Ein Versorger wurde gefunden",
        geriatrics: "Eine Klinik wurde gefunden",
      },
      providerFoundWithoutRecare: {
        care: "Ein Versorger wurde gefunden",
        geriatrics: "Eine Klinik wurde gefunden",
      },
      providerNotNeeded: "Patient benötigt keine Versorgung mehr",
      providerWasFound: (args: { receiverTypeSingular: string }) =>
        template("{{receiverTypeSingular}} wurde gefunden", args),
      providerWillBeInformedOfUnavailability:
        "Bitte beachten Sie, dass folgende Einrichtung/en per E-Mail informiert werden, dass diese Anfrage nicht mehr verfügbar ist:",
      relativesOrganised: "Von Angehörigen",
      selectClosestReason:
        "Geben Sie den Grund so genau wie möglich an, und helfen Sie uns, die Platform zu verbessern",
      socialworkerOrganised: "Vom Sozialdienst",
      title: "Ich möchte die Suche abbrechen ...",
      transferred: "Patient wurde verlegt",
      withoutProvider: (args: { receiverTypeSingular: string }) =>
        template("Patient wurde ohne {{receiverTypeSingular}} entlassen", args),
    },
    stopAuctionAddCareprovider: {
      providerName: (args: { receiverTypeSingular: string }) =>
        template("{{receiverTypeSingular}} ist bekannt\n", args),
      title: (args: { receiverTypeSingular: string }) =>
        template("{{receiverTypeSingular}} wurde gefunden", args),
      unknownProvider: (args: { receiverTypeSingular: string }) =>
        template("{{receiverTypeSingular}} ist unbekannt", args),
      viaPatientFamily: "Über Angehörige gefunden",
    },
    stopAuctionStop: {
      placeholder: "Bitte erläutern Sie",
      title: "Warum möchten Sie diese Suche beenden?",
    },
    stopSearch: {
      cancelButton: "Abbrechen",
      confirmButton: "Bestätigen",
      firstStep: {
        firstStepText:
          "Nach Beenden der Suche informieren wir alle Anbieter automatisch darüber, dass die Suche inaktiv ist. Es besteht daher anschließend kein weiterer Handlungsbedarf für Sie.",
        firstStepTitle: (args: { providerNumber: string }) =>
          template(
            "Für diese Suche wurden {{providerNumber}} Anbieter kontaktiert",
            args,
          ),
      },
      nextButton: "Weiter",
      secondStep: {
        dropdownButton: "Anbieter kann nicht gefunden werden",
        mandatoryFieldInformation: "Bitte wählen Sie den passenden Grund aus",
        mandatoryFieldInformationProvider:
          "Bitte geben sie den Namen des Versorgungsanbieters ein",
        mandatoryFieldInformationSpecialisation:
          "Bitte wählen sie die passende Fachabteilung aus",
        optionOne: "Versorgungsanbieter wurde über einen anderen Weg gefunden",
        questionText: "Bitte wählen Sie den passenden Grund aus (Pflichtfeld)",
        requestAlreadyAccepted:
          "Diese Anfrage wurde schon vom Anbieter angenommen. \n{select:Klicken Sie hier}, um die Validierung im Messenger anzusehen und die Details auf der Anfrageseite anzuzeigen.",
        subtitle:
          "Bitte geben Sie den Grund so genau wie möglich an, um uns bei der Verbesserung der Plattform zu helfen.",
        title: "Warum beenden Sie diese Suche?",
      },
    },
    takesBloodThinner: "Blutverdünner",
    takesBloodThinnerPlaceholder: "",
    titleBarrierFree: "Die Wohnsituation ist barrierefrei",
    titleLengthRehabAssessment: "Umfang des Patientenprofils",
    tooltipPatientMenu: {
      potentialReceivers: "Potentielle Empfänger:",
      searchRadius: "Umkreissuche:",
      specialisation: "Fachabteilungen:",
      startDate: "Startdatum:",
      treatmentLocation: "Ausgangspunkt der Suche:",
      typeOfTransfer: "Art der Überleitung:",
    },
    transfer: "Überleitung",
    transferChanges:
      "Sie können nachfolgend eine neue Art der Überleitung hinzufügen oder ändern. Dies kann jedoch dazu führen, dass manche Einrichtungen entfernt werden, die die ausgewählte(n) Versorgungsart(en) nicht anbieten.",
    transferDateOlderThanTenDaysWarning:
      "Das geplante Startdatum liegt bereits über 7 Tage in der Vergangenheit. Bitte beenden Sie die Suche, wenn sie nicht mehr benötigt wird.",
    transferDetails: {
      hospitalInformation: "",
      phoneNumber: "",
      specialisationsRequired: "",
      station: "Station",
      title: "Details zur Überleitung",
    },
    transferSearchInProgress:
      "Überleitung - Sie können eine neue Art der Überleitung hinzufügen oder diese bearbeiten",
    transitionOfPausedSearchesWarning:
      'Wir arbeiten daran, Ihr Sucherlebnis zu verbessern. Als Teil der Änderungen wird es ab dem 22. August 2023 permanent nicht mehr möglich sein Suchen zu pausieren. Alle pausierten Suchvorgänge werden ab dem Zeitpunkt automatisch von uns gestoppt. Suchen können jederzeit manuell wieder aufgenommen werden, indem Sie auf "Suche erneut starten" klicken.',
    transport: {
      accompanimentMedicallyNecessary: "Begleitung ist Medizinisch notwendig",
      accompanyingPersonNecessary:
        "Begleitperson für den Transport erforderlich",
      dailyTransportNeeded: "Benötigt einen täglichen Fahrdienst",
      labelNameAndContact: "Name und Kontaktinformationen der Begleitperson",
      organizedBy: {
        label: "z.B. Transport muss organisiert werden, Besonderheiten, etc.",
        title: "Weitere Informationen zum Transport",
      },
      patientNeedsTransportation: "Patient benötigt einen Fahrtdienst",
      stepTitle: "Transport",
      timeHasPassed: "Zeitpunkt liegt in der Vergangenheit",
      titleTransportInformation: "Informationen zum Transport",
      typeOfTransport: "Verkehrsmittel",
      weightLimitAdditionalInfo: (args: { weight: string }) =>
        template(
          "Transport von Patienten limitiert auf max. {{weight}} kg",
          args,
        ),
      weightLimitExceeded: "Max. Limit überschritten",
      weightLimitExceededToast:
        "Patient transports are currently only possible up to 180 kg",
    },
    treatmentLocation: {
      privacyDescription:
        "Aus datenschutzrechtlichen Gründen kann die genaue Adresse nicht angegeben werden. Der Standort des Patienten liegt innerhalb des blauen Radius.",
      privacyDescriptionEncrypted:
        "Der Standort des Patienten liegt innerhalb des blauen Radius.",
      title: "Ausgangspunkt der Suche",
    },
    upgradePlan: "Dieses Modul ist nicht Teil Ihres Vertrags.",
    walkAlone: "Kann selbstständig gehen",
    warningLeavingAssessment:
      "Sind Sie sich sicher, dass Sie diese Seite verlassen möchten?\nIhre Änderungen werden nicht automatisch gespeichert.",
    yearOld: "Jahre alt",
  },
  patientForms: {
    buttonImport: "Daten importieren",
    buttonRefreshData: "Daten aktualisieren",
    buttonSave: "Speichern und PDF erstellen",
    buttonSaveOverwrite: "Speichern und PDF überschreiben",
    connectionFailed: {
      buttonTryAgain: "Erneut versuchen",
      title:
        "Recare kann aktuell keine Verbindung zu Ihrem KIS herstellen. Sie können jedoch manuell Patientendaten eingeben.",
    },
    dateField: {
      title: "Datum der Übermittlung an die Krankenkasse",
      warning: "Bitte geben Sie ein gültiges Datum in der Zukunft ein.",
    },
    drvGeneralForm: {
      description:
        'Um Sie beim Ausfüllen des Formulars zu unterstützen, können Sie mit einem Klick auf „Daten importieren" bereits verfügbare Daten in das Formular übernehmen. Bitte überprüfen Sie diese Daten und ergänzen Sie fehlende Informationen.',
      doctorFirstName: "Vorname",
      insuranceInformation: {
        addressHealthInsurance: "Name und Anschrift der Krankenkasse",
        addressHospital: "Name des Krankenhauses und Anschrift",
        addressPensionInsurance: "Anschrift des Rentenversicherungsträgers",
        informationAhbFacility:
          "Anschrift der AHB-Einrichtung (soweit es das Verfahren mit dem zuständigen Rentenversicherungsträger vorsieht)",
        informationSocialWorker: "Name, Vorname, Telefonnummer, Faxnummer",
        pensionInsuranceNumber: "Rentenversicherungsnummer",
        subtitleHospital: "Krankenhaus",
        subtitleRehabClinic: "AHB-Einrichtung",
        subtitleSocialWorker:
          "Kontaktdaten des Ansprechpartners im Krankenhaus (z.B. Sozialdienst/Casemanagement)",
      },
      sectionEight: {
        contributionsAbroad:
          "9.2 Haben Sie Beiträge zur Sozialversicherung im Ausland gezahlt?",
        contributionsPension:
          "9.1 Haben Sie Beiträge zur Deutschen Rentenversicherung gezahlt?",
        contributionsState: "Staat",
        currentContributionsAbroad:
          "9.3 Zahlen Sie aktuell Beiträge zur Sozialversicherung im Ausland?",
        optionNo: "Nein",
        optionYes: "Ja",
        timeline: "Von - bis",
        title: "9. Beiträge zur Sozialversicherung",
      },
      sectionEleven: {
        attached: "Ist beigefügt",
        authorisedRepresentative: "Bevollmächtigter",
        carer: "Betreuer",
        city: "Wohnort",
        email: "Telefax, E-Mail",
        guardian: "Vormund",
        lackOfCapacity:
          "Nachweis über fehlende Einsichtsfähigkeit / Einwilligungsfähigkeit der Versicherten / des Versicherten",
        legalGuardianType: "In der Eigenschaft als",
        legalRep: "Gesetzlicher Vertreter",
        nameRep: "Name, Vorname / Dienststelle (Aktenzeichen) der Vertretung",
        optionNo: "Nein",
        optionYes: "Ja",
        phone: "Telefonnummer",
        street: "Straße, Hausnummer",
        submittedLater: "Wird nachgereicht",
        subtitle: "12.1 Wird der Antrag durch andere Personen gestellt?",
        title: "12. Antragstellung durch eine andere Person",
        zipCode: "Postleitzahl",
      },
      sectionFive: {
        fulltimeWithNightShifts: "Ganztagsarbeit mit Nachtschicht",
        fulltimeWithoutShifts:
          "Ganztagsarbeit ohne Wechselschicht / Akkord / Nachtschicht",
        fulltimeWithShifts: "Ganztagsarbeit mit Wechselschicht / Akkord",
        homeOffice: "Heimarbeit",
        houseWife: "Ausschließlich Tätigkeit als Hausmann / Hausfrau",
        parttime:
          "Teilzeitarbeit, weniger als die Hälfte der üblichen Arbeitszeit",
        partTimeUsual:
          "Teilzeitarbeit, mindestens die Hälfte der üblichen Arbeitszeit",
        registeredUnemployed: "Arbeitslos gemeldet",
        title:
          "6. Arbeit vor Antragstellung oder vor aktueller Arbeitsunfähigkeit",
        unemployed: "Nicht erwerbstätig",
        workshopDisabilities:
          "Beschäftigung in einer Werkstatt für behinderte Menschen",
      },
      sectionFour: {
        apprentice:
          "Auszubildender (Anlernling, Praktikant, Volontär, Student)",
        civilServant:
          "Beamter / DO-Angestellter, Versorgungsempfänger im Sinne des Beamtenrechtes",
        employee: "Angestellter",
        foreman: "Meister, Polier",
        selfEmployed: "Selbstständiger",
        skilledLabourer: "Facharbeiter",
        skilledWorker:
          "Angelernter Arbeiter  in anerkanntem Anlernberuf (nicht als Facharbeiter tätig)",
        subtitle: "Beschäftigungsstatus",
        title: "5. Derzeitige Stellung im Beruf / Erwerbsleben",
        unemployed:
          "Nicht erwerbstätig (zum Beispiel Hausfrau / Hausmann, Rentner)",
        unskilledWorker: "Ungelernter Arbeiter (nicht als Facharbeiter tätig)",
      },
      sectionNine: {
        jobCenter: "Name des Jobcenters, Aktenzeichen",
        optionNo: "Nein",
        optionYes: "Ja",
        subtitle:
          "Beziehen oder bezogen Sie zuletzt Leistungen des Jobcenters oder haben Sie Leistungen beantragt?",
        title: "10. Leistungen des Jobcenters",
      },
      sectionOne: {
        inpatient: "stationär",
        oncologicalRehab:
          "Anschlussrehabilitation (AHB) als Leistung zur onkologischen Rehabilitation für Angehörige",
        outpatient: "ganztägig ambulant",
        rehabForInsured: "Anschlussrehabilitation (AHB) für Versicherte",
        title: "1. Beantragte Leistung",
      },
      sectionSeven: {
        doctorCity: "Wohnort",
        doctorPhone: "Telefonnummer",
        doctorStreet: "Straße, Hausnummer",
        doctorZipCode: "Postleitzahl",
        title: "8. Behandelnde Ärztin / behandelnder Arzt",
      },
      sectionSix: {
        doctorName: "Name",
        insuranceInformation: "7. Krankenkasse",
        insuranceName: "Name der Krankenkasse",
        optionNo: "Nein",
        optionYes: "Ja",
        patientInsurance: "Versicherung der Patienten",
        privatelyInsured: "Private Krankenversicherung",
        publiclyInsured: "Gesetzliche Krankenkasse",
        sinceDate: "Seit",
        subtitleHospitalStay:
          "Waren Sie bereits unmittelbar vor der Krankenhausbehandlung arbeitsunfähig?",
      },
      sectionTen: {
        accidentOrPerson:
          "11.5 Ist die zum Rehabilitationsantrag führende Minderung oder erhebliche Gefährdung Ihrer Erwerbsfähigkeit ganz oder teilweise Folge eines Unfalls oder durch andere Personen verursacht worden?",
        benefitsType: "Art der Leistung",
        compensatedOn: "Am",
        compensationClaims: "Bei welcher Stelle?",
        damageClaims:
          "Sind Schadensersatzansprüche geltend gemacht worden (zum Beispiel bei privaten Versicherungsgesellschaften)?",
        fileNumber: "Aktenzeichen",
        healthIssues: "Welche Gesundheitsstörungen?",
        namePensionInsurance: "Name des Rentenversicherungsträgers",
        optionNo: "Nein",
        optionYes: "Ja",
        otherBenefits:
          "Beziehen Sie eine Leistung, die regelmäßig bis zum Beginn einer Altersrente gezahlt wird (zum Beispiel betriebliche Versorgungsleistung, Vorruhestandsleistung, Knappschaftsausgleichsleistung)?",
        penisonApplication:
          "Beziehen Sie eine Rente oder haben Sie aktuell einen entsprechenden Antrag gestellt?",
        pensionBenefits:
          "11.2. Rentenleistungen aus der gesetzlichen Rentenversicherung",
        pensionInformation:
          "11.1 Wird eine Beschäftigung ausgeübt, aus der nach beamtenrechtlichen oder entsprechenden Vorschriften Anwartschaft auf Versorgung gewährleistet ist oder besteht durch Bezug einer Versorgung wegen Erreichens einer Altersgrenze Versicherungsfreiheit in der gesetzlichen Rentenversicherung?",
        pensionOldAgeBenefits:
          "11.3 Leistungen bis zum Beginn einer Altersrente",
        recognisedBy: "Von welcher Stelle?",
        submittedApplication:
          "Haben Sie aktuell einen entsprechenden Antrag gestellt?",
        submittedWhere: "Bei welcher Stelle?",
        title: "11. Sonstige Angaben",
        workRelatedAccident:
          "11.4 Sind bei Ihnen Gesundheitsstörungen anerkannt worden als Folge eines Arbeitsunfalls oder Wegeunfalls, als Berufskrankheit, als Folge einer Wehrdienstbeschädigung oder Zivildienstbeschädigung, als Folge einer gesundheitlichen Schädigung im Auslandseinsatz als Soldat oder Zivilist, als Folge einer Gewalttat im Sinne des Gesetzes über die Entschädigung für Opfer von Gewalttaten, als Verfolgtenleiden, als Impfschaden?",
      },
      sectionThirteen: {
        additionalFormat:
          "Aufgrund meiner Behinderung bitte ich darum, mir Dokumente zusätzlich in einer für mich wahrnehmbaren Form zuzusenden und zwar:",
        audio: "als Hörmedium (CD-DAISY Format)",
        brailleFull: "in Braille (Vollschrift)",
        brailleShort: "in Braille (Kurzschrift)",
        cd: 'als CD (Schriftdatei oder Textdatei im ".doc"-Format)',
        confirmation:
          "Ich bitte ausschließlich um Übermittlung er Dokumente inelektronischer Form an mein De-Mail-Postfach. Damit entfällt eine Übersendung der Dokumente in Papierform.",
        description:
          "Ich habe bei einem De-Mail-Anbieter ein De-Mail-Postfach eröffnet",
        germanMailAddress: "De-Mail",
        largePrint: "als Großdruck",
        subtitleEmail: "14.1 Per De-email",
        title: "14. Dokumentenzugang",
        visuallyImpaired: "14.2 Für sehbehinderte Menschen",
      },
      sectionThree: {
        divorced: "Geschieden",
        fieldLabelProfession:
          "Zuletzt ausgeübte Erwerbstätigkeit / ausgeübter Beruf",
        married: "Verheiratet",
        single: "Ledig",
        subtitle:
          "4.1 Familienstand (gilt auch für eingetragene Lebenspartnerschaften im Sinne des Lebenspartnerschaftsgesetzes)",
        subtitleProfession:
          "4.2 Zuletzt ausgeübte Erwerbstätigkeit / zuletzt ausgeübter Beruf (möglichst genaue Bezeichnung)",
        title:
          "4. Angaben zum Familienstand und Beruf der Patientin / des Patienten",
        widowed: "Verwitwet",
      },
      sectionThreeNew: {
        subtitle:
          "Ist die Mitnahme einer pflegebedürftigen Person erforderlich?",
        title:
          "3. Mitnahme einer pflegebedürftigen Person (nur bei stationärer medizinischer Rehabilitation)",
        yesIncludeForm: "Ja",
      },
      sectionTwelev: {
        aids: "Sind Sie auf die Bereitstellung eines Gebärdensprachdolmetschers beziehungsweise andrer geeigneter Kommunikationshilfen angewiesen oder nutzen Sie behinderungsbedingt ein anerkanntes Hilfsmittel (zum Beispiel Rollstuhl, Führhund oder Assistenzhund)?",
        descriptionAids: "Ich bin angewiesen auf",
        optionNo: "Nein",
        optionYes: "Ja",
        title: "13. Kommunikationshilfen und anerkannte Hilfsmittel",
      },
      sectionTwo: {
        city: "Wohnort",
        dateOfBirth: "Geburtsdatum",
        diverse: "Divers",
        female: "Weiblich",
        firstName: "Vorname",
        formerNames: "Frühere Namen",
        gender: "Geschlecht",
        lastName: "Nachname",
        maidenName: "Geburtsname",
        male: "Männlich",
        nationality: "Nationalität",
        phone: "Telefonnummer",
        street: "Straße, Hausnummer",
        title: "2. Angaben zur Patientin / zum Patienten",
        unknown: "Ohne Eintrag",
        zipCode: "Postleitzahl",
      },
      title: "DRV - Antrag G0250",
    },
    drvMedicalForm: {
      addressHospital: "Name des Krankenhauses und Anschrift",
      description:
        'Um Sie beim Ausfüllen des Formulars zu unterstützen, können Sie mit einem Klick auf „Daten importieren" bereits verfügbare Daten in das Formular übernehmen. Bitte überprüfen Sie diese Daten und ergänzen Sie fehlende Informationen.',
      descriptionHistory:
        "Unter anderem Histologie bei Malignom, Ejektionsfraktion bei kardiologischer Erkrankung, Lungenfunktionsparameter bei pneumologischer Erkrankungen oder Ähnliches",
      sectionFive: {
        accompanyingPerson: "Begleitperson",
        carelevelDescription: "Höhe des Pflegegrades",
        carelevelLabel: "Ist ein Pflegegrad von der Pflegekasse anerkannt?",
        catheters: "Liegen Katheter / Drainagen / Sonden?",
        cathetersDescription: "Welche?",
        eat: "ohne fremde Hilfe essen?",
        germs: "Besiedelung mit multiresistenten Keimen?",
        germsDescription: "Welche Keime?",
        incontinence: "Besteht Stuhlinkontinenz?",
        lyingDown: "Krankentransport",
        medicalReason:
          "Eine medizinische Begründung ist erforderlich bei (nicht möglich bei Durchführung einer ambulanten AHB):",
        move: "sich alleine auf der Station bewegen?",
        optionNo: "Nein",
        optionYes: "Ja",
        orientation:
          "Ist die Patientin / der Patient situativ / örtlich / zeitlich orientiert?",
        orientationDescription:
          "Genauere Angaben (neurologisches Fähigkeitsprofil, Barthel-Index)",
        sitting: "Krankentransport sitzend",
        taxi: "Taxi",
        title: "5. Kann die Patientin / der Patient",
        travel: "Für die Anreise ist möglich:",
        travelCar: "PKW",
        travelHelicopter: "Helikopter",
        travelPublic: "ÖPNV",
        urinalIncontinence: "Besteht Harninkontinenz?",
        wash: "sich alleine waschen und anziehen?",
        wheelchair: "Rollstuhlfahrer?",
        wound: "Wunde reizlos / geschlossen?",
      },
      sectionFour: {
        height: "Größe in cm",
        title: "4. Klinische und medizinisch-technische Befunde",
        weight: "Gewicht in kg",
      },
      sectionOne: {
        descriptionDiagnosis:
          "Diagnose (bei Malignom einschließlich TNM-Klassifizierung)",
        diagnosisIcd: "Diagnose nach ICD-10-GM",
        drgGroup: "DRG-Fallgruppe",
        title: "1. Diagnose für die Anschlussrehabilitation (AHB)",
      },
      sectionSeven: {
        remarksDescription:
          "zum Beispiel: Ergänzende Hinweise zu Ziffer 5, geringe Kenntnisse der Deutschen Sprache, Vorliegen einer stoffgebundenen Abhängigkeitserkrankung, Stand der Protheseanpassung",
        title: "7. Bemerkungen",
      },
      sectionSix: {
        accountHolder: "Kontoinhaberin / Kontoinhaber",
        address: "Straße, Hausnummer, Postleitzahl, Ort",
        addressPensionInsurance: "Anschrift der Deutschen Rentenversicherung",
        bankName: "Geldinstitut (Name, Ort)",
        iban: "IBAN",
        ikNumber: "Institutionskennzeichen",
        subtitleBankAccountInformation:
          "Geschäftskonto der Zahlungsempfängerin / des Zahlungsempfängers:",
        title: "6. Honorarabrechnung zum ärztlichen Befundbericht",
      },
      sectionThree: {
        descriptionIllnessess:
          "Es liegen weitere behandlungsrelevante Krankheiten, Funktionseinschränkungen, Schweregrad vor",
        diagnosisIcd: "Diagnose nach ICD-10-GM",
        nyhaStages:
          "zum Beispiel NYHA-Stadien, Paresen, kognitive / mnestische Störungen",
        optionNo: "Nein",
        optionYes: "Ja",
        title:
          "3. Weitere behandlungsrelevante Krankheiten, Funktionseinschränkungen, Schweregrad",
      },
      sectionTwo: {
        descriptionComplications:
          "Krankheitsverlauf, Komplikationen, Risikofaktoren",
        descriptionMeasures: "Beschreibung",
        descriptionTherapies:
          "Derzeitige Therapien, Ergebnisse (bei onkologischen Erkrankungen Remissionsstatus)",
        endsOnDate: "Beendet am",
        fromDate: "Von",
        optionNo: "Nein",
        optionYes: "Ja",
        otherMeasures:
          "Weitere erforderliche therapeutische oder diagnostische Maßnahmen",
        title: "2. Krankheitsverlauf, Komplikationen, Risikofaktoren",
        untilDate: "Bis",
      },
      sectionZero: {
        accidentWork: "Arbeitsunfall, Berufskrankheit",
        acuteEvent: "Sonstiges Akut-Ereignis",
        addressAhb:
          "Anschrift der AHB-Einrichtung (soweit es das Verfahren mit dem zuständigen Rentenversicherungsträger vorsieht)",
        addressPatient: "Derzeitige Adresse (Straße, Hausnummer)",
        ahbEventCheckboxGroupLabel: "AHB-begründendes Ereignis",
        ahbEventDate: "AHB-begründendes Ereignis Datum",
        ahbStartDate: "Vorgesehenes Verlegungsdatum in die AHB",
        antineoplasticTherapy: "Letzte antineoplastische Therapie",
        city: "Wohnort",
        contactPersonInformation: "Name, Vorname, Telefonnummer, Faxnummer",
        dateBirthPatient: "Geburtsdatum",
        directDischarge:
          "Direkteinweisung (nur für Versicherte der Deutschen Rentenversicherung Bund / Deutschen Rentenversicherung Mitteldeutschland)",
        dischargeDate: "Voraussichtlicher Entlassungstag",
        instructionAfterExamination: "Einweisung nach Prüfung bei RV-Träger",
        insuranceNumber: "Versicherungsnummer",
        intakeDate: "Aufnahmetag im Krankenhaus",
        mobile: "Ganztägig ambulant",
        notApplicable: "Entfällt",
        patientName: "Name, Vorname",
        radiation: "Letzte Bestrahlung",
        static: "Stationär",
        subtitleAhb: "AHB-Einrichtung",
        subtitleContactPerson:
          "Ansprechpartnerin / Ansprechpartner mit Telefonnummer und Faxnummer",
        subtitleHospital: "Krankenhaus",
        subtitleHospitalAddress: "Anschrift des Krankenhauses",
        subtitlePatientInformation: "Personalien der Patientin / des Patienten",
        subtitleSuggestedRehab: "Vorgeschlagene Rehabilitationsform",
        surgery: "Operation",
        thirdPartyDamage: "Mögliche Schädigung durch Dritte",
        typeOfProcedure: "Art des Verfahrens",
        zipCodePatient: "Postleitzahl",
      },
      title: "DRV - Ärztlicher Befundbericht G0260/G0600",
    },
    editSavedDocument: "Bearbeiten",
    emptyPage:
      "Bitte wählen Sie das gewünschte Dokument aus dem Menü auf der linken Seite, um zu beginnen.",
    emptyPageTitle: "Es gibt keine gespeicherten Dokumente.",
    fileDeletion: {
      cancelButton: "Abbrechen",
      confirmButton: "Jetzt löschen",
      text: "Diese Aktion kann nicht rückgängig gemacht werden. Bei Bedarf können Sie das PDF erneut erstellen.",
      title:
        "Sind Sie sicher, dass Sie alle Inhalte und das PDF löschen möchten?",
    },
    generalForm: {
      buttonImportData: "Daten importieren",
      buttonRefreshData: "Daten aktualisieren",
      buttonSaveAndCreate: "Speichern und PDF erstellen",
      buttonSaveAndOverwrite: "Speichern und PDF überschreiben",
      description:
        'Um Sie beim Ausfüllen des Formulars zu unterstützen, können Sie mit einem Klick auf „Daten importieren" bereits verfügbare Daten in das Formular übernehmen. Bitte überprüfen Sie diese Daten und ergänzen Sie fehlende Informationen.',
      insuredPerson: {
        city: "Postleitzahl, Wohnort",
        dateOfBirth: "Geburtsdatum",
        healthInsurance: "Krankenkasse",
        insuranceNumber: "Versichertennummer",
        name: "Versicherte/Versicherter Name, Vorname",
        phone: "Telefonnummer",
        street: "Straße und Hausnummer",
      },
      optionNo: "Nein",
      optionYes: "Ja",
      savedFile: {
        description:
          "Dieses Dokument wurde automatisch mit Daten aus der Recare-Plattform erstellt und möglicherweise durch Mitarbeitende der Klinik ergänzt bzw. angepasst. Recare übernimmt daher keine Haftung für Vollständigkeit und Richtigkeit der Daten.",
        print: "Drucken",
        savedAt: (args: { savedAt: string }) =>
          template("Gespeichert am {{savedAt}}", args),
        title: "Erstellte PDF Dokumentation",
      },
      sectionI: {
        accessibilityAtHomeSituation:
          "Leben Sie allein oder mit jemandem zu Hause?",
        accessibilityGuardianType:
          "Welche dieser Rollen trifft auf die Person zu, die Sie vertritt?",
        currentHomeCareEnsured:
          "Ist die häusliche Versorgung aktuell sichergestellt?",
        gpCity: "Wohnort",
        gpName: "Name, Vorname",
        gpPhoneNumber: "Telefonnummer",
        gpStreet: "Straße, Hausnummer",
        gpZipCode: "Postleitzahl",
        labelSpecialRequirements:
          "z.B. Nennung einer gewünschten Rehabilitationseinrichtung mit Begründung",
        livingSituationElevator: "Ist ein Aufzug vorhanden? ",
        livingSituationFloor: "In welchem Stockwerk wohnen Sie?",
        livingSituationSpecifications:
          "Besonderheiten zu Ihrer häuslichen Situation (z. B. Treppen im Innen- und Außenbereich): ",
        optionAssistedLiving: "im Betreuten Wohnen ",
        optionAtHome: "Zu Hause und dort",
        optionAtHomeAlone: "allein",
        optionAtHomeWithRelatives: "mit Angehörigen/mit sonstigen Personen",
        optionCareHome: "in einer Alten-/Pflegeeinrichtung",
        optionCarer: "Betreuer/in",
        optionHealthCareRep: "Vorsorgebevollmächtige/r",
        optionLegalRep: "Gesetzliche/r Vertreter/in",
        placeholderLabelInsuranceName: "Träger der Rentenversicherung",
        questionGp:
          "5. Behandelnde Hausärztin / behandelnder Hausarzt (soweit bekannt)",
        questionGuardian:
          "6. Fragen zu einer Betreuung, Vorsorgevollmacht oder gesetzlichen Vertretung",
        questionHomeCare:
          "4. Wie ist Ihre derzeitige soziale und häusliche Versorgungssituation?",
        questionLivingSituation: "3. Fragen zur häuslichen Situation",
        questionNeedForCare:
          "Wurden im Rahmen dieses Krankenhausaufenthaltes Leistungen der Pflegeversicherung beantragt?",
        questionPensionApplication:
          "Wurde in den letzten 6 Monaten ein Rentenantrag gestellt?",
        questionSpecialRequirements:
          "7. Spezielle Anforderungen und individuelle Wünsche an die Rehabilitationseinrichtung",
        subtitleAddressGp: "Adresse (soweit bekannt)",
        subtitleHomeCare: "Leben Sie",
        subtitlePensionReducedEarning:
          "Wurde in den letzten 6 Monaten ein Rentenantrag zur Rente wegen Erwerbsminderung gestellt?",
        subtitleRetirementPension:
          "Wurde in den letzten 6 Monaten ein Rentenantrag zur Altersrente gestellt?",
        title: "I. Angaben der Versicherten/des Versicherten",
        titleNeedForCare: "1. Angaben zur Pflegebedürftigkeit",
        titlePensionApplication: "2. Fragen zur Rentenantragstellung",
      },
      sectionTwo: {
        communicationNo: "Yes",
        communicationNoGerman:
          "In welcher Sprache ist eine Kommunikation möglich?",
        communicationWithPatient:
          "2. Ist eine Kommunikation mit der Patientin/dem Patienten in deutscher Sprache möglich?",
        communicationYes: "Ja",
        costCoverage:
          "3. Wurde vorbehaltlich einer Kostenübernahme bereits eine Rehabilitationseinrichtung angefragt?",
        costCoverageNo: "Nein",
        costCoverageYes: "Ja",
        detailsSocialWorker:
          "1. Kontaktdaten des Ansprechpartners im Krankenhaus (z. B. Sozialdienst / Case Management):",
        hospitalAddressCity: "Postleitzahl, Wohnort",
        hospitalAddressStreet: "Straße, Hausnummer",
        hospitalName: "Krankenhaus",
        patientId: "Fallnummer / Patienten-ID",
        rehabDetails: "Name, Anschrift und Telefonnummer",
        rehabIntakeDate: "Frühestmögliches Aufnahmedatum",
        socialWorkerFax: "Faxnummer",
        socialWorkerName: "Name, Vorname",
        socialWorkerPhone: "Telefonnummer",
        title:
          "II. Angaben des Krankenhauses (z. B. durch den Sozialdienst / Case Management auszufüllen)",
      },
      title: "Einheitlicher Antrag - Antrag auf Anschlussrehabilitation",
    },
    goToForm: "Formular ausfüllen",
    medicalForm: {
      description:
        'Um Sie beim Ausfüllen des Formulars zu unterstützen, können Sie mit einem Klick auf „Daten importieren" bereits verfügbare Daten in das Formular übernehmen. Bitte überprüfen Sie diese Daten und ergänzen Sie fehlende Informationen.',
      sectionFive: {
        addictiveDisorders: "Suchterkrankungen",
        amputation:
          "Major Amputation an der unteren Extremität (proximal des Fußes)",
        backPain:
          "spezifische Rückenschmerzen mit hohem Chronifizierungsrisiko",
        bloodDiseases: "Krankheiten des Blutes und der Blutbildungsorgane",
        bloodVessels: "Krankheiten der Gefäße",
        bypass:
          "Koronar-Arterielle Bypass-Operation auch in Kombination mit einem Herzklappenersatz",
        endocrineDiseases: "Endokrine Krankheiten",
        endoprostheticTreatment:
          "endoprothetische Versorgung von Knie- oder Hüftgelenk",
        gastroenterologicalDiseases: "Gastroenterologische Erkrankungen",
        geriatricRehab:
          "geriatrische Anschlussrehabilitation bei Patientinnen und Patienten mit einer geriatrietypischen Multimorbidität und einem erhöhten Lebensalter (70 Jahre oder älter)",
        geriatrics: "Geriatrie",
        gynaecologicalDiseases: "Gynäkologische Erkrankungen",
        heart: "Krankheiten des Herzens und des Kreislaufs",
        heartFailure: "Herzinsuffizienzen ab NYHA II",
        inflammatoryDiseases: "Entzündlich-rheumatische Erkrankungen",
        mentalIllnesses: "Psychische Erkrankungen",
        mobile: "ambulant",
        mobileOutpatient:
          "ambulant mobil, weil (z. B. schwere Orientierungsstörung, Wanderungstendenz)",
        musculoskeletalSystem:
          "Konservativ und operativ behandelte Erkrankungen GHV Bewegungsapparates",
        myocardialInfarction: "Myokardinfarkt",
        nephrologicalDiseases: "Nephrologische Erkrankungen",
        neurologicalDisease: "Neurologische Erkrankungen",
        neurologicalDiseases:
          "Neurologische Krankheiten einschließlich Operationen an Gehirn, Rückenmark und an peripheren Nerven, wenn sich eindeutig eine Phasenzuordnung zur Phase D (gemäß BAR-Phasenmodell) aus den Feststellungen im ärztlichen Befundbericht sowie den Antragsunterlagen ableiten lässt",
        oncologicalDisease: "Onkologische Krankheiten",
        oncologicalDiseases: "Onkologische Erkrankungen",
        others: "Sonstige",
        otherTextfieldLabel: "Bitte geben Sie weitere Informationen an",
        psychosomaticDisorders:
          "Psychosomatische psychovegetative Erkrankungen",
        rehabDirectiveTypeLabel: "Bitte spezifizieren Sie den Fall näher",
        rehabFocus: "1. Schwerpunkt der Rehabilitationsmaßnahme",
        rehabRl:
          "3. Auf Grundlage der vorgenommenen Bewertung und der o.g. Angaben ist nach Einschätzung der Krankenhausärztin/ des Krankenhausarztes ein Fall nach Paragraph 16 Reha-RL gegeben",
        respiratoryDisease:
          "Krankheiten der Atmungsorgane einschließlich Operationen",
        respiratoryDiseases: "Krankheiten der Atmungsorgane",
        skinDiseases: "Hauterkrankungen",
        spinalCanal: "Spinalkanalstenose/Enger Spinalkanal/Rezessusstenose",
        static:
          "stationär, weil (z. B. Immobilität, Defizite bei der Selbstversorgung)",
        title: "V. Zusammenfassende Bewertung und Einschätzung",
        transplants: "Organ-Transplantationen und –Unterstützungssysteme",
        typeOfRehab: "2. Welche Rehabilitationsart wird empfohlen?",
        urologicalDiseases: "Urologische Erkrankungen",
      },
      sectionFour: {
        rehabGoals:
          "Welches sind die realistischen, alltagsrelevanten Rehabilitationsziele unter Berücksichtigung des bisherigen Verlaufs und der individuell vorhandenen bzw. förderungsfähigen Ressourcen?",
        title: "IV. Rehabilitationsziele/- prognose",
      },
      sectionOne: {
        accessibilityGender:
          "Bitte wählen Sie das Geschlecht der versicherten Person",
        admissionRehab:
          "4. Die Aufnahme in die Anschlussrehabilitation soll erfolgen",
        dischargeDate: "Voraussichtliches Entlassdatum",
        earliestDatePossible: "Frühestens am",
        earlyRehabMeasures:
          "3. Werden derzeit neurologische, geriatrische oder fachübergreifende Frührehabilitationsmaßnahmen durchgeführt?",
        earlyRehabMeasuresNo: "Nein",
        earlyRehabMeasuresYes: "Ja",
        firstName: "Vorname",
        genderDiverse: "Divers",
        genderFemale: "Weiblich",
        genderMale: "Männlich",
        hospitalStayInformation:
          "2. Die Krankenhausbehandlung ggf. einschließlich Frühmobilisation und Wundbehandlung",
        informationInsuredPerson: "1. Personalien der/des Versicherten",
        intakeDate: "Aufnahmedatum",
        lastName: "Nachname",
        medicalReasons: "Medizinische Begründung",
        optionDirect: "als Direktverlegung",
        outsideTwoWeeks: "außerhalb der gesetzlichen Frist von 14 Tagen",
        title: "I. Allgemeine Angaben",
        withinTwoWeeks: "innerhalb der gesetzlichen Frist von 14 Tagen",
      },
      sectionSix: {
        accompanyingPerson:
          "Ist für die Anreise eine Begleitperson erforderlich?",
        ambulance:
          "KTW, da medizinisch-sachliche Betreuung und/oder Einrichtung notwendig ist wegen",
        ambulanceDescriptionLabel: "Bitte geben Sie den Grund an",
        car: "PKW",
        catheter: "Katheter",
        chemotherapy: "Chemotherapie",
        contactInformationDoctor:
          "3. Kontaktdaten der behandelnden Krankenhausärztin/des behandelnden Krankenhausarztes",
        decubitus: "Dekubitus, Stadium",
        dialysis: "Dialyse, welches Verfahren",
        immunosuppressants: "Immunsuppressiva",
        impairment: "gravierende Hör-, Seh- und Sprechbehinderung",
        isolation: "Isolationspflicht",
        lyingDown: "Liegendtransport",
        nameDoctor: "Name, Vorname",
        obesity: "Ausgeprägte Adipositas",
        optionPublicTransport: "öffentliches Verkehrsmittel",
        other: "sonstiges",
        overlengthBed: "Bettüberlänge",
        peg: "PEG",
        phoneDoctor: "Telefon-Nr. für Rückfragen",
        prosthesis: "OS/US-Prothese",
        relatives:
          "Mitaufnahme pflegebedürftiger Angehörige; Pflegegrad (soweit bekannt)",
        specialRequirements:
          "1. Werden besondere Anforderungen an die Rehabilitationseinrichtung gestellt?",
        specialRequirementsSelectOptionsLabel:
          "Bitte wählen Sie die besonderen Anforderungen an die Rehabilitationseinrichtung aus",
        stretcher: "Tragestuhltransport",
        taxi: "Taxi/Mietwagen",
        taxiOptionLabel: "Bitte spezifizieren Sie die Option näher",
        title:
          "VI. Weitere relevante Informationen für die Durchführung der Rehabilitation",
        tracheostoma: "Tracheostoma",
        transportation:
          "2. Welches Verkehrsmittel ist für die Anreise voraussichtlich medizinisch notwendig?",
        ventilation: "Beatmung",
        weaning: "Weaning",
        weightBed: "Schwerlastbett bis zu",
        wheelChair: "Rollstuhltransport",
      },
      sectionThree: {
        behaviouralDisorder:
          "beaufsichtigungspflichtige Verhaltensstörung (mit Eigen- und/oder Fremdgefährdung)",
        communicationDisorder: "schwere Verständigungsstörung",
        earlyRehabIndex: "3. Kriterien aus dem Frühreha-Index (FR-Index)",
        fully: "voll belastbar",
        intensiveCareMonitoring:
          "intensivmedizinisch überwachungspflichtiger Zustand",
        intermittentVentilation: "intermittierende Beatmung",
        musculoskeletalSystem:
          "2. Weitere Informationen zur Belastbarkeit bei Operationen am Bewegungsapparat",
        orientationDisorder:
          "beaufsichtigungspflichtige Orientierungsstörung (Verwirrtheit)",
        partially: "teilbelastbar mit bis zu",
        prosthesis: "Anpassung einer Interimsprothese ist erfolgt",
        resilience:
          "1. Besteht eine ausreichende körperliche und psychisch/kognitive Belastbarkeit, um an der Therapie teilzunehmen?",
        specifics: "Besonderheiten",
        stable: "übungsstabil",
        swallowingDisorder: "beaufsichtigungspflichtige Schluckstörung",
        title: "III. Rehabilitationsfähigkeit",
        tracheostoma: "absaugpflichtiges Tracheostoma",
      },
      sectionTwo: {
        cause: "Ursache",
        complications: "3. Komplikationen im aktuellen Behandlungsverlauf",
        complicationsDescriptionLabel: "Mehr Informationen hier eingeben",
        diagnosisDescription: "Funktionsdiagnosen",
        footnoteOne:
          "Die hier angegebenen Diagnoseschlüssel umfassen die Diagnosen zum Zeitpunkt der Antragstellung und können von den Entlassdiagnosen abweichen",
        functionDiagnoses:
          "1. Antragsrelevante Funktionsdiagnosen in der Reihenfolge ihrer Bedeutung",
        germs: "Besiedlung mit multiresistenten Keimen?",
        germsUnknown: "Nicht bekannt",
        icdCode: "ICD-10-GM1",
        longTermImpairments:
          "4. Drohen oder bestehen ohne die Durchführung der beantragten Anschlussrehabilitation\nlängerfristige (>6 Monate) alltagsrelevante Beeinträchtigungen? (Beurteilung zum Zeitpunkt der Entlassung)",
        optionCardiovascular: "kardiovaskulär (z.B. Herzinfakt, Embolie)",
        optionFive: " 4 = Folgen von Kriegs-, Zivil- oder Wehrdienst",
        optionFour:
          "3 = Schädigungsfolge durch Einwirken Dritter z.B. Unfallfolgen",
        optionOne: "0 = 1-5 trifft nicht zu",
        optionOtherComplication: "sonstige (z.B. Pneumonie, Dekubitus)",
        optionSix: "5 = Meldepflichtige Erkrankung (z. B. IfSG)",
        optionThree: "2 = Berufskrankheit",
        optionTwo: "1 = Arbeitsunfall einschl. Wegeunfall",
        otherTreatments: "Andere Behandlungen",
        performedTreatments: "2. Durchgeführte Behandlungen",
        relatedToDiagnosis:
          "im direkten Zusammenhang mit der AR führenden Diagnose (z.B. postop, Luxation der Endoprothese)",
        singer: "Bitte füllen Sie das Singer Patientenprofil aus:",
        surgeries: "Operation",
        surgeryDate: "Operation am",
        surgeryDescription: "Welche Operationen?",
        surgeryOps: "OPS",
        title: "II. Rehabilitationsbedürftigkeit",
        treatmentsDescription: "Welche Behandlungen?",
        typeOfGerms: "Welche Keime?",
        wound: "Wunde ist geschlossen und reizlos?",
      },
      title: "Einheitlicher Antrag - Ärztlicher Befundbericht",
    },
    menu: {
      buttonDelete: "Löschen",
      buttonEdit: "Bearbeiten",
      buttonPrint: "Drucken",
      drvGeneralForm: "DRV - Antrag G0250",
      drvMedicalForm: "DRV - Ärztlicher Befundbericht G0260/G0600",
      fillOutForm: "Formular ausfüllen",
      maximumFormHint:
        "Sie können nur eine PDF-Datei erstellen. Wenn Sie das Formular wechseln möchten, müssen Sie das bereits gespeicherte Formular löschen.",
      rehabGeneralForm:
        "Einheitlicher Antrag - Antrag auf Anschlussrehabilitation",
      rehabMedicalForm: "Einheitlicher Antrag - Ärztlicher Befundbericht",
      saveDocuments: "Erstellte Dokumentationen",
      subtitleEligibility: "Kostenübernahme",
      subtitleGeneralForm: "EINHEITLICHER ANTRAG",
      subtitleMedicalForm: "ÄRZTLICHER BEFUNDBERICHT",
      subtitleTransitionalCare: "Übergangspflege",
      title: "Antrag Auswahl",
      transitionalCare: "Übergangspflege",
    },
    savedDocumentsDescription:
      "Diese Dokumente wurden automatisch mit Daten aus der Recare-Plattform erstellt und möglicherweise durch Mitarbeitende der Klinik ergänzt bzw. angepasst. Recare übernimmt daher keine Haftung für Vollständigkeit und Richtigkeit der Daten.",
    savedDocumentsTitle: "Erstellte PDF Dokumentationen",
    tabTitle: "Übergangspflege",
    tabWithTransitionalCare: "mit ÜBERGANGSPFLEGE",
    transitionalCareForm: {
      buttonPdf: "PDF erstellen",
      contactInformation: {
        confirmedWithLegalRep:
          "Die gesetzliche Vertretungsperson / Betreuungsperson hat der Verarbeitung / Weitergabe ihrer Daten zugestimmt.",
        footnoteInsuranceNumber:
          "Übergangsweise bei Privatversicherten die Versicherungsnummer (soweit ermittelbar)",
        hospitalCity: "Ort",
        hospitalIkNumber: "Institutionskennzeichen (IK)",
        hospitalName: "Name",
        hospitalStreet: "Straße",
        hospitalStreetNumber: "Hausnummer",
        hospitalZipCode: "Postleitzahl",
        legalRepCity: "Ort",
        legalRepDataProcessing:
          "Der Betreuer hat der Verarbeitung / Weitergabe seiner Daten zugestimmt.",
        legalRepFirstName: "Vorname",
        legalRepName: "Nachname",
        legalRepStreet: "Straße",
        legalRepStreetNumber: "Hausnummer",
        legalRepZipCode: "Postleitzahl",
        patientBirthDate: "Geburtsdatum",
        patientCity: "Ort",
        patientFirstName: "Vorname",
        patientInsuranceNumber: "Krankenversichertennummer",
        patientName: "Nachname",
        patientStreet: "Straße",
        patientStreetNumber: "Hausnummer",
        patientZipCode: "Postleitzahl",
        socialWorkerEmail: "E-Mail-Adresse",
        socialWorkerFax: "Faxnummer",
        socialWorkerFirstName: "Vorname",
        socialWorkerName: "Nachname",
        socialWorkerPhone: "Telefonnummer",
        titleHospital: "Kontaktdaten Krankenhaus",
        titleLegalRep:
          "Kontaktdaten der gesetzlichen Vertretungsperson / Betreuungsperson (sofern vorhanden)",
        titlePatient: "Kontaktdaten Patient",
        titleSocialWorker: "Kontaktdaten Ansprechperson / Sozialdienst",
      },
      createNewBanner:
        'Um Sie beim Ausfüllen des Formulars zu unterstützen, können Sie mit einem Klick auf "Daten importieren" bereits verfügbare Daten in das Formula übernehmen. Bitte überprüfen Sie diese Daten auf Korrektheit und ergänzen Sie die noch nicht befüllten Formularfelder so weit wie möglich.',
      deleteForm: "Alle Inhalte und PDF löschen",
      descriptionEmptyForm:
        "Starten Sie bitte zuerst die Suche nach einem passenden Nachversorger, um den Übergangspflege-Prozess auf der Recare-Plattform zu starten.",
      descriptionExistingForms:
        "Das Dokument wurde automatisch mit Daten aus der Recare-Plattform erstellt und möglicherweise durch Mitarbeitende der Klinik ergänzt bzw. angepasst. Recare übernimmt daher keine Haftung für Vollständigkeit und Richtigkeit der Daten.",
      downloadSection: {
        bodyText:
          "Das Dokument wurde automatisch mit Daten aus der Recare-Plattform erstellt und möglicherweise durch Mitarbeitende der Klinik ergänzt bzw. angepasst. Recare übernimmt daher keine Haftung für Vollständigkeit und Richtigkeit der Daten.",
        hisButton: "Zum KIS senden",
        hisError:
          "Das Formular konnte nicht an das KIS gesendet werden. Bitte versuchen Sie es später noch einmal.",
        hisSuccess: "Erfolgreich an das KIS gesendet",
        printButton: "Drucken",
        savedAt: (args: { savedAt: string }) =>
          template("Gespeichert am {{savedAt}}", args),
        title: "Erstellte PDF Dokumentation der Übergangspflege",
      },
      encryptionInformation:
        "Dieses Dokument sowie das daraus resultierende PDF sind Ende-zu-Ende verschlüsselt.",
      errorDateInTheFuture: "Bitte wählen Sie ein Datum in der Zukunft.",
      errorDateInThePast: "Bitte wählen Sie ein Datum in der Vergangenheit.",
      errorDischargeBeforeIntake:
        "Entlassungstag darf nicht vor dem Aufnahmetag liegen.",
      explanationTransitionalCare:
        "Hinweis: Zur Abrechnung der Übergangspflege im Krankenhaus nach § 39e SGB V als Leistung bei der gesetzlichen Krankenversicherung ist die Dokumentation der Anfrage bei mindestens 20 Nachversorgern erforderlich. Dadurch belegen Sie, dass insbesondere die erforderliche nachstationäre Versorgung nicht oder nicht zum geplanten Entlasstermin zur Verfügung steht. Alle erforderlichen Informationen (Name, Datum und Ergebnis der Anfrage etc.) dokumentiert die Recare-Plattform automatisch für Sie.",
      hospitalStay: {
        departmentCode: "Fachabteilungsschlüssel",
        endDateTransitionalCare: "Entlassungstag",
        footnoteOne:
          "Standortkennzeichen nach § 293 Absatz 6 SGB V des Standortes, an dem die Übergangspflege erbracht wurde",
        footnoteTwo:
          "Fachabteilungsschlüssel gemäß § 5 Absatz 3 der Vereinbarung",
        id: "Krankenhausinternes Kennzeichen der/des Versicherten",
        intakeDate: "Aufnahmetag",
        locationId: "Standortkennzeichen",
        releaseDate: "Entlassungstag",
        startDateTransitionalCare: "Aufnahmetag",
        titlePriorStay: "Vorangegangene Krankenhausbehandlung",
        titleTransitionalCare: "Übergangspflege im Krankenhaus",
      },
      mandatoryFieldInformation: "* Markiert ein Pflichtfeld",
      notPurchased: {
        advantageFive:
          "Einbettung: Verlassen Sie sich auf die intuitive Integration in Ihren gewohnten Bearbeitungsprozess auf der Recare-Plattform.",
        advantageFour:
          "Datensicherheit: Die Ende-zu-Ende-Verschlüsselung aller Formularfelder sowie des generierten PDFs sorgt für die Sicherheit der Patientendaten.",
        advantageOne:
          "Automatisierung: Ihre Dokumentation erfolgt nach Krankenkassen-Vorgaben inkl. Nachweise aller Kontaktversuche an die 20 angefragten Nachversorger.",
        advantageThree:
          "Flexibilität: Ergänzen Sie die Dokumentation manuell, sollten parallel zur digitalen Dokumentation analoge Prozesse über Fax/Telefon nicht vermieden werden können.",
        advantageTwo:
          "KIS-Schnittstelle: Ein einfacher Datenimport und schnelle Aktualisierungen per Klick erhöhen Ihre Datenqualität.",
        cta: "Jetzt Gesprächstermin vereinbaren",
        paragraphOne:
          "Sehen Sie die Übergangspflege (ÜGP) nach § 39e SGB V als ein relevantes Thema für Ihre Häuser an, schrecken aber vor dem manuellen Aufwand und der Komplexität der Dokumentationsanforderungen zurück? Dann haben wir mit unserem neuen Add-on Übergangspflege die passende Lösung für Sie.",
        paragraphTwo:
          "Sie möchten mehr über Übergangspflege erfahren oder das Add-on direkt zu Ihrem Recare-Basic- oder Recare-ONE-Paket hinzubuchen? Dann vereinbaren Sie direkt einen Gesprächstermin.",
        subtitleAdvantages: "Ihre Vorteile in der Übersicht",
        title: "Profitieren Sie von unserem neuen Add-on",
      },
      numberOfProviders:
        "Sollten weniger als 20 Nachversorger vorhanden sein, zählt ersatzweise die dokumentierte Anfrage aller vorhandenen Nachversorger.",
      pdfCreated: "Ihr erstelltes PDF befindet sich oben auf der Seite",
      savedDate: (args: { date: string }) =>
        template("Gespeichert am {{date}}", args),
      sectionA: {
        dateLabel: "(in der Regel durch Einbindung des Sozialdienstes) am",
        title: "a) Beginn der Organisation der Anschlussversorgung",
      },
      sectionB: {
        dateFieldLabel: "Datum der Abstimmung",
        dropdownNumberOfProviders:
          "Anzahl der in Frage kommenden Anschlussversorger",
        textFieldExplanationLabel:
          "Beschreibung, warum keine Abstimmung erfolgt ist",
        textFieldLabel:
          "Beschreibung der geeigneten Anschlussversorgung (Anforderungen an die Anschlussversorger aufgrund eines komplexen oder besonderen Versorgungbedarfes sowie der gewünschte Ort und Umkreis der Versorgung)",
        title: "b) Abstimmung der geeigneten Anschlussversorgung",
        toggleConfirmedWithPatient:
          "Die geeignete Anschlussversorgung wurde mit der Patientin oder dem Patient und ggf. den Angehörigen oder dem gesetzlichen Vertreter bzw. Betreuer abgestimmt.",
        toggleNumberOfProviders:
          "Es kamen mehr als 20 Anschlussversorger als geeignete in Frage",
      },
      sectionC: {
        afterCareRequired:
          "Nachsorgebedarf besteht für Pflegeleistungen nach SGB XI (einschließlich Kurzzeitpflege nach SGB XI)",
        applicationDate: "Datum der Antragstellung",
        applicationSubmitted:
          "Der Antrag/die Verordnung für diese Anschlussversorgung wurde gestellt/ausgestellt",
        careLevel: "Ein Pflegegrad 2 oder höher liegt vor",
        careLevelApplied:
          "Ein Antrag auf Pflegegrad von 2 oder höher wurde gestellt",
        careLevelAppliedDate: "Datum der Antragstellung",
        medicalRehab: "der Medizinischen Rehabilitation (§ 40 SGB V)",
        mobileCare: "der Häuslichen Krankenpflege (§ 37 SGB V)",
        shorttermCare: "der Kurzzeitpflege (§ 39c SGB V)",
        textFieldDescription:
          "Beschreibung des Nachsorgebedarfs und Begründung, warum kein Antrag/Verordnung gestellt/ausgestellt wurde",
        textFieldLabel:
          "Beschreibung des Nachsorgebedarfs und Begründung, warum kein Antrag gestellt oder ob Widerspruch eingelegt wurde",
        title: "c) Feststellung des Nachsorgebedarfs",
      },
      sectionD: {
        dateFieldLabel: "und Übermittlung der erforderlichen Informationen am",
        title: "d) Frühzeitige Einbindung der Krankenkasse",
      },
      sectionE: {
        addMoreProviders: "Anschlussversorger manuell hinzufügen",
        deleteButtonAria: "Schaltfläche „Löschen“",
        deletionScreenReaderAnnouncement: (args: { providerName: string }) =>
          template("Anfrage an Anbieter {{providerName}} wurde gelöscht", args),
        editButtonAriaLabel: "Schaltfläche „Bearbeiten“",
        editCity: "Ort",
        editDate: "Datum der Anfrage",
        editName: "Name",
        editStatus: "Status",
        editStreet: "Straße",
        editStreetNumber: "Hausnummer",
        editTime: "Gesendet um",
        editZipCode: "Postleitzahl",
        importButton: "Anschlussversorger aus Recare importieren",
        importedProvider: "Importiert",
        internetbasedPortals: "Es wurden internetbasierte Portale verwendet",
        mandatoryFieldsInformation: "* Markiert ein Pflichtfeld",
        manuallyAddedProvider: "Manuell hinzugefügt",
        refreshButton: "Anschlussversorger aus Recare aktualisieren",
        saveButton: "Übernehmen",
        title: "e) Anfragen bei den geeigneten Anschlussversorgern",
      },
      sectionF: {
        bulletFour:
          "Bei Entlassung aus der Übergangspflege bestand kein weiterer Bedarf mehr für eine Anschlussversorgung",
        bulletOne:
          "Die erforderliche Anschlussversorgung konnte bereits während der Krankenhausbehandlung (für einen Übergang in die Anschlussversorgung innerhalb des Zeitrahmens für die Übergangspflege) sichergestellt werden",
        bulletThree:
          "Der Übergang in die Anschlussversorgung konnte erst für einen Zeitpunkt außerhalb des Anspruchs auf Übergangspflege (längstens 10 Tage je Krankenhausbehandlung) sichergestellt werden",
        bulletTwo:
          "Die erforderliche Anschlussversorgung konnte während der Übergangspflege (für einen Übergang in die Anschlussversorgung innerhalb des Zeitrahmens für die Übergangspflege) sichergestellt werden",
        dateFieldLabel: "Datum des Beginns der Anschlussversorgung",
        title:
          "f) Angabe, ob die erforderliche Anschlussversorgung nach Abschluss der Übergangspflege sichergestellt werden konnte oder nicht mehr erforderlich war",
      },
      subtitleEditForm: "Editieren",
      subtitleEmptyForm: "Bitte starten Sie zuerst die Nachversorger-Suche",
      subtitleExistingForms: "Erstelltes PDF",
      subtitleNew: "Neu anlegen",
      tabTitle: (args: { tabName: string }) =>
        template("{{tabName}} – mit Übergangspflege", args),
      title: "Übergangspflege",
      titleDocumentationTransitionalCare:
        "Erstellte PDF-Dokumentation der Übergangspflege",
      toTheTop: "Nach oben",
      validationErrorMessage: "Bitte füllen Sie dieses Feld aus.",
    },
    warningNotSaved: {
      leavePage: "Verlassen",
      savePage: "Speichern und verlassen",
      subtitle:
        "Es liegen nicht gespeicherte Änderungen vor, die verloren gehen.",
      title:
        "Sind Sie sicher, dass Sie die Seite ohne Speichern verlassen möchten?",
    },
    warningOverwritePdf: {
      buttonCancel: "Abbrechen",
      buttonConfirm: "Jetzt PDF erstellen",
      subtitle: "Möchten Sie das PDF trotzdem erstellen?",
      title: "Die vorhandene PDF-Datei wird überschrieben.",
    },
  },
  people: {
    centralPhoneNumber: "Zentrale Telefonnummer",
    companyMailAddress: "E-Mail-Adresse der Einrichtung",
    companyMailAddressPlaceholder: "z.B. info@, kontakt@ oder annahme@",
    emailAddress: "E-Mail-Adresse",
    faxNumber: "Faxnummer",
    firstName: "Vorname",
    ikNumber: "IK Nummer",
    invalidEmailAddress: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    invalidPhoneNumber: "Geben Sie eine gültige Nummer und Landesvorwahl ein.",
    jobCategories: {
      admissionBedMgmt: "Aufnahme/Zentrl. Belegungsmanagement",
      chiefDoctor: "Chefarzt/Chefärztin",
      disposition: "Disposition",
      doctor: "Arzt/Ärztin",
      managementAdministration: "Management/Verwaltung",
      medicalStaff: "Medizinisches Personal",
      other: "Andere",
    },
    jobCategory: "Job-Kategorie",
    lastName: "Nachname",
    mailShort: "E-Mail:",
    mobile: "Mobil:",
    mobilePhoneNumber: "Mobilnummer",
    phoneNumber: "Telefonnummer",
    position: "Position",
    tel: "Tel.",
    website: "Website",
  },
  planning: {
    loadingError: "Konnte nicht aktualisiert werden",
    planningTabTerminated:
      "Am 01.7.2020 wird dieser Planer entfernt. Bitte nutzen Sie die Filter in der Hauptansicht “Suche” um Ihren Arbeitsablauf zu organisieren.",
  },
  prompts: {
    continueWithoutSaving: {
      explanationText: "Alle nicht gespeicherten Änderungen gehen verloren.",
      title: "Sind Sie sicher, dass Sie fortfahren möchten?",
    },
    saveChanges: {
      explanationText:
        "Ihre Änderungen gehen verloren, wenn Sie sie nicht speichern.",
      title: "Möchten Sie die vorgenommenen Änderungen speichern?",
    },
  },
  providersearch: {
    accountPage: {
      acceptedPrivacyPolicy: (args: { date: string }) =>
        template(
          "Sie haben der Datenschutzinformation am {{date}} zugestimmt.",
          args,
        ),
      acceptedPrivacyPolicyAdditional:
        "Hier erhalten Sie weitere Informationen zum Datenschutz und Ihren Betroffenenrechten:",
      acceptedPrivacyPolicyLink:
        "Alle weiteren Anfragen zum Datenschutz können Sie ebenfalls an {select: pflege-finder-bayern@recaresolutions.com} stellen.",
      acceptedTermsOfUse: (args: { date: string }) =>
        template(
          "Sie haben der Nutzungsvereinbarung am {{date}} zugestimmt.",
          args,
        ),
      accountCreationDate: (args: { date: string }) =>
        template("Ihr Konto wurde am {{date}} erstellt.", args),
      accountDeactivation: "Informationen zu Ihrem Konto",
      accountDeactivationLink:
        "Um Ihr Konto zu löschen, schicken Sie uns bitte eine E-Mail an {select:pflege-finder-bayern@recaresolutions.com}.",
      buttonReadDocument: "Lesen",
      dataExportRequest: "Datenexport anfordern",
      dataExportRequestLink:
        "Um einen Export Ihrer  Daten zu erhalten, schicken Sie uns bitte eine E-Mail an {select: pflege-finder-bayern@recaresolutions.com}",
      emailAddress: "E-Mail-Adresse",
      linkOpenFile: "Datei öffnen",
      privacyPolicy: "Datenschutzinformation",
      subtitleAccount: "Konto",
      subtitleLegal: "Rechtliches",
      subtitlePersonalDetails: "Persönliche Daten",
      termsOfUse: "Nutzungsvereinbarung",
      title: "Mein Konto",
    },
    assessment: {
      ageGroupWarning:
        "Die angegebene Altersgruppe entspricht nicht den Aufnahmekriterien des Anbieters. Sie können die Anfrage trotzdem abschicken.",
      errorAgeGroup: "Bitte Altersgruppe auswählen",
      errorCareDuration: "Bitte wählen Sie Erwartete Pflegedauer aus",
      errorCareLevel: "Bitte wählen Sie den Pflegegrad aus",
      errorGender: "Bitte wähle ein Geschlecht",
      informationText:
        "Diese Informationen werden zur Bearbeitung Ihrer unverbindlichen Anfrage vom Versorgungsanbieter gebraucht. Ihre Daten werden nur mit Versorgungsanbietern geteilt, mit denen Sie Kontakt aufnehmen. Mehr Informationen darüber, wie Ihre Daten verarbeitet werden, finden Sie in der {select:Datenschutzinformation}.",
      informationTitle: "Warum benötigen wir diese Informationen?",
      mandatoryFieldInformation: "* markiert ein Pflichtfeld.",
      missing: "Bitte wählen Sie eine Option",
      nextButton: "Weiter",
      overweightService: (args: { weight: string }) =>
        template("Mehrgewicht (> {{weight}} kg)", args),
      questionAgeGroup: "Altersgruppe (Jahre)",
      questionCareDuration: "Erwartete Pflegedauer",
      questionCareLevel: "Pflegegrad",
      questionCareNeeds: "Besonderer Bedarf",
      questionGender: "Geschlecht",
      specialCareNeedsWarning:
        "Der angegebene Pflegebedarf entspricht nicht den Aufnahmekriterien und/oder dem Leistungsspektrum des Versorgungsanbieters, wodurch eine Annahme Ihrer Anfrage unwahrscheinlich ist. Sie können die Kontaktanfrage trotzdem abschicken.",
      title: "Neues Profil für eine Versorgungssuche erstellen",
    },
    auctionCreationModal: {
      cancelButton: "Abbrechen",
      nextButton: "Anfrage starten",
      startDate: "Start der Versorgung",
      title:
        "Sie starten eine unverbindliche Kontaktanfrage mit den folgenden Kriterien",
      typeOfCare: "Versorgungsform",
      warningDate:
        "Der Anbieter hat an diesem Datum keine Verfügbarkeit. Sie können trotzdem eine Anfrage senden.",
      warningDateInPast: "Bitte wählen Sie ein gültiges Datum in der Zukunft.",
      warningZipCode:
        "Diese Postleitzahl liegt außerhalb des Einzugsgebiets des Anbieters. Sie können den Anbieter trotzdem kontaktieren.",
      warningZipCodeMissing: "Bitte wählen Sie eine PLZ oder einen Ort",
      zipCode: "Ort",
    },
    auctionResponsePage: {
      buttonLabel: "Zur Suchanfragenübersicht",
      chatLabels: {
        buttonCancel: "Anfrage stornieren",
        buttonValidate: "Anbieter wählen",
      },
      contactDetailsProvider: {
        address: "Adresse",
        email: "E-Mail",
        telephone: "Telefon",
        title: "Kontaktdaten des Versorgungsanbieters",
        website: "Webseite",
      },
      patientInformation: {
        ageGroup: "Altersgruppe",
        careDuration: "Erwartete Pflegedauer",
        careLevel: "Pflegegrad",
        careNeeds: "Besonderer Bedarf",
        gender: "Geschlecht",
        profileId: "Profil-ID-Nr.",
        title: "Informationen zur versorgenden Person",
      },
      print: "Ausdrucken",
      requestStatus: {
        buttonUndoValidation: "Rückgängig machen",
        informationUndoValidation:
          "Sollten Sie die Bestätigung aufheben wollen, klicken Sie auf “Rückgängig machen”.",
        requestWasAccepted: (args: { date: string }) =>
          template("Anfrage angenommen am {{date}}", args),
        requestWasDeclined: (args: { date: string }) =>
          template("Anfrage abgelehnt am {{date}}", args),
        requestWasRejected: (args: { date: string }) =>
          template("Anfrage storniert am {{date}}", args),
        requestWasSent: (args: { date: string }) =>
          template("Anfrage gesendet am {{date}}", args),
        requestWasValidated: (args: { date: string }) =>
          template("Anfrage validiert am {{date}}", args),
        testAttributed:
          "Sie haben den Anbieter bestätigt und der Anbieter wurde darüber informiert. Dies stellt keine verbindliche Buchung dar. Bitte setzen Sie sich mit dem Anbieter in Verbindung, um die Aufnahme und die Details der Versorgung zu besprechen. Sie können hierfür den Chat nutzen.",
        textAccepted:
          'Der Anbieter hat Ihre Kontaktanfrage akzeptiert. Bitte klicken Sie rechts im Chat auf „Anbieter wählen" um den Anbieter auszuwählen, oder auf „Anfrage stornieren", um die Anfrage zurückzuziehen. Ihre Antwort ist unverbindlich.',
        textArchived:
          "Das Profil wurde archiviert und alle offenen Kontaktanfragen wurden automatisch storniert. Diese Aktion kann nicht rückgängig gemacht werden.",
        textAuctionStopped:
          "Sie können den Nachversorger erneut kontaktieren, indem Sie eine neue Anfrage starten.",
        textCancelled:
          'Sie haben die Kontaktanfrage storniert. Sie können die Stornierung aufheben, indem Sie im Chat auf der rechten Seite auf  „Rückgängig machen" klicken.',
        textPending:
          "Bitte warten Sie, bis der Versorgungsanbieter Ihre Anfrage akzeptiert oder ablehnt. Dies kann einige Tage dauern. Kontaktanfragen können jederzeit storniert werden, indem Sie auf „Anfrage stornieren” im Chat klicken.\nBitte beachten Sie, dass das Klicken auf „Anbieter wählen” unverbindlich ist. Sie gehen durch Anklicken des Auswahlfelds keinen Vertrag ein oder geben eine andere rechtsverbindliche Zusage ab.",
        textRejected: "Der Anbieter hat Ihre Kontaktanfrage abgelehnt.",
        title: "Status der Anfrage",
      },
      searchDetails: {
        startDate: "Start der Versorgung",
        title: "Details der Suche",
        typeOfCare: "Angefragte Versorgungsform",
        zipCode: "Ort",
      },
      showProviderLink: "Einrichtung anzeigen",
      title: "Kontaktanfrage an",
      undoValidationDialog: {
        buttonCancel: "Abbrechen",
        buttonConfirm: "Bestätigen",
        cancelValidationReason: "Manuell abgebrochen",
        subtitle: 'Klicken Sie auf „Bestätigen", um die Annahme abzubrechen.',
        title:
          "Sind Sie sicher, dass Sie die Validierung rückgängig machen wollen?",
      },
    },
    emailAddress: "pflege-finder-bayern@recaresolutions.com",
    errorTooManyRequests:
      "Zu viele Anfragen gleichzeitig. Bitte warten Sie einen Moment, bevor Sie es erneut versuchen. Vielen Dank für Ihre Geduld",
    expiredLink:
      "Der Link ist abgelaufen. Bitte fordern Sie einen neuen Link an, indem Sie sich einloggen oder registrieren.",
    filterPanel: {
      filterChip: {
        distanceRadius: "km Radius",
        freeCapacity: "Freie Plätze",
        withConsultants: "Beratungsangebote",
        withProviders: "Versorgungsanbieter",
      },
      filterValues: {
        assistedLiving: "",
        dailyLivingSupportServices: "",
        dayCare: "",
        helpAtHome: "",
        hospiceAssociation: "",
        hospiceMobile: "",
        hospicePartiallyMobile: "",
        hospiceStatic: "",
        isConsultant: "Beratungsangeboten",
        isProvider: "Versorgungsanbietern",
        mobileCare: "",
        nightCareMobile: "",
        nightCareStatic: "",
        sharedFlat: "",
        shortTermStaticCare: "",
        staticCare: "",
      },
      hospicePalliativeCareTitle: "Hospiz- und Palliativversorgung",
      partialStaticCareTitle: "Teilstationäre Pflege",
      receiverTypeTitle: "Suche nach",
      referrer: "Direktanfrage",
      servicesTitle: "Besonderer Bedarf",
      title: "Art der Anfrage",
      titleFilters: "Detailsuche",
      typeOfCareTitle: "Versorgungsformen",
    },
    footer: {
      about: "Über den Pflegefinder",
      accessibilityCertificate: "Zum BITV-Test Prüfbericht",
      accessibilityDeclaration: "Barrierefreiheitserklärung",
      accessibilityDeclarationTooltip:
        "Externer Link: https://assets.recaresolutions.com/recare/pflegefinder_bayern_barrierefreiheitserklarung.pdf (Öffnet neues Fenster) ",
      altTextAccessibilityCertificate:
        "BIK - BITV-konform (geprüfte Seiten), zum Prüfbericht",
      altTextLogoLandesamt:
        "Logo - Bayerisches Landesamt für Pflege - zur Webseite",
      altTextLogoStaatsministerium:
        "Logo - Bayerisches Staatsministerium für Gesundheit, Pflege und Prävention - zur Webseite",
      altTextRecareLogo: "Logo - recare - zur Webseite",
      copyright: (args: { year: string }) =>
        template(
          "© Recare Deutschland GmbH {{year}} - Alle Rechte vorbehalten",
          args,
        ),
      developedBy: "Entwicklung, Implementierung und Betrieb",
      easyLanguage: "Leichte Sprache",
      emailAddress: "E-Mail: pflege-finder-bayern@recaresolutions.com",
      impressum: "Impressum",
      impressumTooltip:
        "Externer Link: https://recaresolutions.com/impressum/ (Öffnet neues Fenster) ",
      inCollaborationWith: "Unter Mitwirkung von",
      logoLandesamtTooltip:
        "Externer Link: https://www.lfp.bayern.de/ (Öffnet neues Fenster) ",
      logoRecareTooltip:
        "Externer Link: https://recaresolutions.com/ (Öffnet neues Fenster) ",
      logoStaatsministeriumTooltip:
        "Externer Link: https://www.stmgp.bayern.de/ (Öffnet neues Fenster) ",
      partnersHospitals: "Bayerische Krankenhausgesellschaft e. V.",
      partnersHospitalsTooltip:
        "Externer Link: https://www.bkg-online.de/ (Öffnet neues Fenster) ",
      partnersInsurance:
        "Arbeitsgemeinschaft der Pflegekassenverbände in Bayern",
      partnersLfp: "Bayerisches Landesamt für Pflege",
      phone: "+493031196372",
      phoneNumberLabel: "Telefonnummer: +49 30 31196372",
      privacyPolicy: "Datenschutzinformation",
      privacyPolicyTooltip:
        "Externer Link: https://assets.recaresolutions.com/recare/pflegefinder_bayern_datenschutzinformation.pdf (Öffnet neues Fenster) ",
      reportBarrier: "Barriere melden",
      reportBarrierLink:
        "mailto:pflege-finder-bayern@recaresolutions.com?Subject=Barriere%20melden",
      reportBarrierTooltip:
        "Externer Link: pflege-finder-bayern@recaresolutions.com (Öffnet neues Fenster)",
      reportContent: "Inhalte melden",
      reportContentLink:
        "mailto:pflege-finder-bayern@recaresolutions.com?Subject=Inhalte%20melden",
      signLanguage: "Gebärdensprache",
      tableOfContent: "Inhaltsverzeichnis",
      termsOfUse: "Nutzungsbedingungen",
      termsOfUseTooltip:
        "Externer Link: https://assets.recaresolutions.com/recare/pflegefinder_bayern_nutzungsbedingungen.pdf (Öffnet neues Fenster)",
    },
    genericAriaLabelEmailLink: "Mailadresse - öffnet neues Fenster",
    genericAriaLabelLink: "Öffnet neues Fenster",
    genericAriaLabelPDFLink: "PDF - öffnet neues Fenster",
    genericAriaLabelPhoneLink: "Telefonnummer",
    genericErrorMessage: "Es ist etwas schief gelaufen",
    genericLoadingNotification: "Lädt",
    glossary: {
      buttonPrint: "Drucken",
      careLevels: {
        bulletPoints: {
          eight: "Haushaltsführung.",
          five: "Bewältigung von und selbständiger Umgang mit krankheits- oder therapiebedingten Anforderungen und Belastungen",
          four: "Selbstversorgung",
          one: "Mobilität",
          seven: "Außerhäusliche Aktivitäten",
          six: "Gestaltung des Alltagslebens und sozialer Kontakte",
          three: "Verhaltensweisen und psychische Problemlagen",
          two: "Kognitive und kommunikative Fähigkeiten",
        },
        descriptions: {
          applied: "{select:Beantragt:} Ein Pflegegrad wurde beantragt.",
          levelFive:
            "{select:Pflegegrad 5:} Schwerste Beeinträchtigung der Selbstständigkeit mit besonderen Anforderungen an die pflegerische Versorgung",
          levelFour:
            "{select:Pflegegrad 4:} Schwerste Beeinträchtigung der Selbstständigkeit",
          levelOne:
            "{select:Pflegegrad 1:} Geringe Beeinträchtigung der Selbstständigkeit",
          levelThree:
            "{select:Pflegegrad 3:} Schwere Beeinträchtigung der Selbstständigkeit",
          levelTwo:
            "{select:Pflegegrad 2:} Erhebliche Beeinträchtigung der Selbstständigkeit",
          none: "{select:Keiner:} Es liegt kein Pflegegrad vor.",
          unknown: "{select:Unbekannt:} Der Pflegegrad ist nicht bekannt.",
        },
        introduction:
          "Entscheidend für die Feststellung von Pflegebedürftigkeit ist die Einschränkung der Selbstständigkeit oder der Fähigkeiten in den pflegerelevanten Bereichen des täglichen Lebens. Bei der Berechnung des Pflegegrades werden in einem bundesweit einheitlichen Verfahren dabei folgende Bereiche berücksichtigt:",
        paragraphOne:
          "Ebenfalls im Rahmen der Begutachtung erhoben werden die Bereiche",
        paragraphThree:
          "Nach einem Punktesystem ergeben sich aus den in der Begutachtung festgestellten Einschränkungen der Selbständigkeit fünf Pflegegrade:",
        paragraphTwo:
          "Letztere gehen aber nicht in die Berechnung des Pflegegrades ein.",
      },
      descriptionsConsultants: {
        dementiaCounselling: "Demenzberatung",
        descriptionDementiaCounselling:
          "Beraten werden sowohl Betroffene mit einer seltenen Demenzerkrankungen und ihre Angehörigen als auch Institutionen wie beispielsweise Pflegeeinrichtungen.",
        descriptionSpecialisedOffice:
          "Die Fachstellen für pflegende Angehörige sind Beratungs- und Anlaufstellen für pflegende Angehörige von älteren pflegebedürftigen Menschen und unterstützen diese durch psychosoziale Beratung, (längerfristige) Begleitung sowie Entlastungsangebote (Angehörigenarbeit).",
        descriptionSupportPoint:
          "Die Pflegestützpunkte bieten eine kostenlose Beratung zu allen Themen rund um die Pflege und stehen allen Bürgerinnen und Bürgern offen.",
        specialisedOffice: "Fachstellen für pflegende Angehörige",
        supportPoint: "Pflegestützpunkt",
      },
      errorNotFound: "Fehler: Glossarelement nicht gefunden",
      glossaryAriaLabel: "Glossar",
      linkToGlossary:
        "Sie finden mehr Informationen in unserem {select:Glossar}",
      navigationCareLevels: "Pflegegrade",
      navigationCareNeeds: "Besonderer Bedarf",
      navigationConsultants: "Beratungsangebote",
      navigationSolutions: "Versorgungsformen",
      navigationTitle: "Themen",
      pageDescription: "Glossar häufig verwendeter medizinischer Fachbegriffe",
      pageTitle: "Glossar",
      solutions: {
        assistedLiving: "Betreutes Wohnen",
        assistedLivingDescription:
          "Bzw. Service-Wohnen richtet sich an Menschen, die nicht oder nur in geringem Umfang pflegebedürftig sind und selbstbestimmt in einer eigenen Wohnung leben können. Die Angebote reichen von Wohnungen, die einer Pflegeeinrichtung angegliedert sind, über seniorengerechte Wohnungen im normalen Wohnungsbau bis hin zu hotelähnlichen Angeboten. In der Regel können Unterstützungsleistungen hinzugebucht werden, wie Hausnotruf, Reinigungsdienst, Wäscheservice oder die Vermittlung von Pflegeleistungen. Betreutes Wohnen bietet demnach keine Rundumversorgung und ist für Menschen mit schwerwiegender Pflegebedürftigkeit oder bei fortgeschrittener Demenz weniger geeignet.",
        dailySupportServices: "Angebote zur Unterstützung im Alltag",
        dailySupportServicesDescription:
          "Pflegebedürftige Versicherte können Angebote zur Unterstützung im Alltag, zu denen Betreuungsangebote, Angebote zur Entlastung im Alltag und Angebote zur Entlastung von Pflegenden gehören, in Anspruch nehmen.",
        helpAtHome: "Hauswirtschaftliche Versorgung",
        helpAtHomeDescription:
          "Beinhaltet Unterstützungsleistungen im Alltag, wie Einkaufen, Kochen, Reinigen und Beheizen der Wohnung, Spülen sowie Wechseln und Waschen der Wäsche und Kleidung. Solche Leistungen erbringen z.B. Pflege- oder Betreuungsdienste oder Angebote zur Unterstützung im Alltag. Hauswirtschaftliche Unterstützung ist aber auch elementarer Bestandteil der vollstationären Versorgung.",
        hospice: "Hospiz- und Palliativversorgung",
        hospiceBulletFour:
          "Ambulantes Hospiz wird maßgeblich von den dort engagierten ehrenamtlichen Hospizbegleiterinnen und -begleitern getragen. Sie begleiten Menschen auf ihrem letzten Weg und stehen ihren An- und Zugehörigen zur Seite.",
        hospiceBulletOne:
          "Vollstationäres Hospiz meint kleine Einrichtungen mit familiärem Charakter. Hier werden schwerstkranke und sterbende Menschen, deren voraussichtliche Lebenserwartung auf Tage oder wenige Wochen beschränkt ist und deren ambulante Versorgung zu Hause nicht mehr gewährleistet werden kann, bis zum Tod betreut.",
        hospiceBulletThree:
          "Teilstationäres Hospiz bietet schwerstkranken und sterbenden Menschen tagsüber Betreuung und Pflege durch ein multiprofessionelles Team und ermöglicht den Betroffenen so Teilhabe am gesellschaftlichen Leben sowie eine Vernetzung mit anderen Gästen.",
        hospiceBulletTwo:
          "Hospizvereine organisieren unter anderem die ehrenamtliche Begleitung schwerstkranker und sterbender Menschen. Darüber hinaus bieten Hospizvereine Aus- und Fortbildungen für Hospizbegleiterinnen und -begleiter an.",
        hospiceDescription:
          "Hospiz- und Palliativversorgung richtet sich an schwerstkranke und sterbende Menschen in ihrer letzten Lebensphase.",
        mobileCare: "Ambulante Pflege",
        mobileCareDescription:
          "Bedeutet Pflege im häuslichen Umfeld, z.B. durch ambulante Pflegedienste und/oder durch häusliche Pflegepersonen (bspw. Familienangehörige, Freunde, Nachbarn). Ziel ist ein Leben in weitgehender Selbstständigkeit und Eigenverantwortung.",
        partiallyStaticCare: "Teilstationäre Pflege",
        partiallyStaticCareBulletOne:
          "Tagespflege ermöglicht die zeitweise Betreuung im Tagesverlauf in einer Pflegeeinrichtung meist unter der Woche. Nachts und am Wochenende kehren diese in ihr häusliches Umfeld zurück.",
        partiallyStaticCareBulletThree:
          "Ambulante Nachtpflege bedeutet die nächtliche Versorgung des Pflegebedürftigen in seinem häuslichen Umfeld durch einen ambulanten Pflegedienst.",
        partiallyStaticCareBulletTwo:
          "Stationäre Nachtpflege meint eine Pflegeeinrichtung, in der Pflegebedürftige über Nacht betreut und versorgt werden und morgens wieder nach Hause zurückkehren.",
        partiallyStaticCareDescription:
          "Teilstationäre Pflege dient vor allem der Entlastung pflegender Angehöriger.",
        sharedFlat: "Wohngemeinschaft",
        sharedFlatDescription:
          "Ermöglicht es pflegebedürftigen Menschen, in einem gemeinsamen Haushalt zu leben und nach Bedarf Pflege- und Betreuungsleistungen, z.B. durch einen Pflegedienst in Anspruch zu nehmen.",
        shortTermCare: "Kurzzeitpflege",
        shortTermCareDescription:
          "Ist eine zeitlich begrenzte Betreuung und Versorgung pflegebedürftiger Menschen in einer vollstationären Pflegeeinrichtung, die ansonsten zu Hause gepflegt werden oder erstmals pflegebedürftig werden.",
        staticCare: "Stationäre Pflege",
        staticCareDescription:
          "Ist ein Lebensort in einer Einrichtung für Pflegebedürftige, die über Tag und Nacht einer umfassenden, leitliniengerechten und menschenwürdigen Versorgung und Betreuung bedürfen. Ein Aufenthalt in einer vollstationären Pflegeeinrichtung kann dann geeignet sein, wenn häusliche und/oder teilstationäre Pflege nicht mehr ausreichen.",
      },
    },
    header: {
      altTextGutePflegeLogo:
        "Logo -  Gute Pflege Daheim in Bayern - zur Startseite",
      glossary: "Glossar",
      homePage: "Startseite",
      logout: "Abmelden",
      mainNavigationAriaLabel: "Hauptmenü",
      myAccount: "Mein Konto",
      register: "Anmelden",
      requestDashboard: "Kontaktanfragen",
      title: "Pflegefinder Bayern",
    },
    listingPage: {
      accessibility: {
        altTextLogo: "Logo -  Gute Pflege Daheim in Bayern - zur Startseite",
        consultantIconAltText: "Beratungsangebot Icon",
        filterLabel: "Filter",
        headline: "Übersicht der Versorgungs-Anbieter und Beratungsangebote",
        markerAltText: "Kartenmarkierung",
        notAvailableIconAltText: "Nicht verfügbar Icon",
        paginationCurrentPage: (args: { pagenumber: string }) =>
          template("Sie befinden sich auf Seite {{pagenumber}}", args),
        paginationFirstPageLabel: "Zur ersten Seite wechseln",
        paginationGeneralLabel: (args: { page: string }) =>
          template("Gehe zu Seite {{page}}", args),
        paginationLastPageLabel: "Zur letzten Seite wechseln",
        paginationNavigationLabel: "Seitennavigation",
        paginationNextPageLabel: "Zur nächsten Seite wechseln",
        paginationPreviousPageLabel: "Zur vorherigen Seite wechseln",
        permanentFilterLabel: "Permanenter Filter",
        productTourHeading:
          "Willkommen! Dies ist ein Tipp um Ihnen den Start auf dem Pflegefinder Bayern zu erleichtern",
        providerIconAltText: "Anbieter Icon",
        providerSearchBarLabel: "Suche nach Anbieter",
        suggestionsAriaLabel: "Vorschläge anzeigen",
        zipcodeFieldLabel: "Postleitzahl oder Ort eingeben",
      },
      actionBar: {
        filter: {
          filterCta: "Detailsuche",
        },
      },
      appliedFilters: {
        clearFilters: "Alle Filter zurücksetzen",
        title: "Angewandte Filter",
      },
      consultantCard: {
        addressDistance: (args: { zipcode: string }) =>
          template("von {{zipcode}}", args),
        agency: (args: { sponsorName: string }) =>
          template("Projektträger: {{sponsorName}}", args),
        appointmentInformation: "Kontaktierbar für Terminvereinbarung",
      },
      consultantFacility: "Beratungsangebot",
      consultantsNearby: "Beratungsstellen in Ihrer Nähe",
      noResultsText:
        "Überprüfen Sie Ihre angewandten Filter. Falls immer noch keine Ergebnisse vorliegen, können Sie eine Beratungsstelle kontaktieren und einen Termin vereinbaren.",
      noResultsTitle:
        "Für diese Suchkriterien konnten keine Ergebnisse gefunden werden.",
      print: "Drucken",
      printColumnTitleFacilities: "Versorgungsangebot",
      printTitle: "Pflegefinder Bayern",
      productTour:
        'Klicken Sie auf "Detailsuche", um Filter zu setzen und die Ergebnisse zu verfeinern.',
      productTourButton: "Alles klar",
      providerCard: {
        addressDistance: (args: { distance: string; zipcode: string }) =>
          template("{{distance}}km von {{zipcode}}", args),
        capacity: {
          capacityUnknown: "Verfügbarkeit auf Anfrage",
          capacityUpdatedAt: (args: { updatedAt: string }) =>
            template("Aktualisiert am {{updatedAt}}", args),
          freeCapacity: "Verfügbar",
          noCapacity: "Nicht verfügbar",
        },
        moreProviders: "und weitere Versorgungsformen",
      },
      providerSearchBarNoResult: "Kein Ergebnis",
      providerSearchBarPlaceholder: "Anbietername suchen",
      result: "Ergebnis",
      results: "Ergebnisse",
      zipCodeModal: {
        body: "Anhand der Angaben wird eine nach Entfernung sortierte Liste von Versorgungsanbietern und Beratungsangeboten erstellt. Einige Suchkriterien sind bereits voreingestellt. Sie können diese im nächsten Schritt anpassen, um Ihre Suche zu verfeinern.",
        privacyPolicy: "Datenschutzinformation",
        startButton: "Suche starten",
        termsOfUse: "Nutzungsbedingungen",
        title: "Bitte geben Sie eine Postleitzahl oder einen Ort ein.",
      },
    },
    loginDropDown: {
      bulletFour: "Fragen per Chat-Nachrichten zu klären",
      bulletOne: "Bei Versorgungsanbietern anzufragen",
      bulletThreee: "Profile für sich und andere anzulegen",
      bulletTwo: "Parallel verschiedene Leistungen anzufragen",
      createAccountButton: "Kostenloses Konto erstellen",
      loginButton: "Einloggen",
      providersInformation:
        "Dann freuen wir uns über Ihre E-Mail an {select:pflege-finder-bayern@recaresolutions.com}",
      providersTitle: "Sie wollen sich als Versorgungsanbieter registrieren?",
      title: "Ihr kostenloses Konto erlaubt Ihnen",
    },
    loginModal: {
      accessibilityHelp:
        "Bitte geben Sie Ihre E-Mail-Adresse ein, um fortzufahren.",
      createAccount: {
        acceptDPA:
          "Ich willige in die Datenverarbeitung wie in der {select:Datenschutzinformation} beschrieben ein.",
        acceptDPAError:
          "Bitte akzeptieren Sie die Datenschutzinformation, um ein Konto zu erstellen. ",
        acceptTerms: "Ich akzeptiere die {select:Nutzungsbedingungen}.",
        acceptTermsError:
          "Bitte akzeptieren Sie die Nutzungsbedingungen, um ein Konto zu erstellen.",
        accountCreationGuide:
          "Schritt-für-Schritt-Anleitung zur Kontoerstellung",
        alreadyExistsError:
          "Es gibt bereits ein Konto mit dieser E-Mail-Adresse. Bitte {select:klicken Sie hier} um sich einzuloggen.",
        alreadyExistsMessage:
          "Ein Konto mit dieser E-Mail-Adresse existiert schon. ",
        confirmEmailPlaceholder: "E-Mail-Adresse bestätigen",
        emailErrorNotEmail: "Das Feld muss eine E-Mail-Adresse beinhalten",
        emailErrorNotMatch: "E-Mail-Adressen stimmen nicht überein",
        emailOnePlaceholder: "E-Mail-Adresse eingeben",
        infoText:
          "Sie können auch für Dritte, z.B. einen Angehörigen einwilligen. Dies ist aber nur möglich, wenn Sie dazu berechtigt sind.",
        logInLinkInfo:
          "{select:Melden Sie sich mit einer existierenden E-Mail-Adresse an.} ",
        registerAccessibilityHelp:
          "Bitte geben Sie Ihre E-Mail-Adresse ein, und stimmen Sie der Datenschutzinformation und den Nutzungsbedingungen zu, um fortzufahren.",
        submitButton: "Weiter",
        successCaption: "Der Link ist für 24 Stunden gültig.",
        successText:
          "Wir haben eine E-Mail mit einem Anmelde-Link an {select:email} gesendet. Bitte prüfen Sie ggf. auch Ihren Spam-Ordner und klicken Sie auf den Link in der E-Mail, um sich auf der Plattform einzuloggen. ",
        successTitle: "Erfolgreiche Kontoerstellung",
        title: "Konto erstellen",
      },
      loginModal: {
        errorAccountDoesNotExist:
          "Für diese E-Mail-Adresse ist kein Konto vorhanden. ",
        errorAccountNotActive:
          "Für diese E-Mail-Adresse gibt es kein aktives Konto.",
        errorGeneric:
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
        registerLinkInfo:
          "{select:Registrieren Sie sich mit einer neuen E-Mail-Adresse.}",
        successCTA: "Alles klar",
        successText:
          "Wir haben Ihnen eine E-Mail mit einem Anmelde-Link zugeschickt. Bitte prüfen Sie ggf. auch Ihren Spam-Ordner und klicken Sie auf den Link in der E-Mail, um sich auf der Plattform einzuloggen. Der Link ist für 24 Stunden gültig.",
        successTitle: "Anmelde-Link wurde versandt ",
        title: "Anmelden",
      },
      needHelp: "Brauchen Sie Hilfe? {select:Kontaktieren Sie uns}",
    },
    loginSuccessful: "Sie haben sich erfolgreich eingeloggt.",
    logoutModal: {
      buttonCancel: "Abbrechen",
      buttonLogout: "Abmelden",
      subtitle: "Sie können sich jederzeit wieder einloggen.",
      title: "Sind Sie sicher, dass Sie sich abmelden möchten? ",
    },
    map: {
      grantConsentButton: "Karte aktivieren",
      grantConsentMapboxLink:
        "Ich bin damit einverstanden, dass mir die Karte angezeigt und dadurch meine IP-Adresse und Standortdaten an {select:Mapbox Inc.} übermittelt und für max. 30 Tage gespeichert werden. ",
      grantConsentText:
        "Details finden Sie in unserer {select:Datenschutzinformation}.",
      grantConsentTitle: "Karte · Externer Inhalt",
      legendConsultant: "Beratungsangebot",
      legendNotAvailable: "Nicht verfügbar",
      legendProvider: "Versorgungsanbieter",
      legendTitle: "Legende",
    },
    notLoggedInError:
      "Sie sind nicht angemeldet, um diese Seite anzuzeigen. Bitte melden Sie sich an und versuchen Sie es erneut",
    onboardingFunnel: {
      answerOther: "Anderes",
      answerUnknown: "Weiß ich noch nicht",
      backButton: "Zurück",
      firstStep: {
        consultants: "Beratungsangebote",
        error: "Bitte wählen Sie mindestens eine Option aus, um fortzufahren.",
        providers: "Versorgungsanbieter",
        question: "Wonach suchen Sie?",
        stepCount: "1 von 4 Fragen",
        subtitle: "Bitte wählen Sie eine oder beide der folgenden Optionen",
      },
      fourthStep: {
        asap: "Sobald wie möglich",
        days: "In ein paar Tagen",
        question: "Wann soll die Versorgung starten?",
        stepCount: "3 von 3 Fragen",
        weeks: "In ein paar Wochen",
      },
      loadingScreen: "Vielen Dank! Wir generieren ihre Ergebnisse...",
      nextButton: "Weiter",
      secondStep: {
        helpAtHome: "Hauswirtschaftliche Versorgung",
        hospice: "Hospizversorgung",
        mobileCare: "Ambulante Pflege",
        question:
          "Welche Versorgungsform suchen Sie? (Mehrfachauswahl möglich)",
        sharedFlat: "Betreutes Wohnen / WG",
        shortTermCare: "Kurzzeitpflege",
        staticCare: "Stationäre Pflege",
        stepCount: "1 von 3 Fragen",
      },
      skipButton: "Alles überspringen und Suche starten",
      startSearchButton: "Suche starten",
      stepper: (args: { step: string; totalSteps: string }) =>
        template("{{step}} von {{totalSteps}} Fragen", args),
      stepperFormatted: (args: { step: string; totalSteps: string }) =>
        template("{select:Frage {{step}}} von {{totalSteps}} ", args),
      textDontKnowStartSearch:
        'Wenn Sie einen flexiblen Startzeitpunkt haben, können Sie einfach auf "Suche starten" drücken',
      textDontKnowWithGlossaryLink:
        "Sie wissen noch nicht genau, wonach Sie suchen möchten? Informieren Sie sich im {select:Glossar}  oder klicken Sie einfach auf „Weiter“.",
      textDontKnw:
        "Sie wissen noch nicht genau, wonach Sie suchen möchten? Informieren Sie sich im Glossar oder klicken Sie einfach auf „Weiter“.",
      thirdStep: {
        dementia: "Demenzbetreuung",
        hospice: "Spezialisierte ambulante Palliativversorgung (SAPV)",
        intensiveCare: "Intensivpflege",
        protectedArea: "Beschützender Bereich",
        psychiatricCare: "Psychiatrische Pflege",
        question:
          "Hat die pflegebedürftige Person einen besonderen Bedarf? (Mehrfachauswahl möglich)",
        stepCount: "2 von 3 Fragen",
      },
    },
    pageTitles: {
      accountPageDescription:
        "Alle Informationen zu Ihrem Pflegefinder-Bayern-Konto ✓ Hinterlegte persönliche Daten ✓ Erläuterung Datenschutz ✚ Datenexport anfordern ✚ Nutzungsbedingungen",
      accountPageTitle:
        "Pflegefinder Bayern – alles rund um Ihr Konto und rechtliche Fragen",
      auctionResponseDescription:
        "Status Ihrer Anfrage über den Pflegefinder Bayern digital einsehen ✓ Fragen per Chat stellen ✓ Patientendaten sicher austauschen ➨ Jetzt Kontakt aufnehmen!",
      auctionResponseTitle:
        "Statusseite zu Ihrer Einzelanfrage und Chatmöglichkeit",
      consultantProfileDescription:
        "Pflege-Beratungsangebote in ganz Bayern finden ✓ Übersichtliche Kontaktinformationen ✓ Digital Kontakt aufnehmen ➨ Jetzt Suche starten!",
      consultantProfileTitle:
        "Profilseite mit Kontaktinformationen des Beratungsangebots",
      listingsDescription:
        "Versorgungsangebote und Beratungsangebote in der Pflege bayernweit finden ✓ Anfragen schnell und sicher versenden ✚ Leicht individualisierbar ➨ Jetzt Suche starten! ",
      listingsTitle:
        "Pflegerische Versorgung und Beratung – Ihre Suchergebnisse",
      loginPageTitle: "Anmeldung beim Pflegefinder Bayern",
      onboardingFunnelDescription:
        "Pflegerische Versorgungsanbieter und Beratungsangebote in Bayern ✓ Verfügbarkeiten direkt einsehen ✓ Digital Anfragen senden und Antworten erhalten ➨ Jetzt starten!",
      onboardingFunnelTitle:
        "Pflegefinder Bayern – Jetzt Suche per Ortseingabe starten",
      printListingPageTitle: (args: { date: string }) =>
        template(
          "Pflegefinder Bayern - Ihre Suchergebnisse vom {{date}}",
          args,
        ),
      providerProfileDescription:
        "Pflege-Angebote in ganz Bayern finden ✓ Übersicht über alle Leistungen ✓ Fragen per Chat klären ✚ Einfach und digital ✚ Datenschutzkonform ➨ Jetzt Anfrage stellen!",
      providerProfileTitle:
        "Leistungen und Kontaktinformationen des Versorgungsanbieters",
      searchDashboardDescription:
        "Mehrere Anfragen für pflegerische Versorgung gleichzeitig stellen ✓ Den Überblick behalten ✚ Nachrichten und Daten sicher austauschen ➨ Jetzt Pflegefinder nutzen!",
      searchDashboardTitle:
        "Übersicht über Ihre aktiven und bestätigen Kontaktanfragen",
      searchForDescription:
        "Pflegerische Versorgungsanbieter und Beratungsangebote in Bayern ✓ Verfügbarkeiten direkt einsehen ✓ Digital Anfragen senden und Antworten erhalten ➨ Jetzt starten!",
      searchForTitle:
        "Pflegefinder Bayern – Suche nach Versorgungsangebot oder Beratung",
      solutionsDescription:
        "Spezifische Versorgungsformen suchen ✓ Ambulante und stationäre Pflege, hauswirtschaftliche Versorgung, betreutes Wohnen, Hospizversorgung und weitere ➨ Jetzt Anfrage stellen!",
      solutionsTitle:
        "Pflegefinder Bayern – Angabe von spezifischen Versorgungsformen",
      specialNeedsDescription:
        "Suche nach besonderen Pflegeangeboten ✓Demenzbetreuung, psychiatrische Pflege, Mehrgewicht, Intensivpflege, SAPV und weitere ➨ Jetzt den Pflegefinder Bayern nutzen!",
      specialNeedsTitle:
        "Pflegefinder Bayern – Angabe von besonderem Pflegebedarf ",
      startTimeDescription:
        "Pflegerische Versorgung zum gewünschten Startdatum suchen ✓ Nachrichten und Patientendaten sicher austauschen ➨ Jetzt Suche starten und Kontakt aufnehmen!",
      startTimeTitle:
        "Pflegefinder Bayern – Angabe des Startdatums der Versorgung",
      zipCodeDescription:
        "Pflegerische Versorgungsanbieter und Beratungsangebote in Bayern ✓ Verfügbarkeiten direkt einsehen ✓ Digital Anfragen senden und Antworten erhalten ➨ Jetzt starten!",
      zipCodeTitle: "Pflegefinder Bayern – Jetzt Suche per Ortseingabe starten",
    },
    preprodLogin: "Anmelden",
    providerDetailsPage: {
      backToListing: "Zurück",
      bannerAlreadyContacted:
        "Dieser Anbieter wurde bereits kontaktiert. Sie können die Anfragen in der {select:Kontaktanfragen-Übersicht} sehen.",
      contactSection: {
        address: "Adresse:",
        addressDistance: (args: { distance: string; zipcode: string }) =>
          template("{{distance}} km von {{zipcode}}", args),
        addressDistanceZero: (args: { zipcode: string }) =>
          template("liegt in {{zipcode}}", args),
        buttonLogin: "Anmelden & Anfrage starten",
        buttonSignup: "Konto erstellen & Anfrage starten",
        capacity: {
          buttonContact: "Kontaktieren",
          capacityUpdatedAt: (args: { updatedAt: string }) =>
            template("(Zuletzt aktualisiert am {{updatedAt}})", args),
          freeCapacityNoStartDate: "Freie Kapazität",
          freeCapacityWithStartDate: "Verfügbar für Ihr gewähltes Datum",
          noCapacityNoStartDate: "Keine Verfügbarkeit",
          noCapacityWithStartDate: "Nicht verfügbar für Ihr gewähltes Datum",
          unknownCapacity: "Verfügbarkeit auf Anfrage",
        },
        contactCaption:
          "Sie können den Versorgungsanbieter unverbindlich kontaktieren und über unseren sicheren, Ende-zu-Ende verschlüsselten Chat Fragen stellen.",
        copiedEmailTooltip: "Kopiert!",
        copyEmailFailedTooltip:
          "Hoppla, der Kopiervorgang ist fehlgeschlagen. Versuchen Sie es noch einmal!",
        copyEmailTooltip: "Klicken Sie hier, um die E-Mail zu kopieren",
        distance: "Entfernung",
        email: "E-Mail:",
        logInCaption:
          "Erstellen Sie ein Konto oder melden Sie sich an, um dem Anbieter eine unverbindliche Kontaktanfrage zu schicken.",
        openingHours: {
          closed: "Geschlossen",
          heading: "Öffnungszeiten",
        },
        telephone: "Telefon:",
        title: "Kontakt",
        website: "Webseite:",
        websiteTooltip:
          "Externer Link zur Webseite des Versorgungsanbieters (Öffnet neues Fenster) ",
      },
      defaultImageCaption:
        "Bei dem angezeigten Bild handelt es sich um ein generisches Beispielbild, das nicht den Anbieter zeigt.",
      descriptionText: {
        careprovider:
          "Dieser Anbieter hat noch keine Profilbeschreibung angegeben.",
        consultant:
          "Dieser Anbieter hat noch keine Profilbeschreibung angegeben.",
        consultantCaresupportcenter:
          "Die Pflegestützpunkte bieten eine kostenlose Beratung zu allen Themen rund um die Pflege und stehen allen Bürgerinnen und Bürgern (gesetzlich oder privat versichert) offen.",
        consultantDementia:
          "Beraten werden sowohl Betroffene mit einer seltenen Demenzerkrankungen und ihre Angehörigen als auch Institutionen wie beispielsweise Pflegeeinrichtungen.",
        consultantSpecialist:
          "Die Fachstellen für pflegende Angehörige sind Beratungs- und Anlaufstellen für pflegende Angehörige von älteren pflegebedürftigen Menschen und unterstützen diese durch psychosoziale Beratung, (längerfristige) Begleitung sowie Entlastungsangebote (Angehörigenarbeit).",
      },
      disclaimer:
        "Alle Informationen werden durch den Versorgungsanbieter oder das Beratungsangebot selbst bereitgestellt. Recare, als Anbieter des Pflegefinders Bayern, übernimmt keine Garantie für die Richtigkeit der Angaben. Bei kursiven Texten handelt es sich um Standarderläuterungen. Diese müssen nicht in jedem Fall mit den tatsächlichen Leistungen des Versorgungsanbieters übereinstimmen.",
      overweightServiceExplanation: (args: { weight: string }) =>
        template(
          "Die adäquate Versorgung von Personen mit einem Gewicht von mehr als  {{weight}}  kg erfordert eine entsprechende Ausstattung sowie geschultes Personal. Daher wird dieser Punkt gesondert aufgeführt.",
          args,
        ),
      print: "Drucken",
      providerImageCaption:
        "Bei dem angezeigten Bild handelt es sich um ein Bild, welches vom Anbieter hochgeladen wurde.",
      servicesHeading: "Leistungen",
      solutionsHeading: "Versorgungsformen",
    },
    redactedRequestsInfoCard: (args: { redactedCount: string }) =>
      template(
        "{{redactedCount}} weitere Direktanfrage(n) wurden auf Nutzerwunsch gelöscht.",
        args,
      ),
    requestConfirmationModal: {
      ageGroup: "Altersgruppe",
      backButton: "Zurück",
      careDuration: "Erwartete Pflegedauer",
      careLevel: "Pflegegrad",
      careNeeds: "Besonderer Bedarf",
      errorStartDate:
        "Der Anbieter hat an diesem Datum keine Verfügbarkeit. Sie können trotzdem eine Anfrage senden, aber es ist möglich, dass Sie eine Absage erhalten.",
      errorTypeOfCareMissing:
        "Bitte wählen Sie mindestens eine Versorgungsform aus.",
      errorZipCodeMissing: "Bitte geben Sie eine gültige Postleitzahl ein.",
      gender: "Geschlecht",
      seeMore: "und weiterer Bedarf",
      seeRequest: "Anfrage ansehen",
      sendButton: "Anfrage senden",
      startDate: "Start der Versorgung",
      title:
        "Bitte überprüfen Sie Ihre Angaben, bevor Sie die unverbindliche Anfrage versenden.",
      typeOfCare: "Versorgungsform",
    },
    searchDashboard: {
      acceptedSection: {
        emptyState: "Keine aktiven Kontaktanfragen",
        subtitle:
          "Hier sehen Sie alle Kontaktanfragen, bei denen entweder die Antwort des Versorgungsanbieters oder eine Rückmeldung von Ihnen aussteht.",
        title: "Aktive Kontaktanfragen",
      },
      accessibility: {
        ariaLabelArchivedProfiles:
          "Hier klicken, um archivierte Profile und Kontaktanfragen von den Profilen anzuzeigen \n",
        ariaLabelProfileTab:
          "Hier klicken, um die Kontaktanfragen von dem Profil anzuzeigen \n",
        menuLabel: "Menü",
      },
      archivedSection: {
        emptyState: "Keine inaktiven Kontaktanfragen",
        subtitle:
          'Hier werden alle von Ihnen stornierte und/oder von Versorgungsanbietern abgelehnte Anfragen aufgelistet. Stornierungen können über den Chat rückgängig gemacht werden, indem Sie auf den Button „Rückgängig machen" klicken.',
        title: "Archivierte Kontaktanfragen",
      },
      backButton: "Zurück zur Anbieterliste",
      inactiveStateTooltip:
        "Sobald Sie Kontaktanfragen versendet haben, können Sie diese hier einsehen.",
      informationText:
        "Hier sehen Sie die von Ihnen unverbindlich angefragten Versorgungsanbieter und bleiben über Rückmeldungen auf dem Laufenden. Pro Profil sind drei Anfragen gleichzeitig möglich. Nach einer Anfrage steht Ihnen mit jedem Anbieter eine Chatfunktion zur Verfügung.",
      pageTitle: "Kontaktanfragen",
      patientProfile: {
        activeProfilesSubtitle: "Sie haben noch keine Profile angelegt.",
        activeProfilesTitle: "Aktive Profile",
        archivedTab: "Archiviert",
        archiveFlow: {
          buttonArchive: "Archivieren",
          buttonCancel: "Abbrechen",
          subtitle:
            "Diese Aktion kann nicht rückgängig gemacht werden und alle derzeit ausstehenden und vom Anbieter akzeptierten Kontaktanfragen werden storniert. Sie können jedoch jederzeit ein neues Profil erstellen, sobald Sie weitere Versorgungsanbieter kontaktieren möchten. Sie können maximal zwei unterschiedliche Profile erstellen.",
          title: (args: { id: string }) =>
            template(
              "Sind Sie sicher, dass Sie Profil {{id}} archivieren möchten?",
              args,
            ),
        },
        archiveProfile: (args: { id: string }) =>
          template("Profil {{id}} ", args),
        archiveTab: {
          date: (args: { date: string }) =>
            template("Archiviert am {{date}}", args),
          profile: (args: { id: string }) => template("Profil {{id}}", args),
          title: "Archiviert",
        },
        informationId:
          "Bitte verwenden Sie die Profil-ID-Nummer als Referenz für die Kommunikation mit den Versorgungsanbietern.",
        myProfiles: "Meine Profile",
        profileName: (args: { id: string }) => template("Profil {{id}}", args),
        seeRequestsButton: "Zu den Anfragen",
        tabProfileTitle: "Ihre Profile",
      },
      profileArchivedSuccessfully: "Profil wurde erfolgreich archiviert",
      requestCard: {
        buttonDetails: "Details und chat",
        buttonNewMessage: "Neue Nachricht",
        date: (args: { date: string }) => template("Am {{date}}", args),
        seeMore: "und weitere Versorgungsformen",
        startDate: (args: { date: string }) => template("Ab {{date}}", args),
        status: "Status",
        subtitleAccepted: "Bitte schließen Sie die Suche ab.",
        subtitleCancelled: "Sie haben diese Anfrage storniert.",
        subtitlePending: "Bitte warten Sie auf eine Antwort des Anbieters.",
        subtitleRejected: "Ihre Anfrage wurde abgelehnt.",
        subtitleValidated: "Ihre Suche ist abgeschlossen.",
        titleAccepted: "Vom Anbieter akzeptiert",
        titleCancelled: "Storniert",
        titlePending: "Ausstehend",
        titleRejected: "Abgelehnt",
        titleValidated: "Bestätigt",
      },
      sectionHeader: (args: { id: string }) =>
        template("Kontaktanfragen für Profil {{id}}", args),
      validatedSection: {
        emptyState: "Keine bestätigten Anfragen",
        subtitle:
          "Hier sehen Sie Kontaktanfragen, die sowohl von Ihnen als auch vom Versorgungsanbieter bestätigt wurden.",
        title: "Bestätigte Kontaktanfragen",
      },
    },
    selectPatientModal: {
      buttonAddNew: "Neues Profil hinzufügen",
      buttonGoToRequest: "Anfrage öffnen",
      noServicePlaceholder: "Keine Angabe",
      profileName: (args: { identifier: string }) =>
        template("Profil {{identifier}}", args),
      selectButton: "Auswählen",
      title:
        "Für welches Profil möchten Sie eine unverbindliche Kontaktanfrage senden?",
      warningAlreadyContact:
        "Sie haben den Versorgungsanbieter für dieses Profil bereits kontaktiert. Bitte wählen Sie ein anderes Profil oder erstellen Sie ein neues Profil, um eine Kontaktanfrage zu senden.",
      warningDoesNotMatch:
        "Dieses Profil entspricht nicht den Aufnahmekriterien des Anbieters. Sie können trotzdem eine Kontaktanfrage senden.",
      warningMaxNumberOfProfiles:
        "Um ein weiteres Profil zu erstellen, müssen Sie erst ein existierendes Profil auf der {select: Kontaktanfragenseite} archivieren. Dafür klicken Sie unterhalb der Profile auf „Archivieren”.",
      warningMaxRequetsSent:
        "Für dieses Profil wurde bereits die maximale Anzahl an Anfragen gesendet. Bitte warten Sie ab, bis die Anbieter antworten, oder stornieren Sie Anfragen.",
    },
    startPage: {
      paragraphOne:
        "Ob Pflegeplatz, Unterstützung zu Hause oder Beratung: Betroffene und Angehörige brauchen schnelle Hilfe. Dafür gibt es den Pflegefinder Bayern, initiiert vom Bayerischen Pflegeministerium. Hier finden Sie schnell und kostenlos Unterstützungsangebote in ganz Bayern.",
      paragraphTwo:
        "Wir führen Sie durch die nächsten Schritte, um die passende Hilfe zu finden.",
      promptZipCode: "Bitte geben Sie eine Postleitzahl oder einen Ort ein:",
      subtitle: "Die Pflegebörse für Bayern",
      title: "Willkommen beim Pflegefinder",
    },
    tableOfContents: {
      accountPage: "Kontoseite",
      activePatientsSubheading: "Aktive Patienten",
      activeRequestsSubheading: "Aktive Anfragen",
      archivedPatientSubheading: "Archivierte Patienten",
      ariaLabel: "Inhaltsverzeichnis",
      inactiveRequestsSubheading: "Inaktive Anfragen",
      pageDescription: "Stellt ein Inhaltsverzeichnis für die Anwendung bereit",
      pageTitle: "Inhaltsverzeichnis",
      patientDashboard: "Patienten-Dashboard",
      profileIdLink: "Profil ID:",
      requestLink: "Anfrage an den Pflegedienstleister:",
      startPage: "Startseite",
      title: "Inhaltsverzeichnis",
      validatedRequestsSubheading: "Validierte Anfragen",
    },
  },
  pwaSettings: {
    chromeInstallInstructionOne:
      "Klicken Sie auf die Schaltfläche unten, um die App auf Ihrem Gerät zu installieren",
    confirmedActions: "Recare-App installiert und Benachrichtigungen erlaubt",
    copyDebugInformationToClipboard:
      "Informationen in die Zwischenablage kopieren",
    debugTitle: "Geräteinformation",
    ensurePermissionsGrantedInSettings:
      "Wenn die Benachrichtigung nicht empfangen wurde, stellen Sie bitte sicher, dass die App in Ihren Einstellungen über Benachrichtigungsberechtigungen verfügt.",
    ensureToAllowNotifications:
      "Bitte stellen Sie sicher, dass Sie Benachrichtigungen zulassen, wenn Sie dazu aufgefordert werden, wenn Sie Benachrichtigungen auf Ihrem Gerät erhalten möchten. Andernfalls kann es sein, dass Sie diesen Schritt deinstallieren und wiederholen müssen.",
    errorAskingPermission: (args: { error: string }) =>
      template(
        "Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten: {{error}}",
        args,
      ),
    ifAlreadyInstalled:
      "Wenn Sie die App bereits installiert haben, gehen Sie bitte auf Ihrem Startbildschirm zur installierten App, um fortzufahren",
    installButtonNotAvailable:
      "Bitte installieren Sie die App, indem Sie die Einstellungen Ihres Browsers aufrufen und die App installieren",
    installToDeviceButton: "Auf Gerät installieren",
    iOsInstallInstructionOne:
      "Klicken Sie auf „Teilen“ {select:icon} und „Zum Startbildschirm hinzufügen“, um die App zu installieren",
    iOsVersionError: (args: { version: string }) =>
      template("Unten erlaubte iOS-Version {{version}}", args),
    notificationAPIUnspported:
      "Die Benachrichtigungs-API wird auf diesem Gerät nicht unterstützt. Versuchen Sie bitte, die Anwendung zu schließen und erneut zu öffnen. Wenn dies nicht funktioniert, wenden Sie sich bitte mit Ihren Debug-Informationen unten an recare.",
    notificationPermissionGranted: "Benachrichtigungsberechtigung erteilt",
    pleaseInstallWithChrome:
      "Für optimale Funktionalität wird empfohlen, diese Anwendung in Chrome zu installieren",
    pleaseInstallWithSafari:
      "Bei Verwendung von iOS müssen PWAs mit Safari installiert werden",
    pleaseUpdatePermissions:
      "Bitte aktualisieren Sie die Berechtigungen dieser App zum Senden von Benachrichtigungen in Ihren Geräteeinstellungen.",
    pleaseUseMobileDevice:
      "Bitte verwenden Sie ein mobiles Gerät, um diese App zu installieren",
    promptDismissed:
      "Installationsaufforderung wurde abgelehnt. Bitte installieren Sie die App über die Chrome-Browsereinstellungen oder aktualisieren Sie die Seite.",
    pushManagerError:
      "Kein Push-Abonnement, schließen Sie die App und öffnen Sie sie erneut. Andernfalls installieren Sie sie bitte neu, um Benachrichtigungen zu erhalten.",
    pushManagerSubscribed: "Push-Manager: abonniert",
    pwaInstalled: "PWA installiert ",
    reestablishPermission:
      "Wenn Sie dieser App zuvor die Benachrichtigungsberechtigung verweigert haben, sollten Sie die Benachrichtigungen wieder aktivieren, indem Sie in Ihren Einstellungen Berechtigungen zulassen und die App schließen und erneut öffnen. Wenn das nicht funktioniert, installieren Sie es bitte neu und stellen Sie sicher, dass Sie im ersten Schritt Berechtigungen zulassen.",
    requestNotificationPermissionButton:
      "Fordern Sie eine Benachrichtigungserlaubnis an",
    serviceWorkerState: "Servicemitarbeiter:",
    serviceWorkerSucess: "Servicemitarbeiter installiert und aktiv",
    settingsTitle: "PWA (Beta)",
    showDebugSwitchLabel: "Debug-Informationen anzeigen",
    stepOne: "Schritt 1, App auf dem Startbildschirm installieren",
    stepThree: "Schritt 3: Recare-Benachrichtigungen zulassen",
    stepTwo: "Schritt 2, Benachrichtigungen zulassen",
    subheading:
      "Um die Recare-App auf Ihrem Mobilgerät zu installieren und Benachrichtigungen zu erhalten, befolgen Sie bitte die folgenden Schritte",
    subscribeToRecareNotifications: "Abonnieren Sie Recare-Benachrichtigungen",
    testNotification: "Ich bin eine Testbenachrichtigung",
    testNotificationButton: "Testbenachrichtigung",
    thankYouForInstalling:
      "Vielen Dank für die Installation der App. Bitte öffnen Sie Recare über Ihre installierten Apps, um fortzufahren",
    title: "PWA-Einstellungen",
    updateServiceWorker:
      "Klicken Sie hier, um den Servicemitarbeiter zu aktualisieren",
  },
  recare: {
    signature:
      "Recare Deutschland GmbH\nAlt-Moabit 103\n10559 Berlin\ninfo@recaresolutions.com\n+49 30 56 79 60 57",
  },
  rehaOnboarding: {
    companyDetails: {
      copyAndPaste: "Informationen kopieren und einfügen",
      deleteSpecialization: "Fachabteilung löschen",
      hintCompanyMailAddress:
        "Sofern eine zentrale E-Mail-Adresse der Einrichtung (z.B. info@, kontakt@) verfügbar ist, können Sie diese hier für die Fachabteilung aktivieren. Bitte beachten Sie, dass diese E-Mail-Adresse nur Anfragen erhält und kein Benutzerkonto sein kann.",
      hintCompanyName: "Sie erstellen einen Account für {select: companyName}",
      hintCopyAndPaste:
        "Verwenden Sie “Informationen kopieren und einfügen”, um identische Informationen, die Sie bereits für eine andere Fachabteilung hinzugefügt haben, einzufügen.",
      hintSpecializations:
        "Bitte geben Sie Ihre Fachabteilungen an und füllen Sie jedes Formular aus.",
      modalCopyAndPasteTitle:
        "Aus welcher Fachabteilung möchten Sie Informationen abrufen?",
      modalNothingFilledYet: "(Noch nicht ausgefüllt)",
      modalTitleFillTheFormFor: "Formular für ",
      specializationsTitle: "Ihre Fachabteilungen",
      title: "Erstellen Sie Ihr Recare-Konto",
      toastCompleteForms:
        "Bitte vervollständigen Sie alle Formulare der jeweiligen Fachabteilung.",
    },
    congratulations: {
      contactUs:
        "Kontaktieren Sie uns unter +49 30 56 79 60 57, falls Sie weitere Fragen haben.",
      infoText:
        "Wir haben alle Ihre Informationen erhalten. Sie werden anschließend eine E-Mail mit der Bestätigung erhalten.",
      thankYou:
        "Vielen Dank, wir freuen uns auf eine erfolgreiche Zusammenarbeit!",
      title: "Herzlichen Glückwunsch!",
      yourTeam: "Ihr Recare-Team",
    },
    copyPasteFabHint:
      "Übertragen Sie bereits eingegebene Daten automatisch in dieses Formular",
    statusCompleted: "Abgeschlossen",
    statusNotFilled: "Klicken Sie auf den Pfeil, um das Formular auszufüllen",
    statusNotFillNoExpansion: "Klicken Sie hier, um das Formular auszufüllen",
    yourTeam: {
      contactRole:
        "Dieser Mitarbeiter soll den Kliniken als Kontaktperson angezeigt werden",
      deleteEmployee: "Mitarbeiter löschen",
      hintEmail:
        "Dies ist die persönliche E-Mail-Adresse, um sich bei Recare später einloggen zu können.",
      hintEmployee: "Bitte fügen Sie sich und weitere Angestellte hinzu.",
      mailPlaceholderText: "Persönliche E-Mail eingeben",
      modalFinishSubtitle:
        "Bitte bestätigen Sie die korrekte Angabe der Daten oder geben Sie weitere Mitarbeiter und/oder Fachabteilungen ein. Ihre bisher eingegebenen Daten sind bereits gespeichert.  Nach der Bestätigung des Onboardings können Sie in den Einstellungen der einzelnen Einrichtungen weitere Mitarbeiter hinzufügen. Um weitere Fachabteilungen im Nachgang aufschalten zu wollen, müssen Sie Recare kontaktieren.",
      modalFinishTitle: "Sie haben das Onboarding abgeschlossen",
      placeholderEmployee: (args: { countOfcreatedEmployee: string }) =>
        template("Mitarbeiter #{{countOfcreatedEmployee}}", args),
      title: "Mitarbeiter Ihrer Einrichtung",
      titleAccess: "Dieser Mitarbeiter hat Zugriff auf",
      toastCompleteEmployee:
        "Bitte fügen Sie mindestens einen Mitarbeiter hinzu, löschen Sie nicht ausgefüllte Mitarbeiter-Profile oder vervollständigen Sie alle Formulare.",
    },
  },
  report: {
    activeProviders: "Aktive Versorger",
    allSearches: "Alle gestarteten Suchen",
    attribution: "Erfolgreiche Vermittlung",
    automaticSuccesses: "Automatische, erfolgreiche Vermittlungen",
    cancelled: "Abgebrochene",
    cancelledSearches: "Abgebrochene Suchen",
    created: "Gestartete",
    descAttribution:
      "Zeit ab Start einer Suche bis zur erfolgreichen Vermittlung (Median)",
    descHours: "Zu Geschäftszeiten zwischen: 07-17 Uhr",
    descOmission:
      "Suchen, für die mehrere Arten der Überleitung ausgewählt wurden, werden nicht berücksichtigt",
    descPositive:
      "Zeit ab Start einer Suche bis zur ersten positiven Rückmeldung (Median)",
    dischargedWithoutProvider: "Ohne Versorger entlassen",
    endDate: "Enddatum",
    inProgress: "Laufende",
    inProgressSearches: "Zum Zeitpunkt laufende Suchen",
    manualSuccesses: "Manuelle, erfolgreiche Vermittlungen",
    numPatients: "Anzahl der vermittelten Patienten",
    other: "Sonstiges",
    patientCreated: "Profile, für die eine Suche gestartet wurde:",
    percentagePatients: "Anteil der vermittelten Patienten",
    placeholderOnlyAvailableInProd:
      "Bericht ist nur in der Live-Umgebung verfügbar",
    positiveResponse: "Erste positive Rückmeldung",
    providerFoundWithoutRecare:
      "Außerhalb von Recare gefundener Versorger ausgewählt",
    providerFoundWithRecare: "Von Recare gefundener Versorger ausgewählt",
    providerName: "Name des Versorgers",
    providersPerSolution: (args: { radius: string }) =>
      template(
        "Anzahl der aktiven Versorger im Umkreis von {{radius}}km",
        args,
      ),
    reason: "Ausgewählter Grund",
    relevant: "Relevante",
    relevantSearches: "Abgeschlossene Suchen",
    relevantSearchesStatusTitle: "Abgeschlossene Suchen nach Status:",
    repartitionSolutionsTitle: "Verteilung der Suchen nach Art der Überleitung",
    reportCreated: (args: { date: string }) =>
      template("Bericht (erstellt am {{date}})", args),
    solution: "Art",
    startDate: "Startdatum",
    thirdPartyReasons: "Abbruchgründe",
    timeToSuccessTitle: "Dauer einer Vermittlung nach Art der Überleitung",
    total: "Summe",
    transferSuccessesTitle:
      "Automatische und manuelle Vermittlungen von Patienten an:",
    unknown: "Nachversorger-Name ist unbekannt",
    unknownAll: (args: { receiverTypeSingular: string }) =>
      template("{{receiverTypeSingular}}-Name ist unbekannt", args),
  },
  requestEvents: {
    addedTheProvider: {
      myAccount: (args: { providerName: string }) =>
        template("Sie haben {{providerName}} hinzugefügt", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template("{{accountName}} hat {{providerName}} hinzugefügt", args),
    },
    answerProviderMessage: "Bitte beantworten Sie die Nachricht",
    canceledAcceptance: (args: { providerName: string }) =>
      template("{{providerName}} hat die Annahme rückgängig gemacht.", args),
    canceledDeclination: (args: { providerName: string }) =>
      template("{{providerName}} hat die Ablehnung rückgängig gemacht", args),
    canceledProviderRejection: {
      myAccount: (args: { providerName: string }) =>
        template(
          "Sie haben die Ablehnung von {{providerName}} rückgängig gemacht",
          args,
        ),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template(
          "{{accountName}} hat die Ablehnung von {{providerName}} rückgängig gemacht",
          args,
        ),
    },
    canceledProviderValidation: {
      myAccount: (args: { providerName: string }) =>
        template(
          "Sie haben die Annahme von {{providerName}} rückgängig gemacht",
          args,
        ),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template(
          "{{accountName}} hat die Annahme von {{providerName}} rückgängig gemacht",
          args,
        ),
    },
    mailBounced: "Anfrage konnte nicht zugestellt werden.",
    providerAcceptedTheRequest: (args: { providerName: string }) =>
      template("{{providerName}} hat die Anfrage angenommen", args),
    providerDeclinedRequest: (args: { providerName: string }) =>
      template("{{providerName}} hat diese Anfrage abgelehnt", args),
    rejectedThisProvider: {
      myAccount: (args: { providerName: string }) =>
        template("Sie haben {{providerName}} abgelehnt", args),
      otherAccount: (args: { providerName: string; accountName: string }) =>
        template("{{providerName}} wurde von {{accountName}} abgelehnt", args),
    },
    removedSolution: "Art der Überleitung wurde entfernt",
    requestAccepted:
      "Anfrage wurde angenommen. Bitte nehmen Sie die Antwort an oder lehnen Sie ab, indem Sie auf eins der Auswahlfelder klicken.",
    requestedPatientData: {
      myAccount: "Sie haben mehr Informationen zum Patienten angefragt",
      otherAccount: (args: { accountName: string }) =>
        template(
          "{{accountName}} hat mehr Informationen zum Patienten angefragt",
          args,
        ),
    },
    requestSent: (args: { careprovider: string }) =>
      template("Anfrage an {{careprovider}} versendet", args),
    requestSomeMailBounced: (args: { count: string; total: string }) =>
      template(
        "Nur {{count}} von {{total}}  Anfragen konnten per E-Mail zugestellt werden",
        args,
      ),
    sentaFile: {
      myAccount: "Sie haben eine Datei gesendet",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat eine Datei gesendet", args),
    },
    sharedPatient: {
      automatic: (args: { providerName: string }) =>
        template(
          "Die Stammdaten für {{providerName}} wurden freigegeben",
          args,
        ),
      myAccount: (args: { providerName: string }) =>
        template(
          "Sie haben die Stammdaten für {{providerName}} freigegeben",
          args,
        ),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template(
          "{{accountName}} hat die Stammdaten für {{providerName}} freigegeben",
          args,
        ),
    },
    updatedPatient: {
      myAccount: "Sie haben das Patientenprofil geändert",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat das Profil geändert", args),
    },
    updatedPatientRuledOut:
      "Das aktualisierte Profil passt nicht mehr zum Leistungsspektrum, da folgendes geändert wurde:",
    validatedThisProvider: {
      myAccount: (args: { providerName: string }) =>
        template("Sie haben {{providerName}} angenommen", args),
      otherAccount: (args: { providerName: string; accountName: string }) =>
        template("{{providerName}} wurde von {{accountName}} angenommen", args),
    },
    waitingForProviderAnswer:
      "Die Anfrage wurde noch nicht angenommen oder abgelehnt",
    wrote: {
      myAccount: "Sie haben eine Nachricht gesendet",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat eine Nachricht gesendet", args),
    },
  },
  responseEvents: {
    acceptedMultiSolution: {
      myAccount: "Sie bieten Folgendes an: ",
      otherAccount: (args: { providerName: string }) =>
        template("{{providerName}} bietet Folgendes an: ", args),
    },
    acceptedTheRequest: {
      myAccount: "Sie haben diese Anfrage angenommen",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat diese Anfrage angenommen", args),
    },
    answerTheRequest: "Bitte beantworten Sie diese Anfrage",
    askAQuestion: "Frage stellen",
    aUser: "ein Benutzer",
    canceledAcceptance: {
      myAccount: "Sie haben die Annahme rückgängig gemacht",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat die Annahme rückgängig gemacht", args),
    },
    canceledDeclination: {
      myAccount: "Sie haben die Ablehnung rückgängig gemacht.",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat die Ablehnung rückgängig gemacht.", args),
    },
    clinicCanceledAttribution: (args: { accountName: string }) =>
      template("{{accountName}} hat Ihre Annahme rückgängig gemacht", args),
    clinicCanceledAttributionWithReason: (args: {
      accountName: string;
      reason: string;
    }) =>
      template(
        "{{accountName}} hat Ihre Annahme rückgängig gemacht: {{reason}}",
        args,
      ),
    declinedRequest: {
      myAccount: "Sie haben diese Anfrage abgelehnt",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat diese Anfrage abgelehnt", args),
    },
    patientAttributedToYou: "Patient wurde Ihnen zugewiesen",
    requestArchivedAutomatically: "Diese Anfrage wurde automatisch archiviert",
    requestArchivedManually: {
      myAccount: "Sie haben die Anfrage archiviert.",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat diese Anfrage archiviert.", args),
      tools: "Der Antrag wurde archiviert",
      undo: {
        myAccount: "Sie haben die Archivierung rückgängig gemacht.",
        otherAccount: (args: { accountName: string }) =>
          template(
            "{{accountName}} hat die Archivierung rückgängig gemacht.",
            args,
          ),
        tools: "Die Anfrage wurde nicht archiviert",
      },
    },
    requestReceived: (args: { socialWorker: string }) =>
      template("Anfrage von {{socialWorker}} erhalten", args),
    searchArchivedAutomatically: "Dieser Patient wurde automatisch archiviert",
    waitingForClinic: "Rückmeldung ausstehend",
  },
  search: {
    accepted: "Angenommen",
    accessibility: {
      searchLabel: "Suche nach Anbieter",
    },
    accessToPatientDataGrantedTooltip: "Stammdaten wurden freigegeben",
    accessToPatientDataNotGrantedTooltip:
      "Nachversorger hat keinen Zugang zu Patienten-Stammdaten",
    actionRequiredBannerTitle: "Aktion erforderlich",
    addPreferredProvider: (args: { receiverTypeSingularPreferred: string }) =>
      template("{{receiverTypeSingularPreferred}} hinzufügen", args),
    addProvider: {
      addAnother: "Weitere hinzufügen",
      care: "Nachversorger hinzufügen",
      contactProviders: "Kontaktieren",
      geriatrics: "Wunschklinik hinzufügen",
      noProviderSelected: "Bitte Empfänger auswählen",
      restartedMovedToStoppedSunsetCostpayerflow:
        "Ihre erneut gestartete Suche wurde aufgrund der Abschaltung der Möglichkeit, AHB-/Reha-Platz-Suchen an Kostenträger zu übergeben, automatisch wie angekündigt zum 01.04.2023 gestoppt.  ",
      successfullySentRequests: (args: { numRequests: string }) =>
        template(
          "{{numRequests}} Empfänger wurden erfolgreich kontaktiert",
          args,
        ),
      successfullySentSingleRequest: "Empfänger wurde erfolgreich kontaktiert",
    },
    allContacted: (args: { receiverTypePlural: string }) =>
      template(
        "Alle passenden {{receiverTypePlural}} wurden kontaktiert",
        args,
      ),
    allContactedNotAllAnsweredTitle: {
      care: "Alle passenden Nachversorger wurden kontaktiert. Manche haben noch nicht geantwortet:",
      geriatrics:
        "Alle passenden Kliniken wurden kontaktiert. Manche haben noch nicht geantwortet:",
    },
    allContactedTitle: {
      care: "Alle passenden Nachversorger wurden kontaktiert",
      geriatrics: "Alle passenden Kliniken wurden kontaktiert",
    },
    allNegativeSendMore:
      "Alle Anfragen wurden abgelehnt. Um die Suche fortzusetzen können Sie mehr Anfragen versenden oder beenden Sie die Suche, wenn sie nicht mehr benötigt wird.",
    allNegativeSuggestMore:
      "Alle Anfragen wurden abgelehnt. Sie können jedoch weitere Wunschkliniken dem Kostenträger vorschlagen.",
    allProvidersRejected: {
      subtitle: "Bitte fügen Sie der Suche weitere Empfänger hinzu.",
      title: "Alle Anfragen wurden abgelehnt.",
    },
    answerSent: "Nachricht wurde versendet",
    archivePatientDescription:
      'Wenn Sie einen Patienten archivieren, können Sie diesen über den Reiter "Archiviert" auf Ihrer Übersichtsseite oder mittels der Suchfunktion finden.',
    auctionStatusStepper: {
      notStarted: "Suche noch nicht gestartet",
      providerConfirmed: "Nachversorger bestätigt",
      requestsSent: "Anfrage versendet",
      responseReceived: "Rückmeldung erhalten",
      searchStarted: "Suche wurde gestartet",
      waitingForResponse: "Warten auf Antwort",
    },
    cancelCardAction: "Rückgängig machen",
    cancelValidationPanel: {
      confirm: "Zuweisung rückgängig machen",
      hint: "Bitte beachten Sie, dass der unten angegebene Grund in der Dokumentation angezeigt wird.",
      placeholder: "Bitte erläutern Sie",
      title: "Warum möchten Sie die Zuweisung rückgängig machen?",
    },
    candidatesCalculating:
      "Potentielle Empfänger werden berechnet, bitte warten",
    canNotStart:
      "Bitte bearbeiten Sie das Profil, um die Suche zu starten. Alle Pflichtfelder müssen ausgefüllt werden.",
    cannotStartSearchNoCandidates: {
      care: "Es gibt keine potentiellen Nachversorger die kontaktiert werden können. Bitte vergewissern Sie sich, dass die Informationen korrekt angegeben wurden (z.B. Adresse).",
      geriatrics:
        "Es gibt keine potentiellen Kliniken die kontaktiert werden können. Bitte vergewissern Sie sich, dass die Informationen korrekt angegeben wurden (z.B. Adresse).",
    },
    careproviderSearch: "Nachversorger suchen",
    careproviderUnknown: {
      care: "Name des Nachversorgers ist unbekannt",
      geriatrics: "Name der Klinik ist unbekannt",
    },
    careproviderUnknownViaFamily: "Name des Nachversorgers ist unbekannt",
    chipPaused: "Suche pausiert",
    chosen: "Ausgewählt",
    confirmChooseProvider: (args: {
      careproviderName: string;
      patientId: string;
    }) =>
      template(
        "Möchten Sie die Wahl von {{careproviderName}} für {{patientId}} bestätigen?",
        args,
      ),
    confirmTitleAboutToValidate: (args: { careproviderName: string }) =>
      template("Sie sind dabei {{careproviderName}} anzunehmen.", args),
    contactedNotAllAnswered: (args: { receiverTypePlural: string }) =>
      template(
        "Alle passenden {{receiverTypePlural}} wurden kontaktiert. Manche haben noch nicht geantwortet:",
        args,
      ),
    contactedProviders: "Kontakiert",
    continueSearch: "Suche fortsetzen",
    declined: {
      care: "Nachversorger hat abgelehnt",
      geriatrics: "Von der Empfängerklinik abgelehnt",
    },
    draft: "Entwurf",
    draftMovedToStoppedSunsetCostpayerflow:
      "Ihre Entwurf wurde aufgrund der Abschaltung der Möglichkeit, AHB-/Reha-Platz-Suchen an Kostenträger zu übergeben, automatisch gestoppt und in beendet verschoben.  ",
    editAcceptedSearch:
      "Es ist möglich, die Angaben im Profil nach einer abgeschlossenen Suche zu bearbeiten:",
    editOrRestart:
      "Es ist auch möglich, das Profil zu bearbeiten oder die Suche erneut zu starten.",
    editProfileTitle: "Oder das Profil bearbeiten:",
    editProfileToBeStarted: "Sie können das Profil hier bearbeiten:",
    editRunningSearch:
      "Es ist möglich, die Angaben im Profil während einer laufenden Suche zu bearbeiten:",
    filteredProviders: "Ausgeschlossen",
    foundProviderTitle: (args: { receiverTypeSingular: string }) =>
      template("{{receiverTypeSingular}} wurde ausgewählt", args),
    goToSearch: "zur Suche",
    grantAccessToFollowing:
      "Sie können nun dem ausgewählten Empfänger folgende Patientendaten freigeben:",
    handoverSearchTitle:
      "Ist das Patientenprofil vollständig? Sie können die Suche nun dem Kostenträger übergeben:",
    howToStartSearchHint:
      'Um die Suche zu starten, können Sie entweder manuell Versorger aus der Liste auswählen, oder  auf "alle kontaktieren" klicken, um alle potenziellen Versorger zu kontaktieren.  Sie können auch nach bestimmten Versorgern suchen, indem Sie deren Namen in die Suchleiste eingeben.',
    infoAutomaticVSManual:
      'Diese Versorger wurden noch nicht kontaktiert. Sie können eine begrenzte Anzahl von Versorgern kontaktieren, indem Sie sie aus der Liste auswählen und auf "Ausgewählte kontaktieren" klicken. Um alle potenziellen Anbieter zu kontaktieren, wählen Sie bitte "alle kontaktieren".',
    manuallyAdded: "Manuell hinzugefügt",
    manuallyRejected: "Manuell abgelehnt",
    manuallyRunning: "Manuelle Suche läuft",
    manualNewAnswersBanner:
      "Derzeit werden nur Kliniken kontaktiert, die manuell ausgewählt wurden.",
    manualNoPendingRequestsTitle: "Alle Anfragen wurden abgelehnt",
    manualSearchInProgressTitle: "Laufende manuelle Suche",
    manualTransportBanner:
      "Für die manuelle Transportsuche ist derzeit nur ein Anbieter zugelassen. Wenn Sie mehr als einen Anbieter kontaktieren möchten, wählen Sie bitte die automatische Suche.",
    maximumAcceptsReached: (args: { receiverTypePlural: string }) =>
      template(
        "Mehrere {{receiverTypePlural}} haben bereits angenommen! Um Ihnen zusätzliche Arbeit zu ersparen, versenden wir vorerst keine weiteren Anfragen. Sobald Sie eine oder mehrere Anfragen ablehnen, werden weitere Anfragen versendet.",
        args,
      ),
    maximumAcceptsReachedSearchMerge: (args: { receiverTypePlural: string }) =>
      template(
        "Mehrere {{receiverTypePlural}} warten auf Ihre Antwort. Bitte beantworten Sie mindestens eine der Rückmeldungen, damit der Patient wieder für alle kontaktierten Nachversorger verfügbar ist.",
        args,
      ),
    noCandidatesCannotManuallySearch: (args: { receiverTypePlural: string }) =>
      template(
        "Es gibt keine potenziellen {{receiverTypePlural}} zum Kontaktieren. Die Suche kann weiterhin gestartet und Empfänger gefunden werden, wenn diese verfügbar sind.",
        args,
      ),
    noCandidatesCannotSearch: (args: { receiverTypePlural: string }) =>
      template(
        "Es gibt keine potentiellen {{receiverTypePlural}} die kontaktiert werden können. Bitte vergewissern Sie sich, dass die Informationen korrekt angegeben wurden (z.B. Adresse).",
        args,
      ),
    noCandidatesStartSearch: (args: { receiverTypePlural: string }) =>
      template(
        "Es gibt keine potentiellen {{receiverTypePlural}} die kontaktiert werden können. Die Suche kann trotzdem gestartet und Empfänger manuell hinzugefügt werden.",
        args,
      ),
    noPotentialReceiversBanner: (args: { receiverTypePlural: string }) =>
      template(
        "Um weitere verfügbare {{receiverTypePlural}} zu finden versenden Sie Anfragen manuell, ändern Sie den Ausgangspunkt der Suche, erhöhen Sie den Suchradius, bearbeiten Sie das Patientenprofil, oder kontaktieren Recare für Unterstützung.",
        args,
      ),
    noPotentialReceiversNoRequestSentBanner: {
      care: "Um weitere, verfügbare Nachversorger zu finden, versenden Sie Anfragen manuell, ändern den Ausgangspunkt der Suche, erhöhen den Suchradius oder kontaktieren Recare für Unterstützung.",
      geriatrics:
        "Um verfügbare Kliniken zu finden, versenden Sie Anfragen manuell, ändern den Ausgangspunkt der Suche, erhöhen den Suchradius oder kontaktieren Recare für Unterstützung",
    },
    noSuggestionsYet: {
      insurance:
        "Das Krankenhaus hat keine Wunschklinik angegeben. Bitte starten Sie die Suche.",
      shareFiles:
        "Während Sie warten: {select:Klicken sie hier} um Dokumente mit der Versicherung zu teilen",
      socialWorker:
        "Die Suche wurde an den Kostenträger übergeben. Bitte warten Sie auf die Rückmeldung oder teilen Sie eine Wunschklinik mit.",
    },
    notActiveTitle: "Nicht auf Recare aktiv",
    notStarted: "Noch nicht gestartet",
    notStartedYet: "Die Suche wurde noch nicht gestartet",
    notStartedYetHints: {
      insurance:
        "Klicken Sie auf der rechten Seite auf SUCHE STARTEN um erste Anfragen an passende Kliniken zu versenden oder überprüfen Sie gegebenenfalls vom Krankenhaus vorgeschlagene Wunschkliniken",
      socialWorker:
        "Bitte warten Sie bis der Kostenträger die Suche gestartet und die ersten Anfragen versendet hat",
    },
    onboarding: "Wird derzeit im Recare-System aufgenommen",
    oneAcceptInfoBanner:
      "Bitte bestätigen oder verwerfen Sie die Annahme des Empfängers, um den Patienten wieder für andere Empfänger verfügbar zu machen",
    patientPreferred: {
      dataNotEncrypted:
        "Das Feld ist nicht verschlüsselt. Bitte geben Sie keine sensiblen Informationen ein.",
      markAs: "Als Patientenwunsch kennzeichnen",
      placeholder: "Begründung des Patientenwunsches",
      reason: "Grund (optional)",
      reasonNotEmpty: "Bitte fügen Sie zuerst Ihren neuen Grund hinzu",
      tooltipRemovePatientPreferred: "Kennzeichnung entfernen",
    },
    patientWishInfo: "Patientenwünsche sind mit einem Stern gekennzeichnet.",
    patientWishInfoIcon: "Patientenwunsch",
    pause: "Suche Pausieren",
    paused: "Pausiert",
    pauseDeprecated:
      "Bitte beachten Sie, dass das Pausieren der Suche nicht mehr erforderlich ist, da alle potenziellen Anbieter kontaktiert worden sind.",
    pauseDeprecatedInformation:
      "Aufgrund von Verbesserungen an der Suchfunktion ist es nicht mehr möglich, Suchen zu pausieren.",
    pausedMovedToStoppedSunsetCostpayerflow:
      "Ihre pausierte Suche wurde aufgrund der Abschaltung der Möglichkeit, AHB-/Reha-Platz-Suchen an Kostenträger zu übergeben, automatisch gestoppt.  ",
    pausedTitle: "Suche wurde pausiert",
    pauseTitle: "Sind Sie sich sicher, dass Sie die Suche pausieren möchten?",
    pauseWarning:
      "Solange eine Suche pausiert ist, werden keine Anfragen mehr automatisch versendet, aber auf bereits versandte Anfragen können Sie und der Empfänger weiterhin reagieren und beantworten. Sie können jedoch jederzeit weitere Anfragen gezielt versenden.\nUm Anfragen erneut automatisch zu versenden, können Sie die Suche jederzeit wieder starten.",
    pending: "Aktion ausstehend",
    potentialProviders: "Potenzielle Nachversorger",
    printConfirmation: "Bestätigung drucken",
    providerFound: "Empfänger gefunden",
    providerFoundTitle: {
      care: "Nachversorger wurde ausgewählt",
      geriatrics: "Klinik wurde ausgewählt",
    },
    providersWhoAccepted: "Angenommen",
    providersWhoDeclined: "Vom Nachversorger abgelehnt",
    providerUnknown: (args: { receiverTypeSingular: string }) =>
      template("{{receiverTypeSingular}}-Name unbekannt", args),
    receiverDecliner: (args: { receiverTypeSingular: string }) =>
      template("{{receiverTypeSingular}} hat abgelehnt", args),
    receiverValidatedEligibilityRejected: {
      insurance:
        "Folgender Empfänger wurde ausgewählt, aber die Kostenübernahme wurde abgelehnt. Bitte stoppen Sie die Suche oder kontaktieren Sie das Krankenhaus.",
      socialWorker:
        "Folgender Empfänger wurde ausgewählt, aber die Kostenübernahme wurde durch den Kostenträger abgelehnt. Bitte stoppen Sie die Suche oder kontaktieren Sie den Kostenträger für mehr Informationen.",
    },
    receiverValidatedWaitingEligibility: {
      insurance:
        "Folgender Empfänger wurde ausgewählt. Um den Fall abzuschließen, muss der Status der Kostenübernahme noch aktualisiert werden.",
      socialWorker:
        "Folgender Empfänger wurde ausgewählt. Um den Fall abzuschließen, muss der Status der Kostenübernahme noch aktualisiert werden.",
    },
    rejected: "Abgelehnt",
    rejectedTitle: "Abgelehnt",
    restartSearch: "Suche erneut starten",
    reviewRequest: "PRÜFEN",
    running: "Suche läuft",
    search: "Suche",
    searchHasBeenRunningFor:
      "Die Suche läuft bereits seit 2 Stunden. Es gibt immer noch keine positiven Antworten.",
    searchInProgress: "Laufende Suchanfragen",
    searchInProgressTitle: "Laufende Suche",
    searchMergeNoProviders: "",
    searchPageTables: {
      accessibility: {
        clickFunctionality: (args: {
          providerName: string;
          tableName: string;
        }) =>
          template(
            "Klicken Sie, um die Antwortseite für {{providerName}} aus der Tabelle {{tableName}} anzuzeigen",
            args,
          ),
        deselectAll:
          "Klicken, um alle Nachversorger in der Tabelle Potentielle Empfänger abzuwählen und Nachversorger einzeln hinzufügen zu können",
        deselectProvider: (args: { providerName: string }) =>
          template(
            "Klicken Sie, um {{providerName}} in der Tabelle Potentielle Empfänger abzuwählen",
            args,
          ),
        patientWishAccessibility: {
          addReason:
            "Klicken, um optional einen Grund für den Patientenwunsch anzugeben",
          cancelReason: "Klicken, um Eingabe abzubrechen",
          checkedStar: (args: { providerName: string }) =>
            template(
              "Klicken, um {{providerName}} als Patientenwunsch zu entfernen",
              args,
            ),
          confirmReason: "Klicken, um Eingabe zu speichern",
          disabled:
            "Der Patientenwunsch kann nicht bearbeitet werden, da dieses Feld derzeit deaktiviert ist.",
          editReason: "Klicken, um eingegebenen Patientenwunsch zu bearbeiten",
          uncheckedStar: (args: { providerName: string }) =>
            template(
              "Klicken, um {{providerName}} als Patientenwunsch zu markieren",
              args,
            ),
        },
        selectAll:
          "Klicken, um alle Empfänger in der Tabelle Potentielle Empfänger auszuwählen",
        selectAllIntermediary:
          "Klicken, um alle Empfänger in der Tabelle Potentielle Empfänger auszuwählen",
        selectProvider: (args: { providerName: string }) =>
          template(
            "Klicken Sie, um {{providerName}} in der Tabelle Potentielle Empfänger auszuwählen",
            args,
          ),
        sortingMenu: {
          sortByDefault:
            "Klicken Sie auf Standard, um die Anbieter standardmäßig zu sortieren",
          sortByDistance:
            "Klicken Sie auf Entfernung, um die Anbieter nach Entfernung von nah bis fern zu sortieren",
          sortByName:
            "Klicken Sie auf Namen, um nach Namen zu sortieren und die Anbieter alphabetisch aufzulisten",
          sortByStatus:
            "Klicken Sie auf Status, um die Anbieter je nach Status aufzulisten",
          title: "Klicken Sie hier, um die aktuelle Sortierung zu ändern",
        },
      },
      actionMenu: {
        acceptRequest: "Accept",
        declineRequest: "Decline",
        dismissSuggestedProvider: "Dismiss suggested provider",
        giveAccessToData: "Give access to patient data",
        nextRequest: "Next request",
        openRequest: "Open",
        rejectProvider: "Reject",
        sendFax: "Send fax",
        sendFile: "Send File",
        sendMessage: "Send message",
        sendRequestToSuggestedProvider: "Send request to suggested provider",
        setReceiverAssignee: "Set assignee",
        undoAcceptRequest: "Undo acceptance",
        undoDeclineRequest: "Undo declination",
        undoDismissRejectedProvider: "Undo dismissal of suggested provider",
        undoManuallyArchiving: "Undo archiving",
        undoRejectProvider: "Undo rejection",
        undoSuggestProvider: "Undo suggestion",
        validateProvider: "Validate",
      },
      columnNames: {
        dateOfRequest: "Anfragedatum",
        declineDate: "Ablehnungsdatum",
        declineReason: "Ablehnungsgrund",
        distance: "Entfernung zum Patienten",
        filterReason: "Filtergrund",
        hint: {
          patientWish:
            "In dieser Spalte können Sie das Patientenwunsch- und Wahlrecht übermitteln, solange die Anfrage noch nicht beantwortet wurde.",
        },
        internalRejectReason: "Interner Ablehnungsgrund",
        location: "Ort",
        potentialStartDate: "Mögliches Startdatum",
        providerName: "Nachversorger",
        rejectDate: "Ablehnungsdatum",
        rejectReason: "Ablehnungsgrund",
        solutions: "Art der Überleitung",
        startDate: "Startdatum",
        status: "Status",
      },
      distanceToPatient: (args: { distance: string }) =>
        template("{{distance}} entfernt", args),
      key: "key",
      manuallyAssignedWinner: {
        dataNotAvailable: "Unbekannt",
        internalTransfer: "Interne Überweisung",
        unknownProvider: "Unbekannter Empfänger",
      },
      modals: {
        contactAnyway: {
          subtitle:
            'Klicken Sie auf "Bestätigen", um den Nachversorger anzufragen.',
          subtitleFax:
            "Bitte beachten Sie, dass dieser Anbieter nicht auf Recare aktiv ist, sondern per Fax kontaktiert wird.",
          title:
            "Möchten Sie diesem Nachversorger eine Anfrage zukommen lassen?",
        },
        sendFax: {
          buttonCancel: "Abbrechen",
          buttonConfirm: "Ich verstehe das Risiko",
          buttonSend: "Jetzt senden",
          subtitle:
            "Wir empfehlen, nach Möglichkeit  alternative Kommunikationsmethoden mit einem höheren Datenschutzstandard zu wählen. Auf der Recare-Plattform gelistete Anbieter können auch sicher über unseren verschlüsselten Messenger kontaktiert werden.",
          title: "Dieser Nachversorger ist nicht Teil der Recare-Plattform.",
          titleConfirmation: (args: { providerName: string }) =>
            template(
              "Sind Sie sicher, dass Sie ein Fax an {{providerName}} senden möchten? ",
              args,
            ),
          warning:
            "Bitte beachten Sie, dass der Versand eines Faxes die am wenigsten sichere Lösung im Hinblick auf die Einhaltung des Datenschutzes ist.",
        },
      },
      pagination: {
        next: "Next page ",
        previous: "Previous page",
      },
      paginationSelectLabel: "Ergebnisse pro Seite",
      placeholderNoResults: "Es liegen noch keine Ergebnisse vor",
      potentialTableActions: {
        contactAll: "Contact all",
        contactSelected: "Contact selected",
        infoText:
          "These providers have not been contacted. You can contact a limited pool of providers by selecting them from the list and clicking on “contact selected”. To contact every potential provider please select “contact all”.",
        requestPreferredProvider: "Request preferred provider",
      },
      printModalTitle: "Welche Abschnitte möchten Sie drucken?",
      printModalTooManyWarning: (args: { maxCount: string }) =>
        template(
          "Wir können nur bis zu {{ maxCount }} Anbieter pro Abschnitt drucken",
          args,
        ),
      sortBy: "Sortieren nach",
      sorting: {
        default: "Standard",
        distance: "Entfernung",
        name: "Name",
        status: "Status",
      },
      status: {
        accepted: "Angenommen",
        faxSent: "Fax verschickt",
        newMessage: "Neue Nachricht",
        notSeen: "Noch nicht gelesen",
        providerInactive: "Inaktiv",
        seen: "Gelesen",
      },
      tableNames: {
        accepted: (args: { total: string }) =>
          template("Angenommen ({{total}})", args),
        contacted: (args: { total: string }) =>
          template("Kontaktierte Nachversorger ({{total}})", args),
        declined: (args: { total: string }) =>
          template("Versorgung nicht möglich ({{total}})", args),
        filtered: (args: { total: string }) =>
          template("Ausgeschlossene Nachversorger ({{total}})", args),
        potential: (args: { total: string }) =>
          template("Potentielle Empfänger ({{total}})", args),
        rejected: (args: { total: string }) =>
          template("Abgelehnt ({{total}})", args),
        subtitle: {
          contactedTable:
            "Von den hier aufgeführten Anbietern gab es noch keine Rückmeldung in Form einer Zu- oder Absage.",
          filteredTable:
            "In dieser Tabelle sind alle Anbieter aufgelistet, die aufgrund Ihrer Suchkriterien herausgefiltert wurden oder weil sie noch nicht Teil des Recare-Netzwerks sind.",
          potentialReceiversTable:
            "Klicken Sie auf das oberste Ankreuzfeld, um alle Nachversorger auszuwählen oder die Auswahl aufzuheben, oder wählen Sie einzelne Nachversorger aus, indem Sie auf das Ankreuzfeld neben dem Namen des Nachversorgers klicken",
          rejectedTable:
            'Übersicht über alle von Ihnen abgelehnten Nachversorger. Wenn Sie die Ablehnung rückgängig machen wollen, klicken Sie auf den Namen des Nachversorgers und anschließend im Chat auf "Rückgängig".',
        },
        validated: "Nachversorger wurde ausgewählt",
      },
    },
    searchPausedArchiveTitle:
      "Sie haben die Suche beendet. Möchten Sie diesen Patienten archivieren?",
    searchPausedTitle: "Diese Suche wurde beendet",
    searchPausedWarning:
      "Bitte beachten Sie, dass zuvor kontaktierte Nachversorger nicht explizit über diese Änderungen informiert werden.",
    searchUpdatedNotification:
      "Die Aktualisierung Ihrer Patientendaten kann sich auf die Auswahl der kontaktierten Pflegedienstleister auswirken.",
    sendRequestCta: {
      contactAnyway: "Trotzdem Kontakt",
      contactAnywayAndStartSearch: "Suche starten und Anfrage senden",
      contactAnywaySearchNotStarted: "Nachversorger trotzdem kontaktieren",
      contactAnywaySearchRunning: "Nachversorger anfragen",
      contactAnywaySearchStopped:
        "Sie müssen die Suche neu starten, um Anfragen senden zu können",
      contactAnywaySearchWon:
        "Anfragen können nicht gesendet werden, nachdem der Patient zugeordnet wurde",
      sendFaxAndStartSearch: "Suche starten und Fax senden",
    },
    sent: "Anfrage wurde versendet",
    sentTitle: "Versendet",
    setUpSearch: "Zur Suche",
    shareEncryptedData: {
      assessment: {
        checkbox: (args: { receiverTypePlural: string }) =>
          template(
            "Stammdaten automatisch mit {{receiverTypePlural}} teilen",
            args,
          ),
        title: "Freigabe von Patientendaten",
      },
      grantAccess: "Stammdaten freigeben",
      hint: "Alternativ können Sie dies zu einem späteren Zeitpunkt im jeweiligen Nachrichtenfenster nachholen. Sobald eine Zuweisung erfolgt, werden die Daten für alle anderen Empfänger erneut verschlüsselt.",
      later: "Später",
      title:
        "Möchten Sie den ausgewählten Empfängern die Stammdaten übermitteln?",
    },
    showMore: "Mehr anzeigen",
    smartSuggestions: {
      autoSearchSelectedAll: (args: { receiverTypePlural: string }) =>
        template(
          "An ausgewählte {{receiverTypePlural}} und automatisch an weitere potentielle Empfänger",
          args,
        ),
      autoSearchSelectedCare:
        "An präferierte und weitere, von Recare vorgeschlagene Nachversorger",
      autoSearchSelectedRehab:
        "An Wunschkliniken und weitere, von Recare vorgeschlagene Kliniken",
      distanceFromPatient: (args: { distance: string }) =>
        template("{{distance}} Entfernung", args),
      hintManuallyAddedAll: (args: { receiverTypeSingular: string }) =>
        template(
          "Sie haben diesen {{receiverTypeSingular}} manuell hinzugefügt.",
          args,
        ),
      hintManuallyAddedCare:
        "Sie haben diesen Nachversorger manuell hinzugefügt.",
      hintManuallyAddedRehab:
        "Sie haben diese Wunschklinik manuell hinzugefügt.",
      hintManuallyAddedShort: "Manuell hinzugefügt",
      manualSearchSelectedAll: (args: { receiverTypePlural: string }) =>
        template("Nur an ausgewählte {{receiverTypePlural}}", args),
      manualSearchSelectedCare: "Nur an präferierte Nachversorger",
      manualSearchSelectedRehab: "Nur an Wunschkliniken",
      markAsPatientWish: "Als Patientenwunsch kennzeichnen",
      panelAllTitleManualAndPreferred: (args: { receiverTypePlural: string }) =>
        template(
          "Fügen Sie {{receiverTypePlural}} zur Suche hinzu und kennzeichnen Sie Patientenwünsche",
          args,
        ),
      panelAllTitleManualAndPreferredAb: (args: {
        receiverTypeSingular: string;
      }) =>
        template(
          "Markieren Sie die Checkbox, um einen bestimmten {{receiverTypeSingular}} zu kontaktieren (Mehrfachauswahl möglich), oder dokumentieren Sie den Patientenwunsch durch Klick auf den Stern",
          args,
        ),
      panelTitleAll: (args: { receiverTypePluralPreferred: string }) =>
        template("Wählen Sie {{receiverTypePluralPreferred}} aus ", args),
      panelTitleCare: "Präferierte Nachversorger auswählen (optional)",
      panelTitleRehab: "Wunschkliniken auswählen (optional)",
      panelTitleRehaInsurance:
        "Teilen Sie dem Kostenträger eine Wunschklinik mit",
      panelTitleRehaInsurancePayer: "Wählen Sie Reha-Einrichtungen aus",
      panelTitleRunningAll: (args: { receiverTypePlural: string }) =>
        template("Weitere Anfragen an {{receiverTypePlural}} senden", args),
      panelTitleRunningCare: "Weitere Anfragen an Nachversorger senden",
      panelTitleRunningCareAb: "",
      panelTitleRunningRehab: "Weitere Anfragen an Kliniken senden",
      searchSelectorHintAll: (args: { receiverTypePlural: string }) =>
        template(
          "Wenn nichts ausgewählt wird, werden alle potentiellen {{receiverTypePlural}} kontaktiert",
          args,
        ),
      searchSelectorHintCare:
        "Wenn nichts ausgewählt wird, werden alle weiteren potentiellen Nachversorger kontaktiert",
      searchSelectorHintRehab:
        "Wenn nichts ausgewählt wird, werden alle weiteren potentiellen Kliniken kontaktiert",
      searchSelectorOptionHintAll: (args: { receiverTypePlural: string }) =>
        template(
          "(Hinweis: zuvor kontaktierte {{receiverTypePlural}} werden weiterhin berücksichtigt)",
          args,
        ),
      searchSelectorOptionHintCare:
        "(Hinweis: zuvor kontaktierte Nachversorger werden weiterhin berücksichtigt)",
      searchSelectorOptionHintRehab:
        "(Hinweis: zuvor kontaktierte Kliniken werden weiterhin berücksichtigt)",
      searchSelectorTitle: "An wen möchten Sie Anfragen schicken? *",
      sendMoreRequests: "Mehr Anfragen versenden",
      sendRequests: "Anfragen senden",
      titleAll: (args: { receiverTypePlural: string }) =>
        template(
          "Aufgrund Ihrer vergangenen Anfragen könnten folgende {{receiverTypePlural}} für Sie interessant sein:",
          args,
        ),
      titleAllNoSuggestions: (args: { receiverTypePluralPreferred: string }) =>
        template(
          "Fügen Sie {{receiverTypePluralPreferred}} manuell hinzu.",
          args,
        ),
      titleCare:
        "Aufgrund Ihrer vergangenen Anfragen könnten folgende Nachversorger für Sie interessant sein:",
      titleCareNoSuggestions:
        "Fügen Sie Ihre präferierten Nachversorger manuell hinzu.",
      titleRehab:
        "Folgende Einrichtungen könnten für Sie interessant sein - fügen Sie diese als Wunschklinik hinzu:",
      titleRehabNoSuggestions: "Fügen Sie Ihre Wunschkliniken manuell hinzu.",
      titleRehaInsurancePayer:
        "Folgende Reha-Einrichtungen könnten für Sie interessant sein - fügen Sie diese hinzu:",
      titleRehaInsurancePayerNoSuggestions:
        "Fügen Sie Reha-Einrichtungen manuell hinzu",
      tooltipPartnerClinic: (args: { receiverTypeSingular: string }) =>
        template("{{receiverTypeSingular}} ist ein Partner von Ihnen", args),
      tooltipPatientsReason:
        "Hier können Sie eine Begründung für die Wunschklinik des Patienten angeben. Der Kostenträger prüft diese anschließend gemäß §8 SGB IX (Wunsch- und Wahlrecht der Leistungsberechtigten)",
    },
    startSearchNoCandidates: {
      care: "Es gibt keine potentiellen Nachversorger, die kontaktiert werden können. Die Suche kann trotzdem gestartet und Nachversorger manuell hinzugefügt werden.",
      geriatrics:
        "Es gibt keine potentiellen Kliniken, die kontaktiert werden können. Die Suche kann trotzdem gestartet und Kliniken manuell hinzugefügt werden.",
    },
    startTheSearch: "Suche starten",
    startTheSearchTitle:
      "Ist das Profil vollständig? Dann können Sie die Suche hier starten:",
    stopNotEligible: "Kostenübernahme nicht möglich",
    stopped: "Beendet",
    stoppedSunsetCostpayerflow:
      "Ihre Suche wurde aufgrund der Abschaltung der Möglichkeit, AHB-/Reha-Platz-Suchen an Kostenträger zu übergeben, automatisch gestoppt.  ",
    suggestProvider: "Wunschklinik mitteilen",
    suggestToCostPayer: "An Kostenträger senden",
    summary: {
      accepted: "Vom Versorger angenommen",
      approved: "Vom KH ausgewählt",
      declined: "Versorgung nicht möglich",
      declinedByProvider: "Versorgung nicht möglich",
      dismissedByCostPayer: "Vom Kostenträger abgelehnt",
      oneAcceptTooltip:
        "Sie müssen die akzeptierte Anfrage erst annehmen oder ablehnen, um weitere Anfragen senden zu können",
      rejected:
        "Unpassender Versorger nach Anpassung des Patientenprofils oder vom KH abgelehnt",
      sent: "Versorger angefragt",
      sentFax: "Versorger angefragt (via Fax)",
      summary: "Zusammenfassung der Anfragen",
      unavailable: "Nicht verfügbar",
      waitingForClinicAnswer: "Antwort des KH ausstehend",
      waitingForProviderAnswer: "Antwort des Versorgers ausstehend",
    },
    switchOnSearchMergeActivated: "Aktivieren Sie die Search Merge: ",
    titleInfo: {
      confirmGrantAccess: (args: { careproviderName: string }) =>
        template(
          "Sie sind dabei {{careproviderName}} anzunehmen. Möchten Sie folgende Stammdaten direkt freigeben?",
          args,
        ),
      onlyPreferred: {
        care: "Derzeit werden nur präferierte Nachversorger kontaktiert.",
        geriatrics: "Derzeit werden nur Wunschkliniken kontaktiert.",
      },
      preferredOnlyContacted: (args: { receiverTypePluralPreferred: string }) =>
        template(
          "Derzeit werden nur {{receiverTypePluralPreferred}} kontaktiert",
          args,
        ),
      searchPaused:
        "Die Suche ist derzeit pausiert und es werden keine Anfragen automatisch versendet. Alle offenen Anfragen können nach wie vor beantwortet werden.",
    },
    toBeSentTitle: "In Prüfung",
    toBeStarted: "An Kostenträger übergeben",
    toBeStartedIns: "Suche übergeben",
    validated: "Bestätigt",
    viaPatientFamily: "Über Angehörige gefunden",
    willSendRequestShortly: "Die ersten Anfragen werden in Kürze gesendet",
    zeroPotentialClinicsPlaceholder:
      "Derzeit gibt es keine Kliniken in Ihrer Umgebung oder niemand hat Kapazität Patienten anzunehmen",
    zeroPotentialProvidersPlaceholder:
      "Derzeit gibt es keine Nachversorger in Ihrer Umgebung oder niemand hat Kapazität Patienten anzunehmen.",
    zeroPotentialReceiversPlaceholderAll: (args: {
      receiverTypePlural: string;
    }) =>
      template(
        "Derzeit gibt es keine {{receiverTypePlural}} in Ihrer Umgebung oder niemand hat Kapazität Patienten anzunehmen.",
        args,
      ),
  },
  termsAndConditions: {
    accept:
      "Ich habe die {select:AGB} zur Kenntnis genommen und akzeptiere diese.",
    agreedAt: (args: { date: string }) =>
      template("Akzeptiert am {{date}}", args),
    termsAndConditions: "Allgemeine Geschäftsbedingungen",
    titleOtherDocuments: "Weitere Dokumente",
    uploadedOn: (args: { date: string }) =>
      template("Hochgeladen am {{date}}", args),
  },
  test: "test",
  timeline: {
    addedPatientWishReason: {
      deletedPatientWishReason: "Der Grund wurde gelöscht.",
      myAccount: "Sie haben den Grund für den Patientenwunsch aktualisiert",
      otherAccount: (args: { accountName: string }) =>
        template(
          "{{accountName}} hat den Grund für den Patientenwunsch aktualisiert",
          args,
        ),
    },
    anotherProviderSelected: "Ein anderer Versorger wurde gefunden",
    anotherProviderWasSelected: "Ein anderer Nachversorger wurde ausgewählt",
    assigneeChanged: (args: {
      oldAssigneeName: string;
      newAssigneeName: string;
    }) =>
      template(
        "Der Fall wurde vom {{oldAssigneeName}} an {{newAssigneeName}} übertragen",
        args,
      ),
    auctionAllContacted: "Alle passenden Nachversorger wurden kontaktiert",
    auctionCancelled: (args: { accountName: string }) =>
      template("Suche wurde von {{accountName}} beendet", args),
    auctionPaused: {
      automatic: "Suche wurde automatisch pausiert",
      myAccount: "Sie haben die Suche pausiert.",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat die Suche pausiert.", args),
    },
    auctionStarted: (args: { accountName: string }) =>
      template("{{accountName}} hat die Suche gestartet", args),
    auctionSuccess: "Empfänger gefunden",
    auctionSuccessProviderKnown: (args: {
      name: string;
      accountName: string;
    }) => template("{{name}} manuell ausgewählt von {{accountName}}", args),
    auctionSuccessProviderUnknown:
      "Empfänger anderweitig gefunden, Name ist unbekannt",
    auctionSuccessUnknown: (args: { receiverTypeSingular: string }) =>
      template(
        "{{receiverTypeSingular}} anderweitig gefunden, Name der Einrichtung ist unbekannt",
        args,
      ),
    auctionToBeStarted: (args: { accountName: string }) =>
      template("{{accountName}} hat die Suche übergeben", args),
    auctionValidationCanceledUnknown: (args: { accountName: string }) =>
      template(
        "{{accountName}} hat die Annahme des unbekannten Empfängers rückgängig gemacht",
        args,
      ),
    auctionValidationCanceledUnknownReason: (args: {
      accountName: string;
      reason: string;
    }) =>
      template(
        "{{accountName}} hat die Annahme des unbekannten Empfängers rückgängig gemacht: {{reason}}",
        args,
      ),
    cancelledValidation: (args: {
      workerName: string;
      providerName: string;
      reason: string;
    }) =>
      template(
        "{{workerName}} hat die Annahme des Angebots von {{providerName}} abgebrochen: {{reason}}",
        args,
      ),
    cancelRejection: (args: { accountName: string; careprovider: string }) =>
      template(
        "{{accountName}} hat die Ablehnung von '{{careprovider}}' rückgängig gemacht",
        args,
      ),
    capacityConfirmed: "Die Kapazität wurde bestätigt",
    capacityUpdated: "Die Kapazität wurde aktualisiert",
    careproviderCreated: "Nachversorger erstellt",
    careproviderOnboarding: (args: { name: string }) =>
      template(
        "{{name}} wird im System aufgenommen. Es wurde noch keine Anfrage versendet.",
        args,
      ),
    careproviderOnboardingStatusChanged: (args: {
      prevStatus: string;
      newStatus: string;
    }) =>
      template(
        "Onboarding status changed from {select:{{prevStatus}}} to {select:{{newStatus}}}",
        args,
      ),
    careproviderStatusChanged: (args: {
      prevStatus: string;
      newStatus: string;
    }) =>
      template(
        "Status changed from {select:{{prevStatus}}} to {select:{{newStatus}}}",
        args,
      ),
    careproviderUpdated: "Nachversorger geändert",
    companyCreated: "Company created",
    companyResourceAdded: (args: { resource: string }) =>
      template("{{resource}} added to company", args),
    companyResourceRemoved: (args: { resource: string }) =>
      template("{{resource}} removed from company", args),
    companyStatusChange: (args: { newStatus: string }) =>
      template("Company status changed to {{newStatus}}", args),
    companyUpdated: "Company updated",
    costPayerChanged: "Kostenträger wurde geändert",
    documentationTitle: "Dokumentation der Patientenüberleitung",
    eligibilityApproved: (args: { accountName: string }) =>
      template(
        "Der Antrag für Kostenübernahme wurde von {{accountName}} genehmigt",
        args,
      ),
    eligibilityBackToNotProcessed: (args: { accountName: string }) =>
      template(
        "Die Übermittlung des Antrags wurde von {{accountName}} rückgängig gemacht",
        args,
      ),
    eligibilityCancelled: (args: { accountName: string }) =>
      template(
        "Der Status für die Kostenübernahme wurde von {{accountName}} zurückgesetzt",
        args,
      ),
    eligibilityInProcess: (args: { accountName: string }) =>
      template(
        "Der Antrag für Kostenübernahme wurde von {{accountName}} übermittelt",
        args,
      ),
    eligibilityRejected: (args: { accountName: string }) =>
      template(
        "Der Antrag für Kostenübernahme wurde von {{accountName}} abgelehnt",
        args,
      ),
    filesChanged: {
      fileAccessChangedMyAccount: (args: {
        previousFileShareMode: string;
        newFileShareMode: string;
      }) =>
        template(
          "Sie haben einen Dateizugriff von {{previousFileShareMode}} auf {{newFileShareMode}} geändert",
          args,
        ),
      fileAccessChangedOtherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat einen Dateizugriff geändert", args),
      fileAccessRevokedMyAccount: (args: { name: string }) =>
        template("Sie haben den Dateizugriff für {{name}} widerrufen", args),
      fileAccessRevokedMyAccountMessenger:
        "Sie haben den Dateizugriff widerrufen",
      fileAccessRevokedOtherAccount: (args: { name: string }) =>
        template("{{name}} hat den Dateizugriff widerrufen", args),
      fileAccessRevokedOtherAccountMessenger: (args: { name: string }) =>
        template("{{name}} hat einen Dateizugriff widerrufen", args),
      fileDeletedMyAccount: "Sie haben eine Datei gelöscht",
      fileDeletedOtherAccount: (args: { name: string }) =>
        template("{{name}} hat eine Datei gelöscht ", args),
      fileSharedMyAccount: (args: { name: string }) =>
        template("Sie haben eine Datei mit {{name}} geteilt", args),
      fileSharedOtherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat eine Datei geteilt", args),
      fileUploaded: "Sie haben eine Datei hochgeladen",
    },
    formSentToInsurance: (args: {
      insuranceName: string;
      accountName: string;
    }) =>
      template(
        "Die Anfrage zur Kostenübernahme wurde an {{insuranceName}} von {{accountName}} gesendet. ",
        args,
      ),
    formWithdrawn: (args: { accountName: string; withdrawReason: string }) =>
      template(
        "Die Anfrage zur Kostenübernahme wurde von {{accountName}} zurückgezogen. Grund: {{withdrawReason}}.",
        args,
      ),
    insuranceAnswered: (args: { insuranceName: string }) =>
      template(
        "Antwort von der Krankenkasse {{insuranceName}} erhalten. ",
        args,
      ),
    mailFailure: "Email could not be sent",
    markedPatientPreferred: {
      myAccount: (args: {
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "Sie haben {{providerName}} als {{receiverTypeSingularPreferred}} des Patienten gekennzeichnet",
          args,
        ),
      otherAccount: (args: {
        accountName: string;
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "{{accountName}} hat {{providerName}} als {{receiverTypeSingularPreferred}} des Patienten gekennzeichnet",
          args,
        ),
    },
    onboardingEmail: {
      notSpecified: "Typ: nicht angegeben",
      prio: " Typ: Prio",
      regular: "Typ: Normal",
      reminder: "Typ: Erinnerung",
    },
    onboardingEmailSent: "Info-Mail geschickt",
    patientArchived: "Patient wurde archiviert",
    patientArchivedNotification:
      'Patient wurde archiviert - dieser kann mittels Suchfeld jederzeit aufgerufen oder im Reiter "Archiviert" gefunden werden.',
    patientCreated: "Patientenprofil erstellt",
    patientDischarged: "Patient entlassen",
    patientUpdated: {
      infoExcludedProviders: (args: { reason: string }) =>
        template(
          "Aufgrund von Änderungen in {{reason}} wurde die Liste der Nachversorger entsprechend aktualisiert.",
          args,
        ),
      infoExcludedProvidersNoReason:
        "Aufgrund von Änderungen wurde die Liste der Nachversorger entsprechend aktualisiert.",
      myAccount: "Sie haben das Profil geändert.",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} hat das Profil geändert.", args),
    },
    pushedToCallcenter: "An das Callcenter geschickt",
    rehaFormCreated: (args: { formName: string; accountName: string }) =>
      template("{{formName}} wurde von {{accountName}} erstellt", args),
    rehaFormDeleted: (args: { formName: string; accountName: string }) =>
      template("{{formName}} wurde von {{accountName}} entfernt", args),
    rehaFormUpdated: (args: { formName: string; accountName: string }) =>
      template("{{formName}} wurde von {{accountName}} editiert", args),
    rehaFormUpdatedAlreadySent: (args: {
      formName: string;
      accountName: string;
    }) =>
      template(
        "{{formName}} wurde bereits von {{accountName}} versendet",
        args,
      ),
    rehaFormUpdatedBackToDraft: (args: {
      formName: string;
      accountName: string;
    }) =>
      template(
        "{{formName}} wurde von {{accountName}} zurück als Entwurf gespeichert",
        args,
      ),
    rehaFormUpdatedCompleted: (args: {
      formName: string;
      accountName: string;
    }) =>
      template("{{formName}} wurde von {{accountName}} fertiggestellt", args),
    removedPatientPreferred: {
      myAccount: (args: {
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "Sie haben {{providerName}} als {{receiverTypeSingularPreferred}} des Patienten abgewählt",
          args,
        ),
      otherAccount: (args: {
        accountName: string;
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "{{accountName}} hat {{providerName}} als {{receiverTypeSingularPreferred}} des Patienten abgewählt",
          args,
        ),
    },
    requestAvailable: "Diese Anfrage ist wieder verfügbar",
    requestDismissed: (args: { accountName: string; careprovider: string }) =>
      template(
        "{{accountName}} hat den Vorschlag: '{{careprovider}}' abgelehnt",
        args,
      ),
    requestManuallyAdded: (args: {
      socialWorker: string;
      careprovider: string;
    }) =>
      template(
        "{{socialWorker}} hat eine Anfrage an '{{careprovider}}' gesendet",
        args,
      ),
    requestResent: (args: { careprovider: string }) =>
      template("Anfrage erneut an {{careprovider}} versendet", args),
    requestToBeSent: (args: { accountName: string; careprovider: string }) =>
      template(
        "{{accountName}} hat '{{careprovider}}' zur Überprüfung vorgeschlagen",
        args,
      ),
    requestUnavailable: "Diese Anfrage ist nicht mehr verfügbar",
    requestUnavailableProvider: "Diese Anfrage ist nicht mehr verfügbar",
    senderCreated: "Sender KH erstellt",
    senderUpdated: "Sender-Profil aktualisiert",
    smsSent: "SMS wurde versendet",
    statusChanged: (args: { prevStatus: string; newStatus: string }) =>
      template(
        "Status wurde von {select:{{prevStatus}}} zu {select:{{newStatus}}} geändert",
        args,
      ),
    suggestionCancelled: (args: {
      accountName: string;
      careprovider: string;
    }) =>
      template(
        "{{accountName}} hat den Vorschlag: {{careprovider}} zurückgezogen",
        args,
      ),
    testAnswered: "Test erfolgreich",
    testSent: "Test gesendet",
    title: "Dokumentation",
    transitionalCareCreated: (args: { accountName: string }) =>
      template(
        "Dokumentation für Übergangspflege wurde von {{accountName}} erstellt",
        args,
      ),
    transitionalCareDeleted: (args: { accountName: string }) =>
      template(
        "Dokumentation für Übergangspflege wurde von {{accountName}} gelöscht",
        args,
      ),
    transitionalCareUpdated: (args: { accountName: string }) =>
      template(
        "Dokumentation für Übergangspflege wurde von {{accountName}} editiert",
        args,
      ),
    you: "Ihnen",
  },
  todo: {
    abbreviatedCareLevel: "PG:",
    archived: "Archiviert",
    cards: {
      answer: "Antworten",
      continueEditing: "Weiter bearbeiten",
      continueSearch: "Weitersuchen",
      insuranceChip: "KT",
      internalId: "Fallnummer",
      newMessage: "neue Nachricht",
      prereserve: "Vorreservieren",
      restartSearch: "Suche neu starten",
      sendRequests: "Anfragen senden",
      startSearch: "Suche starten",
      stopSearch: "Suche beenden",
      tooltipFormShared: "AHB-Antrag wurde übermittelt",
      tooltipInsuranceChip:
        "ist Partner von Recare und übernimmt die Suche für Sie",
      updateProfile: "Profil aktualisieren",
    },
    categoryElective: "WARTEND",
    categoryFinished: "Beendet",
    categoryPatients: "Aktiv",
    discharge: "Startinformationen",
    dischargeDate: "Entlassdatum",
    drafts: "Angelegt",
    filteredBy: "Gefiltert nach",
    finishedHint:
      "Bitte beachten Sie, dass abgebrochene Suchen nach 10 Tagen und bestätigte Suchen nach 30 Tagen automatisch archiviert werden.",
    loadingError: "Konnte nicht aktualisiert werden",
    noRequestFilterResults:
      "Um alle Anfragen zu sehen, löschen Sie Ihre angewendeten Filter.",
    noRequests: "Es gibt nichts zum Anzeigen",
    patient: "Patient",
    reenterDataPostIncident: {
      body: "Nach einem routinemäßigen System-Upgrade ist es bedauerlicherweise zu einem längeren Ausfall der Recare-Datenbank gekommen. In der Folge müssen alle in dem Zeitraum 29.11.2023, 19:30 Uhr bis 30.11.2023, 12:20 Uhr erfolgten Tätigkeiten von Ihnen wiederholt werden (inklusive Erstellung von Patientenprofilen und Import von KIS-Daten). Mehr Informationen finden Sie in der Rubrik “Neues von Recare”.",
      title: "Wiederholung der Dateneingabe benötigt ",
    },
    refreshApp:
      "Bitte aktualisieren Sie die Seite um die neueste Version von Recare zu verwenden.",
    responsiblePerson: "Zust. Mitarbeiter",
    running: "Aktiv",
    startDate: "Startdatum",
    stopped: "Abgebrochen",
    stoppedHint:
      "Bitte beachten Sie, dass abgebrochene Suchen nach 10 Tagen automatisch archiviert werden.",
    titleNavBar: "Suche",
    transfer: "Überleitung",
    validated: "Bestätigt",
    validatedHint:
      "Bitte beachten Sie, dass bestätigte Suchen nach 30 Tagen automatisch archiviert werden.",
  },
  tours: {
    accessPatientData: {
      stepA: {
        text: "Sehen Sie auf einen Blick, ob Sie dem Nachversorger bereits Zugriff auf die Patienten-Stammdaten gewährt haben. Sie können jederzeit entscheiden, wann im Prozess Sie dem Nachversorger Zugriff gewähren.",
        title: "Überblick behalten",
      },
      stepB: {
        text: 'Mit einem Klick auf "Stammdaten freigeben" erhalten Sie wie gewohnt eine detaillierte Übersicht über alle freizugebenden Daten und können den Vorgang auf Wunsch auch wieder abbrechen. Bis zur endgültigen Annahme müssen Sie die Daten nicht freigeben.',
        title: "Stammdaten freigeben",
      },
      stepC: {
        text: "Das Teilen von Stammdaten wird auch im Chatverlauf angezeigt. Den Gesamtüberblick darüber, welche Stammdaten Sie mit welchem Nachversorger geteilt haben, finden Sie gewohnt im “Dokumentation”-Reiter.",
        title: "Änderungen nachverfolgen",
      },
    },
    availableFilesToShare: {
      stepA: {
        intro:
          "Klicken Sie hier um Dateien zu teilen oder Zugriffsrechte zu ändern",
        title: "Dateien teilen",
      },
    },
    files: {
      backButton: "Zurück",
      doneButton: "Beenden",
      nextButton: "Weiter",
      stepA: {
        intro: "Sicheres Teilen von PDF-Dokumenten (20 MB Dateigröße Limit)",
        title: "Ab sofort möglich: ",
      },
      stepB: {
        intro:
          "Laden Sie eine Datei hoch und ordnen Sie diese einer Kategorie zu (falls Sie unsicher sind, wählen Sie bitte “Andere” aus).",
        title: "Dateien hochladen",
      },
      stepC: {
        intro: "Lernen Sie mehr über die Funktionalität und deren Benutzung.",
        title: "Anleitung",
      },
      stepD: {
        intro:
          "Ihre Aktionen (wie Hochladen und Teilen von Dateien) werden zu Prüfungszwecken unter der Rubrik Dokumentation gespeichert.",
        title: "Dokumentation",
      },
      stepE: {
        intro:
          "Falls Sie ein Dokument nur mit ausgewählten Empfängern teilen möchten, klicken Sie bitte auf den Empfänger Ihrer Wahl und teilen Sie die Datei im Nachrichtencenter.",
        title: "Teilen mit ausgewählten Empfängern",
      },
    },
    parallelSearch: {
      stepA: {
        intro:
          "Suchen Sie zeitgleich nach verschiedenen Versorgungsmöglichkeiten und finden Sie so noch schneller einen Versorgungsplatz für Ihren Patienten. Für die Module Pflege, Reha und Krankenhaus unterstützen wir je eine Suche pro Patient.",
        title: "Ab sofort möglich: ",
      },
      stepB: {
        intro:
          "Kombinieren Sie Suchen nach Pflege-, Reha- oder Krankenhausverlegungen miteinander, indem Sie einer existierenden Suche eine weitere Suche hinzufügen. Suchen Sie beispielsweise nach  einer Kurzzeitpflege parallel zu einem Versorgungsplatz in der Akutgeriatrie.",
        title: "Kombinieren Sie verschiedene Suchen für Ihre Patienten",
      },
    },
    receiverDashboard: {
      stepA: {
        intro:
          "Über die Filterfunktion können Sie Anfragen gezielt danach sortieren, ob sie zu Ihren Kapazitäten und Gegebenheiten passen. Beispielsweise können Sie nach Pflegegrad, Start der Versorgung oder Geschlecht filtern.  ",
        title: "Filterfunktion nutzen:",
      },
      stepB: {
        intro:
          "Wenn Sie dieses Kästchen anklicken, können Sie „eine” oder „alle” Anfragen auswählen und gesammelt ablehnen, ohne einen Grund anzugeben. Auch Ablehnungen sind wichtig, denn so wissen Krankenhäuser, dass Sie die Anfragen gesehen haben, aber keine Kapazitäten zur Verfügung stehen.",
        title: "Anfragen bearbeiten:",
      },
      stepC: {
        intro:
          "Manchmal ändern sich die Bedingungen nach Zu- oder Absagen. Keine Sorge: Unter dem Menüpunkt „In Bearbeitung” können Sie Ihre Annahmen zurückziehen und unter dem Menüpunkt „Andere” können Sie Ihre Ablehnungen jederzeit rückgängig machen.",
        title: "Rückmeldungen ändern:",
      },
      stepD: {
        intro:
          "Bitte geben Sie hier regelmäßig Ihre Verfügbarkeiten an, um eine effektive Nutzung der Recare-Plattform sowohl für Sie als auch für die Kliniken zu ermöglichen. Wenn Sie hinterlegen, dass Sie keine Kapazitäten haben, erhalten Sie bis zum angegebenen Datum keine neuen Anfragen. Eine Ausnahme bilden die Anfragen der Suchen, die Sie unter „Suche” angelegt haben.",
        title: "Kapazitäten einstellen:",
      },
      stepThree: {
        intro:
          "Sie sind bis auf einen Platz voll belegt und möchten nur dazu passende Anfragen erhalten? Unter dem Menüpunkt „Suche” legen Sie zu bestimmten Kriterien eine Patientensuche an und werden informiert, wenn eine entsprechende Anfrage eingeht.",
        title: "Auslastung optimieren:",
      },
    },
    receiverSettings: {
      stepA: {
        imagePath: "product-tour-images/receiver_settings_services_de.png",
        intro:
          "Sie können frei bestimmen, aus welchem Radius Sie Anfragen von Krankenhäusern erhalten möchten, oder alternativ einzelne Postleitzahlen auswählen, aus denen Sie Anfragen erhalten möchten.",
        title: "Anfragenradius festlegen:",
      },
      stepB: {
        imagePath:
          "product-tour-images/receiver_settings_reverse_search_de.png",
        intro:
          "Seien Sie proaktiv. Mit dieser Funktion suchen Sie gezielt nach Patienten, die zu Ihren Kapazitäten oder Gegebenheiten passen und planen so Ihre optimale Auslastung. Sie erhalten selbstverständlich weiterhin parallel Anfragen von Krankenhäusern. ",
        title: "Aktiv suchen:",
      },
      stepC: {
        imagePath: "product-tour-images/receiver_settings_capacity_de.png",
        intro:
          "An dieser Stelle können Sie den Erhalt aller neuen Anfragen einstellen, um nur jene Anfragen zu erhalten, die Sie in der aktiven Patientensuche festgelegt haben.",
        title: "Kapazitäten einstellen: ",
      },
    },
    reverseSearch: {
      backButton: "Zurück",
      doneButton: "Beenden",
      nextButton: "Weiter",
      stepA: {
        intro:
          "Starten Sie eine gezielte Patientensuche basierend auf Ihren Kapazitäten.",
        title: "Freie Kapazitäten?",
      },
      stepB: {
        intro: "Gezielte Patientensuche basierend auf Ihren Kapazitäten.",
        title: "Ab sofort möglich",
      },
      stepC: {
        intro:
          "Gestalten Sie Ihre Suche flexibel ganz nach Ihren Wünschen. Sie können Ihre Suche schon mit einem Kriterium starten.",
        title: "Suchkriterien setzen",
      },
      stepD: {
        intro:
          "Klicken Sie auf “Speichern” um Ihre Suche zu starten. Sobald für einen passenden Patienten ein Versorgungsplatz gesucht wird, erhalten Sie eine E-Mail.",
        title: "Suche starten",
      },
      stepE: {
        intro:
          "Starten Sie mehrere Suchen, wenn Sie Kapazitäten für unterschiedliche Patienten haben.",
        introCapacityMode:
          "Starten Sie mehrere Suchen, wenn Sie Kapazitäten für unterschiedliche Patienten haben. Sie erhalten Anfragen für Patienten, die Ihren Kriterien entsprechen, auch wenn Ihre Gesamtkapazität ausgeschaltet ist.",
        title: "Parallele Suche",
      },
      stepF: {
        intro:
          "Starten Sie mehrere Suchen, wenn Sie Kapazitäten für unterschiedliche Patienten haben.",
        introCapacityMode:
          "Starten Sie mehrere Suchen, wenn Sie Kapazitäten für unterschiedliche Patienten haben. Sie erhalten Anfragen für Patienten, die Ihren Kriterien entsprechen, auch wenn Ihre Gesamtkapazität ausgeschaltet ist.",
        title: "Parallele Suche",
      },
      stepG: {
        intro:
          'Klicken Sie auf "Neue Suche hinzufügen" um eine weitere, parallele Patientensuche mit neuen Kriterien zu erstellen. Legen Sie mindestens ein Suchkriterium fest.',
        title: "Zusätzliche Suche starten",
      },
    },
    searchMerge: {
      stepA: {
        intro: "Alle Informationen zu Ihrer Suche auf einen Blick",
        title: "Jetzt noch übersichtlicher:",
      },
      stepB: {
        intro:
          "Klicken Sie auf „Kontaktieren”, um alle potenziellen Nachversorger zu kontaktieren. Sie möchten nur bestimmte Nachversorger kontaktieren? Dann klicken Sie zuerst auf die blaue Checkbox, um die Auswahl aufzuheben und anschließend die gewünschten Nachversorger einzeln auszuwählen. Klicken Sie abschließend auf „Kontaktieren”.",
        title: "Suche starten:",
      },
      stepC: {
        intro:
          "Geben Sie das Wunsch- und Wahlrecht an, indem Sie bei Nachversorgern auf den Stern klicken. Optional können Sie für den Wunsch auch einen Grund hinterlegen. Der entsprechende Nachversorger wird über diese Angaben informiert.",
        title: "Patientenwunsch übermitteln:",
      },
      stepD: {
        intro:
          "Klicken Sie auf eine Zeile, um die jeweilige Suchanfrage einzusehen, und um mit dem Nachversorger per Chat zu kommunizieren.",
        title: "Details einsehen:",
      },
    },
    searchMergeContacted: {
      stepA: {
        intro:
          "In dieser Tabelle sehen Sie alle bereits kontaktierten Nachversorger. Klicken Sie auf eine Reihe, um die Suchanfrage anzusehen.",
        title: "Suche überwachen:",
      },
      stepB: {
        intro:
          "Übermitteln Sie das Patientenwunsch und -wahlrecht nun auch an bereits kontaktierte Nachversorger. Klicken Sie dazu auf den Stern neben dem Namen des Nachversorgers und geben Sie optional einen Grund an. Der Nachversorger wird über diese Angaben informiert.",
        title: "Patientenwünsche berücksichtigen:",
      },
      stepC: {
        intro:
          "In der Statusspalte werden Sie über Neuigkeiten zu einer Anfrage informiert, wie zum Beispiel eine ungelesene Chat-Nachricht oder ein eingegangenes Dokument. Es wird das neueste Ereignis angezeigt.",
        title: "Wichtige Neuigkeiten einsehen:",
      },
    },
    transitionalCare: {
      stepA: {
        intro:
          "Sparen Sie Zeit ein und erhöhen Sie Ihre Datenqualität, indem Sie vorhandene Patientendaten importieren und bei Bedarf aktualisieren. Füllen Sie anschließend das Formular aus und importieren Sie im Abschnitt e) bis zu 20 der zuvor kontaktierten Anschlussversorger über den Button „Anschlussversorger aus Recare importieren”.",
        title: "Daten importieren",
      },
      stepB: {
        intro:
          "Als letzten Schritt speichern Sie den Dokumentationsstand und erstellen Sie ein PDF nach Krankenkassen-Vorgabe. Die Kontaktversuche bei Anschlussversorgern sind detailliert und mit Datum und Uhrzeit nachgewiesen.",
        title: "Speichern und PDF erstellen",
      },
    },
  },
  userManagement: {
    accountEdit: "Konto editieren",
    accountInputLabel: "Konto",
    accountReactivate: "Account reaktivieren",
    activationEmailFail:
      "Die Aktivierungs-E-Mail konnte nicht erneut versendet werden, bitte versuchen Sie es später erneut.",
    activationEmailSuccess:
      "Die Aktivierungs-E-Mail wurde erfolgreich erneut gesendet.",
    addAccountFlow: {
      accountEditPreTitle:
        "Dieser Benutzer existiert bereits in unserem System.",
      accountEditSubTitleCareseeker:
        "Die Informationen, die in diesen Feldern angezeigt werden, sind für alle Kliniken zugänglich, die mit diesem Benutzerkonto verbunden sind. Wenn Sie Änderungen vornehmen, werden sie für alle Kliniken übernommen.",
      backButton: "Zurück",
      cancelButton: "Abbrechen",
      confirmButton: "Bestätigen",
      createButton: "Erstellen",
      emailAddress: "E-Mail-Adresse",
      mandatoryFieldEmail: "Bitte geben Sie eine E-Mail-Adresse ein",
      mandatoryFieldFirstName: "Bitte geben Sie den Vornamen ein",
      mandatoryFieldLastName: "Bitte geben Sie den Nachnamen ein",
      mandatoryFieldRole:
        "Bitte wählen Sie mindestens eine Rolle und Berechtigung aus",
      rolesPermissionTitle: (args: { clinicName: string }) =>
        template(
          "Legen Sie die Rollen und Berechtigungen für diesen Benutzer in {{clinicName}} fest.",
          args,
        ),
      rolesSectionTitle: "Klinikspezifische Informationen",
      stepOneSubtitle:
        "Bitte geben Sie die E-Mail-Adresse des Benutzers ein, den Sie dem System hinzufügen möchten.",
      stepOneTitle: "Konto hinzufügen",
    },
    confirmationModalActivationMail:
      "Sind Sie sicher, dass Sie die Aktivierungs-E-Mail erneut senden möchten?",
    confirmationModalDeactivateText:
      'Sie können deaktivierte Konten reaktivieren, indem Sie im Menü auf "Reaktivieren" klicken.',
    confirmationModalDeactivateTextSSO:
      "Deaktivierte Konten können reaktiviert werden, indem Sie ein neues Konto in Ihrem internen IdP erstellen.",
    confirmationModalDeactivateTitle:
      "Möchten Sie dieses Konto wirklich deaktivieren?",
    confirmationModalNormalAccount:
      "Die Verwaltung der Benutzer dieses Kunden sollte durch den zugewiesenen Benutzerverwalter erfolgen. Sind Sie sicher, dass Sie diesem Sender neue Benutzerkonten hinzufügen wollen?",
    confirmationModalPasswordReset:
      "Sind Sie sicher, dass Sie die E-Mail zum Zurücksetzen des Passworts versenden möchten?",
    confirmationModalReactivateText:
      'Sie können reaktivierte Accounts wieder deaktivieren, indem Sie auf "Deaktivieren" klicken. ',
    confirmationModalReactivateTitle:
      "Möchten Sie dieses Konto wirklich reaktivieren?",
    confirmationModalResetUserManager:
      "Sind Sie sicher, dass Sie die Rolle des Benutzermanagers für dieses Konto zurücksetzen möchten? Das hochgeladene Formular wird gelöscht und muss erneut hochgeladen werden, um die Benutzerverwalterrolle einem anderen Konto zuzuweisen ",
    confirmationModalSetUserManager:
      "Sind Sie sicher, dass Sie diesem Konto die Rolle des Benutzermanagers zuweisen möchten?",
    deactivateButton: "Deaktivieren",
    deactivationDisabledHover:
      'Dies ist der einzige aktive Benutzer mit der Rolle "Benutzerverwaltung". Um diesen Benutzer zu deaktivieren, muss mindestens ein anderer aktiver Benutzer dieses Unternehmens über die Berechtigung "Benutzerverwaltung" verfügen.',
    deactivationFail:
      "Das Konto konnte nicht deaktiviert werden, bitte versuchen Sie es später erneut.",
    deactivationSuccess: "Das Konto wurde erfolgreich deaktiviert.",
    email: "E-Mail",
    errorAccountOutsideGroup:
      "Das Benutzerkonto, das Sie hinzufügen oder bearbeiten möchten, existiert bereits in einem anderen Krankenhaus außerhalb Ihrer Klinikgruppe. Bitte wenden Sie sich an Ihre Recare-Kontaktperson, um dieses Konto hinzuzufügen oder zu bearbeiten.",
    errorDomainNotWhitelisted:
      "Die von Ihnen eingegebene Domain ist nicht in der Liste der Whitelisted Domains für Ihr Unternehmen enthalten. Bitte stellen Sie sicher, dass die Schreibweise korrekt ist. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unser Support-Team.",
    errorEmailAlreadyExists: (args: { emailAddress: string }) =>
      template(
        "Es existiert bereits ein Konto, das mit der E-Mail-Adresse {{emailAddress}} verknüpft ist.",
        args,
      ),
    fileInputLabel: "Unterschriebene Vereinbarung",
    informationBanner:
      'Hier verwalten Sie eigenständig Ihre Nutzer und behalten den Überblick über bestehende Konten. Mit einem Klick auf „Konto hinzufügen” fügen Sie neue Konten hinzu und legen die gewünschten Rollen und Berechtigungen fest, indem Sie die jeweiligen Module aus- oder abwählen. Bestehende Konten bearbeiten Sie, indem Sie in der Zeile des jeweiligen Kontos rechts im Menü mit den drei Punkten „Editieren” wählen. Ein Konto wird nicht mehr benötigt? Klicken Sie einfach auf „Deaktivieren”. Sie möchten das rückgängig machen? Dann klicken Sie auf „Reaktivieren". Bitte beachten Sie, dass die Anzahl an Konten die Sie pro Tag hinzufügen, deaktivieren und reaktivieren können, begrenzt ist.',
    informationBannerShowMore: "",
    informationBannerSSO:
      "Hier haben Sie eine Übersicht über alle Konten und können im Blick behalten, welche Rollen und Berechtigungen den jeweiligen Konten zugeordnet sind.  Ein Konto wird nicht mehr benötigt? Klicken Sie einfach auf „Deaktivieren”. \nDie Erstellung und Bearbeitung ihrer Konten erfolgt über ihr internes IdP. ",
    infoSection: {
      infoSectionFiveText:
        "Konten mit diesem Status wurden deaktiviert. Benutzer können sich nicht mehr auf der Plattform anmelden. Durch die Reaktivierung des Kontos wird erneut eine Aktivierungs-E-Mail versendet und der Benutzer erhält den Status „Erstellt“.",
      infoSectionFiveTitle: "Inaktiv: \n",
      infoSectionFourText:
        "Konten mit diesem Status wurden deaktiviert und eine Anmeldung auf der Plattform ist nicht mehr möglich. Jedes Konto kann durch einen Klick auf „Reaktivieren” wieder aktiviert werden. In diesem Fall wird erneut eine Aktivierungs-E-Mail versendet und das Konto erhält wieder den Status „Erstellt”.",
      infoSectionFourTitle: "Inaktiv: ",
      infoSectionOneText:
        "Bestehende Konten haben immer einen der folgenden drei Status:\n",
      infoSectionOneTitle: "Status",
      infoSectionThreeText:
        "Bei diesem Status wurde das Konto erstellt und eine Aktivierungs-E-Mail gesendet, die Anmeldung aber nicht abgeschlossen. Das Konto ist daher noch nicht aktiviert. Bei Bedarf kann die Aktivierungs-E-Mail im Menü des jeweiligen Kontos erneut gesendet werden.",
      infoSectionThreeTitle: "Erstellt: ",
      infoSectionTwoText:
        "Nur in diesem Status ist die Nutzung der Funktionalitäten im Rahmen der individuell zugewiesenen Rollen und Berechtigungen möglich. Durch die Anmeldung auf der Plattform wird ein Konto aktiv.",
      infoSectionTwoTitle: "Aktiv:",
    },
    infoSectionOneText:
      "Bestehende Konten erhalten immer einen der folgenden drei Status:\n\nAktiv: Konten mit diesem Status haben sich bereits mindestens einmal auf der Plattform angemeldet und können je nach zugewiesenen Rollen und Berechtigungen bestimmte Funktionalitäten nutzen.\n\nErstellt: Dieser Status zeigt an, dass das Konto erstellt und eine Aktivierungs-E-Mail gesendet wurde. Der Benutzer hat sich jedoch noch nicht angemeldet und das Konto daher nicht endgültig aktiviert.\n\nInaktiv: Konten mit diesem Status wurden deaktiviert. Benutzer können sich nicht mehr auf der Plattform anmelden. Durch die Reaktivierung des Kontos wird erneut eine Aktivierungs-E-Mail versendet und der Benutzer erhält den Status „Erstellt“.",
    infoSectionOneTitle: "Status",
    infoSectionTitle: "Benutzerkonten Überblick",
    limitCreationInfo:
      "Sie haben das Tageslimit für die Erstellung neuer Konten erreicht. Bitte versuchen Sie es in 24 Stunden erneut.",
    limitDeactivationInfo:
      "Sie haben das Tageslimit für die Deaktivierung von Konten erreicht. Bitte versuchen Sie es in 24 Stunden erneut.",
    limitReactivationInfo:
      "Sie haben das Tageslimit für die Reaktivierung von Konten erreicht. Bitte versuchen Sie es in 24 Stunden erneut.",
    mandatoryFieldInformation: "* markiert ein Pflichtfeld.",
    name: "Name",
    noAccessInfo: "Sie haben keinen Zugriff auf diese Seite.",
    numberAccounts: (args: {
      numbersearchresults: string;
      numberexistingaccounts: string;
    }) =>
      template("{{numbersearchresults}} von {{numberexistingaccounts}}", args),
    passwordEmailFail:
      "Die E-Mail zum Zurücksetzen des Passworts konnte nicht gesendet werden, bitte versuchen Sie es später erneut.",
    passwordEmailSuccess:
      "Die E-Mail zum Zurücksetzen des Passworts wurde erfolgreich gesendet.",
    permissions: "Berechtigungen",
    reactivateButton: "Reaktivieren",
    reactivationFail:
      "Der Account konnte nicht reaktiviert werden, bitte versuchen Sie es später erneut.",
    reactivationSuccess: "Der Account wurde erfolgreich reaktiviert.",
    resendActicationMailButton: "Aktivierungs-E-Mail erneut senden",
    resetPasswordButton: "Passwort-zurücksetzen-E-Mail",
    roles: "Rollen",
    searchFieldPlaceholder: "Suche nach Name oder E-Mail-Adresse:",
    searchNoResult:
      "Es konnte kein Konto mit diesem Namen oder E-Mail-Adresse gefunden werden. Bitte überprüfen Sie Ihre Eingabe oder fügen Sie ein neues Konto hinzu.",
    status: "Status",
    tableDescription: "Table description",
    tableTitle: "Benutzerkonten",
    title: "Nutzerverwaltung",
    userManagementReceiver: {
      description:
        "Die in diesen Feldern angezeigten Informationen sind für alle Nachversorger zugänglich, mit denen dieses Konto verbunden ist.",
      editableRoles:
        "Diesen Mitarbeiter den Kliniken als Kontaktperson anzeigen",
      rolesModalTitel: "Nachversorgerspezifische Informationen",
      viewOnlyRoles: "Für diesen Nutzer festgelegte Rollen",
    },
  },
  weekdays: {
    everyday: "Täglich",
    friday: "Freitag",
    monday: "Montag",
    saturday: "Samstag",
    sunday: "Sonntag",
    thursday: "Donnerstag",
    timeFormat: "hh:mm",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    weekdays: "Wochentags",
    weekends: "Wochenende",
  },
} as const;

export default de;
