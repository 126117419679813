import { EncryptionSessionCache } from "@seald-io/sdk";
import { differenceInDays, fromUnixTime } from "date-fns";
import { SSCrypto } from "sscrypto";

type SymKey = InstanceType<SSCrypto["SymKey"]>;

type SessionStore = {
  [id: string]: { serializationDate: number; sessionSymKey: string };
};
const SESSION_CACHE_EXPIRATION_DAYS = 20;

export class PersistedSessionCache implements EncryptionSessionCache {
  dbKey: SymKey | undefined;
  accountId: number;
  store: SessionStore;
  sessionId: string;

  getCacheId() {
    return `SealdSessionCache:${this.accountId}:${this.sessionId}`;
  }

  encryptStore() {
    try {
      if (!this.dbKey) return;
      const storeString = JSON.stringify(this.store);
      const storeBuffer = Buffer.from(storeString);
      const encryptedStore = this.dbKey.encrypt(storeBuffer).toString("base64");
      if (!encryptedStore) return;
      localStorage.setItem(this.getCacheId(), encryptedStore);
    } catch (err) {
      console.error("Error encrypting PersistedSessionCache", err);
    }
  }

  decryptStore() {
    try {
      if (!this.dbKey) return;
      const storeString = localStorage.getItem(this.getCacheId());
      if (!storeString) return;
      const storeBuffer = Buffer.from(storeString, "base64");
      const decryptedStore = this.dbKey.decrypt(storeBuffer).toString();
      if (!decryptedStore) return;
      const storeObj = JSON.parse(decryptedStore) as SessionStore;

      let withExpired = false;
      for (const [id, value] of Object.entries(storeObj)) {
        const serializationDateUnix = Math.floor(
          value.serializationDate / 1000,
        );
        const daysDiff = differenceInDays(
          new Date(),
          fromUnixTime(serializationDateUnix),
        );
        if (daysDiff <= SESSION_CACHE_EXPIRATION_DAYS) {
          this.store[id] = value;
        } else {
          withExpired = true;
        }
      }
      if (withExpired) {
        this.encryptStore();
      }
    } catch (err) {
      console.error("Error decrypting PersistedSessionCache", err);
    }
  }

  constructor({
    accountId,
    dbKey,
    sessionId,
  }: {
    accountId: number;
    dbKey: SymKey | undefined;
    sessionId: string;
  }) {
    this.accountId = accountId;
    this.sessionId = sessionId;
    this.store = {};
    this.dbKey = dbKey;

    this.decryptStore();
  }

  get(id: string) {
    return this.store[id];
  }

  set(id: string, value: { serializationDate: number; sessionSymKey: string }) {
    this.store[id] = value;

    this.encryptStore();
  }

  keys() {
    return Object.entries(this.store)
      .map(([key, value]) => (value ? key : undefined))
      .truthy();
  }

  delete(id: string) {
    delete this.store[id];
  }
}
