import SealdSDK from "@seald-io/sdk";
import SealdSDKPluginSSKS2MR from "@seald-io/sdk-plugin-ssks-2mr";
import {
  useInitiateSealdIdentity,
  useUpdateAccount,
} from "apollo/hooks/mutations";
import {
  SEALD_FLOW_DISABLED,
  SEALD_FLOW_PASSWORD,
  TRACK_EVENTS,
} from "core/consts";
import api from "core/model/api";
import {
  Account,
  EnvContext,
  SEALD_FLOW_TYPES,
  SealdIdentityCheck,
  TrackEventFn,
} from "core/types";
import { Locale as LocaleString } from "translations";
import { LOCAL_STORAGE_KEYS } from "../model/localStorage";
import { formatUnixDate } from "../model/utils/dates";
import { getErrorMessage } from "../model/utils/errors";
import {
  instantiateSealdSDK,
  sdkConfig,
  SEALD_TEST_URL_API,
  sealdSDKInstance,
} from "./sdk";
import {
  computeSealdDisplayId,
  computeSSKSEmail,
  getNonce,
  isDev,
  isWeb,
  preGenerateKeys,
  pushAllPreGenerateKeys,
} from "./utils";

type GetTrackerContext = () => AnyObject;

export type UpdateSealdRegistered =
  | ReturnType<typeof useUpdateAccount>[0]
  | null;

type ImportIdentity = {
  account: Account;
  challenge: string | undefined;
  envContext: EnvContext;
  localeString: LocaleString;
  password?: string;
  seald_flow: SEALD_FLOW_TYPES | null;
  seald_force_challenge: boolean | undefined;
  token: string;
  trackEvent: TrackEventFn;
  twoManSessionId?: string;
  updateAccount: UpdateSealdRegistered;
};

type SignInProps = {
  account: Account;
  challenge: string | undefined;
  databaseKey: string | undefined;
  envContext: EnvContext;
  getTrackerContext: GetTrackerContext;
  identityCheck: SealdIdentityCheck;
  nonce: string;
  password: string | undefined;
  seald_flow: SEALD_FLOW_TYPES | null;
  token: string;
  trackEvent: TrackEventFn;
  updateAccount: UpdateSealdRegistered;
  userId: string;
};

type SignUpProps = SignInProps & {
  localeString: LocaleString;
  twoManRuleKey: string | undefined;
  userLicenseToken: string;
};

type SignInWithPasswordSignupWithChallengeProps = Omit<
  SignInProps,
  "identityCheck" | "seald_flow"
> & {
  twoManRuleKey: string | undefined;
};

let ssks_session_id: string | undefined;
const getSsksSessionId = () => ssks_session_id;
const setSsksSessionId = (ssksSessionId: string) => {
  ssks_session_id = ssksSessionId;
};

export type EntityType =
  | "account"
  | "auction_request"
  | "careprovider"
  | "careseeker"
  | "file"
  | "patient";

export async function createIdentityForAccount({
  account,
  envContext,
  initiateSealdIdentity,
  localeString,
  trackEvent,
}: {
  account: Account;
  envContext: EnvContext;
  initiateSealdIdentity: ReturnType<typeof useInitiateSealdIdentity>[0];
  localeString: LocaleString;
  trackEvent: TrackEventFn;
}): Promise<Account | undefined> {
  const userId = computeSealdDisplayId({
    id: account.id,
    type: "account",
    envContext,
  });

  if (account.seald_user_license_token) {
    console.error(`Account id ${account.id} already has a userLicenseToken`);
    return;
  }

  const datetime = formatUnixDate(new Date().getTime() / 1000, localeString, {
    customFormat: "dd.MM.yyyy HH:mm",
  });

  if (isWeb()) {
    window.sessionStorage.setItem(
      LOCAL_STORAGE_KEYS.CHALLENGE_DATETIME,
      datetime,
    );
  }

  const { data } = await initiateSealdIdentity({ account_id: account.id });

  if (!data?.sealdIdentity) {
    throw new Error("No seald identity returned");
  }

  const {
    must_authenticate: mustAuthenticate,
    seald_two_man_rule_key: twoManRuleKey,
    seald_two_man_rule_session_id: twoManRuleSessionId,
    seald_user_license_token: userLicenseToken,
  } = data.sealdIdentity;

  if (mustAuthenticate) {
    console.error(
      `Account id ${account.id} has an email ${account.email} already used by SSKS so it can't have an identity automatically created`,
    );
    return;
  }

  const nonce = getNonce(userLicenseToken);

  if (!SealdSDK || !SealdSDKPluginSSKS2MR) return;
  const tempSealdSDKInstance = SealdSDK(
    sdkConfig({ envContext, accountId: undefined, sessionId: undefined }),
  );
  await tempSealdSDKInstance.initialize();

  await tempSealdSDKInstance.initiateIdentity({
    userId,
    userLicenseToken,
  });

  // save main sub identity in ssks
  await tempSealdSDKInstance?.ssks2MR.saveIdentity({
    challenge: undefined,
    email: computeSSKSEmail({
      nonce,
      email: account.email ?? "",
      envContext,
    }),
    twoManRuleKey,
    userId,
    sessionId: String(twoManRuleSessionId),
  });

  trackEvent({
    name: TRACK_EVENTS.SEALD_IDENTITY_PRE_INITIATED,
    account_id: account.id,
  });

  return { ...account, seald_user_license_token: userLicenseToken };
}

async function signIn({
  account,
  challenge,
  databaseKey,
  envContext,
  getTrackerContext,
  identityCheck,
  nonce,
  password,
  seald_flow,
  token,
  trackEvent,
  updateAccount,
  userId,
}: SignInProps) {
  // create instance to import ssks identity
  await instantiateSealdSDK({
    databaseKey,
    sessionId: nonce,
    envContext,
    accountId: account.id,
  });
  if (!sealdSDKInstance) {
    console.error("Seald SDK not initialized");
    return;
  }

  // retrieve main identity from ssks
  if (seald_flow === SEALD_FLOW_PASSWORD && password) {
    console.log("Seald signin with password");
    await sealdSDKInstance?.ssksPassword.retrieveIdentity({
      userId,
      password,
    });
    // (deprecated: isNewIdentity was removed) there's never a need to init 2mr when signing in with the password flow
    // since isNewIdentity is always true until the sign up w/password is complete
  } else {
    console.log("Seald signin with challenge");
    // push all keys pre-generated when challenge was requested
    pushAllPreGenerateKeys(sealdSDKInstance);
    await sealdSDKInstance.ssks2MR.retrieveIdentity({
      challenge: challenge?.trim() ?? "",
      email: computeSSKSEmail({
        nonce,
        email: account.email ?? "",
        envContext,
      }),
      twoManRuleKey: account.seald_two_man_rule_key ?? "",
      userId,
      sessionId: getSsksSessionId() ?? "",
    });
    if (password && !identityCheck.has_password) {
      console.log("Seald signup with password - just to save");
      const { identity: mainSubIdentity } =
        await sealdSDKInstance.createSubIdentity();
      await fetch("");
      await sealdSDKInstance?.ssksPassword.saveIdentity({
        userId,
        password,
        identity: mainSubIdentity,
      });
    }
  }

  console.log("Seald identity imported from SSKS");
  if (!account.seald_registered) {
    await updateAccount?.(
      { seald_registered: true },
      { id: account.id, jwt: token },
    );
  }
  trackEvent({
    ...getTrackerContext(),
    system_event: true,
    name: TRACK_EVENTS.SEALD_IMPORT,
    type: "from ssks",
  });
}

async function signUp({
  account,
  challenge,
  databaseKey,
  envContext,
  getTrackerContext,
  identityCheck,
  localeString,
  nonce,
  password,
  seald_flow,
  token,
  trackEvent,
  twoManRuleKey,
  updateAccount,
  userId,
  userLicenseToken,
}: SignUpProps) {
  // create instance to create identity
  await instantiateSealdSDK({
    databaseKey,
    sessionId: nonce,
    envContext,
    accountId: account.id,
  });
  if (!sealdSDKInstance) {
    console.error("Seald SDK not initialized");
    return;
  }

  // first identity associated with the email
  // create first device identity
  await sealdSDKInstance.initiateIdentity({
    userId,
    userLicenseToken,
  });

  // save main sub identity in ssks
  if (seald_flow === SEALD_FLOW_PASSWORD && password) {
    console.log("Seald signup with password");
    await sealdSDKInstance?.ssksPassword.saveIdentity({
      userId,
      password,
    });
    // the user could have been in the challenge flow before the password flow was released
    if (!identityCheck.has_2mr && !identityCheck.must_authenticate) {
      console.log("Seald signup with challenge - just to save");

      const datetime = formatUnixDate(
        new Date().getTime() / 1000,
        localeString,
        { customFormat: "dd.MM.yyyy HH:mm" },
      );

      if (isWeb()) {
        window.sessionStorage.setItem(
          LOCAL_STORAGE_KEYS.CHALLENGE_DATETIME,
          datetime,
        );
      }

      const { seald_two_man_rule_key, seald_two_man_rule_session_id } =
        await api.auth.sendChallenge2MR(token, { datetime });

      await sealdSDKInstance?.ssks2MR.saveIdentity({
        email: computeSSKSEmail({
          nonce,
          email: account.email ?? "",
          envContext,
        }),
        twoManRuleKey: seald_two_man_rule_key ?? "",
        userId,
        sessionId: seald_two_man_rule_session_id ?? "",
      });
    }
  } else {
    console.log("Seald signup with challenge");
    await sealdSDKInstance?.ssks2MR.saveIdentity({
      challenge,
      email: computeSSKSEmail({
        nonce,
        email: account.email ?? "",
        envContext,
      }),
      twoManRuleKey: twoManRuleKey ?? "",
      userId,
      sessionId: getSsksSessionId() ?? "",
    });
    if (password && !identityCheck.has_password) {
      console.log("Seald signup with password - just to save");
      await sealdSDKInstance?.ssksPassword.saveIdentity({
        userId,
        password,
      });
    }
  }
  await updateAccount?.(
    { seald_registered: true },
    { id: account.id, jwt: token },
  );

  trackEvent({
    ...getTrackerContext(),
    system_event: true,
    name: TRACK_EVENTS.SEALD_IMPORT,
    type: "first identity",
  });

  return;
}

async function signInWithPasswordSignupWithChallenge({
  account,
  challenge,
  databaseKey,
  envContext,
  getTrackerContext,
  nonce,
  password,
  token,
  trackEvent,
  twoManRuleKey,
  updateAccount,
  userId,
}: SignInWithPasswordSignupWithChallengeProps) {
  // create temporary instance to import ssks identity
  await instantiateSealdSDK({
    databaseKey: undefined,
    sessionId: undefined,
    envContext,
    accountId: undefined,
  });
  if (!sealdSDKInstance) {
    console.error("temporary Seald SDK not initialized");
    return;
  }

  if (!password) {
    console.error("no password to recover identity");
    return;
  }

  console.log("Seald signin with password to retrieve existing identity");
  await sealdSDKInstance?.ssksPassword.retrieveIdentity({
    userId,
    password,
  });

  console.log("Seald signup with challenge with the password identity");
  await sealdSDKInstance?.ssks2MR.saveIdentity({
    challenge,
    email: computeSSKSEmail({
      nonce,
      email: account.email ?? "",
      envContext,
    }),
    twoManRuleKey: twoManRuleKey ?? "",
    userId,
    sessionId: getSsksSessionId() ?? "",
    // identity: use self
  });

  // create device sub identity
  const { identity: deviceSubIdentity } =
    await sealdSDKInstance.createSubIdentity();

  // activate device sub identity
  await instantiateSealdSDK({
    databaseKey,
    sessionId: nonce,
    envContext,
    accountId: account.id,
  });
  await sealdSDKInstance.importIdentity(deviceSubIdentity);
  await updateAccount?.(
    { seald_registered: true },
    { id: account.id, jwt: token },
  );
  trackEvent({
    ...getTrackerContext(),
    name: TRACK_EVENTS.SEALD_IMPORT,
    system_event: true,
    type: "signin password signup challenge",
  });

  return;
}

export async function importIdentity(props: ImportIdentity) {
  try {
    return await importIdentityFlow(props);
  } catch (err) {
    const message = err instanceof Error ? err.message : JSON.stringify(err);
    if (
      // if the user is manually reset
      message.includes("GO_LOGIN_UNKNOWN_USER_LOGIN") ||
      // if the user interrups the login between an identity being created and
      // saved on ssks, must_authenticate will be false but the nonce will be
      // registered for the dashboard, so it needs to be rotated
      message.includes("NONCE_EXISTS") ||
      // seald bug regarding race conditions with shared accounts
      message.includes("BROKEN_INTEGRITY") ||
      // send_challenge handler says the user for 2mr key we have stored
      // does not exist in SSKS, so we need to reset it so we set create_user:true
      // the user should tentatively restore their identity through the password
      (message.includes("404") && message.includes("TMRUser")) ||
      // edge case of a user migrating from the password to the challenge flow,
      // where they kept using a locally stored identity, but their keys
      // i.e. twoManRuleKey are rotated
      message.includes("Invalid appId, userId, or twoManRuleKey")
    ) {
      await api.auth.resetIdentity(props.token);
      console.error(
        `Seald reset for account [${props.account.id}] (with challenge - ${
          props.challenge ? "yes" : "no"
        }): ${message}`,
      );
      props.trackEvent({
        name: TRACK_EVENTS.SEALD_RESET,
        error_message: message,
        account_id: props.account.id,
      });

      try {
        await sealdSDKInstance?.dropDatabase();
      } catch (err) {
        console.error("Error dropping db on reset: ", err);
      }

      if (isWeb()) {
        window.sessionStorage.setItem("logout_reason", "reset_identity");
        window.localStorage.clear();
        window.forceReload = true;
        window.location.reload();
      }
      throw new Error("reset_identity");
    } else if (
      // identity is stale / key was rotated / user needs to re-authenticate
      message.includes("BE_0003 — AUTHENTICATION_FAILED") ||
      // identity has renewed its key on another device, but not on the current one
      message.includes("BE_0053 — LOGIN_WRONG_SIGNING_PUBKEY_HASH") ||
      // local db key is wrong, needs to be dropped
      message.includes("Wrong database key") ||
      // means that the local database already has an identity
      // no need to revoke in this case, just remove the local identity
      message.includes("Already registered")
    ) {
      await sealdSDKInstance?.dropDatabase();
      // only retry if user is not logged / not restoring / token from login
      if (props.token) {
        return await importIdentityFlow({
          ...props,
          account: { ...props.account },
        });
        // otherwise throw it to the restore identity
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
}

async function importIdentityFlow({
  account,
  challenge,
  envContext,
  localeString,
  password,
  seald_flow,
  seald_force_challenge,
  token,
  trackEvent,
  twoManSessionId,
  updateAccount,
}: ImportIdentity) {
  if (seald_flow === SEALD_FLOW_DISABLED) {
    return;
  }

  if (seald_force_challenge) {
    try {
      await sealdSDKInstance?.dropDatabase();
    } catch (err) {
      console.error("Error dropping db on seald_force_challenge: ", err);
    }
  }

  // two man rule session id is passed in here exceptionally for provider search account activation
  // as we dont trigger the challenge email from the FE in that flow
  if (twoManSessionId) {
    setSsksSessionId(twoManSessionId);
  }

  const startSealdImport = performance.now();

  const getTrackerContext: GetTrackerContext = () => ({
    duration: performance.now() - startSealdImport,
    user_information: { account_id: account.id },
  });

  // check seald connection
  if (typeof fetch === "function") {
    try {
      const res = await fetch(SEALD_TEST_URL_API);
      if (res.status !== 200) throw new Error("seald down");
    } catch (err) {
      trackEvent({
        name: TRACK_EVENTS.SEALD_API_NOT_ACCESSIBLE,
        ...getTrackerContext(),
        seald_is_down: getErrorMessage(err) === "seald down",
      });

      throw new Error("seald api not accessible");
    }
  }

  const userId = computeSealdDisplayId({
    id: account.id,
    type: "account",
    envContext,
  });

  const {
    seald_database_key: databaseKey,
    seald_test_identity: testIdentity,
    seald_user_license_token: userLicenseToken,
  } = account;

  const nonce = userLicenseToken && getNonce(userLicenseToken);

  // retrieve Identity
  await instantiateSealdSDK({
    databaseKey,
    sessionId: nonce,
    envContext,
    accountId: account.id,
  });
  if (!sealdSDKInstance) {
    throw new Error("Seald SDK not initialized");
  }
  const status = await sealdSDKInstance.registrationStatus();

  // identity on device for session
  if (status === "registered") {
    await sealdSDKInstance.goatee.account.heartbeat(); // will throw if identity is stale / key was rotated / user needs to re-authenticate
    console.log("Seald identity imported from the registered device");
    if (!account.seald_registered && token) {
      await updateAccount?.(
        { seald_registered: true },
        { id: account.id, jwt: token },
      );
    }
    trackEvent({
      ...getTrackerContext(),
      system_event: true,
      name: TRACK_EVENTS.SEALD_IMPORT,
      type: "from device",
    });

    return;
  } else if (!token) {
    // no token = restoring from page refresh - should only be imported from the device
    throw new Error("No Seald identity to restore in device");
  }

  if (!userLicenseToken) {
    throw new Error("No userLicenseToken");
  }

  if (!nonce) {
    throw new Error("No nonce");
  }

  if (isDev(envContext.env) && testIdentity) {
    await sealdSDKInstance.importIdentity(Buffer.from(testIdentity, "base64"));

    const status = await sealdSDKInstance.registrationStatus();
    if (status === "registered") {
      console.log("Seald test identity imported");
    } else {
      throw new Error("Failed to import test identity");
    }

    return;
  }

  const identityCheck = await api.auth.checkIdentity(token);
  const isFreshIdentity =
    identityCheck.has_2mr === false && identityCheck.has_password === false;

  if (seald_flow === SEALD_FLOW_PASSWORD && !password) {
    throw new Error("Password flow without password");
  }

  if (seald_flow === SEALD_FLOW_PASSWORD) {
    if (isFreshIdentity) {
      await signUp({
        account,
        challenge,
        databaseKey,
        envContext,
        getTrackerContext,
        identityCheck,
        localeString,
        nonce,
        password,
        seald_flow,
        token,
        trackEvent,
        twoManRuleKey: account.seald_two_man_rule_key ?? "",
        updateAccount,
        userId,
        userLicenseToken,
      });
    } else {
      // user has seald disabled but had challenge: password flow activated
      if (!identityCheck.has_password && identityCheck.has_2mr) {
        console.error(
          `Seald reset for account [${
            account.id
          }] for identity migration (with challenge - ${
            challenge ? "yes" : "no"
          })`,
        );
        const {
          seald_database_key: newDatabaseKey,
          seald_user_license_token: newUserLicenseToken,
        } = await api.auth.resetIdentity(token);
        const newNonce = getNonce(newUserLicenseToken);
        const identityCheckAfterReset = await api.auth.checkIdentity(token); // has_2mr will be false
        await signUp({
          account,
          challenge,
          databaseKey: newDatabaseKey,
          envContext,
          getTrackerContext,
          identityCheck: identityCheckAfterReset,
          localeString,
          nonce: newNonce,
          password,
          seald_flow,
          token,
          trackEvent,
          twoManRuleKey: account.seald_two_man_rule_key ?? "",
          updateAccount,
          userId,
          userLicenseToken: newUserLicenseToken,
        });
      } else {
        await signIn({
          account,
          challenge,
          databaseKey,
          envContext,
          getTrackerContext,
          identityCheck,
          nonce,
          password,
          seald_flow,
          token,
          trackEvent,
          updateAccount,
          userId,
        });
      }
    }
    return;
  }

  if (challenge) {
    // seald_flow === SEALD_FLOW_CHALLENGE
    if (isFreshIdentity) {
      await signUp({
        account,
        challenge,
        databaseKey,
        envContext,
        getTrackerContext,
        identityCheck,
        localeString,
        nonce,
        password,
        seald_flow,
        token,
        trackEvent,
        twoManRuleKey: account.seald_two_man_rule_key ?? "",
        updateAccount,
        userId,
        userLicenseToken,
      });
    } else if (identityCheck.has_2mr) {
      await signIn({
        account,
        challenge,
        databaseKey,
        envContext,
        getTrackerContext,
        identityCheck,
        nonce,
        password,
        seald_flow,
        token,
        trackEvent,
        updateAccount,
        userId,
      });
    } else if (identityCheck.has_password && identityCheck.must_authenticate) {
      // identity was reset, retrieve the password one if possible
      await signInWithPasswordSignupWithChallenge({
        account,
        challenge,
        databaseKey,
        envContext,
        getTrackerContext,
        nonce,
        password,
        token,
        trackEvent,
        twoManRuleKey: account.seald_two_man_rule_key ?? "",
        updateAccount,
        userId,
      });
    } else {
      await signUp({
        account,
        challenge,
        databaseKey,
        envContext,
        getTrackerContext,
        identityCheck,
        localeString,
        nonce,
        password,
        seald_flow,
        token,
        trackEvent,
        twoManRuleKey: account.seald_two_man_rule_key ?? "",
        updateAccount,
        userId,
        userLicenseToken,
      });
    }

    return;
  }

  // challenge flow and no identity on device for session
  // call challenge handler
  const datetime = formatUnixDate(new Date().getTime() / 1000, localeString, {
    customFormat: "dd.MM.yyyy HH:mm",
  });
  if (isWeb()) {
    window.sessionStorage.setItem(
      LOCAL_STORAGE_KEYS.CHALLENGE_DATETIME,
      datetime,
    );
  }

  const {
    must_authenticate: mustAuthenticate,
    seald_two_man_rule_key: twoManRuleKey,
    seald_two_man_rule_session_id: twoManRuleSessionId,
  } = await api.auth.sendChallenge2MR(token, { datetime });

  setSsksSessionId(twoManRuleSessionId);

  if (mustAuthenticate) {
    trackEvent({
      ...getTrackerContext(),
      system_event: true,
      name: TRACK_EVENTS.SEALD_IMPORT,
      type: "challenge requested",
    });

    // pre-generate identity keys while the user is retrieving
    // the challenge, to increase the speed of the process
    // if it's the user's first identity retrieval on SSKS-2MR,
    // the plugin will internally trigger a renewal of the identity keys,
    // so to get the best performance we should pre-generate 2 keys
    preGenerateKeys(sealdSDKInstance, 2);
    return mustAuthenticate;
  } else {
    // if must_authenticate=false, the user should always signup (e.g. the first signup failed)
    await signUp({
      account,
      challenge,
      databaseKey,
      envContext,
      getTrackerContext,
      identityCheck,
      localeString,
      nonce,
      password,
      seald_flow,
      token,
      trackEvent,
      twoManRuleKey,
      updateAccount,
      userId,
      userLicenseToken,
    });
  }
}
