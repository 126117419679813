import SealdSDK from "@seald-io/sdk";
import SealdSDKPluginSSKS2MR from "@seald-io/sdk-plugin-ssks-2mr";
import SealdSDKPluginSSKSPassword from "@seald-io/sdk-plugin-ssks-password";
import {
  SDKConstructor,
  SealdSDK as SealdSDKType,
} from "@seald-io/sdk/lib/main";
import { getError } from "core/model/utils/errors";
import { EnvContext } from "core/types";
import { PersistedSessionCache } from "./sessionCache";
import { isWeb } from "./utils";

type SDKConfig = Parameters<SDKConstructor>[0];

export const SEALD_TEST_URL_API = "https://api.seald.io/is_alive/";
export const SEALD_TEST_URL_SSKS = "https://ssks.seald.io/is_alive/";

export let sealdSDKInstance: SealdSDKType | undefined;

/**
 * Creates the SDK configuration object.
 */
export function sdkConfig({
  accountId,
  envContext,
  sessionId,
}: {
  accountId: number | undefined;
  envContext: EnvContext;
  sessionId: string | undefined;
}): SDKConfig {
  const cacheConfig: Partial<SDKConfig> =
    isWeb() && accountId && sessionId
      ? {
          createEncryptionSessionCache: ({ dbKey }) =>
            new PersistedSessionCache({ accountId, sessionId, dbKey }),
          encryptionSessionCacheTTL: -1,
        }
      : {};

  return {
    appId: envContext.config?.seald.appId ?? "",
    apiURL: envContext.config?.seald.apiUrl ?? "",
    plugins: [
      SealdSDKPluginSSKS2MR(envContext.config?.seald.ssksUrl),
      SealdSDKPluginSSKSPassword(envContext.config?.seald.ssksUrl),
    ],
    ...cacheConfig,
    shouldWaterfallApi: false,
  };
}

/**
 * Instantiates and initializes the Seald SDK.
 */
export async function instantiateSealdSDK({
  accountId,
  databaseKey,
  envContext,
  sessionId,
}: {
  accountId: number | undefined;
  databaseKey: string | undefined;
  envContext: EnvContext;
  sessionId: string | undefined;
}) {
  if (!SealdSDK) return;
  const sdkProps = {
    ...sdkConfig({ envContext, accountId, sessionId }),
    databaseKey: databaseKey ?? undefined,
    databasePath: sessionId ? `seald-session-${sessionId}` : undefined,
  };

  sealdSDKInstance = SealdSDK(sdkProps);
  sealdSDKInstance.setLogLevel("warn");
  try {
    await sealdSDKInstance.initialize();
  } catch (err) {
    console.error(`Seald db reset on init [account_id:${accountId}]`, err);
    try {
      await sealdSDKInstance.dropDatabase();
      // dropError won't be thrown in sdk>0.27, delete this trycatch and rune2e
    } catch (dropErr) {
      const dropErrMessage =
        dropErr instanceof Error ? dropErr.message : JSON.stringify(dropErr);
      if (!dropErrMessage.includes("instance is not initialized")) {
        throw dropErr;
      }
    }
    sealdSDKInstance = SealdSDK(sdkProps);
    sealdSDKInstance.setLogLevel("warn");
    await sealdSDKInstance.initialize();
  }
}

/**
 * Helper to check that the SDK instance is registered.
 */
export const isSealdSDKRegistered = async (): Promise<boolean> => {
  const status = await sealdSDKInstance?.registrationStatus();
  return status === "registered";
};

export const getSealdSDKInstance = async (): Promise<SealdSDKType> => {
  if (!sealdSDKInstance) {
    throw new Error(`Seald SDK instance error: no instance available.`);
  }

  try {
    const isRegistered = await isSealdSDKRegistered();
    if (!isRegistered) {
      throw new Error(`Seald SDK instance status not "registered"`);
    }

    return sealdSDKInstance;
  } catch (err) {
    const error = getError(err);
    throw new Error(`get Seald SDK instance error: ${error.message}`, error);
  }
};
